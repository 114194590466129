import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
const TABLE = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const THEAD = styled.thead`
  background-color: #fbfbfb !important;
`;

const TH = styled.th`
  padding: 0.75rem;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: inherit;
`;

const TD = styled.td`
  padding: 0.75rem;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
`;

const TBODY = styled.tbody`
  > tr {
    cursor: pointer;
    :hover {
      background-color: rgba(25, 118, 210, 0.08);
    }
  }
`;

function Table({ data, columns, onRowSelect }) {
  const columnsMemo = React.useMemo(() => columns, [columns]);
  const dataMemo = React.useMemo(() => data, [data]);

  const tableInstance = useTable({ columns: columnsMemo, data: dataMemo });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    // apply the table props
    <TABLE {...getTableProps()}>
      <THEAD>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <TH {...column.getHeaderProps()}>
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </TH>
                ))
              }
            </tr>
          ))
        }
      </THEAD>
      {/* Apply the table body props */}
      <TBODY {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  onRowSelect(row.values);
                }}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <TD {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </TD>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </TBODY>
    </TABLE>
  );
}

export default Table;
