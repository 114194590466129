import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import Column from '../../ui/Column';
import Button from '../../ui/form/Button';
import ButtonGroup from '../../ui/form/ButtonGroup';
import Input from '../../ui/form/Input';
import NumberSpinner from '../../ui/form/NumberSpinner';
import Row from '../../ui/Row';
import Table from '../../ui/Table';
import { APIKEYS_BY_USER } from '../../../api/GraphQL';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import CreateEngine from '../CreateEngine';
import CreateAPI from '../CreateAPI';

const Main = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  padding: 15px;
  flex-direction: column;
`;

export default function API({ user }) {
  const { loading, data ,refetch } = useQuery(APIKEYS_BY_USER, {
    variables: { id: user._id },
    fetchPolicy: 'network-first',
  });

  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Main>
      <Button
        label="New API Key +"
        onClick={() => {
          history.push(match.url + '/create');
        }}
      />
      <Switch>
        <Route path={`${match.path}/create`}>
          <CreateAPI user={user} update={refetch}/>
        </Route>
        <Route path={`${match.path}/`}>
          {data?.apikeysByUserId && (
            <Table
              data={data?.apikeysByUserId}
              columns={[
                { Header: 'Name', accessor: 'name' },
                {
                  Header: 'Key',
                  accessor: 'key',
                },
              ]}
            />
          )}
        </Route>
      </Switch>
    </Main>
  );
}