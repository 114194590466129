import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  Button {
    border-radius: 0;
    border-right: 1px solid transparent;
  }
  Button:last-of-type {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 1px solid #c0c0c0;
  }
  Button:nth-of-type(1) {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Label = styled.label`
  display: flex;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const Buttons = styled.div`
  display: flex;
`;

const Value = styled.div`
  border: 1px solid transparent;
  border-right: none;
  border-color: #c0c0c0;
  padding: 0.5625rem 1rem;
  font-size: 0.75rem;
  line-height: 1.125;
  min-width: 20px;
  text-align: center;
`;
const MAX = Number.MAX_SAFE_INTEGER;
const LOW = -Number.MAX_SAFE_INTEGER;
export default function NumberSpinner({
  items,
  label,
  onChange = () => {},
  value,
  min_value = LOW,
  max_value = MAX,
}) {
  const [counter, setCounter] = useState(value);

  return (
    <Main>
      {label && <Label>{label}</Label>}
      <Buttons>
        <Button
          label="-"
          onClick={() => {
            let newCounter = Math.max(min_value, counter - 1);
            setCounter(newCounter);
            onChange(newCounter);
          }}
        />
        <Value>{counter}</Value>
        <Button
          label="+"
          onClick={() => {
            let newCounter = Math.min(counter + 1, max_value);
            setCounter(newCounter);
            onChange(newCounter);
          }}
        />
      </Buttons>
    </Main>
  );
}
