import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  background-color: #f2f2f2;
  height: 10px;
  border-radius: 0.625rem;
  width: 100%;
  overflow: hidden;
`;

const Inner = styled.div`
  position: relative;
  height: 10px;
  width: 50%;
  background-color: #007bff;
`;
export default function ProgressBar({ percent }) {
  return (
    <Main>
      <Inner style={{ width: percent + '%' }} />
    </Main>
  );
}
