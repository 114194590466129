import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import Button from '../ui/form/Button';
import Page, { PageTitle } from '../ui/Page';
import Create from '../user/Create';
import List from '../user/List';
import User from '../user/User';

function Users() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <Page title={'Users'}>
      <PageTitle>
        {location.pathname === '/users' && (
          <Button
            label="New User +"
            onClick={() => {
              history.push('/users/create');
            }}
          />
        )}
      </PageTitle>
      <Switch>
        <Route path={`${match.path}/create`}>
          <Create />
        </Route>
        <Route path={`${match.path}/:userid`}>
          <User />
        </Route>
        <Route path={match.path}>
          <List />
        </Route>
      </Switch>
    </Page>
  );
}

export default Users;
