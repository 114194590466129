import React from 'react';
import styled from 'styled-components';
import Row from './Row';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right 15px;
`;

const PageHeader = styled.div`
  display: flex;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`;
function Page({ children, title }) {
  let titleElement = children?.find((child) => {
    return child.type.name === 'PageTitle';
  });

  return (
    <Main>
      <Row>
        <PageHeader>{title}</PageHeader>
        {titleElement}
      </Row>
      {children?.filter((child) => child.type.name !== 'PageTitle')}
    </Main>
  );
}

const PageTitleMain = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: auto;
`;

export function PageTitle({ children, title }) {
  return <PageTitleMain>{children}</PageTitleMain>;
}

export default Page;
