const data = {
  competition: {
    _id: '6166b93be65c4ef2042bebc1',
    opta_ID: '2kwbbcootiqqgmrzs6o5inle5',
    __v: 0,
    code: 'EPL',
    competition_type: 'club',
    country: 'England',
    country_code: 'ENG',
    description: '',
    format: 'Domestic league',
    name: 'Premier League',
  },
  season: {
    _id: '6166ba57e65c4ef2042d4af1',
    opta_ID: '8l3o9v8n8tva0bb2cds2dhatw',
    __v: 2,
    active: true,
    competition: '6166b93be65c4ef2042bebc1',
    name: '2021/2022',
    start_date: '2021-08-13Z',
    teamIds: [],
    teams: [
      '6166c3d9e65c4ef20437efb7',
      '6166c3dae65c4ef20437f50a',
      '6166c3dbe65c4ef20437f59b',
      '6166c3dbe65c4ef20437f8fd',
      '6166c3dbe65c4ef20437f98c',
      '6166c3dbe65c4ef20437fa1b',
      '6166c3dce65c4ef20437fd8b',
      '6166c3dce65c4ef20437fe10',
      '6166c3dce65c4ef20437fea1',
      '6166c3dde65c4ef204380264',
      '6166c3dde65c4ef2043802ff',
      '6166c3d9e65c4ef20437efcd',
      '6166c3dae65c4ef20437f4c9',
      '6166c3dee65c4ef204380414',
      '6166c3dee65c4ef204380695',
      '6166c3dee65c4ef2043806f4',
      '6166c3dee65c4ef20438075c',
      '6166c3dfe65c4ef2043807e7',
      '6166c3dfe65c4ef204380b9d',
      '6166c3dfe65c4ef204380c3a',
    ],
  },
  teams: [
    {
      _id: '6166c3d9e65c4ef20437efb7',
      opta_ID: '1c8m2ko0wxq1asfkuykurdr0y',
      __v: 0,
      address: 'Selhurst Park, London',
      badge_name: '',
      club_name: 'Crystal Palace FC',
      code: 'CRY',
      country: 'England',
      founded: '1905',
      name: 'Crystal Palace',
      post_code: 'SE25 6PU',
      short_name: 'Crystal Palace',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3d9e65c4ef20437efcd',
      opta_ID: 'a3nyxabgsqlnqfkeg41m6tnpp',
      __v: 0,
      address: 'Rowsley Street, Manchester',
      badge_name: '',
      club_name: 'Manchester City FC',
      code: 'MCI',
      country: 'England',
      founded: '1880',
      name: 'Manchester City',
      post_code: 'M11 3FF',
      short_name: 'Man City',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dae65c4ef20437f4c9',
      opta_ID: 'avxknfz4f6ob0rv9dbnxdzde0',
      __v: 0,
      address: 'Filbert Street, Leicester',
      badge_name: '',
      club_name: 'Leicester City FC',
      code: 'LEI',
      country: 'England',
      founded: '1884',
      name: 'Leicester City',
      post_code: 'LE2 7FL',
      short_name: 'Leicester',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dae65c4ef20437f50a',
      opta_ID: '22doj4sgsocqpxw45h607udje',
      __v: 0,
      address: '748 High Road, Tottenham',
      badge_name: '',
      club_name: 'Tottenham Hotspur FC',
      code: 'TOT',
      country: 'England',
      founded: '1882',
      name: 'Tottenham Hotspur',
      post_code: 'N17 OAP',
      short_name: 'Tottenham',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dbe65c4ef20437f59b',
      opta_ID: '48gk2hpqtsl6p9sx9kjhaydq4',
      __v: 0,
      address: 'Elland Road, Leeds',
      badge_name: '',
      club_name: 'Leeds United FC',
      code: 'LEE',
      country: 'England',
      founded: '1919',
      name: 'Leeds United',
      post_code: 'LS11 0ES',
      short_name: 'Leeds',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dbe65c4ef20437f8fd',
      opta_ID: '4dsgumo7d4zupm2ugsvm4zm4d',
      __v: 0,
      address: 'Highbury House, 75 Drayton Park, London',
      badge_name: '',
      club_name: 'Arsenal FC',
      code: 'ARS',
      country: 'England',
      founded: '1886',
      name: 'Arsenal',
      post_code: 'N5 1BU',
      short_name: 'Arsenal',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dbe65c4ef20437f98c',
      opta_ID: '4t83rqbdbekinxl5fz2ygsyta',
      __v: 0,
      address: 'Vicarage Road, Watford',
      badge_name: '',
      club_name: 'Watford FC',
      code: 'WAT',
      country: 'England',
      founded: '1881',
      name: 'Watford',
      post_code: 'WD18 0ER',
      short_name: 'Watford',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dbe65c4ef20437fa1b',
      opta_ID: '4txjdaqveermfryvbfrr4taf7',
      __v: 0,
      address: 'Green Street, London',
      badge_name: '',
      club_name: 'West Ham United FC',
      code: 'WHU',
      country: 'England',
      founded: '1895',
      name: 'West Ham United',
      post_code: 'E13 9AZ',
      short_name: 'West Ham',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dce65c4ef20437fd8b',
      opta_ID: '64bxxwu2mv2qqlv0monbkj1om',
      __v: 0,
      address: 'Harry Potts Way, Burnley',
      badge_name: '',
      club_name: 'Burnley FC',
      code: 'BRN',
      country: 'England',
      founded: '1882',
      name: 'Burnley',
      post_code: 'BB10 4BX',
      short_name: 'Burnley',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dce65c4ef20437fe10',
      opta_ID: '6eqit8ye8aomdsrrq0hk3v7gh',
      __v: 0,
      address: 'Sir Matt Busby Way, Manchester',
      badge_name: '',
      club_name: 'Manchester United FC',
      code: 'MUN',
      country: 'England',
      founded: '1878',
      name: 'Manchester United',
      post_code: 'M16 0RA',
      short_name: 'Man Utd',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dce65c4ef20437fea1',
      opta_ID: '7vn2i2kd35zuetw6b38gw9jsz',
      __v: 0,
      address: "St James' Park, Newcastle Upon Tyne",
      badge_name: '',
      club_name: 'Newcastle United FC',
      code: 'NEW',
      country: 'England',
      founded: '1892',
      name: 'Newcastle United',
      post_code: 'NE1 4ST',
      short_name: 'Newcastle',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dde65c4ef204380264',
      opta_ID: '7yx5dqhhphyvfisohikodajhv',
      __v: 0,
      badge_name: '',
      club_name: 'Brentford FC',
      code: 'BRE',
      country: 'England',
      founded: '1889',
      name: 'Brentford',
      post_code: 'TW8 0NT',
      short_name: 'Brentford',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dde65c4ef2043802ff',
      opta_ID: '9q0arba2kbnywth8bkxlhgmdr',
      __v: 0,
      address: 'Fulham Road, London',
      badge_name: '',
      club_name: 'Chelsea FC',
      code: 'CHE',
      country: 'England',
      founded: '1905',
      name: 'Chelsea',
      post_code: 'SW6 1HS',
      short_name: 'Chelsea',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dee65c4ef204380414',
      opta_ID: 'b496gs285it6bheuikox6z9mj',
      __v: 0,
      address: 'Trinity Road, Birmingham',
      badge_name: '',
      club_name: 'Aston Villa FC',
      code: 'AVL',
      country: 'England',
      founded: '1874',
      name: 'Aston Villa',
      post_code: 'B6 6HE',
      short_name: 'Aston Villa',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dee65c4ef204380695',
      opta_ID: 'b9si1jn1lfxfund69e9ogcu2n',
      __v: 0,
      address: 'Waterloo Road, Wolverhampton',
      badge_name: '',
      club_name: 'Wolverhampton Wanderers FC',
      code: 'WOL',
      country: 'England',
      founded: '1877',
      name: 'Wolverhampton Wanderers',
      post_code: 'WV1 4QR',
      short_name: 'Wolves',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dee65c4ef2043806f4',
      opta_ID: 'c8h9bw1l82s06h77xxrelzhur',
      __v: 0,
      address: 'Avenell Road, London',
      badge_name: '',
      club_name: 'Liverpool FC',
      code: 'LIV',
      country: 'England',
      founded: '1892',
      name: 'Liverpool',
      post_code: 'L4 0TH',
      short_name: 'Liverpool',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dee65c4ef20438075c',
      opta_ID: 'd5ydtvt96bv7fq04yqm2w2632',
      __v: 0,
      address: 'Milton Road, Southampton',
      badge_name: '',
      club_name: 'Southampton FC',
      code: 'SOU',
      country: 'England',
      founded: '1885',
      name: 'Southampton',
      post_code: 'S014 5FP',
      short_name: 'Southampton',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dfe65c4ef2043807e7',
      opta_ID: 'e5p0ehyguld7egzhiedpdnc3w',
      __v: 0,
      badge_name: '',
      club_name: 'Brighton & Hove Albion FC',
      code: 'BHA',
      country: 'England',
      founded: '1901',
      name: 'Brighton & Hove Albion',
      post_code: 'BN1 9BL',
      short_name: 'Brighton',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dfe65c4ef204380b9d',
      opta_ID: 'ehd2iemqmschhj2ec0vayztzz',
      __v: 0,
      address: 'Goodison road, Liverpool',
      badge_name: '',
      club_name: 'Everton FC',
      code: 'EVE',
      country: 'England',
      founded: '1878',
      name: 'Everton',
      post_code: 'L4 4EL',
      short_name: 'Everton',
      status: 'active',
      type: 'club',
    },
    {
      _id: '6166c3dfe65c4ef204380c3a',
      opta_ID: 'suz80crpy3anixyzccmu6jzp',
      __v: 0,
      address: 'Carrow Road, Norwich',
      badge_name: '',
      club_name: 'Norwich City FC',
      code: 'NOR',
      country: 'England',
      founded: '1902',
      name: 'Norwich City',
      post_code: 'NR1 1JE',
      short_name: 'Norwich',
      status: 'active',
      type: 'club',
    },
  ],
  squads: [
    {
      _id: '6166e703e65c4ef20467a52c',
      player: '6166c8b0e65c4ef2043e1216',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a5ad',
      player: '6166c8b0e65c4ef2043e122e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 43,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a64a',
      player: '6166c8b0e65c4ef2043e124a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a6de',
      player: '6166c8b0e65c4ef2043e1265',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a787',
      player: '6166c8b0e65c4ef2043e1288',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 40,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a825',
      player: '6166c8b0e65c4ef2043e12a1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a8a7',
      player: '6166c8b0e65c4ef2043e12ba',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467a8bf',
      player: '6166c8b1e65c4ef2043e12d4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467a8d7',
      player: '6166c8b1e65c4ef2043e12f6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467a8f4',
      player: '6166c8b1e65c4ef2043e1317',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 46,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467a948',
      player: '6166c8b1e65c4ef2043e1351',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467a9c8',
      player: '6166c8b1e65c4ef2043e1373',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467aa5a',
      player: '6166c8b1e65c4ef2043e138c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467aaf7',
      player: '6166c8b1e65c4ef2043e13a5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467abb2',
      player: '6166c8b1e65c4ef2043e13bb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467ac52',
      player: '6166c8b1e65c4ef2043e13df',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e704e65c4ef20467acec',
      player: '6166c8b1e65c4ef2043e13fb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e705e65c4ef20467ad33',
      player: '6166c8b1e65c4ef2043e141c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467ad58',
      player: '6166c8b2e65c4ef2043e1434',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467ad7f',
      player: '6166c8b2e65c4ef2043e1454',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467ada3',
      player: '6166c8b2e65c4ef2043e1483',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 49,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467ae46',
      player: '6166c8b2e65c4ef2043e14a7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467aecd',
      player: '6166c8b2e65c4ef2043e14c9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467af93',
      player: '6166c8b2e65c4ef2043e14e3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467b04f',
      player: '6166c8b2e65c4ef2043e14fe',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467b0d9',
      player: '6166c8b2e65c4ef2043e151c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e705e65c4ef20467b15f',
      player: '6166c8b2e65c4ef2043e153d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Unknown',
      type: 'assistant coach',
    },
    {
      _id: '6166e706e65c4ef20467b1b1',
      player: '6166c8b2e65c4ef2043e156c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b1ca',
      player: '6166c8b3e65c4ef2043e1583',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b1e4',
      player: '6166c8b3e65c4ef2043e159c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b204',
      player: '6166c8b3e65c4ef2043e15b2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efb7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ef6a',
      player: '6166c8efe65c4ef2043ed6bf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 56,
    },
    {
      _id: '6166e714e65c4ef20467ef88',
      player: '6166c8efe65c4ef2043ed77f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467efa5',
      player: '6166c8efe65c4ef2043ed7ae',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467efbe',
      player: '6166c8f0e65c4ef2043ed7f7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467efe0',
      player: '6166c8f0e65c4ef2043ed813',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467effd',
      player: '6166c8f0e65c4ef2043ed836',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467f016',
      player: '6166c8f0e65c4ef2043ed871',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467f037',
      player: '6166c8f0e65c4ef2043ed88b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e715e65c4ef20467f05e',
      player: '6166c8f0e65c4ef2043ed8a4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 69,
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467f085',
      player: '6166c863e65c4ef2043da814',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467f0a1',
      player: '6166c8aee65c4ef2043e0f03',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e715e65c4ef20467f0c0',
      player: '6166c8f1e65c4ef2043ed965',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
      opta_shirt_number: 53,
    },
    {
      _id: '6166e716e65c4ef20467f0dd',
      player: '6166c8f1e65c4ef2043ed985',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 47,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f0fc',
      player: '6166c8f1e65c4ef2043ed9ac',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f124',
      player: '6166c8f1e65c4ef2043ed9cc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
      opta_shirt_number: 84,
    },
    {
      _id: '6166e716e65c4ef20467f13f',
      player: '6166c8f1e65c4ef2043ed9e9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
      opta_shirt_number: 87,
    },
    {
      _id: '6166e716e65c4ef20467f15a',
      player: '6166c85fe65c4ef2043da207',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f17a',
      player: '6166c8f1e65c4ef2043eda19',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f1a8',
      player: '6166c8f1e65c4ef2043eda3d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f1c5',
      player: '6166c8f2e65c4ef2043eda74',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e716e65c4ef20467f1df',
      player: '6166c8f2e65c4ef2043eda9b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 79,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f1f9',
      player: '6166c8f2e65c4ef2043edab4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f213',
      player: '6166c8f2e65c4ef2043edad0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f22b',
      player: '6166c8f2e65c4ef2043edb59',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f24e',
      player: '6166c8f2e65c4ef2043edb86',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f26b',
      player: '6166c8f2e65c4ef2043edbad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 94,
    },
    {
      _id: '6166e717e65c4ef20467f285',
      player: '6166c8f2e65c4ef2043edbc6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f2a9',
      player: '6166c8f3e65c4ef2043edbf5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f2c4',
      player: '6166c8f3e65c4ef2043edc12',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f2f3',
      player: '6166c8f3e65c4ef2043edc4b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 90,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f30f',
      player: '6166c8f3e65c4ef2043edc67',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 80,
      type: 'player',
    },
    {
      _id: '6166e717e65c4ef20467f352',
      player: '6166c8f3e65c4ef2043edc9d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f39e',
      player: '6166c8f3e65c4ef2043edcbd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f3be',
      player: '6166c8f3e65c4ef2043edcd8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f3e3',
      player: '6166c8f3e65c4ef2043edcf4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f402',
      player: '6166c8f3e65c4ef2043edd11',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f420',
      player: '6166c8f3e65c4ef2043edd32',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f43e',
      player: '6166c8f3e65c4ef2043edd4d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3d9e65c4ef20437efcd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 97,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467cf73',
      player: '6166c8e0e65c4ef2043ec363',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d01b',
      player: '6166c8e0e65c4ef2043ec384',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d0cf',
      player: '6166c8e0e65c4ef2043ec3a9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d166',
      player: '6166c8e0e65c4ef2043ec3c5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d217',
      player: '6166c8e0e65c4ef2043ec3e5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e70de65c4ef20467d2b3',
      player: '6166c8e0e65c4ef2043ec407',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d366',
      player: '6166c8e0e65c4ef2043ec424',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d417',
      player: '6166c8e0e65c4ef2043ec43d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e70de65c4ef20467d4c6',
      player: '6166c8e0e65c4ef2043ec456',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d50b',
      player: '6166c8e0e65c4ef2043ec46f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d523',
      player: '6166c8e0e65c4ef2043ec48d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d542',
      player: '6166c8e0e65c4ef2043ec4a6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d586',
      player: '6166c8e1e65c4ef2043ec4bc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d614',
      player: '6166c8e1e65c4ef2043ec4d8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d6b5',
      player: '6166c8e1e65c4ef2043ec4f7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d757',
      player: '6166c8e1e65c4ef2043ec51d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d816',
      player: '6166c8e1e65c4ef2043ec541',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d8d9',
      player: '6166c8e1e65c4ef2043ec561',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e70ee65c4ef20467d953',
      player: '6166c8e1e65c4ef2043ec591',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467d96a',
      player: '6166c8e1e65c4ef2043ec5aa',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467d984',
      player: '6166c8e1e65c4ef2043ec5c8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467d9a7',
      player: '6166c8e1e65c4ef2043ec5e1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467da01',
      player: '6166c8e2e65c4ef2043ec5f9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467da87',
      player: '6166c8e2e65c4ef2043ec613',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467db34',
      player: '6166c8e2e65c4ef2043ec636',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 42,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467dbda',
      player: '6166c8e2e65c4ef2043ec652',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467dc80',
      player: '6166c8e2e65c4ef2043ec679',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e70fe65c4ef20467dd24',
      player: '6166c8e2e65c4ef2043ec69b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467ddc2',
      player: '6166c8e2e65c4ef2043ec6b6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467dddb',
      player: '6166c8e2e65c4ef2043ec6cf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6186cf9fa4737fae26d2af06',
      player: '6166e624e65c4ef20465e7ee',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6186cfa1a4737fae26d2b0ec',
      player: '6166e626e65c4ef20465e9e2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6186cfa1a4737fae26d2b16f',
      player: '6166e626e65c4ef20465ea65',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f4c9',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468064a',
      player: '6166c963e65c4ef2043f73f0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468066d',
      player: '6166c963e65c4ef2043f7437',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 49,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468068f',
      player: '6166c963e65c4ef2043f7476',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef2046806a9',
      player: '6166c963e65c4ef2043f74ab',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef2046806cd',
      player: '6166c963e65c4ef2043f74d6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef2046806e7',
      player: '6166c963e65c4ef2043f7502',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef204680712',
      player: '6166c964e65c4ef2043f7547',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468072e',
      player: '6166c964e65c4ef2043f7570',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef204680749',
      player: '6166c964e65c4ef2043f75b1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680764',
      player: '6166c964e65c4ef2043f75ee',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680782',
      player: '6166c8aee65c4ef2043e0f3f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef2046807a1',
      player: '6166c964e65c4ef2043f7670',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef2046807c1',
      player: '6166c964e65c4ef2043f7698',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef2046807ea',
      player: '6166c964e65c4ef2043f76cf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680806',
      player: '6166c965e65c4ef2043f770b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680829',
      player: '6166c965e65c4ef2043f7742',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680849',
      player: '6166c965e65c4ef2043f775f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef20468086c',
      player: '6166c965e65c4ef2043f778f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e727e65c4ef204680887',
      player: '6166c965e65c4ef2043f77b0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef2046808a4',
      player: '6166c965e65c4ef2043f77fe',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef2046808cd',
      player: '6166c965e65c4ef2043f7816',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef2046808f5',
      player: '6166c965e65c4ef2043f783a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef204680917',
      player: '6166c965e65c4ef2043f7851',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef204680934',
      player: '6166c966e65c4ef2043f786e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef204680953',
      player: '6166c966e65c4ef2043f788b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef20468097c',
      player: '6166c966e65c4ef2043f78ae',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef20468099e',
      player: '6166c966e65c4ef2043f78e6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef2046809ba',
      player: '6166c966e65c4ef2043f7912',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e728e65c4ef2046809da',
      player: '6166c966e65c4ef2043f792b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef2046809f8',
      player: '6166c966e65c4ef2043f7959',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680a12',
      player: '6166c966e65c4ef2043f796f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680a30',
      player: '6166c966e65c4ef2043f7988',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680a50',
      player: '6166c967e65c4ef2043f79c5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680a86',
      player: '6166c967e65c4ef2043f79de',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680aa4',
      player: '6166c967e65c4ef2043f7a0d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680adc',
      player: '6166c968e65c4ef2043f7af5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680b04',
      player: '6166c968e65c4ef2043f7b1a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e729e65c4ef204680b20',
      player: '6166c968e65c4ef2043f7b43',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680b3e',
      player: '6166c968e65c4ef2043f7b5c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e72ae65c4ef204680b5e',
      player: '6166c968e65c4ef2043f7b78',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 47,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680b78',
      player: '6166c968e65c4ef2043f7b9b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680b9f',
      player: '6166c968e65c4ef2043f7bbb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680bca',
      player: '6166c969e65c4ef2043f7bef',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680be6',
      player: '6166c969e65c4ef2043f7c10',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6186cfb2a4737fae26d2ce10',
      player: '6186c91ba4737fae26c660b9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dae65c4ef20437f50a',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e70ae65c4ef20467c35b',
      player: '6166c8dce65c4ef2043ebfa8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e70ae65c4ef20467c373',
      player: '6166c8dce65c4ef2043ebfc3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 43,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c38b',
      player: '6166c8dde65c4ef2043ebfd9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 45,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c3ad',
      player: '6166c8dde65c4ef2043ebff7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c45b',
      player: '6166c8dde65c4ef2043ec012',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c502',
      player: '6166c86ce65c4ef2043db3c3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c5b4',
      player: '6166c8dde65c4ef2043ec047',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c659',
      player: '6166c8dde65c4ef2043ec064',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c6ea',
      player: '6166c8dde65c4ef2043ec07e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c768',
      player: '6166c8dde65c4ef2043ec096',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e70ae65c4ef20467c7d6',
      player: '6166c8dde65c4ef2043ec0ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 46,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467c7ec',
      player: '6166c8dde65c4ef2043ec0e6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467c805',
      player: '6166c8dee65c4ef2043ec0fd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467c822',
      player: '6166c8dee65c4ef2043ec116',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467c8c9',
      player: '6166c8dee65c4ef2043ec12c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 42,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467c976',
      player: '6166c8dee65c4ef2043ec143',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467ca19',
      player: '6166c8dee65c4ef2043ec163',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467cac9',
      player: '6166c8dee65c4ef2043ec187',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467cb84',
      player: '6166c8dee65c4ef2043ec19e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467cc31',
      player: '6166c8dee65c4ef2043ec1b3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e70be65c4ef20467cc54',
      player: '6166c8dee65c4ef2043ec1c8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cc6f',
      player: '6166c8dee65c4ef2043ec1e6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cc87',
      player: '6166c8dee65c4ef2043ec214',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cca6',
      player: '6166c8dfe65c4ef2043ec22e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467ccf6',
      player: '6166c8dfe65c4ef2043ec245',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cd9e',
      player: '6166c8dfe65c4ef2043ec25e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467ce15',
      player: '6166c8dfe65c4ef2043ec2ac',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cebb',
      player: '6166c8dfe65c4ef2043ec2d3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cee5',
      player: '6166c8dfe65c4ef2043ec2f9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cf05',
      player: '6166c8dfe65c4ef2043ec313',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e70ce65c4ef20467cf24',
      player: '6166c8dfe65c4ef2043ec32c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f59b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef2046751f9',
      player: '6166c859e65c4ef2043d990a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef204675226',
      player: '6166c859e65c4ef2043d9927',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef204675249',
      player: '6166c85ae65c4ef2043d9943',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'assistant coach',
    },
    {
      _id: '6166e6ede65c4ef20467527a',
      player: '6166c85ae65c4ef2043d9968',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef204675297',
      player: '6166c85ae65c4ef2043d9992',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef2046752b3',
      player: '6166c85ae65c4ef2043d99b5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e6ede65c4ef2046752d0',
      player: '6166c85ae65c4ef2043d99f2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef2046752ef',
      player: '6166c85ae65c4ef2043d9a11',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef20467530b',
      player: '6166c85ae65c4ef2043d9a37',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef20467532e',
      player: '6166c85ae65c4ef2043d9a58',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef204675356',
      player: '6166c85be65c4ef2043d9abb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef204675376',
      player: '6166c85be65c4ef2043d9b12',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef204675393',
      player: '6166c85be65c4ef2043d9b2f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef2046753b6',
      player: '6166c85be65c4ef2043d9b4c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef2046753d7',
      player: '6166c85be65c4ef2043d9b8e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef2046753ec',
      player: '6166c85be65c4ef2043d9bb3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e6eee65c4ef204675401',
      player: '6166c85be65c4ef2043d9bd0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef204675417',
      player: '6166c85be65c4ef2043d9bec',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef20467542f',
      player: '6166c85ce65c4ef2043d9c1a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef20467544d',
      player: '6166c85ce65c4ef2043d9c44',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef204675467',
      player: '6166c85ce65c4ef2043d9c67',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef20467547d',
      player: '6166c85ce65c4ef2043d9c87',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef204675499',
      player: '6166c85ce65c4ef2043d9cc6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef2046754b8',
      player: '6166c85ce65c4ef2043d9ce5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef2046754db',
      player: '6166c85ce65c4ef2043d9d08',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef2046754f2',
      player: '6166c85ce65c4ef2043d9d2e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e6efe65c4ef204675509',
      player: '6166c85ce65c4ef2043d9d4d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef204675522',
      player: '6166c85ce65c4ef2043d9d69',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef204675538',
      player: '6166c85de65c4ef2043d9d95',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef204675558',
      player: '6166c85de65c4ef2043d9de2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef204675574',
      player: '6166c85de65c4ef2043d9e08',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6f0e65c4ef20467558c',
      player: '6166c85de65c4ef2043d9e29',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef2046755a5',
      player: '6166c85de65c4ef2043d9e4a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef2046755cc',
      player: '6166c85de65c4ef2043d9e6c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6186cf88a4737fae26d28c08',
      player: '6166e5cbe65c4ef204657a95',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f8fd',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680c0f',
      player: '6166c96ee65c4ef2043f909b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680c29',
      player: '6166c96ee65c4ef2043f90e9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680c50',
      player: '6166c96ee65c4ef2043f9145',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e72ae65c4ef204680c6e',
      player: '6166c96ee65c4ef2043f91dc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680c89',
      player: '6166c96fe65c4ef2043f925f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680ca2',
      player: '6166c96fe65c4ef2043f92ff',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680cbd',
      player: '6166c96fe65c4ef2043f9398',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680cde',
      player: '6166c96fe65c4ef2043f940f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680cfb',
      player: '6166c96fe65c4ef2043f9491',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d16',
      player: '6166c96fe65c4ef2043f95db',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d3a',
      player: '6166c970e65c4ef2043f96e4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 0,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d50',
      player: '6166c970e65c4ef2043f9743',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d6e',
      player: '6166c970e65c4ef2043f9798',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d87',
      player: '6166c970e65c4ef2043f9807',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e72be65c4ef204680d9f',
      player: '6166c970e65c4ef2043f9885',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680dba',
      player: '6166c970e65c4ef2043f98ea',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680dd3',
      player: '6166c931e65c4ef2043f30ba',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 0,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680def',
      player: '6166c971e65c4ef2043f99a6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680e0c',
      player: '6166c971e65c4ef2043f99f8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680e29',
      player: '6166c971e65c4ef2043f9a62',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680e42',
      player: '6166c971e65c4ef2043f9abd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680e69',
      player: '6166c8c8e65c4ef2043e72e6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680e89',
      player: '6166c971e65c4ef2043f9b68',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e72ce65c4ef204680ea4',
      player: '6166c971e65c4ef2043f9bd3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680ebe',
      player: '6166c8c8e65c4ef2043e7399',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680ed7',
      player: '6166c972e65c4ef2043f9d11',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680ef2',
      player: '6166c86ae65c4ef2043db159',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680f21',
      player: '6166c972e65c4ef2043f9e35',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680f3c',
      player: '6166c972e65c4ef2043f9eab',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680f54',
      player: '6166c972e65c4ef2043f9f13',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680f72',
      player: '6166c8a1e65c4ef2043dff90',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680f9a',
      player: '6166c973e65c4ef2043fa082',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680fba',
      player: '6166c973e65c4ef2043fa1a6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 13,
    },
    {
      _id: '6166e72de65c4ef204680fcb',
      player: '6166c8b2e65c4ef2043e14fe',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e72de65c4ef204680fe2',
      player: '6166c973e65c4ef2043fa2bf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e72ee65c4ef204680ffb',
      player: '6166c973e65c4ef2043fa355',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e72ee65c4ef20468102c',
      player: '6166c973e65c4ef2043fa382',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 0,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef204681080',
      player: '6166c973e65c4ef2043fa3cd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef2046810a7',
      player: '6166c973e65c4ef2043fa490',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef2046810be',
      player: '6166c974e65c4ef2043fa546',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef2046810df',
      player: '6166c968e65c4ef2043f7b9b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437f98c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef2046810fe',
      player: '6166c977e65c4ef2043fb77d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef204681121',
      player: '6166c978e65c4ef2043fb845',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e72ee65c4ef204681137',
      player: '6166c978e65c4ef2043fb901',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef20468114f',
      player: '6166c978e65c4ef2043fb9b3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681169',
      player: '6166c978e65c4ef2043fba5f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681182',
      player: '6166c978e65c4ef2043fbb05',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef2046811a2',
      player: '6166c978e65c4ef2043fbb33',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 75,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef2046811bc',
      player: '6166c978e65c4ef2043fbb56',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef2046811e4',
      player: '6166c978e65c4ef2043fbba3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681200',
      player: '6166c978e65c4ef2043fbbdf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681217',
      player: '6166c978e65c4ef2043fbc7b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 41,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681237',
      player: '6166c979e65c4ef2043fbd2d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681258',
      player: '6166c979e65c4ef2043fbde6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e72fe65c4ef204681273',
      player: '6166c979e65c4ef2043fbe90',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef204681287',
      player: '6166c89de65c4ef2043dfa81',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef2046812a2',
      player: '6166c979e65c4ef2043fbfd9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef2046812ba',
      player: '6166c979e65c4ef2043fbfff',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef2046812db',
      player: '6166c979e65c4ef2043fc02b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e730e65c4ef2046812f8',
      player: '6166c979e65c4ef2043fc071',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef20468130e',
      player: '6166c979e65c4ef2043fc12c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 42,
    },
    {
      _id: '6166e730e65c4ef20468132f',
      player: '6166c921e65c4ef2043f1b69',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef204681345',
      player: '6166c97ae65c4ef2043fc267',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef204681363',
      player: '6166c97ae65c4ef2043fc31e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e730e65c4ef20468137d',
      player: '6166c97ae65c4ef2043fc47f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef204681394',
      player: '6166c97ae65c4ef2043fc498',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef2046813aa',
      player: '6166c97ae65c4ef2043fc4cc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef2046813c0',
      player: '6166c97ae65c4ef2043fc506',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef2046813dd',
      player: '6166c97ae65c4ef2043fc522',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef204681405',
      player: '6166c97ae65c4ef2043fc59b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Unknown',
      type: 'assistant coach',
    },
    {
      _id: '6166e731e65c4ef20468141d',
      player: '6166c97ae65c4ef2043fc663',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6186cfb8a4737fae26d2d7d1',
      player: '6166e68be65c4ef20466d76e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
    },
    {
      _id: '6186cfb9a4737fae26d2da2f',
      player: '6166c979e65c4ef2043fc057',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dbe65c4ef20437fa1b',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 50,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef204678672',
      player: '6166c887e65c4ef2043ddb62',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef204678706',
      player: '6166c887e65c4ef2043ddb82',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef2046787ab',
      player: '6166c888e65c4ef2043ddb9d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef204678827',
      player: '6166c888e65c4ef2043ddbc5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef2046788be',
      player: '6166c888e65c4ef2043ddbe6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef2046788ff',
      player: '6166c888e65c4ef2043ddc11',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678919',
      player: '6166c888e65c4ef2043ddc36',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678946',
      player: '6166c888e65c4ef2043ddc57',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef2046789cb',
      player: '6166c888e65c4ef2043ddc88',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678a23',
      player: '6166c888e65c4ef2043ddcae',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678a47',
      player: '6166c888e65c4ef2043ddce9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678a68',
      player: '6166c888e65c4ef2043ddd41',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678a8a',
      player: '6166c888e65c4ef2043ddd68',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678ad6',
      player: '6166c889e65c4ef2043ddd83',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6fde65c4ef204678b78',
      player: '6166c889e65c4ef2043ddda6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678c07',
      player: '6166c889e65c4ef2043dddc8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678cc3',
      player: '6166c889e65c4ef2043dddf4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678d72',
      player: '6166c889e65c4ef2043dde12',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678e20',
      player: '6166c889e65c4ef2043dde36',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678ec8',
      player: '6166c889e65c4ef2043dde53',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678f54',
      player: '6166c889e65c4ef2043dde6d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef204678ff6',
      player: '6166c889e65c4ef2043dde95',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef2046790a8',
      player: '6166c889e65c4ef2043ddeb0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e6fee65c4ef20467913c',
      player: '6166c889e65c4ef2043ddec9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Unknown',
      type: 'assistant coach',
    },
    {
      _id: '6166e6fee65c4ef2046791ce',
      player: '6166c88ae65c4ef2043ddee4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef20467920a',
      player: '6166c88ae65c4ef2043ddf00',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef204679224',
      player: '6166c88ae65c4ef2043ddf21',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6ffe65c4ef20467924c',
      player: '6166c88ae65c4ef2043ddf45',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef2046792b1',
      player: '6166c88ae65c4ef2043ddf62',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fd8b',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f468',
      player: '6166c8f4e65c4ef2043edd6c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e718e65c4ef20467f491',
      player: '6166c8f4e65c4ef2043edd89',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e718e65c4ef20467f4ad',
      player: '6166c8f4e65c4ef2043eddab',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f4c9',
      player: '6166c8f4e65c4ef2043ede06',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f4e2',
      player: '6166c8f4e65c4ef2043ede25',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f501',
      player: '6166c8f4e65c4ef2043ede49',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f524',
      player: '6166c8f5e65c4ef2043ede62',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f543',
      player: '6166c8f5e65c4ef2043ede7d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f55f',
      player: '6166c8f5e65c4ef2043edeb1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f595',
      player: '6166c8f5e65c4ef2043edee4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f5b6',
      player: '6166c8f5e65c4ef2043edf11',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f5da',
      player: '6166c8f5e65c4ef2043edf31',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f5f4',
      player: '6166c8f5e65c4ef2043edf4d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e719e65c4ef20467f610',
      player: '6166c8f5e65c4ef2043edf68',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f62f',
      player: '6166c8f5e65c4ef2043edf89',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f64f',
      player: '6166c8f6e65c4ef2043edfc0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f671',
      player: '6166c8f6e65c4ef2043edfe4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f690',
      player: '6166c8f6e65c4ef2043ee00b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f6af',
      player: '6166c8f6e65c4ef2043ee02b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f6cc',
      player: '6166c8f6e65c4ef2043ee05e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f6f3',
      player: '6166c8f6e65c4ef2043ee07f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f71a',
      player: '6166c8f6e65c4ef2043ee0a0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f735',
      player: '6166c8f6e65c4ef2043ee0ba',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e71ae65c4ef20467f74f',
      player: '6166c8f6e65c4ef2043ee0d7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f770',
      player: '6166c8f6e65c4ef2043ee0fd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 46,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f78d',
      player: '6166c8f6e65c4ef2043ee11b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f7b3',
      player: '6166c8f7e65c4ef2043ee135',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 47,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f7d5',
      player: '6166c8f7e65c4ef2043ee15a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 43,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f7ff',
      player: '6166c8f7e65c4ef2043ee177',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f822',
      player: '6166c8f7e65c4ef2043ee196',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f840',
      player: '6166c8f7e65c4ef2043ee1dd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f85d',
      player: '6166c8f7e65c4ef2043ee1fa',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f895',
      player: '6166c8f7e65c4ef2043ee216',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e71be65c4ef20467f976',
      player: '6166c8dfe65c4ef2043ec313',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467f994',
      player: '6166c8f7e65c4ef2043ee253',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467f9e3',
      player: '6166c8f8e65c4ef2043ee272',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fa00',
      player: '6166c8f8e65c4ef2043ee28f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fa22',
      player: '6166c8f8e65c4ef2043ee2d0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fa50',
      player: '6166c8f8e65c4ef2043ee2f6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fe10',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fa70',
      player: '6166c907e65c4ef2043ef88c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fa96',
      player: '6166c907e65c4ef2043ef8ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fab0',
      player: '6166c907e65c4ef2043ef8cc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fad4',
      player: '6166c908e65c4ef2043ef8e3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467faf4',
      player: '6166c908e65c4ef2043ef8fb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e71ce65c4ef20467fb0f',
      player: '6166c908e65c4ef2043ef918',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Unknown',
      type: 'assistant coach',
    },
    {
      _id: '6166e71de65c4ef20467fb28',
      player: '6166c85be65c4ef2043d9ae7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fb42',
      player: '6166c908e65c4ef2043ef94a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fb66',
      player: '6166c908e65c4ef2043ef96f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fba2',
      player: '6166c908e65c4ef2043ef98b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fbc1',
      player: '6166c908e65c4ef2043ef9a4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fbe2',
      player: '6166c908e65c4ef2043ef9bf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fbfb',
      player: '6166c908e65c4ef2043ef9d9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fc26',
      player: '6166c908e65c4ef2043ef9f9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e71de65c4ef20467fc41',
      player: '6166c909e65c4ef2043efa1b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fc5d',
      player: '6166c909e65c4ef2043efa32',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fc8c',
      player: '6166c909e65c4ef2043efa4d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fca8',
      player: '6166c909e65c4ef2043efa69',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fcce',
      player: '6166c909e65c4ef2043efa8b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fced',
      player: '6166c909e65c4ef2043efaba',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fd0a',
      player: '6166c909e65c4ef2043efad0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fd24',
      player: '6166c909e65c4ef2043efaea',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 56,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fd46',
      player: '6166c909e65c4ef2043efb03',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fd69',
      player: '6166c909e65c4ef2043efb1d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e71ee65c4ef20467fd86',
      player: '6166c909e65c4ef2043efb37',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e71ee65c4ef20467fda3',
      player: '6166c90ae65c4ef2043efb55',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fdbc',
      player: '6166c90ae65c4ef2043efb6c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fdd7',
      player: '6166c90ae65c4ef2043efbaa',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Unknown',
      type: 'assistant coach',
    },
    {
      _id: '6166e71fe65c4ef20467fdf8',
      player: '6166c90ae65c4ef2043efbc6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fe18',
      player: '6166c90ae65c4ef2043efbf7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fe33',
      player: '6166c90ae65c4ef2043efc0e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fe56',
      player: '6166c90ae65c4ef2043efc2a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e71fe65c4ef20467fe9c',
      player: '6166c90ae65c4ef2043efc43',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6186cfaca4737fae26d2c268',
      player: '6166e748e65c4ef204683133',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dce65c4ef20437fea1',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6f4e65c4ef2046760b5',
      player: '6166c879e65c4ef2043dc7ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6f4e65c4ef20467614c',
      player: '6166c879e65c4ef2043dc7c8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef2046761ec',
      player: '6166c87ae65c4ef2043dc7e3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef20467627d',
      player: '6166c87ae65c4ef2043dc7fd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef204676330',
      player: '6166c87ae65c4ef2043dc837',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef2046763b9',
      player: '6166c87ae65c4ef2043dc856',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef204676446',
      player: '6166c87ae65c4ef2043dc873',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef2046764a4',
      player: '6166c87ae65c4ef2043dc88e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef2046764ba',
      player: '6166c87ae65c4ef2043dc8a9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef2046764dd',
      player: '6166c87ae65c4ef2043dc8c6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef204676549',
      player: '6166c87ae65c4ef2043dc8ee',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef2046765f4',
      player: '6166c87ae65c4ef2043dc908',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef204676689',
      player: '6166c87ae65c4ef2043dc921',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef204676743',
      player: '6166c87be65c4ef2043dc93d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef2046767cf',
      player: '6166c87be65c4ef2043dc95a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e6f5e65c4ef204676863',
      player: '6166c87be65c4ef2043dc985',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676907',
      player: '6166c87be65c4ef2043dc9a6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676926',
      player: '6166c87be65c4ef2043dc9cb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676946',
      player: '6166c87be65c4ef2043dc9e8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676975',
      player: '6166c87be65c4ef2043dca06',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676a19',
      player: '6166c87be65c4ef2043dca25',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676aa3',
      player: '6166c87be65c4ef2043dca47',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676b35',
      player: '6166c87be65c4ef2043dca60',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 40,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676bc1',
      player: '6166c87be65c4ef2043dca78',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676c4f',
      player: '6166c87ce65c4ef2043dca92',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e6f6e65c4ef204676ce3',
      player: '6166c87ce65c4ef2043dcad4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676d69',
      player: '6166c87ce65c4ef2043dcb2b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676d7f',
      player: '6166c87ce65c4ef2043dcb5a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676d9d',
      player: '6166c87ce65c4ef2043dcb76',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676e25',
      player: '6166c87ce65c4ef2043dcb93',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676ed1',
      player: '6166c87ce65c4ef2043dcbad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676f45',
      player: '6166c87ce65c4ef2043dcbd1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204676fd4',
      player: '6166c87ce65c4ef2043dcbf5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef204677068',
      player: '6166c87ce65c4ef2043dcc0e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef2046770fe',
      player: '6166c87ce65c4ef2043dcc2b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e6f7e65c4ef20467718b',
      player: '6166c87de65c4ef2043dcc45',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6186cf8da4737fae26d29374',
      player: '6186b4efa4737fae26aa5747',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6186cf8ea4737fae26d2944b',
      player: '6186b4f0a4737fae26aa5869',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 41,
      type: 'player',
    },
    {
      _id: '6186cf8fa4737fae26d295b2',
      player: '6186b4f1a4737fae26aa5a19',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef204380264',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef20467937a',
      player: '6166c89ae65c4ef2043df714',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'assistant coach',
    },
    {
      _id: '6166e6ffe65c4ef20467943e',
      player: '6166c89be65c4ef2043df72e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef2046794dc',
      player: '6166c89be65c4ef2043df770',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e6ffe65c4ef20467958b',
      player: '6166c89be65c4ef2043df78e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 31,
    },
    {
      _id: '6166e6ffe65c4ef204679637',
      player: '6166c89be65c4ef2043df7af',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef2046796aa',
      player: '6166c89be65c4ef2043df7e9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef2046796c4',
      player: '6166c89be65c4ef2043df821',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef2046796e5',
      player: '6166c89be65c4ef2043df83a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef204679753',
      player: '6166c89be65c4ef2043df855',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef204679819',
      player: '6166c89ce65c4ef2043df8c5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef2046798b1',
      player: '6166c89ce65c4ef2043df8e5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef204679962',
      player: '6166c89ce65c4ef2043df936',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef204679a33',
      player: '6166c89ce65c4ef2043df952',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e700e65c4ef204679ac9',
      player: '6166c89ce65c4ef2043df972',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679b69',
      player: '6166c89ce65c4ef2043df98b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679b82',
      player: '6166c89ce65c4ef2043df9a4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679ba0',
      player: '6166c89de65c4ef2043df9c4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679c33',
      player: '6166c89de65c4ef2043dfa06',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679cca',
      player: '6166c89de65c4ef2043dfa2c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679d65',
      player: '6166c89de65c4ef2043dfa47',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679e30',
      player: '6166c89de65c4ef2043dfa6a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e701e65c4ef204679e95',
      player: '6166c89de65c4ef2043dfa81',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef204679f37',
      player: '6166c89de65c4ef2043dfabc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef204679fdc',
      player: '6166c89de65c4ef2043dfaf1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a03a',
      player: '6166c89ee65c4ef2043dfb0a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a08d',
      player: '6166c89ee65c4ef2043dfb25',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a12c',
      player: '6166c89ee65c4ef2043dfb43',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
      opta_shirt_number: 43,
    },
    {
      _id: '6166e702e65c4ef20467a1b6',
      player: '6166c89ee65c4ef2043dfb7c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e702e65c4ef20467a225',
      player: '6166c89ee65c4ef2043dfba2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a2c5',
      player: '6166c89ee65c4ef2043dfbc7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a35a',
      player: '6166c89ee65c4ef2043dfbe3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e702e65c4ef20467a403',
      player: '6166c89ee65c4ef2043dfc03',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 29,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a469',
      player: '6166c89ee65c4ef2043dfc25',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a47e',
      player: '6166c89ee65c4ef2043dfc3d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e703e65c4ef20467a49c',
      player: '6166c89fe65c4ef2043dfc6e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6186cf97a4737fae26d2a165',
      player: '6166e5f2e65c4ef20465aa75',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6186cf97a4737fae26d2a2a3',
      player: '6166e5f3e65c4ef20465abdb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dde65c4ef2043802ff',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef2046755f4',
      player: '6166c85de65c4ef2043d9e91',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef20467560d',
      player: '6166c85de65c4ef2043d9eac',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 50,
      type: 'player',
    },
    {
      _id: '6166e6f0e65c4ef204675624',
      player: '6166c85ee65c4ef2043d9ef7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 46,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef20467563e',
      player: '6166c85ee65c4ef2043d9f47',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 40,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef204675654',
      player: '6166c85ee65c4ef2043d9f67',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef204675681',
      player: '6166c85ee65c4ef2043d9fa7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef2046756a5',
      player: '6166c85ee65c4ef2043da07c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef2046756bc',
      player: '6166c85ee65c4ef2043da0e0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef2046756d7',
      player: '6166c85fe65c4ef2043da0fd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef2046756f7',
      player: '6166c85fe65c4ef2043da127',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef204675722',
      player: '6166c85fe65c4ef2043da156',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef20467573c',
      player: '6166c85fe65c4ef2043da17f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef204675753',
      player: '6166c85fe65c4ef2043da19c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e6f1e65c4ef20467576c',
      player: '6166c85fe65c4ef2043da249',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675783',
      player: '6166c85fe65c4ef2043da267',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef20467579d',
      player: '6166c85fe65c4ef2043da284',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef2046757bf',
      player: '6166c860e65c4ef2043da2c3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675823',
      player: '6166c860e65c4ef2043da2f8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef2046758b5',
      player: '6166c860e65c4ef2043da31f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675961',
      player: '6166c860e65c4ef2043da342',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675a1a',
      player: '6166c860e65c4ef2043da387',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 36,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675aa6',
      player: '6166c860e65c4ef2043da3d2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675b45',
      player: '6166c860e65c4ef2043da3f0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e6f2e65c4ef204675bcf',
      player: '6166c861e65c4ef2043da498',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675be5',
      player: '6166c861e65c4ef2043da4b7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675bfe',
      player: '6166c861e65c4ef2043da4d5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 41,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675c1d',
      player: '6166c861e65c4ef2043da4ff',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675cbe',
      player: '6166c861e65c4ef2043da525',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675d51',
      player: '6166c861e65c4ef2043da567',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675e0f',
      player: '6166c861e65c4ef2043da588',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675e99',
      player: '6166c862e65c4ef2043da5ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'assistant coach',
    },
    {
      _id: '6166e6f3e65c4ef204675f43',
      player: '6166c862e65c4ef2043da5dd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e6f3e65c4ef204675fec',
      player: '6166c862e65c4ef2043da606',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6f3e65c4ef204676043',
      player: '6166c862e65c4ef2043da625',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 49,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef20467605e',
      player: '6166c862e65c4ef2043da647',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e6f4e65c4ef204676077',
      player: '6166c862e65c4ef2043da66c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380414',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef204681445',
      player: '6166c97fe65c4ef2043fd913',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef20468145c',
      player: '6166c97fe65c4ef2043fd995',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef204681481',
      player: '6166c97fe65c4ef2043fda0d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 49,
      type: 'player',
    },
    {
      _id: '6166e731e65c4ef204681499',
      player: '6166c97fe65c4ef2043fda70',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046814af',
      player: '6166c97fe65c4ef2043fdaf1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046814cb',
      player: '6166c97fe65c4ef2043fdd78',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046814f3',
      player: '6166c945e65c4ef2043f4e9e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef204681516',
      player: '6166c980e65c4ef2043fded9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef204681531',
      player: '6166c980e65c4ef2043fdf87',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef20468154c',
      player: '6166c980e65c4ef2043fe028',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef20468156f',
      player: '6166c980e65c4ef2043fe0cf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef204681585',
      player: '6166c980e65c4ef2043fe190',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046815a3',
      player: '6166c980e65c4ef2043fe1ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046815c0',
      player: '6166c980e65c4ef2043fe1c9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e732e65c4ef2046815d9',
      player: '6166c980e65c4ef2043fe210',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef2046815f0',
      player: '6166c981e65c4ef2043fe37b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef204681609',
      player: '6166c981e65c4ef2043fe433',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef204681620',
      player: '6166c981e65c4ef2043fe4f0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e733e65c4ef20468163f',
      player: '6166c981e65c4ef2043fe5c2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef20468165b',
      player: '6166c981e65c4ef2043fe627',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef204681672',
      player: '6166c981e65c4ef2043fe64a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef204681691',
      player: '6166c981e65c4ef2043fe667',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef2046816be',
      player: '6166c981e65c4ef2043fe685',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 77,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef2046816dd',
      player: '6166c981e65c4ef2043fe6e1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e733e65c4ef2046816fb',
      player: '6166c982e65c4ef2043fe7a2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 39,
      type: 'player',
    },
    {
      _id: '6166e734e65c4ef204681714',
      player: '6166c982e65c4ef2043fe83c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 75,
      type: 'player',
    },
    {
      _id: '6166e734e65c4ef20468172d',
      player: '6166c982e65c4ef2043fe8f3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 62,
      type: 'player',
    },
    {
      _id: '6166e734e65c4ef20468174a',
      player: '6166c982e65c4ef2043fe9c0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 37,
      type: 'player',
    },
    {
      _id: '6166e734e65c4ef204681769',
      player: '6166c982e65c4ef2043fea91',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e734e65c4ef20468178a',
      player: '6166c982e65c4ef2043feadb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6186cfbba4737fae26d2dd84',
      player: '6186c936a4737fae26c68842',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef204380695',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 48,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467ddfd',
      player: '6166c8e8e65c4ef2043ecdcb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 66,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467de75',
      player: '6166c8e8e65c4ef2043ecde2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467df01',
      player: '6166c8e8e65c4ef2043ecdf7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 46,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467df90',
      player: '6166c8e8e65c4ef2043ece10',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467e039',
      player: '6166c8e8e65c4ef2043ece36',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467e0f5',
      player: '6166c8e8e65c4ef2043ece4c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e710e65c4ef20467e193',
      player: '6166c8e9e65c4ef2043ece74',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e212',
      player: '6166c8e9e65c4ef2043ece8e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e22b',
      player: '6166c8e9e65c4ef2043eceaa',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e24a',
      player: '6166c8e9e65c4ef2043ecec2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 76,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e2a6',
      player: '6166c8e9e65c4ef2043ecef7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e340',
      player: '6166c8e9e65c4ef2043ecf0f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e3e6',
      player: '6166c8e9e65c4ef2043ecf28',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e490',
      player: '6166c8e9e65c4ef2043ecf40',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e533',
      player: '6166c8e9e65c4ef2043ecf5a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e711e65c4ef20467e5eb',
      player: '6166c8e9e65c4ef2043ecf92',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e712e65c4ef20467e662',
      player: '6166c8eae65c4ef2043ecfa7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e712e65c4ef20467e67f',
      player: '6166c8eae65c4ef2043ecfc0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e712e65c4ef20467e697',
      player: '6166c8eae65c4ef2043ecfe0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e712e65c4ef20467e6b8',
      player: '6166c8eae65c4ef2043ecffc',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e712e65c4ef20467e731',
      player: '6166c8eae65c4ef2043ed01e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 47,
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467eafa',
      player: '6166c8eae65c4ef2043ed03e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467eb2a',
      player: '6166c8eae65c4ef2043ed071',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467ebe5',
      player: '6166c8eae65c4ef2043ed0a6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e713e65c4ef20467ec6c',
      player: '6166c8eae65c4ef2043ed0e7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467ed19',
      player: '6166c8ebe65c4ef2043ed166',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467edbc',
      player: '6166c8ebe65c4ef2043ed188',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467ee15',
      player: '6166c8ebe65c4ef2043ed1b6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e713e65c4ef20467ee33',
      player: '6166c8ebe65c4ef2043ed1d9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ee60',
      player: '6166c8ebe65c4ef2043ed1f9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ee79',
      player: '6166c8ebe65c4ef2043ed211',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 58,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ee9d',
      player: '6166c8ebe65c4ef2043ed243',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 62,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467eec0',
      player: '6166c8ebe65c4ef2043ed263',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467eee5',
      player: '6166c8ebe65c4ef2043ed27c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 67,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ef06',
      player: '6166c8ece65c4ef2043ed293',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e714e65c4ef20467ef29',
      player: '6166c8ece65c4ef2043ed2b5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
      opta_shirt_number: 80,
    },
    {
      _id: '6166e714e65c4ef20467ef45',
      player: '6166c8ece65c4ef2043ed2d1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6186cfa3a4737fae26d2b3fe',
      player: '6186b4dea4737fae26aa3e35',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6186cfa3a4737fae26d2b454',
      player: '6166e62de65c4ef20465f416',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6186cfa4a4737fae26d2b59e',
      player: '6166c8eae65c4ef2043ed08b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6186cfa4a4737fae26d2b632',
      player: '6166e62fe65c4ef20465f65b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6186cfa4a4737fae26d2b6c8',
      player: '6166c8ebe65c4ef2043ed22b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6186cfa5a4737fae26d2b721',
      player: '6186c89fa4737fae26c54048',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef2043806f4',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef20468028f',
      player: '6166c94ce65c4ef2043f5785',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef2046802ae',
      player: '6166c94ce65c4ef2043f57b2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef2046802ca',
      player: '6166c94ce65c4ef2043f57cd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef2046802ec',
      player: '6166c94de65c4ef2043f57ee',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef204680319',
      player: '6166c94de65c4ef2043f581c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef20468033a',
      player: '6166c94de65c4ef2043f588b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef204680359',
      player: '6166c8bbe65c4ef2043e31bf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef204680373',
      player: '6166c94de65c4ef2043f58c2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef204680390',
      player: '6166c89ce65c4ef2043df89e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef2046803b0',
      player: '6166c94de65c4ef2043f58e9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef2046803d0',
      player: '6166c94de65c4ef2043f5907',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef2046803ed',
      player: '6166c94ee65c4ef2043f5920',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef20468040c',
      player: '6166c94ee65c4ef2043f593d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef204680431',
      player: '6166c94ee65c4ef2043f5953',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef204680458',
      player: '6166c94ee65c4ef2043f596f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'assistant coach',
    },
    {
      _id: '6166e724e65c4ef20468047c',
      player: '6166c94ee65c4ef2043f5992',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 43,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef204680498',
      player: '6166c94ee65c4ef2043f59b5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e724e65c4ef2046804b3',
      player: '6166c94ee65c4ef2043f59d0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e725e65c4ef2046804dc',
      player: '6166c94ee65c4ef2043f5a09',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef2046804f7',
      player: '6166c94ee65c4ef2043f5a23',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef20468051c',
      player: '6166c94ee65c4ef2043f5a42',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 41,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef20468053b',
      player: '6166c94fe65c4ef2043f5a5d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef204680569',
      player: '6166c94fe65c4ef2043f5a79',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef204680588',
      player: '6166c94fe65c4ef2043f5a94',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef2046805a9',
      player: '6166c86fe65c4ef2043db739',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef2046805ce',
      player: '6166c94fe65c4ef2043f5ad7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e725e65c4ef2046805ef',
      player: '6166c94fe65c4ef2043f5af8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468060b',
      player: '6166c94fe65c4ef2043f5b14',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e726e65c4ef20468062a',
      player: '6166c94fe65c4ef2043f5b42',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dee65c4ef20438075c',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef2046771ef',
      player: '6166c87de65c4ef2043dcc69',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef204677208',
      player: '6166c86ce65c4ef2043db3de',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef204677228',
      player: '6166c87de65c4ef2043dccb1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 53,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef204677293',
      player: '6166c87de65c4ef2043dcccd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef20467730e',
      player: '6166c87de65c4ef2043dccf7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef20467739b',
      player: '6166c87de65c4ef2043dcd13',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef204677447',
      player: '6166c87de65c4ef2043dcd32',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef20467753b',
      player: '6166c87de65c4ef2043dcd56',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e6f8e65c4ef2046775c9',
      player: '6166c87de65c4ef2043dcd70',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e6f9e65c4ef204677665',
      player: '6166c87de65c4ef2043dcd8c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef2046776c8',
      player: '6166c87ee65c4ef2043dcdc9',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef2046776eb',
      player: '6166c87ee65c4ef2043dcdee',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 55,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677714',
      player: '6166c87ee65c4ef2043dce2b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef2046777ac',
      player: '6166c87ee65c4ef2043dce49',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677836',
      player: '6166c87ee65c4ef2043dce67',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef2046778cf',
      player: '6166c87ee65c4ef2043dce8a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677972',
      player: '6166c87ee65c4ef2043dcea8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677a0f',
      player: '6166c87ee65c4ef2043dcecd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677a7c',
      player: '6166c87ee65c4ef2043dceeb',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e6f9e65c4ef204677af5',
      player: '6166c87ee65c4ef2043dcf06',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677b3e',
      player: '6166c87fe65c4ef2043dcf25',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677b54',
      player: '6166c87fe65c4ef2043dcf49',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 60,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677b74',
      player: '6166c87fe65c4ef2043dcf66',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677bfc',
      player: '6166c87fe65c4ef2043dcf97',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677c95',
      player: '6166c87fe65c4ef2043dcfb8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677d2c',
      player: '6166c87fe65c4ef2043dcfd6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 42,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677dd1',
      player: '6166c87fe65c4ef2043dd00f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677e74',
      player: '6166c87fe65c4ef2043dd034',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 58,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677ef6',
      player: '6166c87fe65c4ef2043dd050',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e6fae65c4ef204677f8c',
      player: '6166c87fe65c4ef2043dd06b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef204677fd8',
      player: '6166c880e65c4ef2043dd08d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef204677fee',
      player: '6166c880e65c4ef2043dd0ad',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef204678012',
      player: '6166c880e65c4ef2043dd0d4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'assistant coach',
    },
    {
      _id: '6166e6fbe65c4ef204678086',
      player: '6166c880e65c4ef2043dd100',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef204678116',
      player: '6166c880e65c4ef2043dd11f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 59,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef2046781c2',
      player: '6166c880e65c4ef2043dd13d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef20467826f',
      player: '6166c880e65c4ef2043dd159',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef20467830b',
      player: '6166c880e65c4ef2043dd17c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 38,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef204678396',
      player: '6166c880e65c4ef2043dd19b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 54,
      type: 'player',
    },
    {
      _id: '6166e6fbe65c4ef20467841e',
      player: '6166c880e65c4ef2043dd1b7',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef20467846b',
      player: '6166c881e65c4ef2043dd1d1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef204678488',
      player: '6166c881e65c4ef2043dd1ef',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef2046784a7',
      player: '6166c881e65c4ef2043dd20f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef204678520',
      player: '6166c881e65c4ef2043dd238',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 57,
      type: 'player',
    },
    {
      _id: '6166e6fce65c4ef2046785a7',
      player: '6166c881e65c4ef2043dd252',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef2043807e7',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b2cb',
      player: '6166c8bae65c4ef2043e2cb2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b377',
      player: '6166c8bae65c4ef2043e2cd6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 32,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b427',
      player: '6166c8bae65c4ef2043e2cf3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b4d5',
      player: '6166c8bae65c4ef2043e2d55',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b581',
      player: '6166c8bae65c4ef2043e2e20',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e706e65c4ef20467b5fb',
      player: '6166c8bae65c4ef2043e2edd',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b615',
      player: '6166c8bbe65c4ef2043e2f8e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b62b',
      player: '6166c8bbe65c4ef2043e3048',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 27,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b64d',
      player: '6166c8bbe65c4ef2043e3106',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 50,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b674',
      player: '6166c8bbe65c4ef2043e314e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 57,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b6e7',
      player: '6166c8bbe65c4ef2043e317a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b76f',
      player: '6166c8bbe65c4ef2043e31a0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b7eb',
      player: '6166c8bbe65c4ef2043e31db',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b863',
      player: '6166c8bbe65c4ef2043e32ae',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 61,
      type: 'player',
    },
    {
      _id: '6166e707e65c4ef20467b8f7',
      player: '6166c8bbe65c4ef2043e333b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467b98c',
      player: '6166c8bce65c4ef2043e33bf',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467ba22',
      player: '6166c8bce65c4ef2043e343f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 34,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467baae',
      player: '6166c8bce65c4ef2043e34c5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 31,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bac9',
      player: '6166c8bce65c4ef2043e35e5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bb65',
      player: '6166c8bce65c4ef2043e365b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bbff',
      player: '6166c8bce65c4ef2043e367b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bca8',
      player: '6166c8bce65c4ef2043e3697',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bd43',
      player: '6166c8bce65c4ef2043e386c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467bde4',
      player: '6166c8bde65c4ef2043e391d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e708e65c4ef20467be82',
      player: '6166c8bde65c4ef2043e3ac8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e709e65c4ef20467bedb',
      player: '6166c8bde65c4ef2043e3b0f',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467bef1',
      player: '6166c8bde65c4ef2043e3b2d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467bf0a',
      player: '6166c8bde65c4ef2043e3b5b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467bf2c',
      player: '6166c8bde65c4ef2043e3b7a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467bfc8',
      player: '6166c8bde65c4ef2043e3cf8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 13,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467c068',
      player: '6166c8bde65c4ef2043e3db0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467c119',
      player: '6166c8bee65c4ef2043e3e58',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467c1c0',
      player: '6166c8bee65c4ef2043e3ef3',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467c266',
      player: '6166c8bee65c4ef2043e3f90',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 12,
      type: 'player',
    },
    {
      _id: '6166e709e65c4ef20467c304',
      player: '6166c8bee65c4ef2043e3fb8',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6186cf9ca4737fae26d2a9f9',
      player: '6166c8bde65c4ef2043e39ba',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 53,
      type: 'player',
    },
    {
      _id: '6186cf9ca4737fae26d2aa0f',
      player: '6166c8bde65c4ef2043e3a60',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380b9d',
      user: null,
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 64,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467febc',
      player: '6166c911e65c4ef2043f0446',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 17,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467fed7',
      player: '6166c8e2e65c4ef2043ec70e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467fefd',
      player: '6166c911e65c4ef2043f047d',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 9,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ff18',
      player: '6166c911e65c4ef2043f0494',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 5,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ff36',
      player: '6166c911e65c4ef2043f04c4',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 28,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ff50',
      player: '6166c911e65c4ef2043f04e2',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 24,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ff73',
      player: '6166c8fce65c4ef2043ee847',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 25,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ff8f',
      player: '6166c912e65c4ef2043f0506',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 26,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ffb3',
      player: '6166c912e65c4ef2043f0524',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 8,
      type: 'player',
    },
    {
      _id: '6166e720e65c4ef20467ffcc',
      player: '6166c912e65c4ef2043f0540',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 23,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef20467ffe5',
      player: '6166c912e65c4ef2043f055a',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 15,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef20467fff7',
      player: '6166c8f6e65c4ef2043edfc0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 21,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef204680012',
      player: '6166c912e65c4ef2043f0595',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 4,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef20468003d',
      player: '6166c912e65c4ef2043f05ae',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 11,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef20468005a',
      player: '6166c912e65c4ef2043f05c6',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: false,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef204680074',
      player: '6166c912e65c4ef2043f05f0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 44,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef204680098',
      player: '6166c912e65c4ef2043f060b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 2,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef2046800b1',
      player: '6166c912e65c4ef2043f062b',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 16,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef2046800d5',
      player: '6166c913e65c4ef2043f064e',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 22,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef2046800f2',
      player: '6166c913e65c4ef2043f0667',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 30,
      type: 'player',
    },
    {
      _id: '6166e721e65c4ef20468010c',
      player: '6166c913e65c4ef2043f0686',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 1,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef204680129',
      player: '6166c913e65c4ef2043f06a5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 3,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef204680142',
      player: '6166c913e65c4ef2043f06f0',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 18,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef204680166',
      player: '6166c913e65c4ef2043f0709',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 7,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef204680187',
      player: '6166c913e65c4ef2043f0737',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Attacker',
      opta_shirt_number: 35,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef2046801a3',
      player: '6166c913e65c4ef2043f0750',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 19,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef2046801c0',
      player: '6166c913e65c4ef2043f0771',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Goalkeeper',
      opta_shirt_number: 33,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef2046801eb',
      player: '6166c914e65c4ef2043f0793',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 14,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef204680214',
      player: '6166c914e65c4ef2043f07d5',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Defender',
      opta_shirt_number: 6,
      type: 'player',
    },
    {
      _id: '6166e722e65c4ef20468022e',
      player: '6166c914e65c4ef2043f07f1',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      type: 'coach',
    },
    {
      _id: '6166e723e65c4ef20468024b',
      player: '6166c914e65c4ef2043f081c',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 20,
      type: 'player',
    },
    {
      _id: '6166e723e65c4ef204680264',
      player: '6166c914e65c4ef2043f0845',
      season: '6166ba57e65c4ef2042d4af1',
      team: '6166c3dfe65c4ef204380c3a',
      __v: 0,
      active: true,
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_position: 'Midfielder',
      opta_shirt_number: 10,
      type: 'player',
    },
  ],
  players: [
    {
      _id: '6166c859e65c4ef2043d990a',
      opta_ID: '172klpuv2h1enskc8fz79xg45',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-01-20',
      foot: 'right',
      height: '182',
      opta_first_name: 'Calum',
      opta_last_name: 'Chambers',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Petersfield',
      type: 'player',
      weight: '66',
      first_name: 'Calum',
      first_name_upper: 'CALUM',
      last_name: 'Chambers',
      last_name_upper: 'CHAMBERS',
    },
    {
      _id: '6166c859e65c4ef2043d9927',
      opta_ID: '2922aevmtpw4qvcwohmxmqtbd',
      __v: 0,
      country_of_birth: 'Japan',
      date_of_birth: '1998-11-05',
      foot: 'both',
      height: '188',
      opta_first_name: 'Takehiro',
      opta_last_name: 'Tomiyasu',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d2b8',
      place_of_birth: 'Fukuoka',
      type: 'player',
      weight: '70',
      first_name: 'Takehiro',
      first_name_upper: 'TAKEHIRO',
      last_name: 'Tomiyasu',
      last_name_upper: 'TOMIYASU',
    },
    {
      _id: '6166c85ae65c4ef2043d9943',
      opta_ID: '2czdtvqp0392800x9fkek0hjp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1962-11-16',
      foot: 'right',
      height: '193',
      opta_first_name: 'Steve',
      opta_last_name: 'Bould',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stoke-on-Trent',
      type: 'assistant coach',
      weight: '89',
      first_name: 'Steve',
      first_name_upper: 'STEVE',
      last_name: 'Bould',
      last_name_upper: 'BOULD',
    },
    {
      _id: '6166c85ae65c4ef2043d9968',
      opta_ID: '2f79rc4i712sxstahjkq6pxp1',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1992-03-04',
      foot: 'right',
      height: '190',
      opta_first_name: 'Bernd',
      opta_last_name: 'Leno',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Bietigheim-Bissingen',
      type: 'player',
      weight: '83',
      first_name: 'Bernd',
      first_name_upper: 'BERND',
      last_name: 'Leno',
      last_name_upper: 'LENO',
    },
    {
      _id: '6166c85ae65c4ef2043d9992',
      opta_ID: '2im16cd31tgg3yac3c8c18jbp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-05-30',
      foot: '',
      height: '175',
      opta_first_name: 'Edward',
      opta_last_name: 'Nketiah',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '72',
      first_name: 'Edward',
      first_name_upper: 'EDWARD',
      last_name: 'Nketiah',
      last_name_upper: 'NKETIAH',
    },
    {
      _id: '6166c85ae65c4ef2043d99b5',
      opta_ID: '375gnysvjk9bvq02ex1lkmynd',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1999-10-22',
      foot: 'right',
      height: '173',
      opta_first_name: 'Albert-Mboyo',
      opta_last_name: 'Sambi Lokonga',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: '',
      type: 'player',
      weight: '67',
      first_name: 'Albert-Mboyo',
      first_name_upper: 'ALBERT-MBOYO',
      last_name: 'Sambi Lokonga',
      last_name_upper: 'SAMBI LOKONGA',
    },
    {
      _id: '6166c85ae65c4ef2043d99f2',
      opta_ID: '3e8mxxfs5q7gt4lg2beqpkwyd',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1995-03-19',
      foot: 'right',
      height: '178',
      opta_first_name: 'Héctor',
      opta_last_name: 'Bellerín Moruno',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Barcelona',
      type: 'player',
      weight: '74',
      first_name: 'Héctor',
      first_name_upper: 'HÉCTOR',
      last_name: 'Bellerín Moruno',
      last_name_upper: 'BELLERÍN MORUNO',
    },
    {
      _id: '6166c85ae65c4ef2043d9a11',
      opta_ID: '3imsbq3n9zgkzp668lsbklg45',
      __v: 0,
      country_of_birth: 'Egypt',
      date_of_birth: '1992-07-11',
      foot: 'right',
      height: '180',
      opta_first_name: 'Mohamed Naser',
      opta_last_name: 'Elsayed Elneny',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d40b',
      place_of_birth: 'El-Mahalla El-Kubra',
      type: 'player',
      weight: '70',
      first_name: 'Mohamed Naser',
      first_name_upper: 'MOHAMED NASER',
      last_name: 'Elsayed Elneny',
      last_name_upper: 'ELSAYED ELNENY',
    },
    {
      _id: '6166c85ae65c4ef2043d9a37',
      opta_ID: '3n9jnuteyjx6w6dxviuead0b9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1995-05-29',
      foot: 'left',
      height: '178',
      opta_first_name: 'Nicolas',
      opta_last_name: 'Pépé',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Mantes-la-Jolie',
      type: 'player',
      weight: '68',
      first_name: 'Nicolas',
      first_name_upper: 'NICOLAS',
      last_name: 'Pépé',
      last_name_upper: 'PÉPÉ',
    },
    {
      _id: '6166c85ae65c4ef2043d9a58',
      opta_ID: '4ali7e2nk26rfzr0660xdrx0p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-07-28',
      foot: 'right',
      height: '182',
      opta_first_name: 'Emile',
      opta_last_name: 'Smith Rowe',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '79',
      first_name: 'Emile',
      first_name_upper: 'EMILE',
      last_name: 'Smith Rowe',
      last_name_upper: 'SMITH ROWE',
    },
    {
      _id: '6166c85be65c4ef2043d9abb',
      opta_ID: '57gg79gxvcfqtaoi0ruog7wmh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-09-20',
      foot: 'right',
      height: '189',
      opta_first_name: 'Rob',
      opta_last_name: 'Holding',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stalybridge',
      type: 'player',
      weight: '75',
      first_name: 'Rob',
      first_name_upper: 'ROB',
      last_name: 'Holding',
      last_name_upper: 'HOLDING',
    },
    {
      _id: '6166c85be65c4ef2043d9ae7',
      opta_ID: '5tbabvk43ljv3z3wd9mh0y8r9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-08-20',
      foot: 'right',
      height: '179',
      opta_first_name: 'Joseph',
      opta_last_name: 'Willock',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '71',
      first_name: 'Joseph',
      first_name_upper: 'JOSEPH',
      last_name: 'Willock',
      last_name_upper: 'WILLOCK',
    },
    {
      _id: '6166c85be65c4ef2043d9b12',
      opta_ID: '5wzmyf6ahylow73zpvvixhv55',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-09',
      foot: '',
      height: '',
      opta_first_name: 'Arthur',
      opta_last_name: 'Okonkwo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Arthur',
      first_name_upper: 'ARTHUR',
      last_name: 'Okonkwo',
      last_name_upper: 'OKONKWO',
    },
    {
      _id: '6166c85be65c4ef2043d9b2f',
      opta_ID: '6264xjr1y00yu7b9j2pknalzp',
      __v: 0,
      country_of_birth: 'Switzerland',
      date_of_birth: '1992-09-27',
      foot: 'left',
      height: '185',
      opta_first_name: 'Granit',
      opta_last_name: 'Xhaka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d909',
      place_of_birth: 'Basel',
      type: 'player',
      weight: '82',
      first_name: 'Granit',
      first_name_upper: 'GRANIT',
      last_name: 'Xhaka',
      last_name_upper: 'XHAKA',
    },
    {
      _id: '6166c85be65c4ef2043d9b4c',
      opta_ID: '6yovfcug6e9hykfauqy0fq67d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-05-14',
      foot: '',
      height: '188',
      opta_first_name: 'Aaron',
      opta_last_name: 'Ramsdale',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stoke-on-Trent',
      type: 'player',
      weight: '77',
      first_name: 'Aaron',
      first_name_upper: 'AARON',
      last_name: 'Ramsdale',
      last_name_upper: 'RAMSDALE',
    },
    {
      _id: '6166c85be65c4ef2043d9b8e',
      opta_ID: '7ocm7nugzwos59il1pm4eub8q',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '2001-06-18',
      foot: 'right',
      height: '178',
      opta_first_name: 'Gabriel Teodoro',
      opta_last_name: 'Martinelli Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Guarulhos',
      type: 'player',
      weight: '75',
      first_name: 'Gabriel Teodoro',
      first_name_upper: 'GABRIEL TEODORO',
      last_name: 'Martinelli Silva',
      last_name_upper: 'MARTINELLI SILVA',
    },
    {
      _id: '6166c85be65c4ef2043d9bb3',
      opta_ID: '8i5ee4fmkqhl1rcuo31qomxud',
      __v: 0,
      country_of_birth: 'Isle of Man',
      date_of_birth: '1997-06-05',
      foot: 'left',
      height: '178',
      opta_first_name: 'Kieran',
      opta_last_name: 'Tierney',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Douglas',
      type: 'player',
      weight: '70',
      first_name: 'Kieran',
      first_name_upper: 'KIERAN',
      last_name: 'Tierney',
      last_name_upper: 'TIERNEY',
    },
    {
      _id: '6166c85be65c4ef2043d9bd0',
      opta_ID: '8libvn3hxypz8x7gm4mr7kj55',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1997-12-19',
      foot: 'left',
      height: '190',
      opta_first_name: 'Gabriel',
      opta_last_name: 'dos Santos Magalhães',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'São Paulo',
      type: 'player',
      weight: '78',
      first_name: 'Gabriel',
      first_name_upper: 'GABRIEL',
      last_name: 'dos Santos Magalhães',
      last_name_upper: 'DOS SANTOS MAGALHÃES',
    },
    {
      _id: '6166c85be65c4ef2043d9bec',
      opta_ID: '8tc7dcuwdeqbjgt7vw5p38xgq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-05',
      foot: 'left',
      height: '178',
      opta_first_name: 'Bukayo',
      opta_last_name: 'Saka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '65',
      first_name: 'Bukayo',
      first_name_upper: 'BUKAYO',
      last_name: 'Saka',
      last_name_upper: 'SAKA',
    },
    {
      _id: '6166c85ce65c4ef2043d9c1a',
      opta_ID: '9j742o5vdc7d54x1xdz4t9ck5',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1993-06-20',
      foot: 'left',
      height: '183',
      opta_first_name: 'Sead',
      opta_last_name: 'Kolašinac',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d714',
      place_of_birth: 'Karlsruhe',
      type: 'player',
      weight: '85',
      first_name: 'Sead',
      first_name_upper: 'SEAD',
      last_name: 'Kolašinac',
      last_name_upper: 'KOLAŠINAC',
    },
    {
      _id: '6166c85ce65c4ef2043d9c44',
      opta_ID: 'actvnm36s31zf8wh8hjikzlqt',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1989-06-18',
      foot: 'right',
      height: '187',
      opta_first_name: 'Pierre-Emerick Emiliano François',
      opta_last_name: 'Aubameyang',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d414',
      place_of_birth: 'Laval',
      type: 'player',
      weight: '80',
      first_name: 'Pierre-Emerick Emiliano François',
      first_name_upper: 'PIERRE-EMERICK EMILIANO FRANÇOIS',
      last_name: 'Aubameyang',
      last_name_upper: 'AUBAMEYANG',
    },
    {
      _id: '6166c85ce65c4ef2043d9c67',
      opta_ID: 'afcwu800ox2u5zx6xaiygg1qt',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1988-08-09',
      foot: 'right',
      height: '175',
      opta_first_name: 'Willian',
      opta_last_name: 'Borges da Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Ribeirão Pires',
      type: 'player',
      weight: '77',
      first_name: 'Willian',
      first_name_upper: 'WILLIAN',
      last_name: 'Borges da Silva',
      last_name_upper: 'BORGES DA SILVA',
    },
    {
      _id: '6166c85ce65c4ef2043d9c87',
      opta_ID: 'b1lcxeihx8z6pfv1jef4j5jh1',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '1998-12-17',
      foot: 'left',
      height: '178',
      opta_first_name: 'Martin',
      opta_last_name: 'Ødegaard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Drammen',
      type: 'player',
      weight: '68',
      first_name: 'Martin',
      first_name_upper: 'MARTIN',
      last_name: 'Ødegaard',
      last_name_upper: 'ØDEGAARD',
    },
    {
      _id: '6166c85ce65c4ef2043d9cc6',
      opta_ID: 'bzummkjt2ya27kvolzmqn91wp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-12-10',
      foot: 'right',
      height: '175',
      opta_first_name: 'Reiss',
      opta_last_name: 'Nelson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '71',
      first_name: 'Reiss',
      first_name_upper: 'REISS',
      last_name: 'Nelson',
      last_name_upper: 'NELSON',
    },
    {
      _id: '6166c85ce65c4ef2043d9ce5',
      opta_ID: 'cnmlhoum9aahwqruxgmz2gcfd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-08',
      foot: 'right',
      height: '185',
      opta_first_name: 'Benjamin',
      opta_last_name: 'White',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Poole',
      type: 'player',
      weight: '78',
      first_name: 'Benjamin',
      first_name_upper: 'BENJAMIN',
      last_name: 'White',
      last_name_upper: 'WHITE',
    },
    {
      _id: '6166c85ce65c4ef2043d9d08',
      opta_ID: 'dqsslq1iz1azubowq3anwws4q',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '2000-01-26',
      foot: 'left',
      height: '183',
      opta_first_name: 'Nuno Albertino',
      opta_last_name: 'Varela Tavares',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Lisboa',
      type: 'player',
      weight: '75',
      first_name: 'Nuno Albertino',
      first_name_upper: 'NUNO ALBERTINO',
      last_name: 'Varela Tavares',
      last_name_upper: 'VARELA TAVARES',
    },
    {
      _id: '6166c85ce65c4ef2043d9d2e',
      opta_ID: 'dydwiixiims98cx2iihfuf8wl',
      __v: 0,
      country_of_birth: 'Ghana',
      date_of_birth: '1993-06-13',
      foot: 'right',
      height: '185',
      opta_first_name: 'Thomas',
      opta_last_name: 'Partey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Odumase Krobo',
      type: 'player',
      weight: '77',
      first_name: 'Thomas',
      first_name_upper: 'THOMAS',
      last_name: 'Partey',
      last_name_upper: 'PARTEY',
    },
    {
      _id: '6166c85ce65c4ef2043d9d4d',
      opta_ID: 'dzsd79k6345s9105mb2r4fqol',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-08-29',
      foot: 'right',
      height: '177',
      opta_first_name: 'Ainsley',
      opta_last_name: 'Maitland-Niles',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '71',
      first_name: 'Ainsley',
      first_name_upper: 'AINSLEY',
      last_name: 'Maitland-Niles',
      last_name_upper: 'MAITLAND-NILES',
    },
    {
      _id: '6166c85ce65c4ef2043d9d69',
      opta_ID: 'e36lcgg2vegiecbez2j15fl9m',
      __v: 0,
      country_of_birth: 'Estonia',
      date_of_birth: '2002-04-13',
      foot: 'right',
      height: '193',
      opta_first_name: 'Karl Jakob',
      opta_last_name: 'Hein',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d738',
      place_of_birth: 'Põlva',
      type: 'player',
      weight: '',
      first_name: 'Karl Jakob',
      first_name_upper: 'KARL JAKOB',
      last_name: 'Hein',
      last_name_upper: 'HEIN',
    },
    {
      _id: '6166c85de65c4ef2043d9d95',
      opta_ID: 'edti8t30h7ukwi3wqlzaczje1',
      __v: 0,
      country_of_birth: 'USA',
      date_of_birth: '2001-07-03',
      foot: '',
      height: '178',
      opta_first_name: 'Folarin',
      opta_last_name: 'Balogun',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '66',
      first_name: 'Folarin',
      first_name_upper: 'FOLARIN',
      last_name: 'Balogun',
      last_name_upper: 'BALOGUN',
    },
    {
      _id: '6166c85de65c4ef2043d9de2',
      opta_ID: 'erdqr6vrr5tlfypbks3og0dlh',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1993-08-31',
      foot: 'left',
      height: '193',
      opta_first_name: 'Pablo',
      opta_last_name: 'Marí Villar',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Valencia',
      type: 'player',
      weight: '87',
      first_name: 'Pablo',
      first_name_upper: 'PABLO',
      last_name: 'Marí Villar',
      last_name_upper: 'MARÍ VILLAR',
    },
    {
      _id: '6166c85de65c4ef2043d9e08',
      opta_ID: 'etf1n9y07i3sxiys514vj7ufp',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1982-03-26',
      foot: 'right',
      height: '175',
      opta_first_name: 'Mikel',
      opta_last_name: 'Arteta Amatriain',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'San Sebastián',
      type: 'coach',
      weight: '64',
      first_name: 'Mikel',
      first_name_upper: 'MIKEL',
      last_name: 'Arteta Amatriain',
      last_name_upper: 'ARTETA AMATRIAIN',
    },
    {
      _id: '6166c85de65c4ef2043d9e29',
      opta_ID: 'f4cykd65su8xbme6xdc1iw7o5',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1991-05-28',
      foot: 'right',
      height: '175',
      opta_first_name: 'Alexandre',
      opta_last_name: 'Lacazette',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Lyon',
      type: 'player',
      weight: '73',
      first_name: 'Alexandre',
      first_name_upper: 'ALEXANDRE',
      last_name: 'Lacazette',
      last_name_upper: 'LACAZETTE',
    },
    {
      _id: '6166c85de65c4ef2043d9e4a',
      opta_ID: 'quh8tfvwi106toymoh38tbrp',
      __v: 0,
      country_of_birth: 'Iceland',
      date_of_birth: '1995-02-18',
      foot: 'right',
      height: '186',
      opta_first_name: 'Rúnar Alex',
      opta_last_name: 'Rúnarsson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d777',
      place_of_birth: '',
      type: 'player',
      weight: '78',
      first_name: 'Rúnar Alex',
      first_name_upper: 'RÚNAR ALEX',
      last_name: 'Rúnarsson',
      last_name_upper: 'RÚNARSSON',
    },
    {
      _id: '6166c85de65c4ef2043d9e6c',
      opta_ID: 'zo32khapaj950oma9gipptxx',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1991-08-31',
      foot: 'right',
      height: '172',
      opta_first_name: 'Cédric Ricardo',
      opta_last_name: 'Alves Soares',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Singen',
      type: 'player',
      weight: '67',
      first_name: 'Cédric Ricardo',
      first_name_upper: 'CÉDRIC RICARDO',
      last_name: 'Alves Soares',
      last_name_upper: 'ALVES SOARES',
    },
    {
      _id: '6166c85de65c4ef2043d9e91',
      opta_ID: '1e9txzr9hqz94xtun6byo0vs9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-08-07',
      foot: '',
      height: '175',
      opta_first_name: 'Matty',
      opta_last_name: 'Cash',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Slough',
      type: 'player',
      weight: '',
      first_name: 'Matty',
      first_name_upper: 'MATTY',
      last_name: 'Cash',
      last_name_upper: 'CASH',
    },
    {
      _id: '6166c85de65c4ef2043d9eac',
      opta_ID: '1whd636wefd9uyes3r963xg4',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2004-01-06',
      foot: '',
      height: '',
      opta_first_name: 'Sil',
      opta_last_name: 'Swinkels',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Sil',
      first_name_upper: 'SIL',
      last_name: 'Swinkels',
      last_name_upper: 'SWINKELS',
    },
    {
      _id: '6166c85ee65c4ef2043d9ef7',
      opta_ID: '1xvaas2f0890izpa8viwbshgq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-01-25',
      foot: '',
      height: '',
      opta_first_name: 'Chigozier Caleb',
      opta_last_name: 'Chukwuemeka',
      opta_last_updated: '2021-11-06T15:06:18Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Chigozier Caleb',
      first_name_upper: 'CHIGOZIER CALEB',
      last_name: 'Chukwuemeka',
      last_name_upper: 'CHUKWUEMEKA',
    },
    {
      _id: '6166c85ee65c4ef2043d9f47',
      opta_ID: '20uzxhlbdk0tkehbz43gwb90q',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-21',
      foot: '',
      height: '',
      opta_first_name: 'Aaron',
      opta_last_name: 'Ramsey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Aaron',
      first_name_upper: 'AARON',
      last_name: 'Ramsey',
      last_name_upper: 'RAMSEY',
    },
    {
      _id: '6166c85ee65c4ef2043d9f67',
      opta_ID: '268os78lzi8fggnve9n15h5ux',
      __v: 0,
      country_of_birth: 'Finland',
      date_of_birth: '2001-10-11',
      foot: '',
      height: '190',
      opta_first_name: 'Viljami Kari Veikko',
      opta_last_name: 'Sinisalo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d731',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Viljami Kari Veikko',
      first_name_upper: 'VILJAMI KARI VEIKKO',
      last_name: 'Sinisalo',
      last_name_upper: 'SINISALO',
    },
    {
      _id: '6166c85ee65c4ef2043d9fa7',
      opta_ID: '2smmv9hdoiet7necaf00ol51x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-03-13',
      foot: 'left',
      height: '196',
      opta_first_name: 'Tyrone',
      opta_last_name: 'Mings',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bath',
      type: 'player',
      weight: '77',
      first_name: 'Tyrone',
      first_name_upper: 'TYRONE',
      last_name: 'Mings',
      last_name_upper: 'MINGS',
    },
    {
      _id: '6166c85ee65c4ef2043da07c',
      opta_ID: '328p2bnnvpv59ei2cf68kgy51',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-09-22',
      foot: 'left',
      height: '183',
      opta_first_name: 'Jed',
      opta_last_name: 'Steer',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Norwich',
      type: 'player',
      weight: '80',
      first_name: 'Jed',
      first_name_upper: 'JED',
      last_name: 'Steer',
      last_name_upper: 'STEER',
    },
    {
      _id: '6166c85ee65c4ef2043da0e0',
      opta_ID: '3fb1otw3tr49iqf1cpynrcd79',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-07-16',
      foot: 'left',
      height: '191',
      opta_first_name: 'Kortney',
      opta_last_name: 'Hause',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Redbridge',
      type: 'player',
      weight: '77',
      first_name: 'Kortney',
      first_name_upper: 'KORTNEY',
      last_name: 'Hause',
      last_name_upper: 'HAUSE',
    },
    {
      _id: '6166c85fe65c4ef2043da0fd',
      opta_ID: '3zl1q2gk6tdmzirsyfgm38no5',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1992-09-02',
      foot: 'right',
      height: '195',
      opta_first_name: 'Damián Emiliano',
      opta_last_name: 'Martínez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Mar del Plata',
      type: 'player',
      weight: '88',
      first_name: 'Damián Emiliano',
      first_name_upper: 'DAMIÁN EMILIANO',
      last_name: 'Martínez',
      last_name_upper: 'MARTÍNEZ',
    },
    {
      _id: '6166c85fe65c4ef2043da127',
      opta_ID: '4jyrztlaueae7eg18kuvxa489',
      __v: 0,
      country_of_birth: 'Jamaica',
      date_of_birth: '1997-08-09',
      foot: 'left',
      height: '178',
      opta_first_name: 'Leon',
      opta_last_name: 'Bailey Butler',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d594',
      place_of_birth: 'Kingston',
      type: 'player',
      weight: '79',
      first_name: 'Leon',
      first_name_upper: 'LEON',
      last_name: 'Bailey Butler',
      last_name_upper: 'BAILEY BUTLER',
    },
    {
      _id: '6166c85fe65c4ef2043da156',
      opta_ID: '4w3454m7kftnibu5rdnb0jw0l',
      __v: 0,
      country_of_birth: 'Egypt',
      date_of_birth: '1994-10-01',
      foot: 'both',
      height: '180',
      opta_first_name: 'Mahmoud Ahmed Ibrahim',
      opta_last_name: 'Hassan',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d40b',
      place_of_birth: 'Kafr el-Sheikh',
      type: 'player',
      weight: '71',
      first_name: 'Mahmoud Ahmed Ibrahim',
      first_name_upper: 'MAHMOUD AHMED IBRAHIM',
      last_name: 'Hassan',
      last_name_upper: 'HASSAN',
    },
    {
      _id: '6166c85fe65c4ef2043da17f',
      opta_ID: '5m0j33eoa5c8pqlr0tdf7undh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-12-30',
      foot: '',
      height: '180',
      opta_first_name: 'Ollie',
      opta_last_name: 'Watkins',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Torbay',
      type: 'player',
      weight: '70',
      first_name: 'Ollie',
      first_name_upper: 'OLLIE',
      last_name: 'Watkins',
      last_name_upper: 'WATKINS',
    },
    {
      _id: '6166c85fe65c4ef2043da19c',
      opta_ID: '5ql70wwevaoqpxabylew5f696',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-10-20',
      foot: '',
      height: '',
      opta_first_name: 'Carney Chibueze',
      opta_last_name: 'Chukwuemeka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Carney Chibueze',
      first_name_upper: 'CARNEY CHIBUEZE',
      last_name: 'Chukwuemeka',
      last_name_upper: 'CHUKWUEMEKA',
    },
    {
      _id: '6166c85fe65c4ef2043da207',
      opta_ID: '72nph557ee2a9tdky8rvk12vp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-09-10',
      foot: 'right',
      height: '175',
      opta_first_name: 'Jack',
      opta_last_name: 'Grealish',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birmingham',
      type: 'player',
      weight: '68',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Grealish',
      last_name_upper: 'GREALISH',
    },
    {
      _id: '6166c85fe65c4ef2043da249',
      opta_ID: '7atxpldexe9adg8ft1wbjndud',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-12-24',
      foot: 'right',
      height: '178',
      opta_first_name: 'Frédéric',
      opta_last_name: 'Guilbert',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Valognes',
      type: 'player',
      weight: '70',
      first_name: 'Frédéric',
      first_name_upper: 'FRÉDÉRIC',
      last_name: 'Guilbert',
      last_name_upper: 'GUILBERT',
    },
    {
      _id: '6166c85fe65c4ef2043da267',
      opta_ID: '7g5elxh8h34yj55h9heydhzv9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-09-18',
      foot: 'left',
      height: '183',
      opta_first_name: 'Matt',
      opta_last_name: 'Targett',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Eastleigh',
      type: 'player',
      weight: '70',
      first_name: 'Matt',
      first_name_upper: 'MATT',
      last_name: 'Targett',
      last_name_upper: 'TARGETT',
    },
    {
      _id: '6166c85fe65c4ef2043da284',
      opta_ID: '7gaf68yeu28zp80kkb0tqgpd6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-12-09',
      foot: '',
      height: '181',
      opta_first_name: 'Cameron Desmond',
      opta_last_name: 'Archer',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Walsall',
      type: 'player',
      weight: '72',
      first_name: 'Cameron Desmond',
      first_name_upper: 'CAMERON DESMOND',
      last_name: 'Archer',
      last_name_upper: 'ARCHER',
    },
    {
      _id: '6166c860e65c4ef2043da2c3',
      opta_ID: '7j84ozqdpgupzxevbxemi5tyh',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1998-05-09',
      foot: 'right',
      height: '175',
      opta_first_name: 'Douglas Luiz',
      opta_last_name: 'Soares de Paulo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Rio de Janeiro',
      type: 'player',
      weight: '66',
      first_name: 'Douglas Luiz',
      first_name_upper: 'DOUGLAS LUIZ',
      last_name: 'Soares de Paulo',
      last_name_upper: 'SOARES DE PAULO',
    },
    {
      _id: '6166c860e65c4ef2043da2f8',
      opta_ID: '7paw998ux29k7hx4t1g0wu85x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1985-07-09',
      foot: 'both',
      height: '175',
      opta_first_name: 'Ashley',
      opta_last_name: 'Young',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stevenage',
      type: 'player',
      weight: '65',
      first_name: 'Ashley',
      first_name_upper: 'ASHLEY',
      last_name: 'Young',
      last_name_upper: 'YOUNG',
    },
    {
      _id: '6166c860e65c4ef2043da31f',
      opta_ID: '8qys6qtdwgsycxducl062zld5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-23',
      foot: 'right',
      height: '183',
      opta_first_name: 'Ezri',
      opta_last_name: 'Konsa Ngoyo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '77',
      first_name: 'Ezri',
      first_name_upper: 'EZRI',
      last_name: 'Konsa Ngoyo',
      last_name_upper: 'KONSA NGOYO',
    },
    {
      _id: '6166c860e65c4ef2043da342',
      opta_ID: '8y3obwxpwckjfl7hkrrg97sgl',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1991-02-02',
      foot: '',
      height: '180',
      opta_first_name: 'Conor',
      opta_last_name: 'Hourihane',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Cork',
      type: 'player',
      weight: '62',
      first_name: 'Conor',
      first_name_upper: 'CONOR',
      last_name: 'Hourihane',
      last_name_upper: 'HOURIHANE',
    },
    {
      _id: '6166c860e65c4ef2043da387',
      opta_ID: '9jj7t00bb5zflz78nlz3bvllg',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2004-01-05',
      foot: '',
      height: '',
      opta_first_name: 'Lamare',
      opta_last_name: 'Bogarde',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Lamare',
      first_name_upper: 'LAMARE',
      last_name: 'Bogarde',
      last_name_upper: 'BOGARDE',
    },
    {
      _id: '6166c860e65c4ef2043da3d2',
      opta_ID: '9uo88sreasbyj41lg34hxy28l',
      __v: 0,
      country_of_birth: 'Burkina Faso',
      date_of_birth: '1995-09-06',
      foot: 'left',
      height: '181',
      opta_first_name: 'Bertrand Isidore',
      opta_last_name: 'Traoré',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3d2',
      place_of_birth: 'Bobo-Dioulasso',
      type: 'player',
      weight: '73',
      first_name: 'Bertrand Isidore',
      first_name_upper: 'BERTRAND ISIDORE',
      last_name: 'Traoré',
      last_name_upper: 'TRAORÉ',
    },
    {
      _id: '6166c860e65c4ef2043da3f0',
      opta_ID: '9urq3hoaztk60dewpwuessfe1',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1996-11-24',
      foot: 'right',
      height: '191',
      opta_first_name: 'Wesley',
      opta_last_name: 'Moraes Ferreira da Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Juiz de Fora',
      type: 'player',
      weight: '93',
      first_name: 'Wesley',
      first_name_upper: 'WESLEY',
      last_name: 'Moraes Ferreira da Silva',
      last_name_upper: 'MORAES FERREIRA DA SILVA',
    },
    {
      _id: '6166c861e65c4ef2043da498',
      opta_ID: 'ashza2qkgnak7wb3vuz5bnzyt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-07-23',
      foot: 'right',
      height: '178',
      opta_first_name: 'Daniel William John',
      opta_last_name: 'Ings',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Winchester',
      type: 'player',
      weight: '73',
      first_name: 'Daniel William John',
      first_name_upper: 'DANIEL WILLIAM JOHN',
      last_name: 'Ings',
      last_name_upper: 'INGS',
    },
    {
      _id: '6166c861e65c4ef2043da4b7',
      opta_ID: 'bdg3xeth77c0nw61hvj5fct0p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-02-13',
      foot: 'right',
      height: '191',
      opta_first_name: 'Keinan',
      opta_last_name: 'Davis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '68',
      first_name: 'Keinan',
      first_name_upper: 'KEINAN',
      last_name: 'Davis',
      last_name_upper: 'DAVIS',
    },
    {
      _id: '6166c861e65c4ef2043da4d5',
      opta_ID: 'bhsh1n3qfg8awmctxlh8k7yzt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-05-28',
      foot: '',
      height: '',
      opta_first_name: 'Jacob',
      opta_last_name: 'Ramsey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Jacob',
      first_name_upper: 'JACOB',
      last_name: 'Ramsey',
      last_name_upper: 'RAMSEY',
    },
    {
      _id: '6166c861e65c4ef2043da4ff',
      opta_ID: 'bq2q90k6q53nlf53et7j46cid',
      __v: 0,
      country_of_birth: 'Zimbabwe',
      date_of_birth: '1994-01-19',
      foot: 'left',
      height: '178',
      opta_first_name: 'Marvelous',
      opta_last_name: 'Nakamba',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d552',
      place_of_birth: 'Hwange',
      type: 'player',
      weight: '71',
      first_name: 'Marvelous',
      first_name_upper: 'MARVELOUS',
      last_name: 'Nakamba',
      last_name_upper: 'NAKAMBA',
    },
    {
      _id: '6166c861e65c4ef2043da525',
      opta_ID: 'btvuam6p4391am219taqtcted',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1994-10-18',
      foot: '',
      height: '178',
      opta_first_name: 'John',
      opta_last_name: 'McGinn',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'player',
      weight: '68',
      first_name: 'John',
      first_name_upper: 'JOHN',
      last_name: 'McGinn',
      last_name_upper: 'MCGINN',
    },
    {
      _id: '6166c861e65c4ef2043da567',
      opta_ID: 'c8j50o9oqu2okdpjsr3nvnhsl',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1995-05-03',
      foot: 'right',
      height: '188',
      opta_first_name: 'Anwar',
      opta_last_name: 'El Ghazi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Barendrecht',
      type: 'player',
      weight: '90',
      first_name: 'Anwar',
      first_name_upper: 'ANWAR',
      last_name: 'El Ghazi',
      last_name_upper: 'EL GHAZI',
    },
    {
      _id: '6166c861e65c4ef2043da588',
      opta_ID: 'cr74l0rwvkf4yck0go4esw44p',
      __v: 0,
      country_of_birth: 'Congo DR',
      date_of_birth: '1997-11-14',
      foot: 'right',
      height: '186',
      opta_first_name: 'Axel',
      opta_last_name: 'Tuanzebe',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bunia',
      type: 'player',
      weight: '75',
      first_name: 'Axel',
      first_name_upper: 'AXEL',
      last_name: 'Tuanzebe',
      last_name_upper: 'TUANZEBE',
    },
    {
      _id: '6166c862e65c4ef2043da5ad',
      opta_ID: 'd1bmtavfr10i8z4kwsofv4mad',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1976-05-13',
      foot: '',
      height: '',
      opta_first_name: 'Mark Anthony',
      opta_last_name: 'Delaney',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Haverfordwest',
      type: 'assistant coach',
      weight: '',
      first_name: 'Mark Anthony',
      first_name_upper: 'MARK ANTHONY',
      last_name: 'Delaney',
      last_name_upper: 'DELANEY',
    },
    {
      _id: '6166c862e65c4ef2043da5dd',
      opta_ID: 'd394go61g90f1u6bddu1narit',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-08-18',
      foot: 'right',
      height: '180',
      opta_first_name: 'Morgan',
      opta_last_name: 'Sanson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Saint-Doulchard',
      type: 'player',
      weight: '73',
      first_name: 'Morgan',
      first_name_upper: 'MORGAN',
      last_name: 'Sanson',
      last_name_upper: 'SANSON',
    },
    {
      _id: '6166c862e65c4ef2043da606',
      opta_ID: 'f2nxpa1xl9b1fvp8v02l8h979',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1971-03-19',
      foot: '',
      height: '',
      opta_first_name: 'Dean',
      opta_last_name: 'Smith',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'West Bromwich',
      type: 'coach',
      weight: '',
      first_name: 'Dean',
      first_name_upper: 'DEAN',
      last_name: 'Smith',
      last_name_upper: 'SMITH',
    },
    {
      _id: '6166c862e65c4ef2043da625',
      opta_ID: 'hfvy9yiyonvzfpg736vokcx6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-09-02',
      foot: '',
      height: '',
      opta_first_name: 'Hayden',
      opta_last_name: 'Taylor Lindley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Hayden',
      first_name_upper: 'HAYDEN',
      last_name: 'Taylor Lindley',
      last_name_upper: 'TAYLOR LINDLEY',
    },
    {
      _id: '6166c862e65c4ef2043da647',
      opta_ID: 'l0qqn9tlgov91p5d1zzdupcl',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1996-12-25',
      foot: 'right',
      height: '172',
      opta_first_name: 'Emiliano',
      opta_last_name: 'Buendía',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Mar del Plata',
      type: 'player',
      weight: '72',
      first_name: 'Emiliano',
      first_name_upper: 'EMILIANO',
      last_name: 'Buendía',
      last_name_upper: 'BUENDÍA',
    },
    {
      _id: '6166c862e65c4ef2043da66c',
      opta_ID: 't9fp85x7cuopfnxat4h830q2',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-02-08',
      foot: '',
      height: '',
      opta_first_name: 'Jaden',
      opta_last_name: 'Philogene-Bidace',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Jaden',
      first_name_upper: 'JADEN',
      last_name: 'Philogene-Bidace',
      last_name_upper: 'PHILOGENE-BIDACE',
    },
    {
      _id: '6166c863e65c4ef2043da814',
      opta_ID: '47zyjwv31a878u1r1dgm3iwo9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2000-07-23',
      foot: 'right',
      height: '180',
      opta_first_name: 'Claudio',
      opta_last_name: 'Gomes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Argenteuil',
      type: 'player',
      weight: '',
      first_name: 'Claudio',
      first_name_upper: 'CLAUDIO',
      last_name: 'Gomes',
      last_name_upper: 'GOMES',
    },
    {
      _id: '6166c86ae65c4ef2043db159',
      opta_ID: 'a13bt8fcp7mnpoqfrjwjsvetx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1988-06-29',
      foot: 'right',
      height: '183',
      opta_first_name: 'Troy',
      opta_last_name: 'Deeney',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birmingham',
      type: 'player',
      weight: '90',
      first_name: 'Troy',
      first_name_upper: 'TROY',
      last_name: 'Deeney',
      last_name_upper: 'DEENEY',
    },
    {
      _id: '6166c86ce65c4ef2043db3c3',
      opta_ID: '2eahh3dfgpv7rquum50bn5ip5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-02-09',
      foot: 'left',
      height: '156',
      opta_first_name: 'Ian Carlo',
      opta_last_name: 'Poveda-Ocampo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Ian Carlo',
      first_name_upper: 'IAN CARLO',
      last_name: 'Poveda-Ocampo',
      last_name_upper: 'POVEDA-OCAMPO',
    },
    {
      _id: '6166c86ce65c4ef2043db3de',
      opta_ID: '2gj7h5fxuh4y0tbp6j308yk4q',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2000-06-08',
      foot: 'right',
      height: '189',
      opta_first_name: 'Jan Paul',
      opta_last_name: 'van Hecke',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Arnemuiden',
      type: 'player',
      weight: '',
      first_name: 'Jan Paul',
      first_name_upper: 'JAN PAUL',
      last_name: 'van Hecke',
      last_name_upper: 'VAN HECKE',
    },
    {
      _id: '6166c86fe65c4ef2043db739',
      opta_ID: 'dusjrdiyxfadenwx4pjr7catx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-02-10',
      foot: 'right',
      height: '174',
      opta_first_name: 'Adam',
      opta_last_name: 'Armstrong',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'West Denton',
      type: 'player',
      weight: '69',
      first_name: 'Adam',
      first_name_upper: 'ADAM',
      last_name: 'Armstrong',
      last_name_upper: 'ARMSTRONG',
    },
    {
      _id: '6166c879e65c4ef2043dc7ad',
      opta_ID: '1rv3g0f7hwihwtv18aydane1h',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1973-10-09',
      foot: '',
      height: '',
      opta_first_name: 'Thomas',
      opta_last_name: 'Frank',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: '',
      type: 'coach',
      weight: '',
      first_name: 'Thomas',
      first_name_upper: 'THOMAS',
      last_name: 'Frank',
      last_name_upper: 'FRANK',
    },
    {
      _id: '6166c879e65c4ef2043dc7c8',
      opta_ID: '1x9aotlnlnuficv4k6dtd9l91',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-11-05',
      foot: 'right',
      height: '180',
      opta_first_name: 'Tarique',
      opta_last_name: 'Fosu',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Wandsworth',
      type: 'player',
      weight: '75',
      first_name: 'Tarique',
      first_name_upper: 'TARIQUE',
      last_name: 'Fosu',
      last_name_upper: 'FOSU',
    },
    {
      _id: '6166c87ae65c4ef2043dc7e3',
      opta_ID: '2nzefp5pwvldt2ttmxfeakamt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-07-08',
      foot: '',
      height: '170',
      opta_first_name: 'Rico',
      opta_last_name: 'Henry',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birmingham',
      type: 'player',
      weight: '67',
      first_name: 'Rico',
      first_name_upper: 'RICO',
      last_name: 'Henry',
      last_name_upper: 'HENRY',
    },
    {
      _id: '6166c87ae65c4ef2043dc7fd',
      opta_ID: '2o5etqath4k9qaliho4op0hd5',
      __v: 0,
      country_of_birth: 'Iceland',
      date_of_birth: '2000-11-15',
      foot: '',
      height: '190',
      opta_first_name: 'Patrik Sigurður',
      opta_last_name: 'Gunnarsson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d777',
      place_of_birth: '',
      type: 'player',
      weight: '84',
      first_name: 'Patrik Sigurður',
      first_name_upper: 'PATRIK SIGURÐUR',
      last_name: 'Gunnarsson',
      last_name_upper: 'GUNNARSSON',
    },
    {
      _id: '6166c87ae65c4ef2043dc837',
      opta_ID: '2xgvd7xovqcd6q30m59dbi11x',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '1993-09-06',
      foot: 'right',
      height: '176',
      opta_first_name: 'Saman',
      opta_last_name: 'Ghoddos',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d251',
      place_of_birth: 'Malmö',
      type: 'player',
      weight: '75',
      first_name: 'Saman',
      first_name_upper: 'SAMAN',
      last_name: 'Ghoddos',
      last_name_upper: 'GHODDOS',
    },
    {
      _id: '6166c87ae65c4ef2043dc856',
      opta_ID: '32bvxwhceid2xdcfx6cd8a8d1',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '1998-04-17',
      foot: 'right',
      height: '198',
      opta_first_name: 'Kristoffer',
      opta_last_name: 'Vassbakk Ajer',
      opta_last_updated: '2021-11-06T15:42:34Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Rælingen',
      type: 'player',
      weight: '92',
      first_name: 'Kristoffer',
      first_name_upper: 'KRISTOFFER',
      last_name: 'Vassbakk Ajer',
      last_name_upper: 'VASSBAKK AJER',
    },
    {
      _id: '6166c87ae65c4ef2043dc873',
      opta_ID: '34b0oqo4725okjxxngin9piw9',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1998-01-01',
      foot: '',
      height: '183',
      opta_first_name: 'Frank',
      opta_last_name: 'Ogochukwu Onyeka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Maiduguri',
      type: 'player',
      weight: '',
      first_name: 'Frank',
      first_name_upper: 'FRANK',
      last_name: 'Ogochukwu Onyeka',
      last_name_upper: 'OGOCHUKWU ONYEKA',
    },
    {
      _id: '6166c87ae65c4ef2043dc88e',
      opta_ID: '43knl3z17afypz27sz4qzkxnu',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-09-18',
      foot: '',
      height: '',
      opta_first_name: 'Myles',
      opta_last_name: 'Peart-Harris',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Myles',
      first_name_upper: 'MYLES',
      last_name: 'Peart-Harris',
      last_name_upper: 'PEART-HARRIS',
    },
    {
      _id: '6166c87ae65c4ef2043dc8a9',
      opta_ID: '45zetxtrvjb3muvcrr9b5it3p',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1990-04-23',
      foot: 'right',
      height: '191',
      opta_first_name: 'Mathias',
      opta_last_name: 'Jattah-Njie Jørgensen',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '79',
      first_name: 'Mathias',
      first_name_upper: 'MATHIAS',
      last_name: 'Jattah-Njie Jørgensen',
      last_name_upper: 'JATTAH-NJIE JØRGENSEN',
    },
    {
      _id: '6166c87ae65c4ef2043dc8c6',
      opta_ID: '4iijb6llnz28unsz4rirr3umt',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1995-09-15',
      foot: 'right',
      height: '183',
      opta_first_name: 'David',
      opta_last_name: 'Raya Martin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Barcelona',
      type: 'player',
      weight: '80',
      first_name: 'David',
      first_name_upper: 'DAVID',
      last_name: 'Raya Martin',
      last_name_upper: 'RAYA MARTIN',
    },
    {
      _id: '6166c87ae65c4ef2043dc8ee',
      opta_ID: '4j6a6qec8m58zgojpbv8wxnf9',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '1991-02-13',
      foot: 'right',
      height: '194',
      opta_first_name: 'Pontus',
      opta_last_name: 'Jansson',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d985',
      place_of_birth: 'Arlöv',
      type: 'player',
      weight: '89',
      first_name: 'Pontus',
      first_name_upper: 'PONTUS',
      last_name: 'Jansson',
      last_name_upper: 'JANSSON',
    },
    {
      _id: '6166c87ae65c4ef2043dc908',
      opta_ID: '6272debmua73jpg30up9gp04p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-08-03',
      foot: 'right',
      height: '196',
      opta_first_name: 'Charlie',
      opta_last_name: 'Goode',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Watford',
      type: 'player',
      weight: '89',
      first_name: 'Charlie',
      first_name_upper: 'CHARLIE',
      last_name: 'Goode',
      last_name_upper: 'GOODE',
    },
    {
      _id: '6166c87ae65c4ef2043dc921',
      opta_ID: '68n1ydedtlw2s6toghw9qiprd',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1999-06-24',
      foot: 'right',
      height: '184',
      opta_first_name: 'Mads',
      opta_last_name: 'Roerslev Rasmussen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '',
      first_name: 'Mads',
      first_name_upper: 'MADS',
      last_name: 'Roerslev Rasmussen',
      last_name_upper: 'ROERSLEV RASMUSSEN',
    },
    {
      _id: '6166c87be65c4ef2043dc93d',
      opta_ID: '7w32oxa2v9mk5hh9ansznk3c9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-07-26',
      foot: 'left',
      height: '185',
      opta_first_name: 'Dominic',
      opta_last_name: 'Thompson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Willesden',
      type: 'player',
      weight: '75',
      first_name: 'Dominic',
      first_name_upper: 'DOMINIC',
      last_name: 'Thompson',
      last_name_upper: 'THOMPSON',
    },
    {
      _id: '6166c87be65c4ef2043dc95a',
      opta_ID: '84xefpsrml48n7czpnruzxkru',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-04-08',
      foot: 'right',
      height: '175',
      opta_first_name: 'Shandon',
      opta_last_name: 'Baptiste',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d57b',
      place_of_birth: 'Reading',
      type: 'player',
      weight: '67',
      first_name: 'Shandon',
      first_name_upper: 'SHANDON',
      last_name: 'Baptiste',
      last_name_upper: 'BAPTISTE',
    },
    {
      _id: '6166c87be65c4ef2043dc985',
      opta_ID: '8ju1opnca42o8i00wq3onzmqd',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1994-03-10',
      foot: 'right',
      height: '187',
      opta_first_name: 'Christian',
      opta_last_name: 'Thers Nørgaard',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '76',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Thers Nørgaard',
      last_name_upper: 'THERS NØRGAARD',
    },
    {
      _id: '6166c87be65c4ef2043dc9a6',
      opta_ID: '8tesu8352jvqfvclei8hykjbp',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1992-03-31',
      foot: 'right',
      height: '185',
      opta_first_name: 'Julian',
      opta_last_name: 'Jeanvier',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d42a',
      place_of_birth: 'Clichy',
      type: 'player',
      weight: '84',
      first_name: 'Julian',
      first_name_upper: 'JULIAN',
      last_name: 'Jeanvier',
      last_name_upper: 'JEANVIER',
    },
    {
      _id: '6166c87be65c4ef2043dc9cb',
      opta_ID: '9k8gysrsy1a261nhcszo4aoyx',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1998-05-10',
      foot: 'left',
      height: '184',
      opta_first_name: 'Vitaly',
      opta_last_name: 'Janelt',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Hamburg',
      type: 'player',
      weight: '79',
      first_name: 'Vitaly',
      first_name_upper: 'VITALY',
      last_name: 'Janelt',
      last_name_upper: 'JANELT',
    },
    {
      _id: '6166c87be65c4ef2043dc9e8',
      opta_ID: '9y3cnx4ymvbndjyu6sk936k4q',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-04-10',
      foot: '',
      height: '',
      opta_first_name: 'Finley',
      opta_last_name: 'Stevens',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Finley',
      first_name_upper: 'FINLEY',
      last_name: 'Stevens',
      last_name_upper: 'STEVENS',
    },
    {
      _id: '6166c87be65c4ef2043dca06',
      opta_ID: 'a0dyuysg0p6ekvy4qkkfkwnvt',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1999-05-08',
      foot: 'right',
      height: '187',
      opta_first_name: 'Luka',
      opta_last_name: 'Račić',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Greve',
      type: 'player',
      weight: '74',
      first_name: 'Luka',
      first_name_upper: 'LUKA',
      last_name: 'Račić',
      last_name_upper: 'RAČIĆ',
    },
    {
      _id: '6166c87be65c4ef2043dca25',
      opta_ID: 'a87w6l9zsbahdi4knm1347zs9',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1999-01-07',
      foot: 'left',
      height: '189',
      opta_first_name: 'Mads',
      opta_last_name: 'Bech Sørensen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Horsens',
      type: 'player',
      weight: '92',
      first_name: 'Mads',
      first_name_upper: 'MADS',
      last_name: 'Bech Sørensen',
      last_name_upper: 'BECH SØRENSEN',
    },
    {
      _id: '6166c87be65c4ef2043dca47',
      opta_ID: 'aa40go6nrv6mpdny6zdc1jeg9',
      __v: 0,
      country_of_birth: 'Finland',
      date_of_birth: '2000-11-07',
      foot: 'right',
      height: '184',
      opta_first_name: 'Jaakko',
      opta_last_name: 'Oksanen',
      opta_last_updated: '2021-11-06T15:42:34Z',
      opta_nationality: '6166c13be65c4ef20434d731',
      place_of_birth: 'Helsinki',
      type: 'player',
      weight: '72',
      first_name: 'Jaakko',
      first_name_upper: 'JAAKKO',
      last_name: 'Oksanen',
      last_name_upper: 'OKSANEN',
    },
    {
      _id: '6166c87be65c4ef2043dca60',
      opta_ID: 'agd2nhvdhp18dg1weape5olpl',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1998-04-13',
      foot: 'right',
      height: '185',
      opta_first_name: 'Álvaro',
      opta_last_name: 'Fernández Llorente',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Arnedo',
      type: 'player',
      weight: '75',
      first_name: 'Álvaro',
      first_name_upper: 'ÁLVARO',
      last_name: 'Fernández Llorente',
      last_name_upper: 'FERNÁNDEZ LLORENTE',
    },
    {
      _id: '6166c87be65c4ef2043dca78',
      opta_ID: 'alg22aw3grzl4b9o0pf1o54kq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-01-21',
      foot: '',
      height: '',
      opta_first_name: 'Maxwell',
      opta_last_name: 'Haygarth',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Maxwell',
      first_name_upper: 'MAXWELL',
      last_name: 'Haygarth',
      last_name_upper: 'HAYGARTH',
    },
    {
      _id: '6166c87ce65c4ef2043dca92',
      opta_ID: 'b36foqukxn8obd969q6sea7yt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-03-16',
      foot: 'right',
      height: '179',
      opta_first_name: 'Ivan',
      opta_last_name: 'Toney',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Northampton',
      type: 'player',
      weight: '65',
      first_name: 'Ivan',
      first_name_upper: 'IVAN',
      last_name: 'Toney',
      last_name_upper: 'TONEY',
    },
    {
      _id: '6166c87ce65c4ef2043dcad4',
      opta_ID: 'bs7iyv9ys61m88qh4rvb0tpzp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-10-23',
      foot: 'left',
      height: '184',
      opta_first_name: 'Pelenda Joshua Tunga',
      opta_last_name: 'Dasilva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '75',
      first_name: 'Pelenda Joshua Tunga',
      first_name_upper: 'PELENDA JOSHUA TUNGA',
      last_name: 'Dasilva',
      last_name_upper: 'DASILVA',
    },
    {
      _id: '6166c87ce65c4ef2043dcb2b',
      opta_ID: 'bt226z4iktw4xzqqgacvvgt4a',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1999-12-08',
      foot: 'right',
      height: '183',
      opta_first_name: 'Halil İbrahim',
      opta_last_name: 'Dervişoğlu',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d93e',
      place_of_birth: 'Rotterdam',
      type: 'player',
      weight: '76',
      first_name: 'Halil İbrahim',
      first_name_upper: 'HALIL İBRAHIM',
      last_name: 'Dervişoğlu',
      last_name_upper: 'DERVIŞOĞLU',
    },
    {
      _id: '6166c87ce65c4ef2043dcb5a',
      opta_ID: 'bxjc2heo4ve54cyvw98qcck45',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1996-09-03',
      foot: 'right',
      height: '176',
      opta_first_name: 'Yoane',
      opta_last_name: 'Wissa',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3bc',
      place_of_birth: 'Epinay-sous-Sénart',
      type: 'player',
      weight: '74',
      first_name: 'Yoane',
      first_name_upper: 'YOANE',
      last_name: 'Wissa',
      last_name_upper: 'WISSA',
    },
    {
      _id: '6166c87ce65c4ef2043dcb76',
      opta_ID: 'd9a62lhx21b0ym73rxd6mw8h5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-05-29',
      foot: 'left',
      height: '187',
      opta_first_name: 'Ethan',
      opta_last_name: 'Pinnock',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d594',
      place_of_birth: 'London',
      type: 'player',
      weight: '79',
      first_name: 'Ethan',
      first_name_upper: 'ETHAN',
      last_name: 'Pinnock',
      last_name_upper: 'PINNOCK',
    },
    {
      _id: '6166c87ce65c4ef2043dcb93',
      opta_ID: 'do5gstk4ks97daehnudehyjxm',
      __v: 0,
      country_of_birth: 'Czech Republic',
      date_of_birth: '2001-02-13',
      foot: '',
      height: '182',
      opta_first_name: 'Jan',
      opta_last_name: 'Žambůrek',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: '',
      type: 'player',
      weight: '70',
      first_name: 'Jan',
      first_name_upper: 'JAN',
      last_name: 'Žambůrek',
      last_name_upper: 'ŽAMBŮREK',
    },
    {
      _id: '6166c87ce65c4ef2043dcbad',
      opta_ID: 'ds1wjqejslhsbcvzaufxubey2',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '2001-02-25',
      foot: '',
      height: '',
      opta_first_name: 'Mads',
      opta_last_name: 'Bidstrup',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Køge',
      type: 'player',
      weight: '',
      first_name: 'Mads',
      first_name_upper: 'MADS',
      last_name: 'Bidstrup',
      last_name_upper: 'BIDSTRUP',
    },
    {
      _id: '6166c87ce65c4ef2043dcbd1',
      opta_ID: 'dtol82tjlq48bs0s70sisguz9',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1996-01-01',
      foot: 'both',
      height: '180',
      opta_first_name: 'Mathias',
      opta_last_name: 'Jensen',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Jerslev',
      type: 'player',
      weight: '68',
      first_name: 'Mathias',
      first_name_upper: 'MATHIAS',
      last_name: 'Jensen',
      last_name_upper: 'JENSEN',
    },
    {
      _id: '6166c87ce65c4ef2043dcbf5',
      opta_ID: 'eg2jk0f0l2l1gurwwn2au6nyt',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1997-02-02',
      foot: 'right',
      height: '177',
      opta_first_name: 'Sergi',
      opta_last_name: 'Canós Tenes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Nules',
      type: 'player',
      weight: '75',
      first_name: 'Sergi',
      first_name_upper: 'SERGI',
      last_name: 'Canós Tenes',
      last_name_upper: 'CANÓS TENES',
    },
    {
      _id: '6166c87ce65c4ef2043dcc0e',
      opta_ID: 'ezhz85tya5qbfk1x0ksrc2y21',
      __v: 0,
      country_of_birth: 'Finland',
      date_of_birth: '1999-06-18',
      foot: 'right',
      height: '182',
      opta_first_name: 'Marcus',
      opta_last_name: 'Forss',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d731',
      place_of_birth: 'Turku',
      type: 'player',
      weight: '76',
      first_name: 'Marcus',
      first_name_upper: 'MARCUS',
      last_name: 'Forss',
      last_name_upper: 'FORSS',
    },
    {
      _id: '6166c87ce65c4ef2043dcc2b',
      opta_ID: 'f0sa0skucdf742t06hsmi8ne2',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2001-02-12',
      foot: '',
      height: '',
      opta_first_name: 'Lewis',
      opta_last_name: 'Gordon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Lewis',
      first_name_upper: 'LEWIS',
      last_name: 'Gordon',
      last_name_upper: 'GORDON',
    },
    {
      _id: '6166c87de65c4ef2043dcc45',
      opta_ID: 'v78uhwxgzojcec8lsdvucusp',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-08-07',
      foot: 'left',
      height: '173',
      opta_first_name: 'Bryan',
      opta_last_name: 'Mbeumo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Avallon',
      type: 'player',
      weight: '',
      first_name: 'Bryan',
      first_name_upper: 'BRYAN',
      last_name: 'Mbeumo',
      last_name_upper: 'MBEUMO',
    },
    {
      _id: '6166c87de65c4ef2043dcc69',
      opta_ID: '1g15ehziupxnsy1kvl3pqmbc9',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1996-08-30',
      foot: 'right',
      height: '182',
      opta_first_name: 'Yves',
      opta_last_name: 'Bissouma',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d47b',
      place_of_birth: 'Issia',
      type: 'player',
      weight: '80',
      first_name: 'Yves',
      first_name_upper: 'YVES',
      last_name: 'Bissouma',
      last_name_upper: 'BISSOUMA',
    },
    {
      _id: '6166c87de65c4ef2043dccb1',
      opta_ID: '2imz9ikcnqdhrbdwmb8un53ze',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-12-30',
      foot: '',
      height: '',
      opta_first_name: 'Antef',
      opta_last_name: 'Tsoungui',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Antef',
      first_name_upper: 'ANTEF',
      last_name: 'Tsoungui',
      last_name_upper: 'TSOUNGUI',
    },
    {
      _id: '6166c87de65c4ef2043dcccd',
      opta_ID: '2up6hmpgok15ge6cqkb8j444p',
      __v: 0,
      country_of_birth: 'Zambia',
      date_of_birth: '1998-01-01',
      foot: 'right',
      height: '184',
      opta_first_name: 'Enock',
      opta_last_name: 'Mwepu',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4dd',
      place_of_birth: 'Lusaka',
      type: 'player',
      weight: '76',
      first_name: 'Enock',
      first_name_upper: 'ENOCK',
      last_name: 'Mwepu',
      last_name_upper: 'MWEPU',
    },
    {
      _id: '6166c87de65c4ef2043dccf7',
      opta_ID: '2wwo7wcxz4gfy0ubkzynvrsnp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-07-20',
      foot: 'left',
      height: '180',
      opta_first_name: 'Solomon',
      opta_last_name: 'March',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Eastbourne',
      type: 'player',
      weight: '88',
      first_name: 'Solomon',
      first_name_upper: 'SOLOMON',
      last_name: 'March',
      last_name_upper: 'MARCH',
    },
    {
      _id: '6166c87de65c4ef2043dcd13',
      opta_ID: '31xsuz1icsj7zt1462swjpk2d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-08-18',
      foot: 'right',
      height: '188',
      opta_first_name: 'Jason',
      opta_last_name: 'Steele',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Newton Aycliffe',
      type: 'player',
      weight: '79',
      first_name: 'Jason',
      first_name_upper: 'JASON',
      last_name: 'Steele',
      last_name_upper: 'STEELE',
    },
    {
      _id: '6166c87de65c4ef2043dcd32',
      opta_ID: '36ylt0kjn7t0vajmelu4mbazu',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '2001-06-17',
      foot: 'right',
      height: '188',
      opta_first_name: 'Abdallah Dipo',
      opta_last_name: 'Sima',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Abdallah Dipo',
      first_name_upper: 'ABDALLAH DIPO',
      last_name: 'Sima',
      last_name_upper: 'SIMA',
    },
    {
      _id: '6166c87de65c4ef2043dcd56',
      opta_ID: '3k6znit6fd9mbfcnphlwyvj6d',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1991-06-15',
      foot: 'right',
      height: '181',
      opta_first_name: 'Pascal',
      opta_last_name: 'Groß',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Mannheim',
      type: 'player',
      weight: '78',
      first_name: 'Pascal',
      first_name_upper: 'PASCAL',
      last_name: 'Groß',
      last_name_upper: 'GROSS',
    },
    {
      _id: '6166c87de65c4ef2043dcd70',
      opta_ID: '3sjm87upw8pz4u87lsl3c8l3p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1975-05-20',
      foot: '',
      height: '185',
      opta_first_name: 'Graham',
      opta_last_name: 'Potter',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Solihull',
      type: 'coach',
      weight: '',
      first_name: 'Graham',
      first_name_upper: 'GRAHAM',
      last_name: 'Potter',
      last_name_upper: 'POTTER',
    },
    {
      _id: '6166c87de65c4ef2043dcd8c',
      opta_ID: '3uc9z96xvain84leorf4ls962',
      __v: 0,
      country_of_birth: 'Ecuador',
      date_of_birth: '2001-11-02',
      foot: '',
      height: '178',
      opta_first_name: 'Moisés Isaac',
      opta_last_name: 'Caicedo Corozo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d66a',
      place_of_birth: 'Santo Domingo',
      type: 'player',
      weight: '73',
      first_name: 'Moisés Isaac',
      first_name_upper: 'MOISÉS ISAAC',
      last_name: 'Caicedo Corozo',
      last_name_upper: 'CAICEDO COROZO',
    },
    {
      _id: '6166c87ee65c4ef2043dcdc9',
      opta_ID: '4905drgcc9husos98ppdup4gl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-11-09',
      foot: 'left',
      height: '196',
      opta_first_name: 'Christian',
      opta_last_name: 'Walton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Truro',
      type: 'player',
      weight: '75',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Walton',
      last_name_upper: 'WALTON',
    },
    {
      _id: '6166c87ee65c4ef2043dcdee',
      opta_ID: '4g0nu20yc6mtgtemxj8ccxu22',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-18',
      foot: '',
      height: '',
      opta_first_name: 'Ed',
      opta_last_name: 'Turns',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Ed',
      first_name_upper: 'ED',
      last_name: 'Turns',
      last_name_upper: 'TURNS',
    },
    {
      _id: '6166c87ee65c4ef2043dce2b',
      opta_ID: '4qr07cfbwquppkww057mb9tk9',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '1999-04-07',
      foot: 'right',
      height: '188',
      opta_first_name: 'Jakub Piotr',
      opta_last_name: 'Moder',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Szczecinek',
      type: 'player',
      weight: '78',
      first_name: 'Jakub Piotr',
      first_name_upper: 'JAKUB PIOTR',
      last_name: 'Moder',
      last_name_upper: 'MODER',
    },
    {
      _id: '6166c87ee65c4ef2043dce49',
      opta_ID: '69ivqkcipxp15b7x5jl83ud49',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-09-08',
      foot: 'right',
      height: '180',
      opta_first_name: 'Steven',
      opta_last_name: 'Alzate',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Steven',
      first_name_upper: 'STEVEN',
      last_name: 'Alzate',
      last_name_upper: 'ALZATE',
    },
    {
      _id: '6166c87ee65c4ef2043dce67',
      opta_ID: '6f1ljb0an6d0g4nbap6xgxsyd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-05-09',
      foot: 'left',
      height: '198',
      opta_first_name: 'Dan',
      opta_last_name: 'Burn',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Blyth',
      type: 'player',
      weight: '87',
      first_name: 'Dan',
      first_name_upper: 'DAN',
      last_name: 'Burn',
      last_name_upper: 'BURN',
    },
    {
      _id: '6166c87ee65c4ef2043dce8a',
      opta_ID: '6o9mxqdmxip6ik6w6zgd7k8ut',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-01-04',
      foot: 'right',
      height: '191',
      opta_first_name: 'Adam',
      opta_last_name: 'Webster',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'West Wittering',
      type: 'player',
      weight: '75',
      first_name: 'Adam',
      first_name_upper: 'ADAM',
      last_name: 'Webster',
      last_name_upper: 'WEBSTER',
    },
    {
      _id: '6166c87ee65c4ef2043dcea8',
      opta_ID: '77cfyl34koayx5uzza87go2y1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-12-04',
      foot: 'left',
      height: '180',
      opta_first_name: 'Taylor',
      opta_last_name: 'Richards',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '71',
      first_name: 'Taylor',
      first_name_upper: 'TAYLOR',
      last_name: 'Richards',
      last_name_upper: 'RICHARDS',
    },
    {
      _id: '6166c87ee65c4ef2043dcecd',
      opta_ID: '7c2wnewpcf1idj6ghj6gu0wyd',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1998-07-22',
      foot: 'left',
      height: '172',
      opta_first_name: 'Marc',
      opta_last_name: 'Cucurella Saseta',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Alella',
      type: 'player',
      weight: '66',
      first_name: 'Marc',
      first_name_upper: 'MARC',
      last_name: 'Cucurella Saseta',
      last_name_upper: 'CUCURELLA SASETA',
    },
    {
      _id: '6166c87ee65c4ef2043dceeb',
      opta_ID: '83jpq8fuprm9h1e2thoxv1tyh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-30',
      foot: '',
      height: '',
      opta_first_name: 'Tariq',
      opta_last_name: 'Lamptey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Tariq',
      first_name_upper: 'TARIQ',
      last_name: 'Lamptey',
      last_name_upper: 'LAMPTEY',
    },
    {
      _id: '6166c87ee65c4ef2043dcf06',
      opta_ID: '84yhv0iilunn0vllz1r5fe7l5',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2000-01-23',
      foot: 'right',
      height: '204',
      opta_first_name: 'Kjell',
      opta_last_name: 'Scherpen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: '',
      type: 'player',
      weight: '98',
      first_name: 'Kjell',
      first_name_upper: 'KJELL',
      last_name: 'Scherpen',
      last_name_upper: 'SCHERPEN',
    },
    {
      _id: '6166c87fe65c4ef2043dcf25',
      opta_ID: '8jf11l2tivq8jpw0fblgdvh05',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1992-01-01',
      foot: 'right',
      height: '193',
      opta_first_name: 'Shane Patrick Michael',
      opta_last_name: 'Duffy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Derry',
      type: 'player',
      weight: '76',
      first_name: 'Shane Patrick Michael',
      first_name_upper: 'SHANE PATRICK MICHAEL',
      last_name: 'Duffy',
      last_name_upper: 'DUFFY',
    },
    {
      _id: '6166c87fe65c4ef2043dcf49',
      opta_ID: '8ok0giwhuu7ax85ngr8tunpey',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '2002-06-16',
      foot: '',
      height: '',
      opta_first_name: 'Jeremy Leonel',
      opta_last_name: 'Sarmiento Morante',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d66a',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '',
      first_name: 'Jeremy Leonel',
      first_name_upper: 'JEREMY LEONEL',
      last_name: 'Sarmiento Morante',
      last_name_upper: 'SARMIENTO MORANTE',
    },
    {
      _id: '6166c87fe65c4ef2043dcf66',
      opta_ID: '8qwpdf5jof7o6yy47v6iv11hx',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1994-12-04',
      foot: 'right',
      height: '171',
      opta_first_name: 'Leandro',
      opta_last_name: 'Trossard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Waterschei',
      type: 'player',
      weight: '61',
      first_name: 'Leandro',
      first_name_upper: 'LEANDRO',
      last_name: 'Trossard',
      last_name_upper: 'TROSSARD',
    },
    {
      _id: '6166c87fe65c4ef2043dcf97',
      opta_ID: '9axxsm36vw51uct5hjxmdlrai',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '2001-03-13',
      foot: 'both',
      height: '175',
      opta_first_name: 'Michał',
      opta_last_name: 'Karbownik',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Radom',
      type: 'player',
      weight: '',
      first_name: 'Michał',
      first_name_upper: 'MICHAŁ',
      last_name: 'Karbownik',
      last_name_upper: 'KARBOWNIK',
    },
    {
      _id: '6166c87fe65c4ef2043dcfb8',
      opta_ID: 'aayoay8x24gev2vhrut6xcmad',
      __v: 0,
      country_of_birth: 'Switzerland',
      date_of_birth: '1999-06-22',
      foot: 'left',
      height: '181',
      opta_first_name: 'Andi Avdi',
      opta_last_name: 'Zeqiri',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d909',
      place_of_birth: 'Lausanne',
      type: 'player',
      weight: '72',
      first_name: 'Andi Avdi',
      first_name_upper: 'ANDI AVDI',
      last_name: 'Zeqiri',
      last_name_upper: 'ZEQIRI',
    },
    {
      _id: '6166c87fe65c4ef2043dcfd6',
      opta_ID: 'abe34z2p6hoxv9ycdhytfwkfe',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2001-12-19',
      foot: '',
      height: '',
      opta_first_name: 'Marc',
      opta_last_name: 'Leonard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Marc',
      first_name_upper: 'MARC',
      last_name: 'Leonard',
      last_name_upper: 'LEONARD',
    },
    {
      _id: '6166c87fe65c4ef2043dd00f',
      opta_ID: 'am6ppbdrbz4ml3xsbty53o73d',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1998-12-24',
      foot: 'right',
      height: '176',
      opta_first_name: 'Alexis',
      opta_last_name: 'Mac Allister',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'La Pampa',
      type: 'player',
      weight: '72',
      first_name: 'Alexis',
      first_name_upper: 'ALEXIS',
      last_name: 'Mac Allister',
      last_name_upper: 'MAC ALLISTER',
    },
    {
      _id: '6166c87fe65c4ef2043dd034',
      opta_ID: 'b0dpz0ixcb9hiyurc87z0rotm',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2004-10-19',
      foot: '',
      height: '',
      opta_first_name: 'Evan',
      opta_last_name: 'Ferguson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Evan',
      first_name_upper: 'EVAN',
      last_name: 'Ferguson',
      last_name_upper: 'FERGUSON',
    },
    {
      _id: '6166c87fe65c4ef2043dd050',
      opta_ID: 'b78dxfxiyuaac9k80pa0fev9x',
      __v: 0,
      country_of_birth: 'Romania',
      date_of_birth: '1993-04-11',
      foot: 'right',
      height: '180',
      opta_first_name: 'Florin',
      opta_last_name: 'Andone',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7ef',
      place_of_birth: 'Botoșani',
      type: 'player',
      weight: '78',
      first_name: 'Florin',
      first_name_upper: 'FLORIN',
      last_name: 'Andone',
      last_name_upper: 'ANDONE',
    },
    {
      _id: '6166c87fe65c4ef2043dd06b',
      opta_ID: 'bbgnf53iva9u2rzc6xz045j9x',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1993-11-07',
      foot: 'right',
      height: '193',
      opta_first_name: 'Jürgen',
      opta_last_name: 'Locadia',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Emmen',
      type: 'player',
      weight: '85',
      first_name: 'Jürgen',
      first_name_upper: 'JÜRGEN',
      last_name: 'Locadia',
      last_name_upper: 'LOCADIA',
    },
    {
      _id: '6166c880e65c4ef2043dd08d',
      opta_ID: 'bh20puvz2w21mnag7ul1g3c9h',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1992-01-15',
      foot: 'right',
      height: '184',
      opta_first_name: 'Joël Ivo',
      opta_last_name: 'Veltman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Velsen',
      type: 'player',
      weight: '75',
      first_name: 'Joël Ivo',
      first_name_upper: 'JOËL IVO',
      last_name: 'Veltman',
      last_name_upper: 'VELTMAN',
    },
    {
      _id: '6166c880e65c4ef2043dd0ad',
      opta_ID: 'c0yxqwkxqx1sty7abj1qwh9zp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-11-26',
      foot: 'right',
      height: '185',
      opta_first_name: 'Daniel Nii',
      opta_last_name: 'Tackie Mensah Welbeck',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Manchester',
      type: 'player',
      weight: '73',
      first_name: 'Daniel Nii',
      first_name_upper: 'DANIEL NII',
      last_name: 'Tackie Mensah Welbeck',
      last_name_upper: 'TACKIE MENSAH WELBECK',
    },
    {
      _id: '6166c880e65c4ef2043dd0d4',
      opta_ID: 'c69ep1zqxlen64ckdh7c2eevp',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1963-07-18',
      foot: '',
      height: '',
      opta_first_name: 'Billy',
      opta_last_name: 'Reid',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'assistant coach',
      weight: '',
      first_name: 'Billy',
      first_name_upper: 'BILLY',
      last_name: 'Reid',
      last_name_upper: 'REID',
    },
    {
      _id: '6166c880e65c4ef2043dd100',
      opta_ID: 'cpchs83o7jkdwzdclxgm8q0gl',
      __v: 0,
      country_of_birth: 'South Africa',
      date_of_birth: '1994-05-13',
      foot: 'left',
      height: '175',
      opta_first_name: 'Percy Muzi',
      opta_last_name: 'Tau',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4c5',
      place_of_birth: 'Witbank',
      type: 'player',
      weight: '',
      first_name: 'Percy Muzi',
      first_name_upper: 'PERCY MUZI',
      last_name: 'Tau',
      last_name_upper: 'TAU',
    },
    {
      _id: '6166c880e65c4ef2043dd11f',
      opta_ID: 'crkiffz2cvig7rpf1llg3mane',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2003-10-15',
      foot: '',
      height: '',
      opta_first_name: 'Andrew',
      opta_last_name: 'Moran',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Andrew',
      first_name_upper: 'ANDREW',
      last_name: 'Moran',
      last_name_upper: 'MORAN',
    },
    {
      _id: '6166c880e65c4ef2043dd13d',
      opta_ID: 'ctpm8dcmrebv2ygrdweo1kt0p',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2000-01-28',
      foot: '',
      height: '',
      opta_first_name: 'Aaron Anthony',
      opta_last_name: 'Connolly',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Galway',
      type: 'player',
      weight: '',
      first_name: 'Aaron Anthony',
      first_name_upper: 'AARON ANTHONY',
      last_name: 'Connolly',
      last_name_upper: 'CONNOLLY',
    },
    {
      _id: '6166c880e65c4ef2043dd159',
      opta_ID: 'd9yxninyn4bac8f0ufxsnlqll',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1997-11-18',
      foot: 'left',
      height: '197',
      opta_first_name: 'Robert',
      opta_last_name: 'Lynch Sánchez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Cartagena',
      type: 'player',
      weight: '90',
      first_name: 'Robert',
      first_name_upper: 'ROBERT',
      last_name: 'Lynch Sánchez',
      last_name_upper: 'LYNCH SÁNCHEZ',
    },
    {
      _id: '6166c880e65c4ef2043dd17c',
      opta_ID: 'e23f2mp5xqkwgcfnhmaknh9ex',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-03-25',
      foot: 'right',
      height: '185',
      opta_first_name: 'Thomas',
      opta_last_name: 'McGill',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Haywards Heath',
      type: 'player',
      weight: '80',
      first_name: 'Thomas',
      first_name_upper: 'THOMAS',
      last_name: 'McGill',
      last_name_upper: 'MCGILL',
    },
    {
      _id: '6166c880e65c4ef2043dd19b',
      opta_ID: 'e7dz8u1uz579dl68v1p40fq7e',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2002-06-07',
      foot: '',
      height: '178',
      opta_first_name: 'James',
      opta_last_name: 'Furlong',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Dublin',
      type: 'player',
      weight: '72',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Furlong',
      last_name_upper: 'FURLONG',
    },
    {
      _id: '6166c880e65c4ef2043dd1b7',
      opta_ID: 'ex0sua0fyuk5kb3uwbxm7brvt',
      __v: 0,
      country_of_birth: 'Romania',
      date_of_birth: '1999-03-27',
      foot: 'right',
      height: '192',
      opta_first_name: 'Tudor Cristian',
      opta_last_name: 'Băluţă',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7ef',
      place_of_birth: 'Craiova',
      type: 'player',
      weight: '80',
      first_name: 'Tudor Cristian',
      first_name_upper: 'TUDOR CRISTIAN',
      last_name: 'Băluţă',
      last_name_upper: 'BĂLUŢĂ',
    },
    {
      _id: '6166c881e65c4ef2043dd1d1',
      opta_ID: 'f34582vxl4yv5qqudl09a7umt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1988-05-10',
      foot: 'right',
      height: '172',
      opta_first_name: 'Adam David',
      opta_last_name: 'Lallana',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'St Albans',
      type: 'player',
      weight: '73',
      first_name: 'Adam David',
      first_name_upper: 'ADAM DAVID',
      last_name: 'Lallana',
      last_name_upper: 'LALLANA',
    },
    {
      _id: '6166c881e65c4ef2043dd1ef',
      opta_ID: 'h4x8lz5ou8esdao80mvls9yi',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-05-10',
      foot: 'left',
      height: '175',
      opta_first_name: 'Hayden',
      opta_last_name: 'Roberts',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Brighton',
      type: 'player',
      weight: '68',
      first_name: 'Hayden',
      first_name_upper: 'HAYDEN',
      last_name: 'Roberts',
      last_name_upper: 'ROBERTS',
    },
    {
      _id: '6166c881e65c4ef2043dd20f',
      opta_ID: 'jagvvzc7329tf2c64mnc251',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-11-21',
      foot: 'right',
      height: '192',
      opta_first_name: 'Lewis',
      opta_last_name: 'Dunk',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Brighton',
      type: 'player',
      weight: '88',
      first_name: 'Lewis',
      first_name_upper: 'LEWIS',
      last_name: 'Dunk',
      last_name_upper: 'DUNK',
    },
    {
      _id: '6166c881e65c4ef2043dd238',
      opta_ID: 'xd3gpwotm25q4mo2fkip8vhm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-26',
      foot: '',
      height: '',
      opta_first_name: 'Odeluga',
      opta_last_name: 'Offiah',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Odeluga',
      first_name_upper: 'ODELUGA',
      last_name: 'Offiah',
      last_name_upper: 'OFFIAH',
    },
    {
      _id: '6166c881e65c4ef2043dd252',
      opta_ID: 'xu7mykgl15kaib3olilmsez9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1996-08-14',
      foot: 'right',
      height: '173',
      opta_first_name: 'Neal',
      opta_last_name: 'Maupay',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Versailles',
      type: 'player',
      weight: '69',
      first_name: 'Neal',
      first_name_upper: 'NEAL',
      last_name: 'Maupay',
      last_name_upper: 'MAUPAY',
    },
    {
      _id: '6166c887e65c4ef2043ddb62',
      opta_ID: '33ba9gzkz30b5m4049ze1rw7p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-06-25',
      foot: 'right',
      height: '183',
      opta_first_name: 'Jack',
      opta_last_name: 'Cork',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '70',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Cork',
      last_name_upper: 'CORK',
    },
    {
      _id: '6166c887e65c4ef2043ddb82',
      opta_ID: '35jaxclh329duzefna708jk5x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-11-19',
      foot: 'right',
      height: '185',
      opta_first_name: 'James',
      opta_last_name: 'Tarkowski',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Manchester',
      type: 'player',
      weight: '81',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Tarkowski',
      last_name_upper: 'TARKOWSKI',
    },
    {
      _id: '6166c888e65c4ef2043ddb9d',
      opta_ID: '4740i4523j4dvvf4lnmtsg9n9',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1985-06-28',
      foot: 'right',
      height: '180',
      opta_first_name: 'Phil',
      opta_last_name: 'Bardsley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Salford',
      type: 'player',
      weight: '74',
      first_name: 'Phil',
      first_name_upper: 'PHIL',
      last_name: 'Bardsley',
      last_name_upper: 'BARDSLEY',
    },
    {
      _id: '6166c888e65c4ef2043ddbc5',
      opta_ID: '4s848k6xkf7jqp11jkoit2a51',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-08-12',
      foot: 'right',
      height: '195',
      opta_first_name: 'Will',
      opta_last_name: 'Norris',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Watford',
      type: 'player',
      weight: '74',
      first_name: 'Will',
      first_name_upper: 'WILL',
      last_name: 'Norris',
      last_name_upper: 'NORRIS',
    },
    {
      _id: '6166c888e65c4ef2043ddbe6',
      opta_ID: '5raent8a0bdt01n7769n3dw16',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-01-30',
      foot: 'right',
      height: '186',
      opta_first_name: 'Bobby',
      opta_last_name: 'Thomas',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Chester',
      type: 'player',
      weight: '',
      first_name: 'Bobby',
      first_name_upper: 'BOBBY',
      last_name: 'Thomas',
      last_name_upper: 'THOMAS',
    },
    {
      _id: '6166c888e65c4ef2043ddc11',
      opta_ID: '66939rcic8zhw13nlcniiq4t1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-09-21',
      foot: 'left',
      height: '180',
      opta_first_name: 'Ben',
      opta_last_name: 'Mee',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sale',
      type: 'player',
      weight: '74',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Mee',
      last_name_upper: 'MEE',
    },
    {
      _id: '6166c888e65c4ef2043ddc36',
      opta_ID: '6wa837v5i330nm5lqm9nckfrp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-06-12',
      foot: 'right',
      height: '169',
      opta_first_name: 'Dale',
      opta_last_name: 'Stephens',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bolton',
      type: 'player',
      weight: '71',
      first_name: 'Dale',
      first_name_upper: 'DALE',
      last_name: 'Stephens',
      last_name_upper: 'STEPHENS',
    },
    {
      _id: '6166c888e65c4ef2043ddc57',
      opta_ID: '72e3or4zfv7cwpklt5yofr8fe',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-02-07',
      foot: '',
      height: '',
      opta_first_name: 'Lewis',
      opta_last_name: 'Richardson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Lewis',
      first_name_upper: 'LEWIS',
      last_name: 'Richardson',
      last_name_upper: 'RICHARDSON',
    },
    {
      _id: '6166c888e65c4ef2043ddc88',
      opta_ID: '7qgt7sht6py670pt6t4wjhqay',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-11-22',
      foot: 'left',
      height: '',
      opta_first_name: 'Dwight',
      opta_last_name: 'McNeil',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Dwight',
      first_name_upper: 'DWIGHT',
      last_name: 'McNeil',
      last_name_upper: 'MCNEIL',
    },
    {
      _id: '6166c888e65c4ef2043ddcae',
      opta_ID: '7sr1pnf0p9xmgu7xx6bbd4w5x',
      __v: 0,
      country_of_birth: 'Iceland',
      date_of_birth: '1990-10-27',
      foot: 'left',
      height: '186',
      opta_first_name: 'Johann',
      opta_last_name: 'Berg Guðmunds­son',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d777',
      place_of_birth: 'Reykjavík',
      type: 'player',
      weight: '78',
      first_name: 'Johann',
      first_name_upper: 'JOHANN',
      last_name: 'Berg Guðmunds­son',
      last_name_upper: 'BERG GUÐMUNDS­SON',
    },
    {
      _id: '6166c888e65c4ef2043ddce9',
      opta_ID: '8bhdwi4p824s82bbzkh2vy6t',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1987-01-24',
      foot: 'right',
      height: '198',
      opta_first_name: 'Wayne Robert',
      opta_last_name: 'Hennessey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Bangor',
      type: 'player',
      weight: '90',
      first_name: 'Wayne Robert',
      first_name_upper: 'WAYNE ROBERT',
      last_name: 'Hennessey',
      last_name_upper: 'HENNESSEY',
    },
    {
      _id: '6166c888e65c4ef2043ddd41',
      opta_ID: '94aaeni055fwrbnnpr1vqi2tx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-06-09',
      foot: 'right',
      height: '180',
      opta_first_name: 'Matthew',
      opta_last_name: 'Lowton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Chesterfield',
      type: 'player',
      weight: '78',
      first_name: 'Matthew',
      first_name_upper: 'MATTHEW',
      last_name: 'Lowton',
      last_name_upper: 'LOWTON',
    },
    {
      _id: '6166c888e65c4ef2043ddd68',
      opta_ID: '9g7jit9xa9c5b25gfl6wy7kd1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-04-01',
      foot: 'right',
      height: '175',
      opta_first_name: 'Ashley',
      opta_last_name: 'Westwood',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Nantwich',
      type: 'player',
      weight: '80',
      first_name: 'Ashley',
      first_name_upper: 'ASHLEY',
      last_name: 'Westwood',
      last_name_upper: 'WESTWOOD',
    },
    {
      _id: '6166c889e65c4ef2043ddd83',
      opta_ID: '9k0kubwlwu9axyiuwjp3zg2qx',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1995-09-23',
      foot: 'right',
      height: '175',
      opta_first_name: 'Connor',
      opta_last_name: 'Roberts',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Neath',
      type: 'player',
      weight: '71',
      first_name: 'Connor',
      first_name_upper: 'CONNOR',
      last_name: 'Roberts',
      last_name_upper: 'ROBERTS',
    },
    {
      _id: '6166c889e65c4ef2043ddda6',
      opta_ID: '9niqvxfgy02wzphokpoh6u66t',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-09-18',
      foot: 'left',
      height: '176',
      opta_first_name: 'Charlie',
      opta_last_name: 'Taylor',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'York',
      type: 'player',
      weight: '70',
      first_name: 'Charlie',
      first_name_upper: 'CHARLIE',
      last_name: 'Taylor',
      last_name_upper: 'TAYLOR',
    },
    {
      _id: '6166c889e65c4ef2043dddc8',
      opta_ID: '9s9i1a7hdq5bud8ma2dqi295h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-04-19',
      foot: 'right',
      height: '191',
      opta_first_name: 'Nick',
      opta_last_name: 'Pope',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Soham',
      type: 'player',
      weight: '76',
      first_name: 'Nick',
      first_name_upper: 'NICK',
      last_name: 'Pope',
      last_name_upper: 'POPE',
    },
    {
      _id: '6166c889e65c4ef2043dddf4',
      opta_ID: 'a145j0ygpazk4wq1qo8wiamz9',
      __v: 0,
      country_of_birth: 'Czech Republic',
      date_of_birth: '1992-05-01',
      foot: 'right',
      height: '180',
      opta_first_name: 'Matěj',
      opta_last_name: 'Vydra',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: 'Chotebor',
      type: 'player',
      weight: '71',
      first_name: 'Matěj',
      first_name_upper: 'MATĚJ',
      last_name: 'Vydra',
      last_name_upper: 'VYDRA',
    },
    {
      _id: '6166c889e65c4ef2043dde12',
      opta_ID: 'a5jevih0cosa0398dvl3bz3rp',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1988-08-07',
      foot: 'left',
      height: '183',
      opta_first_name: 'Erik',
      opta_last_name: 'Pieters',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Tiel',
      type: 'player',
      weight: '84',
      first_name: 'Erik',
      first_name_upper: 'ERIK',
      last_name: 'Pieters',
      last_name_upper: 'PIETERS',
    },
    {
      _id: '6166c889e65c4ef2043dde36',
      opta_ID: 'blczo3w34ik1wuu9r042gfvf9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-07-29',
      foot: 'right',
      height: '185',
      opta_first_name: 'Jay',
      opta_last_name: 'Rodriguez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Burnley',
      type: 'player',
      weight: '80',
      first_name: 'Jay',
      first_name_upper: 'JAY',
      last_name: 'Rodriguez',
      last_name_upper: 'RODRIGUEZ',
    },
    {
      _id: '6166c889e65c4ef2043dde53',
      opta_ID: 'bwgjvtqtuflgq4ov3xis75vbp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-10-30',
      foot: 'right',
      height: '186',
      opta_first_name: 'Ashley Luke',
      opta_last_name: 'Barnes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bath',
      type: 'player',
      weight: '77',
      first_name: 'Ashley Luke',
      first_name_upper: 'ASHLEY LUKE',
      last_name: 'Barnes',
      last_name_upper: 'BARNES',
    },
    {
      _id: '6166c889e65c4ef2043dde6d',
      opta_ID: 'cjdycctu96jilvbvnl7ndh9sl',
      __v: 0,
      country_of_birth: 'New Zealand',
      date_of_birth: '1991-12-07',
      foot: 'right',
      height: '191',
      opta_first_name: 'Christopher Grant',
      opta_last_name: 'Wood',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d69f',
      place_of_birth: 'Auckland',
      type: 'player',
      weight: '81',
      first_name: 'Christopher Grant',
      first_name_upper: 'CHRISTOPHER GRANT',
      last_name: 'Wood',
      last_name_upper: 'WOOD',
    },
    {
      _id: '6166c889e65c4ef2043dde95',
      opta_ID: 'csinkvzxcphggdjeflmo173zd',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2001-04-30',
      foot: 'right',
      height: '193',
      opta_first_name: 'Nathan Michael',
      opta_last_name: 'Collins',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Leixlip',
      type: 'player',
      weight: '',
      first_name: 'Nathan Michael',
      first_name_upper: 'NATHAN MICHAEL',
      last_name: 'Collins',
      last_name_upper: 'COLLINS',
    },
    {
      _id: '6166c889e65c4ef2043ddeb0',
      opta_ID: 'd4t6vzdlry8s22l0qhm1bdn11',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-12-19',
      foot: 'right',
      height: '178',
      opta_first_name: 'Josh',
      opta_last_name: 'Brownhill',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Warrington',
      type: 'player',
      weight: '69',
      first_name: 'Josh',
      first_name_upper: 'JOSH',
      last_name: 'Brownhill',
      last_name_upper: 'BROWNHILL',
    },
    {
      _id: '6166c889e65c4ef2043ddec9',
      opta_ID: 'd6azt92y5518lmik69zknjx05',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1967-12-14',
      foot: '',
      height: '',
      opta_first_name: 'Ian',
      opta_last_name: 'Woan',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Heswall',
      type: 'assistant coach',
      weight: '',
      first_name: 'Ian',
      first_name_upper: 'IAN',
      last_name: 'Woan',
      last_name_upper: 'WOAN',
    },
    {
      _id: '6166c88ae65c4ef2043ddee4',
      opta_ID: 'e1w0qzt14fcj84j8c5xde8lt6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-03-19',
      foot: '',
      height: '',
      opta_first_name: 'Owen',
      opta_last_name: 'Dodgson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Owen',
      first_name_upper: 'OWEN',
      last_name: 'Dodgson',
      last_name_upper: 'DODGSON',
    },
    {
      _id: '6166c88ae65c4ef2043ddf00',
      opta_ID: 'e9f7232gs1vgtv7frh8spdbkl',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1990-08-18',
      foot: 'right',
      height: '188',
      opta_first_name: 'Kevin',
      opta_last_name: 'Long',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Cork',
      type: 'player',
      weight: '83',
      first_name: 'Kevin',
      first_name_upper: 'KEVIN',
      last_name: 'Long',
      last_name_upper: 'LONG',
    },
    {
      _id: '6166c88ae65c4ef2043ddf21',
      opta_ID: 'f0w1ygf25k3cfj5jjkfqf40yd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1971-06-28',
      foot: '',
      height: '',
      opta_first_name: 'Sean',
      opta_last_name: 'Dyche',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Kettering',
      type: 'coach',
      weight: '',
      first_name: 'Sean',
      first_name_upper: 'SEAN',
      last_name: 'Dyche',
      last_name_upper: 'DYCHE',
    },
    {
      _id: '6166c88ae65c4ef2043ddf45',
      opta_ID: 'f2pknjv2uohldehbp39j0so2d',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1996-09-27',
      foot: 'right',
      height: '179',
      opta_first_name: 'Gnaly Maxwel',
      opta_last_name: 'Cornet',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Bregbo',
      type: 'player',
      weight: '69',
      first_name: 'Gnaly Maxwel',
      first_name_upper: 'GNALY MAXWEL',
      last_name: 'Cornet',
      last_name_upper: 'CORNET',
    },
    {
      _id: '6166c88ae65c4ef2043ddf62',
      opta_ID: 'z0b91n9uq42fn1lx6p4t1g45',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1987-04-16',
      foot: 'right',
      height: '165',
      opta_first_name: 'Aaron',
      opta_last_name: 'Lennon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Chapeltown',
      type: 'player',
      weight: '63',
      first_name: 'Aaron',
      first_name_upper: 'AARON',
      last_name: 'Lennon',
      last_name_upper: 'LENNON',
    },
    {
      _id: '6166c89ae65c4ef2043df714',
      opta_ID: '10h3s39lz43wevvxuro2s9hhx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1978-12-22',
      foot: '',
      height: '167',
      opta_first_name: 'Jody',
      opta_last_name: 'Morris',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'assistant coach',
      weight: '',
      first_name: 'Jody',
      first_name_upper: 'JODY',
      last_name: 'Morris',
      last_name_upper: 'MORRIS',
    },
    {
      _id: '6166c89be65c4ef2043df72e',
      opta_ID: '1av9mmv19jwvc4xdr0p9owyxh',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1996-04-10',
      foot: 'right',
      height: '187',
      opta_first_name: 'Andreas',
      opta_last_name: 'Bødtker Christensen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Lillerød',
      type: 'player',
      weight: '82',
      first_name: 'Andreas',
      first_name_upper: 'ANDREAS',
      last_name: 'Bødtker Christensen',
      last_name_upper: 'BØDTKER CHRISTENSEN',
    },
    {
      _id: '6166c89be65c4ef2043df770',
      opta_ID: '22s7hpp9k5ihtc2pwiaytdp05',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1992-03-01',
      foot: 'right',
      height: '197',
      opta_first_name: 'Edouard',
      opta_last_name: 'Mendy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: 'Montivilliers',
      type: 'player',
      weight: '86',
      first_name: 'Edouard',
      first_name_upper: 'EDOUARD',
      last_name: 'Mendy',
      last_name_upper: 'MENDY',
    },
    {
      _id: '6166c89be65c4ef2043df78e',
      opta_ID: '2axi9gr1lhuewlt06emrk44q1',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-01-23',
      foot: 'left',
      height: '180',
      opta_first_name: 'Malang',
      opta_last_name: 'Sarr',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Nice',
      type: 'player',
      weight: '70',
      first_name: 'Malang',
      first_name_upper: 'MALANG',
      last_name: 'Sarr',
      last_name_upper: 'SARR',
    },
    {
      _id: '6166c89be65c4ef2043df7af',
      opta_ID: '2f49etxdi4l2tsobpmsg5i5pm',
      __v: 0,
      country_of_birth: 'Finland',
      date_of_birth: '2002-09-05',
      foot: '',
      height: '',
      opta_first_name: 'Lucas',
      opta_last_name: 'Bergström',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d731',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Lucas',
      first_name_upper: 'LUCAS',
      last_name: 'Bergström',
      last_name_upper: 'BERGSTRÖM',
    },
    {
      _id: '6166c89be65c4ef2043df7e9',
      opta_ID: '2pjr6yz65f4uqvwniy4vphn85',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1996-03-06',
      foot: 'right',
      height: '180',
      opta_first_name: 'Timo',
      opta_last_name: 'Werner',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Stuttgart',
      type: 'player',
      weight: '75',
      first_name: 'Timo',
      first_name_upper: 'TIMO',
      last_name: 'Werner',
      last_name_upper: 'WERNER',
    },
    {
      _id: '6166c89be65c4ef2043df821',
      opta_ID: '363nsa82dww242zeo09wpcslx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-02',
      foot: 'right',
      height: '190',
      opta_first_name: 'Tammy',
      opta_last_name: 'Bakumo-Abraham',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '80',
      first_name: 'Tammy',
      first_name_upper: 'TAMMY',
      last_name: 'Bakumo-Abraham',
      last_name_upper: 'BAKUMO-ABRAHAM',
    },
    {
      _id: '6166c89be65c4ef2043df83a',
      opta_ID: '40h47dhimsmeiury61w1ourx1',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1994-11-21',
      foot: 'left',
      height: '184',
      opta_first_name: 'Saúl',
      opta_last_name: 'Ñíguez Esclapez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Elche',
      type: 'player',
      weight: '76',
      first_name: 'Saúl',
      first_name_upper: 'SAÚL',
      last_name: 'Ñíguez Esclapez',
      last_name_upper: 'ÑÍGUEZ ESCLAPEZ',
    },
    {
      _id: '6166c89be65c4ef2043df855',
      opta_ID: '5e2zgfe3ltc6qbnoqdfshqsph',
      __v: 0,
      country_of_birth: 'Sierra Leone',
      date_of_birth: '1999-07-05',
      foot: '',
      height: '',
      opta_first_name: 'Trevoh',
      opta_last_name: 'Chalobah',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Freetown',
      type: 'player',
      weight: '',
      first_name: 'Trevoh',
      first_name_upper: 'TREVOH',
      last_name: 'Chalobah',
      last_name_upper: 'CHALOBAH',
    },
    {
      _id: '6166c89ce65c4ef2043df89e',
      opta_ID: '6e6ttu4unqctb2addbnp5cyga',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-10',
      foot: 'right',
      height: '183',
      opta_first_name: 'Armando',
      opta_last_name: 'Broja',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d6fc',
      place_of_birth: 'Slough',
      type: 'player',
      weight: '',
      first_name: 'Armando',
      first_name_upper: 'ARMANDO',
      last_name: 'Broja',
      last_name_upper: 'BROJA',
    },
    {
      _id: '6166c89ce65c4ef2043df8c5',
      opta_ID: '6fzbp94hf19ysotdassrqdc5x',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1993-03-03',
      foot: 'right',
      height: '190',
      opta_first_name: 'Antonio',
      opta_last_name: 'Rüdiger',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Berlin',
      type: 'player',
      weight: '85',
      first_name: 'Antonio',
      first_name_upper: 'ANTONIO',
      last_name: 'Rüdiger',
      last_name_upper: 'RÜDIGER',
    },
    {
      _id: '6166c89ce65c4ef2043df8e5',
      opta_ID: '6j3wbk9xlq4om4m8ih6b3tyvp',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1994-10-03',
      foot: 'right',
      height: '189',
      opta_first_name: 'Kepa',
      opta_last_name: 'Arrizabalaga Revuelta',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Ondárroa',
      type: 'player',
      weight: '84',
      first_name: 'Kepa',
      first_name_upper: 'KEPA',
      last_name: 'Arrizabalaga Revuelta',
      last_name_upper: 'ARRIZABALAGA REVUELTA',
    },
    {
      _id: '6166c89ce65c4ef2043df936',
      opta_ID: '7usl4495egoptlrd762f0arbp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-12-21',
      foot: 'left',
      height: '178',
      opta_first_name: 'Ben',
      opta_last_name: 'Chilwell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Milton Keynes',
      type: 'player',
      weight: '77',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Chilwell',
      last_name_upper: 'CHILWELL',
    },
    {
      _id: '6166c89ce65c4ef2043df952',
      opta_ID: '7y6f63pj7horkzhdzbge02rqh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-11-07',
      foot: 'right',
      height: '182',
      opta_first_name: 'Callum',
      opta_last_name: 'Hudson-Odoi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '76',
      first_name: 'Callum',
      first_name_upper: 'CALLUM',
      last_name: 'Hudson-Odoi',
      last_name_upper: 'HUDSON-ODOI',
    },
    {
      _id: '6166c89ce65c4ef2043df972',
      opta_ID: '8nh6qf9i1d0qw7nx4wk25qzth',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-01-10',
      foot: 'right',
      height: '178',
      opta_first_name: 'Mason',
      opta_last_name: 'Mount',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Portsmouth',
      type: 'player',
      weight: '70',
      first_name: 'Mason',
      first_name_upper: 'MASON',
      last_name: 'Mount',
      last_name_upper: 'MOUNT',
    },
    {
      _id: '6166c89ce65c4ef2043df98b',
      opta_ID: '8qezzr83ofw7htqufohg2fwlx',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1994-08-03',
      foot: 'left',
      height: '176',
      opta_first_name: 'Emerson',
      opta_last_name: 'Palmieri dos Santos',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Santos',
      type: 'player',
      weight: '63',
      first_name: 'Emerson',
      first_name_upper: 'EMERSON',
      last_name: 'Palmieri dos Santos',
      last_name_upper: 'PALMIERI DOS SANTOS',
    },
    {
      _id: '6166c89ce65c4ef2043df9a4',
      opta_ID: '8qgbxff7xlg0zjtyees7ljljp',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1993-05-13',
      foot: 'left',
      height: '190',
      opta_first_name: 'Romelu',
      opta_last_name: 'Lukaku Menama',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Antwerpen',
      type: 'player',
      weight: '93',
      first_name: 'Romelu',
      first_name_upper: 'ROMELU',
      last_name: 'Lukaku Menama',
      last_name_upper: 'LUKAKU MENAMA',
    },
    {
      _id: '6166c89de65c4ef2043df9c4',
      opta_ID: '8r547g1qv706wo9iqr5unfnpx',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1993-03-19',
      foot: 'left',
      height: '181',
      opta_first_name: 'Hakim',
      opta_last_name: 'Ziyech',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d48b',
      place_of_birth: 'Dronten',
      type: 'player',
      weight: '65',
      first_name: 'Hakim',
      first_name_upper: 'HAKIM',
      last_name: 'Ziyech',
      last_name_upper: 'ZIYECH',
    },
    {
      _id: '6166c89de65c4ef2043dfa06',
      opta_ID: '93soe2rjn0z874cc9ofcesgkp',
      __v: 0,
      country_of_birth: 'USA',
      date_of_birth: '1998-09-18',
      foot: 'both',
      height: '172',
      opta_first_name: 'Christian',
      opta_last_name: 'Mate Pulisic',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d605',
      place_of_birth: 'Hershey',
      type: 'player',
      weight: '69',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Mate Pulisic',
      last_name_upper: 'MATE PULISIC',
    },
    {
      _id: '6166c89de65c4ef2043dfa2c',
      opta_ID: '9i7lb16qty1t21bnc5h7skclx',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1990-12-28',
      foot: 'left',
      height: '188',
      opta_first_name: 'Marcos',
      opta_last_name: 'Alonso Mendoza',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '84',
      first_name: 'Marcos',
      first_name_upper: 'MARCOS',
      last_name: 'Alonso Mendoza',
      last_name_upper: 'ALONSO MENDOZA',
    },
    {
      _id: '6166c89de65c4ef2043dfa47',
      opta_ID: '9id859f7xnqwj0jazjzkbjzdh',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1991-12-20',
      foot: 'both',
      height: '180',
      opta_first_name: 'Jorge Luiz',
      opta_last_name: 'Frello Filho',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Imbituba',
      type: 'player',
      weight: '65',
      first_name: 'Jorge Luiz',
      first_name_upper: 'JORGE LUIZ',
      last_name: 'Frello Filho',
      last_name_upper: 'FRELLO FILHO',
    },
    {
      _id: '6166c89de65c4ef2043dfa6a',
      opta_ID: '9l3cvbbgw484v7la0bjv43mj9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-05-24',
      foot: 'right',
      height: '194',
      opta_first_name: 'Marcus',
      opta_last_name: 'Bettinelli',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Camberwell',
      type: 'player',
      weight: '82',
      first_name: 'Marcus',
      first_name_upper: 'MARCUS',
      last_name: 'Bettinelli',
      last_name_upper: 'BETTINELLI',
    },
    {
      _id: '6166c89de65c4ef2043dfa81',
      opta_ID: '9qdm0nxg1g046olii48z3crf9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-10-27',
      foot: 'right',
      height: '190',
      opta_first_name: 'Kurt Happy',
      opta_last_name: 'Zouma',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Lyon',
      type: 'player',
      weight: '96',
      first_name: 'Kurt Happy',
      first_name_upper: 'KURT HAPPY',
      last_name: 'Zouma',
      last_name_upper: 'ZOUMA',
    },
    {
      _id: '6166c89de65c4ef2043dfabc',
      opta_ID: 'am70epdl0361z5af8ahskcdsl',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1991-03-29',
      foot: 'right',
      height: '168',
      opta_first_name: "N'Golo",
      opta_last_name: 'Kanté',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Paris',
      type: 'player',
      weight: '68',
      first_name: "N'Golo",
      first_name_upper: "N'GOLO",
      last_name: 'Kanté',
      last_name_upper: 'KANTÉ',
    },
    {
      _id: '6166c89de65c4ef2043dfaf1',
      opta_ID: 'bvw1jgmhfyc896gfi6uj0skwl',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1989-08-28',
      foot: 'right',
      height: '178',
      opta_first_name: 'César',
      opta_last_name: 'Azpilicueta Tanco',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Pamplona',
      type: 'player',
      weight: '76',
      first_name: 'César',
      first_name_upper: 'CÉSAR',
      last_name: 'Azpilicueta Tanco',
      last_name_upper: 'AZPILICUETA TANCO',
    },
    {
      _id: '6166c89ee65c4ef2043dfb0a',
      opta_ID: 'byjq5v4tqpjt9scaj17ccuesq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-11-23',
      foot: 'right',
      height: '185',
      opta_first_name: 'Faustino Adebola Rasheed',
      opta_last_name: 'Anjorin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Poole',
      type: 'player',
      weight: '73',
      first_name: 'Faustino Adebola Rasheed',
      first_name_upper: 'FAUSTINO ADEBOLA RASHEED',
      last_name: 'Anjorin',
      last_name_upper: 'ANJORIN',
    },
    {
      _id: '6166c89ee65c4ef2043dfb25',
      opta_ID: 'c6pqhaqusk8s1c2m21i5wtrkl',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1984-09-22',
      foot: 'right',
      height: '183',
      opta_first_name: 'Thiago Emiliano',
      opta_last_name: 'da Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Rio de Janeiro',
      type: 'player',
      weight: '79',
      first_name: 'Thiago Emiliano',
      first_name_upper: 'THIAGO EMILIANO',
      last_name: 'da Silva',
      last_name_upper: 'DA SILVA',
    },
    {
      _id: '6166c89ee65c4ef2043dfb43',
      opta_ID: 'c9epkro6fot3km9zak1kswfhm',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2001-12-31',
      foot: '',
      height: '196',
      opta_first_name: 'Xavier Tshimanga Kamaluba',
      opta_last_name: 'Mbuyamba',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Maastricht',
      type: 'player',
      weight: '',
      first_name: 'Xavier Tshimanga Kamaluba',
      first_name_upper: 'XAVIER TSHIMANGA KAMALUBA',
      last_name: 'Mbuyamba',
      last_name_upper: 'MBUYAMBA',
    },
    {
      _id: '6166c89ee65c4ef2043dfb7c',
      opta_ID: 'ct6uptb7n4hiejohdvs6jkic5',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1973-08-29',
      foot: '',
      height: '192',
      opta_first_name: 'Thomas',
      opta_last_name: 'Tuchel',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Krumbach',
      type: 'coach',
      weight: '85',
      first_name: 'Thomas',
      first_name_upper: 'THOMAS',
      last_name: 'Tuchel',
      last_name_upper: 'TUCHEL',
    },
    {
      _id: '6166c89ee65c4ef2043dfba2',
      opta_ID: 'dczrgxyolp7lcz413wkz7ap61',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-12-08',
      foot: '',
      height: '183',
      opta_first_name: 'Reece',
      opta_last_name: 'James',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '82',
      first_name: 'Reece',
      first_name_upper: 'REECE',
      last_name: 'James',
      last_name_upper: 'JAMES',
    },
    {
      _id: '6166c89ee65c4ef2043dfbc7',
      opta_ID: 'dxeju5zcb6j8etdgwq0kweor9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-12-05',
      foot: 'right',
      height: '189',
      opta_first_name: 'Ross',
      opta_last_name: 'Barkley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '76',
      first_name: 'Ross',
      first_name_upper: 'ROSS',
      last_name: 'Barkley',
      last_name_upper: 'BARKLEY',
    },
    {
      _id: '6166c89ee65c4ef2043dfbe3',
      opta_ID: 'e6jaaazxqeczj6ziaj0qnqpxx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-01-23',
      foot: 'right',
      height: '191',
      opta_first_name: 'Ruben',
      opta_last_name: 'Loftus-Cheek',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '88',
      first_name: 'Ruben',
      first_name_upper: 'RUBEN',
      last_name: 'Loftus-Cheek',
      last_name_upper: 'LOFTUS-CHEEK',
    },
    {
      _id: '6166c89ee65c4ef2043dfc03',
      opta_ID: 'ed20ebhqi5c32vbbsze8r6yuh',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1999-06-11',
      foot: 'both',
      height: '188',
      opta_first_name: 'Kai Lukas',
      opta_last_name: 'Havertz',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Aachen',
      type: 'player',
      weight: '83',
      first_name: 'Kai Lukas',
      first_name_upper: 'KAI LUKAS',
      last_name: 'Havertz',
      last_name_upper: 'HAVERTZ',
    },
    {
      _id: '6166c89ee65c4ef2043dfc25',
      opta_ID: 'ehopba709zukxj4vgcync942d',
      __v: 0,
      country_of_birth: 'Austria',
      date_of_birth: '1994-05-06',
      foot: 'right',
      height: '178',
      opta_first_name: 'Mateo',
      opta_last_name: 'Kovačić',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d728',
      place_of_birth: 'Linz',
      type: 'player',
      weight: '78',
      first_name: 'Mateo',
      first_name_upper: 'MATEO',
      last_name: 'Kovačić',
      last_name_upper: 'KOVAČIĆ',
    },
    {
      _id: '6166c89ee65c4ef2043dfc3d',
      opta_ID: 'f1ma05bi3t1hfbflylx5d9tyh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-14',
      foot: 'right',
      height: '183',
      opta_first_name: 'Ethan',
      opta_last_name: 'Ampadu',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Exeter',
      type: 'player',
      weight: '78',
      first_name: 'Ethan',
      first_name_upper: 'ETHAN',
      last_name: 'Ampadu',
      last_name_upper: 'AMPADU',
    },
    {
      _id: '6166c89fe65c4ef2043dfc6e',
      opta_ID: 'wr2f0yl1bf68r73hkjee2pqt',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-08-17',
      foot: 'right',
      height: '185',
      opta_first_name: 'Tiemoué',
      opta_last_name: 'Bakayoko',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Paris',
      type: 'player',
      weight: '77',
      first_name: 'Tiemoué',
      first_name_upper: 'TIEMOUÉ',
      last_name: 'Bakayoko',
      last_name_upper: 'BAKAYOKO',
    },
    {
      _id: '6166c8a1e65c4ef2043dff90',
      opta_ID: 'bos0z54lr710ov1ppq0rahje2',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-28',
      foot: 'right',
      height: '190',
      opta_first_name: 'Matthew',
      opta_last_name: 'Pollock',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Redhill',
      type: 'player',
      weight: '80',
      first_name: 'Matthew',
      first_name_upper: 'MATTHEW',
      last_name: 'Pollock',
      last_name_upper: 'POLLOCK',
    },
    {
      _id: '6166c8aee65c4ef2043e0f03',
      opta_ID: '4v067hf9lvyhgj4o099yvlqwa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-06-14',
      foot: 'left',
      height: '',
      opta_first_name: 'Ben',
      opta_last_name: 'Knight',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Cambridge',
      type: 'player',
      weight: '',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Knight',
      last_name_upper: 'KNIGHT',
    },
    {
      _id: '6166c8aee65c4ef2043e0f3f',
      opta_ID: '5ggqo66owln1s1f6z3gg4s67e',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-12-07',
      foot: '',
      height: '',
      opta_first_name: "J'Neil",
      opta_last_name: 'Lloyd-Bennett',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: "J'Neil",
      first_name_upper: "J'NEIL",
      last_name: 'Lloyd-Bennett',
      last_name_upper: 'LLOYD-BENNETT',
    },
    {
      _id: '6166c8b0e65c4ef2043e1216',
      opta_ID: '1rz6bw398na1i3t29rop7ikt1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-10-29',
      foot: 'right',
      height: '188',
      opta_first_name: 'Joel',
      opta_last_name: 'Ward',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Portsmouth',
      type: 'player',
      weight: '83',
      first_name: 'Joel',
      first_name_upper: 'JOEL',
      last_name: 'Ward',
      last_name_upper: 'WARD',
    },
    {
      _id: '6166c8b0e65c4ef2043e122e',
      opta_ID: '2igy6xcn47rt8brn359pivnxl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-11',
      foot: '',
      height: '',
      opta_first_name: 'Reece',
      opta_last_name: 'Hannam',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Reece',
      first_name_upper: 'REECE',
      last_name: 'Hannam',
      last_name_upper: 'HANNAM',
    },
    {
      _id: '6166c8b0e65c4ef2043e124a',
      opta_ID: '3vt38r8n9081i3a4dj89lx905',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-02-10',
      foot: 'right',
      height: '184',
      opta_first_name: 'Remi',
      opta_last_name: 'Matthews',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Gorleston',
      type: 'player',
      weight: '78',
      first_name: 'Remi',
      first_name_upper: 'REMI',
      last_name: 'Matthews',
      last_name_upper: 'MATTHEWS',
    },
    {
      _id: '6166c8b0e65c4ef2043e1265',
      opta_ID: '3xthf6r5hvlx8oje4rqcs3it',
      __v: 0,
      country_of_birth: 'Serbia',
      date_of_birth: '1991-04-07',
      foot: 'right',
      height: '186',
      opta_first_name: 'Luka',
      opta_last_name: 'Milivojević',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8ec',
      place_of_birth: 'Kragujevac',
      type: 'player',
      weight: '80',
      first_name: 'Luka',
      first_name_upper: 'LUKA',
      last_name: 'Milivojević',
      last_name_upper: 'MILIVOJEVIĆ',
    },
    {
      _id: '6166c8b0e65c4ef2043e1288',
      opta_ID: '4qrs83a12dvxue8w115nga5m',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2001-09-26',
      foot: 'left',
      height: '183',
      opta_first_name: 'Scott Brian',
      opta_last_name: 'Banks',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Linlithgow',
      type: 'player',
      weight: '',
      first_name: 'Scott Brian',
      first_name_upper: 'SCOTT BRIAN',
      last_name: 'Banks',
      last_name_upper: 'BANKS',
    },
    {
      _id: '6166c8b0e65c4ef2043e12a1',
      opta_ID: '4wic6z72nwmhkheme209fcmp5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-06-29',
      foot: '',
      height: '',
      opta_first_name: 'Eberechi',
      opta_last_name: 'Eze',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Eberechi',
      first_name_upper: 'EBERECHI',
      last_name: 'Eze',
      last_name_upper: 'EZE',
    },
    {
      _id: '6166c8b0e65c4ef2043e12ba',
      opta_ID: '5wkso3mls5ulkilr0xzfix1p1',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1996-05-31',
      foot: 'right',
      height: '192',
      opta_first_name: 'Joachim Christian',
      opta_last_name: 'Andersen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'Frederiksberg',
      type: 'player',
      weight: '90',
      first_name: 'Joachim Christian',
      first_name_upper: 'JOACHIM CHRISTIAN',
      last_name: 'Andersen',
      last_name_upper: 'ANDERSEN',
    },
    {
      _id: '6166c8b1e65c4ef2043e12d4',
      opta_ID: '6dqnwhx0eglxhelidxg0dphm2',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-09-01',
      foot: '',
      height: '',
      opta_first_name: 'Tyrick',
      opta_last_name: 'Mitchell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Tyrick',
      first_name_upper: 'TYRICK',
      last_name: 'Mitchell',
      last_name_upper: 'MITCHELL',
    },
    {
      _id: '6166c8b1e65c4ef2043e12f6',
      opta_ID: '6ebb2xcovp1tx5nv5yyncb7x1',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1992-12-23',
      foot: 'both',
      height: '178',
      opta_first_name: 'Jeffrey',
      opta_last_name: 'Schlupp',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Hamburg',
      type: 'player',
      weight: '72',
      first_name: 'Jeffrey',
      first_name_upper: 'JEFFREY',
      last_name: 'Schlupp',
      last_name_upper: 'SCHLUPP',
    },
    {
      _id: '6166c8b1e65c4ef2043e1317',
      opta_ID: '6hkzb4vehsx4wwm9y98euwacq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-26',
      foot: '',
      height: '',
      opta_first_name: 'Robert Nicholas',
      opta_last_name: 'Street',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Oxford',
      type: 'player',
      weight: '',
      first_name: 'Robert Nicholas',
      first_name_upper: 'ROBERT NICHOLAS',
      last_name: 'Street',
      last_name_upper: 'STREET',
    },
    {
      _id: '6166c8b1e65c4ef2043e1351',
      opta_ID: '7yte0egh8y7assst6g0vnn9w5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-03-10',
      foot: 'right',
      height: '196',
      opta_first_name: 'Jack',
      opta_last_name: 'Butland',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bristol',
      type: 'player',
      weight: '95',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Butland',
      last_name_upper: 'BUTLAND',
    },
    {
      _id: '6166c8b1e65c4ef2043e1373',
      opta_ID: '8k88isvd2hwr5e4gn9oxzr2t',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1987-01-10',
      foot: 'right',
      height: '190',
      opta_first_name: 'Vicente',
      opta_last_name: 'Guaita Panadero',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Torrente',
      type: 'player',
      weight: '81',
      first_name: 'Vicente',
      first_name_upper: 'VICENTE',
      last_name: 'Guaita Panadero',
      last_name_upper: 'GUAITA PANADERO',
    },
    {
      _id: '6166c8b1e65c4ef2043e138c',
      opta_ID: '8wl7vvsx3j15tv3kmemf3n7it',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-04-27',
      foot: 'right',
      height: '191',
      opta_first_name: 'Martin',
      opta_last_name: 'Kelly',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bolton',
      type: 'player',
      weight: '77',
      first_name: 'Martin',
      first_name_upper: 'MARTIN',
      last_name: 'Kelly',
      last_name_upper: 'KELLY',
    },
    {
      _id: '6166c8b1e65c4ef2043e13a5',
      opta_ID: '9c927a1i8iqblent14xat7aad',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1991-09-11',
      foot: 'right',
      height: '182',
      opta_first_name: 'Jordan Pierre',
      opta_last_name: 'Ayew',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Marseille',
      type: 'player',
      weight: '80',
      first_name: 'Jordan Pierre',
      first_name_upper: 'JORDAN PIERRE',
      last_name: 'Ayew',
      last_name_upper: 'AYEW',
    },
    {
      _id: '6166c8b1e65c4ef2043e13bb',
      opta_ID: '9ikkppq7uewf36gahgz0kzaol',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1992-11-10',
      foot: 'right',
      height: '180',
      opta_first_name: 'Wilfried',
      opta_last_name: 'Zaha',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Abidjan',
      type: 'player',
      weight: '66',
      first_name: 'Wilfried',
      first_name_upper: 'WILFRIED',
      last_name: 'Zaha',
      last_name_upper: 'ZAHA',
    },
    {
      _id: '6166c8b1e65c4ef2043e13df',
      opta_ID: '9ipux4xiejibplsbbhqz8d5gp',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '2000-07-13',
      foot: 'right',
      height: '182',
      opta_first_name: 'Marc',
      opta_last_name: 'Guehi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Abidjan',
      type: 'player',
      weight: '82',
      first_name: 'Marc',
      first_name_upper: 'MARC',
      last_name: 'Guehi',
      last_name_upper: 'GUEHI',
    },
    {
      _id: '6166c8b1e65c4ef2043e13fb',
      opta_ID: '9wwz6ihpxdxw5wijdy1ez4pg5',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '1976-06-23',
      foot: '',
      height: '',
      opta_first_name: 'Patrick',
      opta_last_name: 'Vieira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Dakar',
      type: 'coach',
      weight: '',
      first_name: 'Patrick',
      first_name_upper: 'PATRICK',
      last_name: 'Vieira',
      last_name_upper: 'VIEIRA',
    },
    {
      _id: '6166c8b1e65c4ef2043e141c',
      opta_ID: 'a1oyrdwf1kyutqivxk753mzmd',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1987-10-07',
      foot: 'right',
      height: '178',
      opta_first_name: 'James',
      opta_last_name: 'McArthur',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'player',
      weight: '75',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'McArthur',
      last_name_upper: 'MCARTHUR',
    },
    {
      _id: '6166c8b2e65c4ef2043e1434',
      opta_ID: 'a6m9jcx0e4l6krv1u0pbfuoyd',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '1989-12-21',
      foot: 'right',
      height: '189',
      opta_first_name: 'Cheikhou',
      opta_last_name: 'Kouyaté',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: 'Dakar',
      type: 'player',
      weight: '83',
      first_name: 'Cheikhou',
      first_name_upper: 'CHEIKHOU',
      last_name: 'Kouyaté',
      last_name_upper: 'KOUYATÉ',
    },
    {
      _id: '6166c8b2e65c4ef2043e1454',
      opta_ID: 'a7uo47nehqo2irfllck81uqxh',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1996-09-09',
      foot: 'left',
      height: '182',
      opta_first_name: 'Jaïro',
      opta_last_name: 'Riedewald',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Haarlem',
      type: 'player',
      weight: '79',
      first_name: 'Jaïro',
      first_name_upper: 'JAÏRO',
      last_name: 'Riedewald',
      last_name_upper: 'RIEDEWALD',
    },
    {
      _id: '6166c8b2e65c4ef2043e1483',
      opta_ID: 'ald5oocxwh4pip8fw6v3ym8h6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-05',
      foot: '',
      height: '',
      opta_first_name: 'Jesuran',
      opta_last_name: 'Rak-Sakyi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Jesuran',
      first_name_upper: 'JESURAN',
      last_name: 'Rak-Sakyi',
      last_name_upper: 'RAK-SAKYI',
    },
    {
      _id: '6166c8b2e65c4ef2043e14a7',
      opta_ID: 'c83h08n0vnjvfgglyefy03h0p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-02-06',
      foot: '',
      height: '',
      opta_first_name: 'Conor',
      opta_last_name: 'Gallagher',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Epsom',
      type: 'player',
      weight: '',
      first_name: 'Conor',
      first_name_upper: 'CONOR',
      last_name: 'Gallagher',
      last_name_upper: 'GALLAGHER',
    },
    {
      _id: '6166c8b2e65c4ef2043e14c9',
      opta_ID: 'c9pmcoyzgtgcnlc63cl4283bp',
      __v: 0,
      country_of_birth: 'French Guiana',
      date_of_birth: '1998-01-16',
      foot: 'right',
      height: '187',
      opta_first_name: 'Odsonne',
      opta_last_name: 'Édouard',
      opta_last_updated: '2021-11-05T07:24:46Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Kourou',
      type: 'player',
      weight: '83',
      first_name: 'Odsonne',
      first_name_upper: 'ODSONNE',
      last_name: 'Édouard',
      last_name_upper: 'ÉDOUARD',
    },
    {
      _id: '6166c8b2e65c4ef2043e14e3',
      opta_ID: 'ceayo9dbjvo9l0kbs3ungva3t',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-10-06',
      foot: '',
      height: '',
      opta_first_name: 'Nathan',
      opta_last_name: 'Ferguson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Nathan',
      first_name_upper: 'NATHAN',
      last_name: 'Ferguson',
      last_name_upper: 'FERGUSON',
    },
    {
      _id: '6166c8b2e65c4ef2043e14fe',
      opta_ID: 'dbebh5mvzowjrp7h4qvzxvaol',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-04-17',
      foot: 'left',
      height: '185',
      opta_first_name: 'Will',
      opta_last_name: 'Hughes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Surrey',
      type: 'player',
      weight: '74',
      first_name: 'Will',
      first_name_upper: 'WILL',
      last_name: 'Hughes',
      last_name_upper: 'HUGHES',
    },
    {
      _id: '6166c8b2e65c4ef2043e151c',
      opta_ID: 'djgqihf08sxqh0zt2xe2xzv1l',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1997-06-28',
      foot: 'right',
      height: '190',
      opta_first_name: 'Jean-Philippe',
      opta_last_name: 'Mateta',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Sevran',
      type: 'player',
      weight: '84',
      first_name: 'Jean-Philippe',
      first_name_upper: 'JEAN-PHILIPPE',
      last_name: 'Mateta',
      last_name_upper: 'MATETA',
    },
    {
      _id: '6166c8b2e65c4ef2043e153d',
      opta_ID: 'dmrymoz2guiix60c0c0usrqei',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1956-09-07',
      foot: '',
      height: '',
      opta_first_name: 'Ray',
      opta_last_name: 'Lewington',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Lambeth',
      type: 'assistant coach',
      weight: '',
      first_name: 'Ray',
      first_name_upper: 'RAY',
      last_name: 'Lewington',
      last_name_upper: 'LEWINGTON',
    },
    {
      _id: '6166c8b2e65c4ef2043e156c',
      opta_ID: 'ezrn62nekq3wpw1qs1yu1ezmd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-04-05',
      foot: 'right',
      height: '175',
      opta_first_name: 'Nathaniel Edwin',
      opta_last_name: 'Clyne',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stockwell',
      type: 'player',
      weight: '67',
      first_name: 'Nathaniel Edwin',
      first_name_upper: 'NATHANIEL EDWIN',
      last_name: 'Clyne',
      last_name_upper: 'CLYNE',
    },
    {
      _id: '6166c8b3e65c4ef2043e1583',
      opta_ID: 'm2c4lckkcr7yilnqxrrsgh2i',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2001-12-12',
      foot: '',
      height: '',
      opta_first_name: 'Michael',
      opta_last_name: 'Olise',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Michael',
      first_name_upper: 'MICHAEL',
      last_name: 'Olise',
      last_name_upper: 'OLISE',
    },
    {
      _id: '6166c8b3e65c4ef2043e159c',
      opta_ID: 'ubuow4ad6azso5zz51b4tivp',
      __v: 0,
      country_of_birth: 'Congo DR',
      date_of_birth: '1990-12-03',
      foot: 'right',
      height: '190',
      opta_first_name: 'Christian',
      opta_last_name: 'Benteke Liolo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Kinshasa',
      type: 'player',
      weight: '83',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Benteke Liolo',
      last_name_upper: 'BENTEKE LIOLO',
    },
    {
      _id: '6166c8b3e65c4ef2043e15b2',
      opta_ID: 'z0btpgav68n4lqoht3ax3845',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-03-29',
      foot: 'right',
      height: '192',
      opta_first_name: 'James',
      opta_last_name: 'Tomkins',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Basildon',
      type: 'player',
      weight: '74',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Tomkins',
      last_name_upper: 'TOMKINS',
    },
    {
      _id: '6166c8bae65c4ef2043e2cb2',
      opta_ID: '1o60e5s7cvgtpaaxlvi3krf11',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1983-10-19',
      foot: 'left',
      height: '192',
      opta_first_name: 'Andrew',
      opta_last_name: 'Lonergan',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Preston',
      type: 'player',
      weight: '87',
      first_name: 'Andrew',
      first_name_upper: 'ANDREW',
      last_name: 'Lonergan',
      last_name_upper: 'LONERGAN',
    },
    {
      _id: '6166c8bae65c4ef2043e2cd6',
      opta_ID: '1ph4g125dfsijfjzjpcvpcmlm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-06-27',
      foot: 'left',
      height: '195',
      opta_first_name: 'Jarrad',
      opta_last_name: 'Branthwaite',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Carlisle',
      type: 'player',
      weight: '67',
      first_name: 'Jarrad',
      first_name_upper: 'JARRAD',
      last_name: 'Branthwaite',
      last_name_upper: 'BRANTHWAITE',
    },
    {
      _id: '6166c8bae65c4ef2043e2cf3',
      opta_ID: '20wvh9al53baedfzbzykpvkyd',
      __v: 0,
      country_of_birth: 'Venezuela',
      date_of_birth: '1989-09-16',
      foot: 'right',
      height: '186',
      opta_first_name: 'José Salomón',
      opta_last_name: 'Rondón Giménez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65a',
      place_of_birth: 'Caracas',
      type: 'player',
      weight: '86',
      first_name: 'José Salomón',
      first_name_upper: 'JOSÉ SALOMÓN',
      last_name: 'Rondón Giménez',
      last_name_upper: 'RONDÓN GIMÉNEZ',
    },
    {
      _id: '6166c8bae65c4ef2043e2d55',
      opta_ID: '34f7qo18vadmtj5qq0w3x5xat',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-07-16',
      foot: 'left',
      height: '181',
      opta_first_name: 'Andros',
      opta_last_name: 'Townsend',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '77',
      first_name: 'Andros',
      first_name_upper: 'ANDROS',
      last_name: 'Townsend',
      last_name_upper: 'TOWNSEND',
    },
    {
      _id: '6166c8bae65c4ef2043e2e20',
      opta_ID: '374kcix9j7e1cob4uxmfncwyd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-03-16',
      foot: 'right',
      height: '187',
      opta_first_name: 'Dominic',
      opta_last_name: 'Calvert-Lewin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sheffield',
      type: 'player',
      weight: '71',
      first_name: 'Dominic',
      first_name_upper: 'DOMINIC',
      last_name: 'Calvert-Lewin',
      last_name_upper: 'CALVERT-LEWIN',
    },
    {
      _id: '6166c8bae65c4ef2043e2edd',
      opta_ID: '3r1zsjvt43kg7aoudhh7o3rl6',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2000-11-01',
      foot: 'left',
      height: '180',
      opta_first_name: 'Niels Patrick',
      opta_last_name: 'Nkounkou',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Niels Patrick',
      first_name_upper: 'NIELS PATRICK',
      last_name: 'Nkounkou',
      last_name_upper: 'NKOUNKOU',
    },
    {
      _id: '6166c8bbe65c4ef2043e2f8e',
      opta_ID: '3uryxh0ztshuidtv7urv251hx',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1991-06-07',
      foot: 'both',
      height: '183',
      opta_first_name: 'Cenk',
      opta_last_name: 'Tosun',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d93e',
      place_of_birth: 'Wetzlar',
      type: 'player',
      weight: '78',
      first_name: 'Cenk',
      first_name_upper: 'CENK',
      last_name: 'Tosun',
      last_name_upper: 'TOSUN',
    },
    {
      _id: '6166c8bbe65c4ef2043e3048',
      opta_ID: '3zqpvn7r1c779l9vwwh7yukfd',
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '2000-02-28',
      foot: 'right',
      height: '182',
      opta_first_name: 'Moise Bioty',
      opta_last_name: 'Kean',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Vercelli',
      type: 'player',
      weight: '67',
      first_name: 'Moise Bioty',
      first_name_upper: 'MOISE BIOTY',
      last_name: 'Kean',
      last_name_upper: 'KEAN',
    },
    {
      _id: '6166c8bbe65c4ef2043e3106',
      opta_ID: '4b3axlxopby87f4luevihgwdl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-01-05',
      foot: 'right',
      height: '183',
      opta_first_name: 'Ellis',
      opta_last_name: 'Simms',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Oldham',
      type: 'player',
      weight: '73',
      first_name: 'Ellis',
      first_name_upper: 'ELLIS',
      last_name: 'Simms',
      last_name_upper: 'SIMMS',
    },
    {
      _id: '6166c8bbe65c4ef2043e314e',
      opta_ID: '4l0n6z5cqyv9kwxqhwaiyib1m',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-09-16',
      foot: '',
      height: '',
      opta_first_name: 'Charlie',
      opta_last_name: 'Whitaker',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Charlie',
      first_name_upper: 'CHARLIE',
      last_name: 'Whitaker',
      last_name_upper: 'WHITAKER',
    },
    {
      _id: '6166c8bbe65c4ef2043e317a',
      opta_ID: '4n782p6eaok71ai7ecj1jo1jp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-06-28',
      foot: 'both',
      height: '180',
      opta_first_name: 'Demarai',
      opta_last_name: 'Gray',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birmingham',
      type: 'player',
      weight: '74',
      first_name: 'Demarai',
      first_name_upper: 'DEMARAI',
      last_name: 'Gray',
      last_name_upper: 'GRAY',
    },
    {
      _id: '6166c8bbe65c4ef2043e31a0',
      opta_ID: '4njl5iqybub0cxbhjh2xbft9l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-10-22',
      foot: 'right',
      height: '184',
      opta_first_name: 'Mason',
      opta_last_name: 'Holgate',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Doncaster',
      type: 'player',
      weight: '63',
      first_name: 'Mason',
      first_name_upper: 'MASON',
      last_name: 'Holgate',
      last_name_upper: 'HOLGATE',
    },
    {
      _id: '6166c8bbe65c4ef2043e31bf',
      opta_ID: '5d5ed49lj51ognhobfni5ngx6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2004-08-01',
      foot: '',
      height: '',
      opta_first_name: 'Thierry',
      opta_last_name: 'Small',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Thierry',
      first_name_upper: 'THIERRY',
      last_name: 'Small',
      last_name_upper: 'SMALL',
    },
    {
      _id: '6166c8bbe65c4ef2043e31db',
      opta_ID: '5tlxrhab4kdeud8d2r9z9hk2d',
      __v: 0,
      country_of_birth: 'Bosnia and Herzegovina',
      date_of_birth: '1987-06-20',
      foot: 'right',
      height: '199',
      opta_first_name: 'Asmir',
      opta_last_name: 'Begović',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d714',
      place_of_birth: 'Trebinje',
      type: 'player',
      weight: '101',
      first_name: 'Asmir',
      first_name_upper: 'ASMIR',
      last_name: 'Begović',
      last_name_upper: 'BEGOVIĆ',
    },
    {
      _id: '6166c8bbe65c4ef2043e32ae',
      opta_ID: '6kxph7ulzz58v8tm3aaomy1ka',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-03',
      foot: '',
      height: '',
      opta_first_name: 'Lewis Norman',
      opta_last_name: 'Dobbin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Lewis Norman',
      first_name_upper: 'LEWIS NORMAN',
      last_name: 'Dobbin',
      last_name_upper: 'DOBBIN',
    },
    {
      _id: '6166c8bbe65c4ef2043e333b',
      opta_ID: '6og6s61vrg51rfqxk9cipylzp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-01-11',
      foot: 'right',
      height: '191',
      opta_first_name: 'Michael',
      opta_last_name: 'Keane',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stockport',
      type: 'player',
      weight: '82',
      first_name: 'Michael',
      first_name_upper: 'MICHAEL',
      last_name: 'Keane',
      last_name_upper: 'KEANE',
    },
    {
      _id: '6166c8bce65c4ef2043e33bf',
      opta_ID: '709tvsyynpz1tx7snba1whwwl',
      __v: 0,
      country_of_birth: 'Iceland',
      date_of_birth: '1989-09-08',
      foot: 'right',
      height: '186',
      opta_first_name: 'Gylfi Þór',
      opta_last_name: 'Sigurðsson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d777',
      place_of_birth: 'Reykjavík',
      type: 'player',
      weight: '79',
      first_name: 'Gylfi Þór',
      first_name_upper: 'GYLFI ÞÓR',
      last_name: 'Sigurðsson',
      last_name_upper: 'SIGURÐSSON',
    },
    {
      _id: '6166c8bce65c4ef2043e343f',
      opta_ID: '7fpcipp26a9fqes841ad7lh3p',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1998-04-05',
      foot: 'right',
      height: '178',
      opta_first_name: 'Nathan Paul',
      opta_last_name: 'Broadhead',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Bangor',
      type: 'player',
      weight: '73',
      first_name: 'Nathan Paul',
      first_name_upper: 'NATHAN PAUL',
      last_name: 'Broadhead',
      last_name_upper: 'BROADHEAD',
    },
    {
      _id: '6166c8bce65c4ef2043e34c5',
      opta_ID: '7rvlba00jv66w7wtdmk178a95',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1999-10-10',
      foot: 'left',
      height: '193',
      opta_first_name: 'João Manuel',
      opta_last_name: 'Neves Virginia',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Faro',
      type: 'player',
      weight: '83',
      first_name: 'João Manuel',
      first_name_upper: 'JOÃO MANUEL',
      last_name: 'Neves Virginia',
      last_name_upper: 'NEVES VIRGINIA',
    },
    {
      _id: '6166c8bce65c4ef2043e35e5',
      opta_ID: '8g7clz1z04oqjxhg8xnqc7105',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-01-01',
      foot: 'right',
      height: '184',
      opta_first_name: 'Abdoulaye',
      opta_last_name: 'Doucouré',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Meulan-en-Yvelines',
      type: 'player',
      weight: '75',
      first_name: 'Abdoulaye',
      first_name_upper: 'ABDOULAYE',
      last_name: 'Doucouré',
      last_name_upper: 'DOUCOURÉ',
    },
    {
      _id: '6166c8bce65c4ef2043e365b',
      opta_ID: '8kb5h8df97ju107n9ibigo1cl',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1996-05-03',
      foot: 'right',
      height: '180',
      opta_first_name: 'Alex',
      opta_last_name: 'Iwobi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Lagos',
      type: 'player',
      weight: '75',
      first_name: 'Alex',
      first_name_upper: 'ALEX',
      last_name: 'Iwobi',
      last_name_upper: 'IWOBI',
    },
    {
      _id: '6166c8bce65c4ef2043e367b',
      opta_ID: '8mjj0wgufggpc1ai2dk21h90p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-02-24',
      foot: '',
      height: '',
      opta_first_name: 'Anthony',
      opta_last_name: 'Gordon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Anthony',
      first_name_upper: 'ANTHONY',
      last_name: 'Gordon',
      last_name_upper: 'GORDON',
    },
    {
      _id: '6166c8bce65c4ef2043e3697',
      opta_ID: '8ro3lpzufz8ea9pa2j1jivqad',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-01-15',
      foot: 'right',
      height: '183',
      opta_first_name: 'Ben',
      opta_last_name: 'Godfrey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'York',
      type: 'player',
      weight: '74',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Godfrey',
      last_name_upper: 'GODFREY',
    },
    {
      _id: '6166c8bce65c4ef2043e386c',
      opta_ID: '9nq9bocnqq6bu8ho4m26qmkt1',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1988-10-11',
      foot: 'right',
      height: '177',
      opta_first_name: 'Séamus',
      opta_last_name: 'Coleman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Donegal',
      type: 'player',
      weight: '67',
      first_name: 'Séamus',
      first_name_upper: 'SÉAMUS',
      last_name: 'Coleman',
      last_name_upper: 'COLEMAN',
    },
    {
      _id: '6166c8bde65c4ef2043e391d',
      opta_ID: '9q6c7nbjhgwp2yrelznmk1gb9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-11-21',
      foot: 'left',
      height: '174',
      opta_first_name: 'Fabian',
      opta_last_name: 'Delph',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bradford',
      type: 'player',
      weight: '60',
      first_name: 'Fabian',
      first_name_upper: 'FABIAN',
      last_name: 'Delph',
      last_name_upper: 'DELPH',
    },
    {
      _id: '6166c8bde65c4ef2043e39ba',
      opta_ID: 'ahbzrw21ft3ljkg16vodmmp2i',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-12-06',
      foot: '',
      height: '',
      opta_first_name: 'Harry',
      opta_last_name: 'Tyrer',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Harry',
      first_name_upper: 'HARRY',
      last_name: 'Tyrer',
      last_name_upper: 'TYRER',
    },
    {
      _id: '6166c8bde65c4ef2043e3a60',
      opta_ID: 'b0a2w14w1tb395mnwkhykbg4q',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-09-19',
      foot: '',
      height: '',
      opta_first_name: 'Reece',
      opta_last_name: 'Welch',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Reece',
      first_name_upper: 'REECE',
      last_name: 'Welch',
      last_name_upper: 'WELCH',
    },
    {
      _id: '6166c8bde65c4ef2043e3ac8',
      opta_ID: 'b6j2nh4cxol3r88ob6jp1rh79',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1960-04-16',
      foot: '',
      height: '175',
      opta_first_name: 'Rafael',
      opta_last_name: 'Benítez Maudes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'coach',
      weight: '',
      first_name: 'Rafael',
      first_name_upper: 'RAFAEL',
      last_name: 'Benítez Maudes',
      last_name_upper: 'BENÍTEZ MAUDES',
    },
    {
      _id: '6166c8bde65c4ef2043e3b0f',
      opta_ID: 'bfdco9clf3dwltaogfhv5v0et',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1993-07-30',
      foot: 'right',
      height: '188',
      opta_first_name: 'André Filipe',
      opta_last_name: 'Tavares Gomes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Grijó',
      type: 'player',
      weight: '84',
      first_name: 'André Filipe',
      first_name_upper: 'ANDRÉ FILIPE',
      last_name: 'Tavares Gomes',
      last_name_upper: 'TAVARES GOMES',
    },
    {
      _id: '6166c8bde65c4ef2043e3b2d',
      opta_ID: 'c5zmr0m17riujelm4up44etih',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1997-05-10',
      foot: 'right',
      height: '184',
      opta_first_name: 'Richarlison',
      opta_last_name: 'de Andrade',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Nova Venécia',
      type: 'player',
      weight: '83',
      first_name: 'Richarlison',
      first_name_upper: 'RICHARLISON',
      last_name: 'de Andrade',
      last_name_upper: 'DE ANDRADE',
    },
    {
      _id: '6166c8bde65c4ef2043e3b5b',
      opta_ID: 'cjjo9piy65xdij746y97pyl79',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '1991-07-12',
      foot: 'left',
      height: '180',
      opta_first_name: 'James David',
      opta_last_name: 'Rodríguez Rubio',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'Cúcuta',
      type: 'player',
      weight: '75',
      first_name: 'James David',
      first_name_upper: 'JAMES DAVID',
      last_name: 'Rodríguez Rubio',
      last_name_upper: 'RODRÍGUEZ RUBIO',
    },
    {
      _id: '6166c8bde65c4ef2043e3b7a',
      opta_ID: 'cpd8prwzxf0rzevtk33fpryt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-06-30',
      foot: 'right',
      height: '180',
      opta_first_name: 'Tom',
      opta_last_name: 'Davies',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '70',
      first_name: 'Tom',
      first_name_upper: 'TOM',
      last_name: 'Davies',
      last_name_upper: 'DAVIES',
    },
    {
      _id: '6166c8bde65c4ef2043e3cf8',
      opta_ID: 'd4vdrtte9kb9qmlkrx1rvgk45',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '1994-09-23',
      foot: 'right',
      height: '195',
      opta_first_name: 'Yerry Fernando',
      opta_last_name: 'Mina González',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'Guachené',
      type: 'player',
      weight: '94',
      first_name: 'Yerry Fernando',
      first_name_upper: 'YERRY FERNANDO',
      last_name: 'Mina González',
      last_name_upper: 'MINA GONZÁLEZ',
    },
    {
      _id: '6166c8bde65c4ef2043e3db0',
      opta_ID: 'dr2upvki95vzd9pclkjpsjs2d',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1995-09-25',
      foot: 'right',
      height: '186',
      opta_first_name: 'Jean-Philippe',
      opta_last_name: 'Gbamin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'San-Pédro',
      type: 'player',
      weight: '83',
      first_name: 'Jean-Philippe',
      first_name_upper: 'JEAN-PHILIPPE',
      last_name: 'Gbamin',
      last_name_upper: 'GBAMIN',
    },
    {
      _id: '6166c8bee65c4ef2043e3e58',
      opta_ID: 'drv9wksjusdgp1np315rokzit',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-03-15',
      foot: 'right',
      height: '176',
      opta_first_name: 'Jonjoe',
      opta_last_name: 'Kenny',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '70',
      first_name: 'Jonjoe',
      first_name_upper: 'JONJOE',
      last_name: 'Kenny',
      last_name_upper: 'KENNY',
    },
    {
      _id: '6166c8bee65c4ef2043e3ef3',
      opta_ID: 'e8dina16u5kt5ho4u5igtmimt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-03-07',
      foot: 'left',
      height: '185',
      opta_first_name: 'Jordan',
      opta_last_name: 'Pickford',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Washington',
      type: 'player',
      weight: '77',
      first_name: 'Jordan',
      first_name_upper: 'JORDAN',
      last_name: 'Pickford',
      last_name_upper: 'PICKFORD',
    },
    {
      _id: '6166c8bee65c4ef2043e3f90',
      opta_ID: 'e8gz0tmmdh1ql8qrn1ncrvnpx',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-07-20',
      foot: 'left',
      height: '178',
      opta_first_name: 'Lucas',
      opta_last_name: 'Digne',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Meaux',
      type: 'player',
      weight: '74',
      first_name: 'Lucas',
      first_name_upper: 'LUCAS',
      last_name: 'Digne',
      last_name_upper: 'DIGNE',
    },
    {
      _id: '6166c8bee65c4ef2043e3fb8',
      opta_ID: 'npyr018r8w1ubvvdy1k6f3dh',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1991-01-08',
      foot: 'right',
      height: '173',
      opta_first_name: 'Allan',
      opta_last_name: 'Marques Loureiro',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Rio de Janeiro',
      type: 'player',
      weight: '73',
      first_name: 'Allan',
      first_name_upper: 'ALLAN',
      last_name: 'Marques Loureiro',
      last_name_upper: 'MARQUES LOUREIRO',
    },
    {
      _id: '6166c8c8e65c4ef2043e72e6',
      opta_ID: '8f3eqla9yo5js50fv2tdu5vjd',
      __v: 0,
      country_of_birth: 'Guinea-Bissau',
      date_of_birth: '1999-11-18',
      foot: 'right',
      height: '179',
      opta_first_name: 'Domingos',
      opta_last_name: 'Quina',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Bissau',
      type: 'player',
      weight: '72',
      first_name: 'Domingos',
      first_name_upper: 'DOMINGOS',
      last_name: 'Quina',
      last_name_upper: 'QUINA',
    },
    {
      _id: '6166c8c8e65c4ef2043e7399',
      opta_ID: '8u8he2dwkbgjf3yo8jsltq9qt',
      __v: 0,
      country_of_birth: 'Sierra Leone',
      date_of_birth: '1994-12-12',
      foot: 'right',
      height: '187',
      opta_first_name: 'Nathaniel',
      opta_last_name: 'Chalobah',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Freetown',
      type: 'player',
      weight: '80',
      first_name: 'Nathaniel',
      first_name_upper: 'NATHANIEL',
      last_name: 'Chalobah',
      last_name_upper: 'CHALOBAH',
    },
    {
      _id: '6166c8dce65c4ef2043ebfa8',
      opta_ID: '1j7yc8sqqm49ip12wnmfbo7v9',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1955-07-21',
      foot: '',
      height: '',
      opta_first_name: 'Marcelo Alberto',
      opta_last_name: 'Bielsa Caldera',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Rosario',
      type: 'coach',
      weight: '',
      first_name: 'Marcelo Alberto',
      first_name_upper: 'MARCELO ALBERTO',
      last_name: 'Bielsa Caldera',
      last_name_upper: 'BIELSA CALDERA',
    },
    {
      _id: '6166c8dce65c4ef2043ebfc3',
      opta_ID: '1r5zkfhxkrrgzhd0k0huxtas5',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '1990-06-13',
      foot: 'right',
      height: '183',
      opta_first_name: 'Mateusz Andrzej',
      opta_last_name: 'Klich',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Tarnów',
      type: 'player',
      weight: '84',
      first_name: 'Mateusz Andrzej',
      first_name_upper: 'MATEUSZ ANDRZEJ',
      last_name: 'Klich',
      last_name_upper: 'KLICH',
    },
    {
      _id: '6166c8dde65c4ef2043ebfd9',
      opta_ID: '1yv726vwh4pr461lz79lh8l7u',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-03-07',
      foot: '',
      height: '176',
      opta_first_name: 'Liam',
      opta_last_name: 'McCarron',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Preston',
      type: 'player',
      weight: '61',
      first_name: 'Liam',
      first_name_upper: 'LIAM',
      last_name: 'McCarron',
      last_name_upper: 'MCCARRON',
    },
    {
      _id: '6166c8dde65c4ef2043ebff7',
      opta_ID: '1zumt9vkrqd8d3p3eqtwezi6t',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1996-07-17',
      foot: 'right',
      height: '192',
      opta_first_name: 'Robin',
      opta_last_name: 'Koch',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Kaiserslautern',
      type: 'player',
      weight: '85',
      first_name: 'Robin',
      first_name_upper: 'ROBIN',
      last_name: 'Koch',
      last_name_upper: 'KOCH',
    },
    {
      _id: '6166c8dde65c4ef2043ec012',
      opta_ID: '29i4ruk1vkwrl1w9vg9ggqpqt',
      __v: 0,
      country_of_birth: 'Angola',
      date_of_birth: '1994-01-12',
      foot: 'left',
      height: '178',
      opta_first_name: 'Hélder Wander',
      opta_last_name: 'Sousa Azevedo Costa',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3ef',
      place_of_birth: 'Luanda',
      type: 'player',
      weight: '69',
      first_name: 'Hélder Wander',
      first_name_upper: 'HÉLDER WANDER',
      last_name: 'Sousa Azevedo Costa',
      last_name_upper: 'SOUSA AZEVEDO COSTA',
    },
    {
      _id: '6166c8dde65c4ef2043ec047',
      opta_ID: '2wl6h1rlrfnn2mr65vuo3t815',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1996-12-14',
      foot: 'left',
      height: '176',
      opta_first_name: 'Raphael',
      opta_last_name: 'Dias Belloli',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Porto Alegre',
      type: 'player',
      weight: '58',
      first_name: 'Raphael',
      first_name_upper: 'RAPHAEL',
      last_name: 'Dias Belloli',
      last_name_upper: 'DIAS BELLOLI',
    },
    {
      _id: '6166c8dde65c4ef2043ec064',
      opta_ID: '3ojnucluy7h8fgk2myyo0zx4a',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-05-04',
      foot: '',
      height: '175',
      opta_first_name: 'Joe',
      opta_last_name: 'Gelhardt',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '65',
      first_name: 'Joe',
      first_name_upper: 'JOE',
      last_name: 'Gelhardt',
      last_name_upper: 'GELHARDT',
    },
    {
      _id: '6166c8dde65c4ef2043ec07e',
      opta_ID: '4hmivvhmplqof776e9tbqg251',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1991-03-06',
      foot: 'left',
      height: '182',
      opta_first_name: 'Rodrigo',
      opta_last_name: 'Moreno Machado',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Rio de Janeiro',
      type: 'player',
      weight: '77',
      first_name: 'Rodrigo',
      first_name_upper: 'RODRIGO',
      last_name: 'Moreno Machado',
      last_name_upper: 'MORENO MACHADO',
    },
    {
      _id: '6166c8dde65c4ef2043ec096',
      opta_ID: '4vnpdi15nazck2sywdgkve2cq',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2002-09-18',
      foot: '',
      height: '179',
      opta_first_name: 'Stuart',
      opta_last_name: 'McKinstry',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Wishaw',
      type: 'player',
      weight: '68',
      first_name: 'Stuart',
      first_name_upper: 'STUART',
      last_name: 'McKinstry',
      last_name_upper: 'MCKINSTRY',
    },
    {
      _id: '6166c8dde65c4ef2043ec0ad',
      opta_ID: '4xvg49ogu1sz6yuiuc79k13ze',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-10-08',
      foot: '',
      height: '',
      opta_first_name: 'Jamie',
      opta_last_name: 'Shackleton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leeds',
      type: 'player',
      weight: '',
      first_name: 'Jamie',
      first_name_upper: 'JAMIE',
      last_name: 'Shackleton',
      last_name_upper: 'SHACKLETON',
    },
    {
      _id: '6166c8dde65c4ef2043ec0e6',
      opta_ID: '6clj9dk6hdeaomo6yi7vcsv6d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-08-30',
      foot: 'left',
      height: '180',
      opta_first_name: 'Liam',
      opta_last_name: 'Cooper',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Kingston upon Hull',
      type: 'player',
      weight: '73',
      first_name: 'Liam',
      first_name_upper: 'LIAM',
      last_name: 'Cooper',
      last_name_upper: 'COOPER',
    },
    {
      _id: '6166c8dee65c4ef2043ec0fd',
      opta_ID: '6kih58n0fzlod6c792xpey57t',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-11-20',
      foot: '',
      height: '175',
      opta_first_name: 'Jack',
      opta_last_name: 'Harrison',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stoke-on-Trent',
      type: 'player',
      weight: '70',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Harrison',
      last_name_upper: 'HARRISON',
    },
    {
      _id: '6166c8dee65c4ef2043ec116',
      opta_ID: '6q6i9x4w4vcdwwmiwq8k6sixh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-09-05',
      foot: 'left',
      height: '185',
      opta_first_name: 'Patrick',
      opta_last_name: 'Bamford',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Grantham',
      type: 'player',
      weight: '71',
      first_name: 'Patrick',
      first_name_upper: 'PATRICK',
      last_name: 'Bamford',
      last_name_upper: 'BAMFORD',
    },
    {
      _id: '6166c8dee65c4ef2043ec12c',
      opta_ID: '7oikovl62nfxs6z54dzd85gx5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-01-26',
      foot: '',
      height: '',
      opta_first_name: 'Sam',
      opta_last_name: 'Greenwood',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Sam',
      first_name_upper: 'SAM',
      last_name: 'Greenwood',
      last_name_upper: 'GREENWOOD',
    },
    {
      _id: '6166c8dee65c4ef2043ec143',
      opta_ID: '7sdbx7mzjxhvofvke9mp26kbu',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2001-10-30',
      foot: '',
      height: '174',
      opta_first_name: 'Crysencio',
      opta_last_name: 'Summerville',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Rotterdam',
      type: 'player',
      weight: '',
      first_name: 'Crysencio',
      first_name_upper: 'CRYSENCIO',
      last_name: 'Summerville',
      last_name_upper: 'SUMMERVILLE',
    },
    {
      _id: '6166c8dee65c4ef2043ec163',
      opta_ID: '8kbc4k8711uqmwlt1h0eeqi8l',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1991-04-19',
      foot: 'right',
      height: '183',
      opta_first_name: 'Stuart',
      opta_last_name: 'Dallas',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Cookstown',
      type: 'player',
      weight: '81',
      first_name: 'Stuart',
      first_name_upper: 'STUART',
      last_name: 'Dallas',
      last_name_upper: 'DALLAS',
    },
    {
      _id: '6166c8dee65c4ef2043ec187',
      opta_ID: '8szyip8c62bier901hmpqc7vt',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2000-03-02',
      foot: 'right',
      height: '196',
      opta_first_name: 'Illan',
      opta_last_name: 'Meslier',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Lorient',
      type: 'player',
      weight: '74',
      first_name: 'Illan',
      first_name_upper: 'ILLAN',
      last_name: 'Meslier',
      last_name_upper: 'MESLIER',
    },
    {
      _id: '6166c8dee65c4ef2043ec19e',
      opta_ID: '983mn5yig2f4upkq9e97hx87p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-08-25',
      foot: 'right',
      height: '183',
      opta_first_name: 'Luke',
      opta_last_name: 'Ayling',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '72',
      first_name: 'Luke',
      first_name_upper: 'LUKE',
      last_name: 'Ayling',
      last_name_upper: 'AYLING',
    },
    {
      _id: '6166c8dee65c4ef2043ec1b3',
      opta_ID: '99d29g8af71vt1hrur06qvy7d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-12-02',
      foot: 'right',
      height: '178',
      opta_first_name: 'Kalvin',
      opta_last_name: 'Phillips',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leeds',
      type: 'player',
      weight: '72',
      first_name: 'Kalvin',
      first_name_upper: 'KALVIN',
      last_name: 'Phillips',
      last_name_upper: 'PHILLIPS',
    },
    {
      _id: '6166c8dee65c4ef2043ec1c8',
      opta_ID: '9ao7l82r1xd2rjnvv5u6plcr9',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1993-08-16',
      foot: 'right',
      height: '186',
      opta_first_name: 'Diego Javier',
      opta_last_name: 'Llorente Ríos',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '75',
      first_name: 'Diego Javier',
      first_name_upper: 'DIEGO JAVIER',
      last_name: 'Llorente Ríos',
      last_name_upper: 'LLORENTE RÍOS',
    },
    {
      _id: '6166c8dee65c4ef2043ec1e6',
      opta_ID: '9ccfl5n9m3t2jmv2n39wv7ufp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-01-12',
      foot: 'right',
      height: '180',
      opta_first_name: 'Tyler',
      opta_last_name: 'Roberts',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Gloucester',
      type: 'player',
      weight: '75',
      first_name: 'Tyler',
      first_name_upper: 'TYLER',
      last_name: 'Roberts',
      last_name_upper: 'ROBERTS',
    },
    {
      _id: '6166c8dee65c4ef2043ec214',
      opta_ID: '9rfwfsz5cmckyjripqgtbc7op',
      __v: 0,
      country_of_birth: 'Dominican Republic',
      date_of_birth: '1996-08-22',
      foot: 'left',
      height: '184',
      opta_first_name: 'Héctor Junior',
      opta_last_name: 'Firpo Adames',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Santo Domingo',
      type: 'player',
      weight: '78',
      first_name: 'Héctor Junior',
      first_name_upper: 'HÉCTOR JUNIOR',
      last_name: 'Firpo Adames',
      last_name_upper: 'FIRPO ADAMES',
    },
    {
      _id: '6166c8dfe65c4ef2043ec22e',
      opta_ID: '9trvbmdwo8fkndwjz9mr1bfmx',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '2000-11-27',
      foot: '',
      height: '189',
      opta_first_name: 'Kristoffer-August',
      opta_last_name: 'Sundquist Klaesson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: '',
      type: 'player',
      weight: '76',
      first_name: 'Kristoffer-August',
      first_name_upper: 'KRISTOFFER-AUGUST',
      last_name: 'Sundquist Klaesson',
      last_name_upper: 'SUNDQUIST KLAESSON',
    },
    {
      _id: '6166c8dfe65c4ef2043ec245',
      opta_ID: 'aarmpoi343teu4medudpl49ka',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-08-17',
      foot: 'right',
      height: '183',
      opta_first_name: 'Charlie',
      opta_last_name: 'Cresswell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Preston',
      type: 'player',
      weight: '',
      first_name: 'Charlie',
      first_name_upper: 'CHARLIE',
      last_name: 'Cresswell',
      last_name_upper: 'CRESSWELL',
    },
    {
      _id: '6166c8dfe65c4ef2043ec25e',
      opta_ID: 'ab2502dhe0p0fr35nf7urjxbe',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-12-08',
      foot: '',
      height: '',
      opta_first_name: 'Cody Callum Pierre',
      opta_last_name: 'Drameh',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Cody Callum Pierre',
      first_name_upper: 'CODY CALLUM PIERRE',
      last_name: 'Drameh',
      last_name_upper: 'DRAMEH',
    },
    {
      _id: '6166c8dfe65c4ef2043ec2ac',
      opta_ID: 'ao2tl1brzen40y5leuuc3b5qt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-10-08',
      foot: 'right',
      height: '185',
      opta_first_name: 'Adam',
      opta_last_name: 'Forshaw',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '71',
      first_name: 'Adam',
      first_name_upper: 'ADAM',
      last_name: 'Forshaw',
      last_name_upper: 'FORSHAW',
    },
    {
      _id: '6166c8dfe65c4ef2043ec2d3',
      opta_ID: 'b275i2lg3anqae5lv9gmrzmqx',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1999-08-11',
      foot: 'left',
      height: '190',
      opta_first_name: 'Pascal',
      opta_last_name: 'Struijk',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: '',
      type: 'player',
      weight: '79',
      first_name: 'Pascal',
      first_name_upper: 'PASCAL',
      last_name: 'Struijk',
      last_name_upper: 'STRUIJK',
    },
    {
      _id: '6166c8dfe65c4ef2043ec2f9',
      opta_ID: 'cxxhe0dwp3wi35c28spse4l7u',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-10',
      foot: '',
      height: '',
      opta_first_name: 'Nohan',
      opta_last_name: 'Kenneh',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Nohan',
      first_name_upper: 'NOHAN',
      last_name: 'Kenneh',
      last_name_upper: 'KENNEH',
    },
    {
      _id: '6166c8dfe65c4ef2043ec313',
      opta_ID: 'dkwkmrmpb7rq4e660efcu5hat',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-11-10',
      foot: 'right',
      height: '171',
      opta_first_name: 'Daniel',
      opta_last_name: 'James',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Kingston upon Hull',
      type: 'player',
      weight: '76',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'James',
      last_name_upper: 'JAMES',
    },
    {
      _id: '6166c8dfe65c4ef2043ec32c',
      opta_ID: 'dvks9tdtrt7orsbravvxpz59m',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '2003-08-26',
      foot: 'left',
      height: '188',
      opta_first_name: 'Leo Fuhr',
      opta_last_name: 'Hjelde',
      opta_last_updated: '2021-11-06T15:42:34Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Leo Fuhr',
      first_name_upper: 'LEO FUHR',
      last_name: 'Hjelde',
      last_name_upper: 'HJELDE',
    },
    {
      _id: '6166c8e0e65c4ef2043ec363',
      opta_ID: '18paf5a7rfe4kutxjl4cwd695',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-12-09',
      foot: '',
      height: '174',
      opta_first_name: 'Harvey Lewis',
      opta_last_name: 'Barnes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Burnley',
      type: 'player',
      weight: '66',
      first_name: 'Harvey Lewis',
      first_name_upper: 'HARVEY LEWIS',
      last_name: 'Barnes',
      last_name_upper: 'BARNES',
    },
    {
      _id: '6166c8e0e65c4ef2043ec384',
      opta_ID: '2bkp1lcaxmvwoanxwo0775cdl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-09-06',
      foot: '',
      height: '',
      opta_first_name: 'Kiernan',
      opta_last_name: 'Dewsbury-Hall',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Shepshed',
      type: 'player',
      weight: '',
      first_name: 'Kiernan',
      first_name_upper: 'KIERNAN',
      last_name: 'Dewsbury-Hall',
      last_name_upper: 'DEWSBURY-HALL',
    },
    {
      _id: '6166c8e0e65c4ef2043ec3a9',
      opta_ID: '33p3p5buydosugm9alv57o0r9',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1993-10-06',
      foot: 'right',
      height: '175',
      opta_first_name: 'Ricardo Domingos',
      opta_last_name: 'Barbosa Pereira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Lisbonne',
      type: 'player',
      weight: '70',
      first_name: 'Ricardo Domingos',
      first_name_upper: 'RICARDO DOMINGOS',
      last_name: 'Barbosa Pereira',
      last_name_upper: 'BARBOSA PEREIRA',
    },
    {
      _id: '6166c8e0e65c4ef2043ec3c5',
      opta_ID: '4316y525qbk02gs9l3fm8z8b9',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1992-08-03',
      foot: 'left',
      height: '199',
      opta_first_name: 'Jannik',
      opta_last_name: 'Vestergaard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '98',
      first_name: 'Jannik',
      first_name_upper: 'JANNIK',
      last_name: 'Vestergaard',
      last_name_upper: 'VESTERGAARD',
    },
    {
      _id: '6166c8e0e65c4ef2043ec3e5',
      opta_ID: '4gbsgam95rj7acvyee5kkx9zp',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1973-01-26',
      foot: '',
      height: '',
      opta_first_name: 'Brendan',
      opta_last_name: 'Rodgers',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Carnlough',
      type: 'coach',
      weight: '',
      first_name: 'Brendan',
      first_name_upper: 'BRENDAN',
      last_name: 'Rodgers',
      last_name_upper: 'RODGERS',
    },
    {
      _id: '6166c8e0e65c4ef2043ec407',
      opta_ID: '51xwrb0qit04oz9x01p2gghjp',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1996-10-03',
      foot: 'left',
      height: '185',
      opta_first_name: 'Kelechi Promise',
      opta_last_name: 'Iheanacho',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Owerri',
      type: 'player',
      weight: '82',
      first_name: 'Kelechi Promise',
      first_name_upper: 'KELECHI PROMISE',
      last_name: 'Iheanacho',
      last_name_upper: 'IHEANACHO',
    },
    {
      _id: '6166c8e0e65c4ef2043ec424',
      opta_ID: '5h2biadv4hd2ntcd7zo2c8639',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1993-06-22',
      foot: 'right',
      height: '191',
      opta_first_name: 'Danny',
      opta_last_name: 'Ward',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Wrexham',
      type: 'player',
      weight: '88',
      first_name: 'Danny',
      first_name_upper: 'DANNY',
      last_name: 'Ward',
      last_name_upper: 'WARD',
    },
    {
      _id: '6166c8e0e65c4ef2043ec43d',
      opta_ID: '6icypdqtfk2k81ax0i49j5xpl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-02-23',
      foot: 'right',
      height: '183',
      opta_first_name: 'James',
      opta_last_name: 'Justin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Luton',
      type: 'player',
      weight: '80',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Justin',
      last_name_upper: 'JUSTIN',
    },
    {
      _id: '6166c8e0e65c4ef2043ec456',
      opta_ID: '6tma6gvgf6xphc5gm2el35vbp',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1996-12-16',
      foot: 'right',
      height: '183',
      opta_first_name: 'Onyinye Wilfred',
      opta_last_name: 'Ndidi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Lagos',
      type: 'player',
      weight: '74',
      first_name: 'Onyinye Wilfred',
      first_name_upper: 'ONYINYE WILFRED',
      last_name: 'Ndidi',
      last_name_upper: 'NDIDI',
    },
    {
      _id: '6166c8e0e65c4ef2043ec46f',
      opta_ID: '6v1o4s2acog9guff3folo1jh1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1987-01-11',
      foot: 'right',
      height: '179',
      opta_first_name: 'Jamie',
      opta_last_name: 'Vardy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sheffield',
      type: 'player',
      weight: '74',
      first_name: 'Jamie',
      first_name_upper: 'JAMIE',
      last_name: 'Vardy',
      last_name_upper: 'VARDY',
    },
    {
      _id: '6166c8e0e65c4ef2043ec48d',
      opta_ID: '70jmsf551d1sspqnm8t5lrch5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-06-10',
      foot: '',
      height: '',
      opta_first_name: 'Luke',
      opta_last_name: 'Thomas',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Luke',
      first_name_upper: 'LUKE',
      last_name: 'Thomas',
      last_name_upper: 'THOMAS',
    },
    {
      _id: '6166c8e0e65c4ef2043ec4a6',
      opta_ID: '7o49rb7fgi2ml87up7hh2z0t1',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1988-01-03',
      foot: 'both',
      height: '188',
      opta_first_name: 'Jonathan Grant',
      opta_last_name: 'Evans',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Belfast',
      type: 'player',
      weight: '77',
      first_name: 'Jonathan Grant',
      first_name_upper: 'JONATHAN GRANT',
      last_name: 'Evans',
      last_name_upper: 'EVANS',
    },
    {
      _id: '6166c8e1e65c4ef2043ec4bc',
      opta_ID: '7wu5l9m2kt30c4nifxdi3btg5',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1992-06-23',
      foot: 'right',
      height: '167',
      opta_first_name: 'Nampalys',
      opta_last_name: 'Mendy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: 'La Seyne-sur-Mer',
      type: 'player',
      weight: '73',
      first_name: 'Nampalys',
      first_name_upper: 'NAMPALYS',
      last_name: 'Mendy',
      last_name_upper: 'MENDY',
    },
    {
      _id: '6166c8e1e65c4ef2043ec4d8',
      opta_ID: '7yirx71oya71zjsgldj5ucqcq',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2000-12-17',
      foot: 'right',
      height: '190',
      opta_first_name: 'Wesley',
      opta_last_name: 'Fofana',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Wesley',
      first_name_upper: 'WESLEY',
      last_name: 'Fofana',
      last_name_upper: 'FOFANA',
    },
    {
      _id: '6166c8e1e65c4ef2043ec4f7',
      opta_ID: '832arq1qtwnwbki4v3vuj49n9',
      __v: 0,
      country_of_birth: 'Bosnia and Herzegovina',
      date_of_birth: '1984-10-02',
      foot: 'right',
      height: '191',
      opta_first_name: 'Eldin',
      opta_last_name: 'Jakupovic',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d909',
      place_of_birth: 'Kozarac',
      type: 'player',
      weight: '85',
      first_name: 'Eldin',
      first_name_upper: 'ELDIN',
      last_name: 'Jakupovic',
      last_name_upper: 'JAKUPOVIC',
    },
    {
      _id: '6166c8e1e65c4ef2043ec51d',
      opta_ID: '8d04q3yp2kouwog0lj0s7ew2x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-20',
      foot: 'left',
      height: '174',
      opta_first_name: 'Ademola',
      opta_last_name: 'Lookman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '78',
      first_name: 'Ademola',
      first_name_upper: 'ADEMOLA',
      last_name: 'Lookman',
      last_name_upper: 'LOOKMAN',
    },
    {
      _id: '6166c8e1e65c4ef2043ec541',
      opta_ID: '8govqd0xf8ijst50tgypaep5h',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1986-11-05',
      foot: 'right',
      height: '189',
      opta_first_name: 'Kasper Peter',
      opta_last_name: 'Schmeichel',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '89',
      first_name: 'Kasper Peter',
      first_name_upper: 'KASPER PETER',
      last_name: 'Schmeichel',
      last_name_upper: 'SCHMEICHEL',
    },
    {
      _id: '6166c8e1e65c4ef2043ec561',
      opta_ID: '8prtj2o0lz1zbzpuw4ah4qhud',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1994-05-14',
      foot: 'right',
      height: '181',
      opta_first_name: 'Dennis',
      opta_last_name: 'Praet',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Leuven',
      type: 'player',
      weight: '78',
      first_name: 'Dennis',
      first_name_upper: 'DENNIS',
      last_name: 'Praet',
      last_name_upper: 'PRAET',
    },
    {
      _id: '6166c8e1e65c4ef2043ec591',
      opta_ID: 'a1yoz8f4985o166nzcyigpj2t',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-11-23',
      foot: 'right',
      height: '175',
      opta_first_name: 'James',
      opta_last_name: 'Maddison',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Coventry',
      type: 'player',
      weight: '73',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Maddison',
      last_name_upper: 'MADDISON',
    },
    {
      _id: '6166c8e1e65c4ef2043ec5aa',
      opta_ID: 'ayyswiouwrh2yn1j75owovob9',
      __v: 0,
      country_of_birth: 'Turkey',
      date_of_birth: '1996-05-23',
      foot: 'right',
      height: '185',
      opta_first_name: 'Çağlar',
      opta_last_name: 'Söyüncü',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d93e',
      place_of_birth: 'İzmir',
      type: 'player',
      weight: '82',
      first_name: 'Çağlar',
      first_name_upper: 'ÇAĞLAR',
      last_name: 'Söyüncü',
      last_name_upper: 'SÖYÜNCÜ',
    },
    {
      _id: '6166c8e1e65c4ef2043ec5c8',
      opta_ID: 'bhsoe49s9vpa6v62rswirhsl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-08-05',
      foot: 'left',
      height: '179',
      opta_first_name: 'Ryan',
      opta_last_name: 'Bertrand',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Southwark',
      type: 'player',
      weight: '85',
      first_name: 'Ryan',
      first_name_upper: 'RYAN',
      last_name: 'Bertrand',
      last_name_upper: 'BERTRAND',
    },
    {
      _id: '6166c8e1e65c4ef2043ec5e1',
      opta_ID: 'c2kzyykn8bwtswj6lacvrj3s9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-01',
      foot: '',
      height: '178',
      opta_first_name: 'Hamza',
      opta_last_name: 'Choudhury',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '64',
      first_name: 'Hamza',
      first_name_upper: 'HAMZA',
      last_name: 'Choudhury',
      last_name_upper: 'CHOUDHURY',
    },
    {
      _id: '6166c8e2e65c4ef2043ec5f9',
      opta_ID: 'drwacyyicuhy3heg4j0ipqj85',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1993-07-29',
      foot: 'right',
      height: '178',
      opta_first_name: 'Ayoze',
      opta_last_name: 'Pérez Gutiérrez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Santa Cruz de Tenerife',
      type: 'player',
      weight: '72',
      first_name: 'Ayoze',
      first_name_upper: 'AYOZE',
      last_name: 'Pérez Gutiérrez',
      last_name_upper: 'PÉREZ GUTIÉRREZ',
    },
    {
      _id: '6166c8e2e65c4ef2043ec613',
      opta_ID: 'eahcgnvlq1tkswvjuovyzxxw5',
      __v: 0,
      country_of_birth: 'Croatia',
      date_of_birth: '1997-07-13',
      foot: 'right',
      height: '194',
      opta_first_name: 'Filip',
      opta_last_name: 'Benković',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d728',
      place_of_birth: 'Zagreb',
      type: 'player',
      weight: '80',
      first_name: 'Filip',
      first_name_upper: 'FILIP',
      last_name: 'Benković',
      last_name_upper: 'BENKOVIĆ',
    },
    {
      _id: '6166c8e2e65c4ef2043ec636',
      opta_ID: 'egi71rvlclvis07nzbvjhl9m1',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-02-27',
      foot: 'right',
      height: '188',
      opta_first_name: 'Boubakary',
      opta_last_name: 'Soumaré',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Noisy-le-Sec',
      type: 'player',
      weight: '70',
      first_name: 'Boubakary',
      first_name_upper: 'BOUBAKARY',
      last_name: 'Soumaré',
      last_name_upper: 'SOUMARÉ',
    },
    {
      _id: '6166c8e2e65c4ef2043ec652',
      opta_ID: 'enyatxtjef9lacem6c8cc1mmt',
      __v: 0,
      country_of_birth: 'Ghana',
      date_of_birth: '1994-12-21',
      foot: 'right',
      height: '186',
      opta_first_name: 'Daniel',
      opta_last_name: 'Amartey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Accra',
      type: 'player',
      weight: '79',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Amartey',
      last_name_upper: 'AMARTEY',
    },
    {
      _id: '6166c8e2e65c4ef2043ec679',
      opta_ID: 'eo4uva2gnx08xdazfaqzyz2dx',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1997-05-07',
      foot: 'both',
      height: '176',
      opta_first_name: 'Youri',
      opta_last_name: 'Tielemans',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Ukkel',
      type: 'player',
      weight: '72',
      first_name: 'Youri',
      first_name_upper: 'YOURI',
      last_name: 'Tielemans',
      last_name_upper: 'TIELEMANS',
    },
    {
      _id: '6166c8e2e65c4ef2043ec69b',
      opta_ID: 'otz9c2ifww514ummo10nfoix',
      __v: 0,
      country_of_birth: 'Zambia',
      date_of_birth: '1998-10-09',
      foot: 'right',
      height: '183',
      opta_first_name: 'Patson',
      opta_last_name: 'Daka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4dd',
      place_of_birth: 'Chingola',
      type: 'player',
      weight: '71',
      first_name: 'Patson',
      first_name_upper: 'PATSON',
      last_name: 'Daka',
      last_name_upper: 'DAKA',
    },
    {
      _id: '6166c8e2e65c4ef2043ec6b6',
      opta_ID: 'p87gxg8kc9ta6iipqt5fxmtx',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1995-12-05',
      foot: 'right',
      height: '185',
      opta_first_name: 'Timothy',
      opta_last_name: 'Castagne',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Arlon',
      type: 'player',
      weight: '80',
      first_name: 'Timothy',
      first_name_upper: 'TIMOTHY',
      last_name: 'Castagne',
      last_name_upper: 'CASTAGNE',
    },
    {
      _id: '6166c8e2e65c4ef2043ec6cf',
      opta_ID: 'xm50j2dki5ml1gzx3y0i0zbp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-11-18',
      foot: 'right',
      height: '175',
      opta_first_name: 'Marc',
      opta_last_name: 'Albrighton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Tamworth',
      type: 'player',
      weight: '74',
      first_name: 'Marc',
      first_name_upper: 'MARC',
      last_name: 'Albrighton',
      last_name_upper: 'ALBRIGHTON',
    },
    {
      _id: '6166c8e2e65c4ef2043ec70e',
      opta_ID: '1oxfxpbvol3526lbe42wxxze2',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '2002-09-23',
      foot: 'right',
      height: '',
      opta_first_name: 'Tyrese',
      opta_last_name: 'Omotoye',
      opta_last_updated: '2021-11-06T15:06:18Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Tyrese',
      first_name_upper: 'TYRESE',
      last_name: 'Omotoye',
      last_name_upper: 'OMOTOYE',
    },
    {
      _id: '6166c8e8e65c4ef2043ecdcb',
      opta_ID: '19z8jmren3eytg7fbuzqv73kl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-10-07',
      foot: 'right',
      height: '175',
      opta_first_name: 'Trent',
      opta_last_name: 'Alexander-Arnold',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '69',
      first_name: 'Trent',
      first_name_upper: 'TRENT',
      last_name: 'Alexander-Arnold',
      last_name_upper: 'ALEXANDER-ARNOLD',
    },
    {
      _id: '6166c8e8e65c4ef2043ecde2',
      opta_ID: '1dwwsz1i6v8zwh802ujwf7emt',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1995-04-18',
      foot: 'both',
      height: '185',
      opta_first_name: 'Divock',
      opta_last_name: 'Okoth Origi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Oostende',
      type: 'player',
      weight: '75',
      first_name: 'Divock',
      first_name_upper: 'DIVOCK',
      last_name: 'Okoth Origi',
      last_name_upper: 'OKOTH ORIGI',
    },
    {
      _id: '6166c8e8e65c4ef2043ecdf7',
      opta_ID: '1su093v6qbmqj1ogjb1pclii1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-02-03',
      foot: '',
      height: '',
      opta_first_name: 'Rhys',
      opta_last_name: 'Williams',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Preston',
      type: 'player',
      weight: '',
      first_name: 'Rhys',
      first_name_upper: 'RHYS',
      last_name: 'Williams',
      last_name_upper: 'WILLIAMS',
    },
    {
      _id: '6166c8e8e65c4ef2043ece10',
      opta_ID: '25yu90pekqr1lhs098f3uxy39',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-06-17',
      foot: 'right',
      height: '182',
      opta_first_name: 'Jordan',
      opta_last_name: 'Henderson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sunderland',
      type: 'player',
      weight: '67',
      first_name: 'Jordan',
      first_name_upper: 'JORDAN',
      last_name: 'Henderson',
      last_name_upper: 'HENDERSON',
    },
    {
      _id: '6166c8e8e65c4ef2043ece36',
      opta_ID: '29dp5gmu5xbd04mozwxq5tjh1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-05-23',
      foot: 'right',
      height: '188',
      opta_first_name: 'Joe',
      opta_last_name: 'Gomez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '77',
      first_name: 'Joe',
      first_name_upper: 'JOE',
      last_name: 'Gomez',
      last_name_upper: 'GOMEZ',
    },
    {
      _id: '6166c8e8e65c4ef2043ece4c',
      opta_ID: '2r4ox2h5r7doo3ys9ynm2nbo5',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1987-01-03',
      foot: 'right',
      height: '190',
      opta_first_name: 'Adrián',
      opta_last_name: 'San Miguel del Castillo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Sevilla',
      type: 'player',
      weight: '80',
      first_name: 'Adrián',
      first_name_upper: 'ADRIÁN',
      last_name: 'San Miguel del Castillo',
      last_name_upper: 'SAN MIGUEL DEL CASTILLO',
    },
    {
      _id: '6166c8e9e65c4ef2043ece74',
      opta_ID: '2zfjgh5747ge9r9q0thbk123t',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-05-25',
      foot: 'right',
      height: '192',
      opta_first_name: 'Ibrahima',
      opta_last_name: 'Konaté',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Paris',
      type: 'player',
      weight: '93',
      first_name: 'Ibrahima',
      first_name_upper: 'IBRAHIMA',
      last_name: 'Konaté',
      last_name_upper: 'KONATÉ',
    },
    {
      _id: '6166c8e9e65c4ef2043ece8e',
      opta_ID: '3kdl679ra6mlobdg09xqf7fft',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-01-30',
      foot: '',
      height: '185',
      opta_first_name: 'Curtis',
      opta_last_name: 'Jones',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '75',
      first_name: 'Curtis',
      first_name_upper: 'CURTIS',
      last_name: 'Jones',
      last_name_upper: 'JONES',
    },
    {
      _id: '6166c8e9e65c4ef2043eceaa',
      opta_ID: '3kwkd3b95n8qzqtq4idxav45x',
      __v: 0,
      country_of_birth: 'Greece',
      date_of_birth: '1996-05-12',
      foot: 'left',
      height: '177',
      opta_first_name: 'Konstantinos',
      opta_last_name: 'Tsimikas',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d75f',
      place_of_birth: 'Thessaloníki',
      type: 'player',
      weight: '70',
      first_name: 'Konstantinos',
      first_name_upper: 'KONSTANTINOS',
      last_name: 'Tsimikas',
      last_name_upper: 'TSIMIKAS',
    },
    {
      _id: '6166c8e9e65c4ef2043ecec2',
      opta_ID: '3mgjcyaseuexxlssgatjgsand',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '2001-04-13',
      foot: 'right',
      height: '183',
      opta_first_name: 'Neco',
      opta_last_name: 'Williams',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: '',
      type: 'player',
      weight: '72',
      first_name: 'Neco',
      first_name_upper: 'NECO',
      last_name: 'Williams',
      last_name_upper: 'WILLIAMS',
    },
    {
      _id: '6166c8e9e65c4ef2043ecef7',
      opta_ID: '3xqbru6q5dvtrhv6coe7retp1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1986-01-04',
      foot: 'right',
      height: '175',
      opta_first_name: 'James Philip',
      opta_last_name: 'Milner',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leeds',
      type: 'player',
      weight: '70',
      first_name: 'James Philip',
      first_name_upper: 'JAMES PHILIP',
      last_name: 'Milner',
      last_name_upper: 'MILNER',
    },
    {
      _id: '6166c8e9e65c4ef2043ecf0f',
      opta_ID: '53d0pvr7ylvfhp6p749iniqc5',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1992-10-02',
      foot: 'right',
      height: '193',
      opta_first_name: 'Alisson Ramsés',
      opta_last_name: 'Becker',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Novo Hamburgo',
      type: 'player',
      weight: '91',
      first_name: 'Alisson Ramsés',
      first_name_upper: 'ALISSON RAMSÉS',
      last_name: 'Becker',
      last_name_upper: 'BECKER',
    },
    {
      _id: '6166c8e9e65c4ef2043ecf28',
      opta_ID: '5ilkkfbsss0bxd6ttdlqg0uz9',
      __v: 0,
      country_of_birth: 'Egypt',
      date_of_birth: '1992-06-15',
      foot: 'left',
      height: '175',
      opta_first_name: 'Mohamed',
      opta_last_name: 'Salah Ghaly',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d40b',
      place_of_birth: 'Muḥāfaẓat al Gharbiyya',
      type: 'player',
      weight: '71',
      first_name: 'Mohamed',
      first_name_upper: 'MOHAMED',
      last_name: 'Salah Ghaly',
      last_name_upper: 'SALAH GHALY',
    },
    {
      _id: '6166c8e9e65c4ef2043ecf40',
      opta_ID: '5tbz2hexz7vind39ot6govqzu',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '2003-07-09',
      foot: '',
      height: '180',
      opta_first_name: 'Conor',
      opta_last_name: 'Bradley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Dungannon',
      type: 'player',
      weight: '',
      first_name: 'Conor',
      first_name_upper: 'CONOR',
      last_name: 'Bradley',
      last_name_upper: 'BRADLEY',
    },
    {
      _id: '6166c8e9e65c4ef2043ecf5a',
      opta_ID: '6b9erfx1rf9nff83300tntadx',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1991-10-02',
      foot: 'right',
      height: '181',
      opta_first_name: 'Roberto Firmino',
      opta_last_name: 'Barbosa de Oliveira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Maceió',
      type: 'player',
      weight: '76',
      first_name: 'Roberto Firmino',
      first_name_upper: 'ROBERTO FIRMINO',
      last_name: 'Barbosa de Oliveira',
      last_name_upper: 'BARBOSA DE OLIVEIRA',
    },
    {
      _id: '6166c8e9e65c4ef2043ecf92',
      opta_ID: '6onkmsi77t7bvk9tpha472vv9',
      __v: 0,
      country_of_birth: 'Guinea',
      date_of_birth: '1995-02-10',
      foot: 'right',
      height: '172',
      opta_first_name: 'Naby',
      opta_last_name: 'Keïta',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d42a',
      place_of_birth: 'Conakry',
      type: 'player',
      weight: '64',
      first_name: 'Naby',
      first_name_upper: 'NABY',
      last_name: 'Keïta',
      last_name_upper: 'KEÏTA',
    },
    {
      _id: '6166c8eae65c4ef2043ecfa7',
      opta_ID: '7m4pw4plxndt68iq7f3s60cet',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1993-06-22',
      foot: 'right',
      height: '189',
      opta_first_name: 'Loris',
      opta_last_name: 'Karius',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Biberach an der Riß',
      type: 'player',
      weight: '90',
      first_name: 'Loris',
      first_name_upper: 'LORIS',
      last_name: 'Karius',
      last_name_upper: 'KARIUS',
    },
    {
      _id: '6166c8eae65c4ef2043ecfc0',
      opta_ID: '7mms3tmwizhtvzakotl4hv0et',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1993-10-23',
      foot: 'right',
      height: '188',
      opta_first_name: 'Fábio Henrique',
      opta_last_name: 'Tavares',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Campinas',
      type: 'player',
      weight: '78',
      first_name: 'Fábio Henrique',
      first_name_upper: 'FÁBIO HENRIQUE',
      last_name: 'Tavares',
      last_name_upper: 'TAVARES',
    },
    {
      _id: '6166c8eae65c4ef2043ecfe0',
      opta_ID: '7s1q4rd90gi1gwbko34fuvhlh',
      __v: 0,
      country_of_birth: 'Kosovo',
      date_of_birth: '1991-10-10',
      foot: 'both',
      height: '169',
      opta_first_name: 'Xherdan',
      opta_last_name: 'Shaqiri',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d909',
      place_of_birth: 'Gjilan',
      type: 'player',
      weight: '72',
      first_name: 'Xherdan',
      first_name_upper: 'XHERDAN',
      last_name: 'Shaqiri',
      last_name_upper: 'SHAQIRI',
    },
    {
      _id: '6166c8eae65c4ef2043ecffc',
      opta_ID: '83n6uee0egley0s9rzjq1x905',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-06-19',
      foot: 'left',
      height: '177',
      opta_first_name: 'Oluwaseyi Babajide',
      opta_last_name: 'Ojo',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Hemel Hempstead',
      type: 'player',
      weight: '64',
      first_name: 'Oluwaseyi Babajide',
      first_name_upper: 'OLUWASEYI BABAJIDE',
      last_name: 'Ojo',
      last_name_upper: 'OJO',
    },
    {
      _id: '6166c8eae65c4ef2043ed01e',
      opta_ID: '8ckml3ywxcyv3w7978rki88vd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-03-21',
      foot: 'right',
      height: '190',
      opta_first_name: 'Nathaniel',
      opta_last_name: 'Phillips',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bolton',
      type: 'player',
      weight: '',
      first_name: 'Nathaniel',
      first_name_upper: 'NATHANIEL',
      last_name: 'Phillips',
      last_name_upper: 'PHILLIPS',
    },
    {
      _id: '6166c8eae65c4ef2043ed03e',
      opta_ID: '8iwme9n9fhlu50yqm3pvtrd91',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-08-11',
      foot: 'left',
      height: '185',
      opta_first_name: 'Ben',
      opta_last_name: 'Davies',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Barrow',
      type: 'player',
      weight: '74',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Davies',
      last_name_upper: 'DAVIES',
    },
    {
      _id: '6166c8eae65c4ef2043ed071',
      opta_ID: '9twvo5p1ge4zfgy5hcu0nsvdh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-08-15',
      foot: 'both',
      height: '180',
      opta_first_name: 'Alex',
      opta_last_name: 'Oxlade-Chamberlain',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Portsmouth',
      type: 'player',
      weight: '70',
      first_name: 'Alex',
      first_name_upper: 'ALEX',
      last_name: 'Oxlade-Chamberlain',
      last_name_upper: 'OXLADE-CHAMBERLAIN',
    },
    {
      _id: '6166c8eae65c4ef2043ed08b',
      opta_ID: '9yvceeo1ghefz61ibursg3ckq',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '2002-12-20',
      foot: 'right',
      height: '191',
      opta_first_name: 'Marcelo',
      opta_last_name: 'de Araújo Pitaluga Filho',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Niterói',
      type: 'player',
      weight: '75',
      first_name: 'Marcelo',
      first_name_upper: 'MARCELO',
      last_name: 'de Araújo Pitaluga Filho',
      last_name_upper: 'DE ARAÚJO PITALUGA FILHO',
    },
    {
      _id: '6166c8eae65c4ef2043ed0a6',
      opta_ID: '9yw8otph5rger7665zx1yqrpx',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1967-06-16',
      foot: '',
      height: '191',
      opta_first_name: 'Jürgen',
      opta_last_name: 'Klopp',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Stuttgart',
      type: 'coach',
      weight: '83',
      first_name: 'Jürgen',
      first_name_upper: 'JÜRGEN',
      last_name: 'Klopp',
      last_name_upper: 'KLOPP',
    },
    {
      _id: '6166c8eae65c4ef2043ed0e7',
      opta_ID: 'bnle6fgjp2e3jrhh70ulfhzsa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2004-10-05',
      foot: '',
      height: '',
      opta_first_name: 'Kaide',
      opta_last_name: 'Gordon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Kaide',
      first_name_upper: 'KAIDE',
      last_name: 'Gordon',
      last_name_upper: 'GORDON',
    },
    {
      _id: '6166c8ebe65c4ef2043ed166',
      opta_ID: 'bz2p1aiij462e1emd7sh5psd1',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1996-12-04',
      foot: 'right',
      height: '178',
      opta_first_name: 'Diogo José',
      opta_last_name: 'Teixeira da Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Massarelos',
      type: 'player',
      weight: '68',
      first_name: 'Diogo José',
      first_name_upper: 'DIOGO JOSÉ',
      last_name: 'Teixeira da Silva',
      last_name_upper: 'TEIXEIRA DA SILVA',
    },
    {
      _id: '6166c8ebe65c4ef2043ed188',
      opta_ID: 'cl5yu890xe9edh3xd7obfxhm2',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-09-20',
      foot: '',
      height: '',
      opta_first_name: 'James',
      opta_last_name: 'Balagizi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Balagizi',
      last_name_upper: 'BALAGIZI',
    },
    {
      _id: '6166c8ebe65c4ef2043ed1b6',
      opta_ID: 'cotiiu6mjkfx5xa63nhfbdf4l',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '1992-04-10',
      foot: 'right',
      height: '175',
      opta_first_name: 'Sadio',
      opta_last_name: 'Mané',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: 'Sedhiou',
      type: 'player',
      weight: '69',
      first_name: 'Sadio',
      first_name_upper: 'SADIO',
      last_name: 'Mané',
      last_name_upper: 'MANÉ',
    },
    {
      _id: '6166c8ebe65c4ef2043ed1d9',
      opta_ID: 'csvqjyuk06gzvjm408513nydx',
      __v: 0,
      country_of_birth: 'Japan',
      date_of_birth: '1995-01-16',
      foot: 'right',
      height: '174',
      opta_first_name: 'Takumi',
      opta_last_name: 'Minamino',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d2b8',
      place_of_birth: 'Osaka',
      type: 'player',
      weight: '68',
      first_name: 'Takumi',
      first_name_upper: 'TAKUMI',
      last_name: 'Minamino',
      last_name_upper: 'MINAMINO',
    },
    {
      _id: '6166c8ebe65c4ef2043ed1f9',
      opta_ID: 'dbvpo938uwe0s420lospkethx',
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '1991-04-11',
      foot: 'right',
      height: '174',
      opta_first_name: 'Thiago',
      opta_last_name: 'Alcântara do Nascimento',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'San Pietro Vernotico',
      type: 'player',
      weight: '70',
      first_name: 'Thiago',
      first_name_upper: 'THIAGO',
      last_name: 'Alcântara do Nascimento',
      last_name_upper: 'ALCÂNTARA DO NASCIMENTO',
    },
    {
      _id: '6166c8ebe65c4ef2043ed211',
      opta_ID: 'ddmopdo3f8jnpvqjg8vu08251',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-10-15',
      foot: 'right',
      height: '174',
      opta_first_name: 'Benjamin Luke',
      opta_last_name: 'Woodburn',
      opta_last_updated: '2021-11-05T07:24:46Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Chester',
      type: 'player',
      weight: '72',
      first_name: 'Benjamin Luke',
      first_name_upper: 'BENJAMIN LUKE',
      last_name: 'Woodburn',
      last_name_upper: 'WOODBURN',
    },
    {
      _id: '6166c8ebe65c4ef2043ed22b',
      opta_ID: 'dlek4e9wgzy10smm0ph933nu2',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '2002-08-09',
      foot: '',
      height: '',
      opta_first_name: 'Owen',
      opta_last_name: 'Beck',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Owen',
      first_name_upper: 'OWEN',
      last_name: 'Beck',
      last_name_upper: 'BECK',
    },
    {
      _id: '6166c8ebe65c4ef2043ed243',
      opta_ID: 'dpw4s6nbkfcrjlqxxgf9j5yhh',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1998-11-23',
      foot: 'right',
      height: '188',
      opta_first_name: 'Caoimhin',
      opta_last_name: 'Kelleher',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Cork',
      type: 'player',
      weight: '',
      first_name: 'Caoimhin',
      first_name_upper: 'CAOIMHIN',
      last_name: 'Kelleher',
      last_name_upper: 'KELLEHER',
    },
    {
      _id: '6166c8ebe65c4ef2043ed263',
      opta_ID: 'dxze3b3fsfl814bjcs7q6wcet',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1991-07-08',
      foot: 'right',
      height: '193',
      opta_first_name: 'Virgil',
      opta_last_name: 'van Dijk',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Breda',
      type: 'player',
      weight: '92',
      first_name: 'Virgil',
      first_name_upper: 'VIRGIL',
      last_name: 'van Dijk',
      last_name_upper: 'VAN DIJK',
    },
    {
      _id: '6166c8ebe65c4ef2043ed27c',
      opta_ID: 'dz2rfsmhib89jwilpuub10qcq',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-04-04',
      foot: '',
      height: '170',
      opta_first_name: 'Harvey',
      opta_last_name: 'Elliott',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Harvey',
      first_name_upper: 'HARVEY',
      last_name: 'Elliott',
      last_name_upper: 'ELLIOTT',
    },
    {
      _id: '6166c8ece65c4ef2043ed293',
      opta_ID: 'eziqega9e25bg939pfh4vjp3p',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1994-03-11',
      foot: 'left',
      height: '178',
      opta_first_name: 'Andrew',
      opta_last_name: 'Robertson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'player',
      weight: '64',
      first_name: 'Andrew',
      first_name_upper: 'ANDREW',
      last_name: 'Robertson',
      last_name_upper: 'ROBERTSON',
    },
    {
      _id: '6166c8ece65c4ef2043ed2b5',
      opta_ID: 'j0b21u0klyu8taaxmooycdpm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-31',
      foot: '',
      height: '',
      opta_first_name: 'Tyler',
      opta_last_name: 'Morton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Tyler',
      first_name_upper: 'TYLER',
      last_name: 'Morton',
      last_name_upper: 'MORTON',
    },
    {
      _id: '6166c8ece65c4ef2043ed2d1',
      opta_ID: 'kvchpptoxgvcyiv358w9gtzp',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1991-08-08',
      foot: 'right',
      height: '195',
      opta_first_name: 'Joël Andre',
      opta_last_name: 'Job Matip',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3d1',
      place_of_birth: 'Bochum',
      type: 'player',
      weight: '90',
      first_name: 'Joël Andre',
      first_name_upper: 'JOËL ANDRE',
      last_name: 'Job Matip',
      last_name_upper: 'JOB MATIP',
    },
    {
      _id: '6166c8efe65c4ef2043ed6bf',
      opta_ID: '1xofhzib5lyqx285j8x36xg8a',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-02',
      foot: '',
      height: '',
      opta_first_name: 'Conrad Jonathan',
      opta_last_name: 'Egan-Riley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Conrad Jonathan',
      first_name_upper: 'CONRAD JONATHAN',
      last_name: 'Egan-Riley',
      last_name_upper: 'EGAN-RILEY',
    },
    {
      _id: '6166c8efe65c4ef2043ed77f',
      opta_ID: '23637o19ff0sw18pqv8g96ndh',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1993-08-17',
      foot: 'left',
      height: '188',
      opta_first_name: 'Ederson',
      opta_last_name: 'Santana de Moraes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Osasco',
      type: 'player',
      weight: '86',
      first_name: 'Ederson',
      first_name_upper: 'EDERSON',
      last_name: 'Santana de Moraes',
      last_name_upper: 'SANTANA DE MORAES',
    },
    {
      _id: '6166c8efe65c4ef2043ed7ae',
      opta_ID: '29pq64smaxdwlraopnxoleqxh',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1994-05-27',
      foot: 'right',
      height: '182',
      opta_first_name: 'João Pedro',
      opta_last_name: 'Cavaco Cancelo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Barreiro',
      type: 'player',
      weight: '74',
      first_name: 'João Pedro',
      first_name_upper: 'JOÃO PEDRO',
      last_name: 'Cavaco Cancelo',
      last_name_upper: 'CAVACO CANCELO',
    },
    {
      _id: '6166c8f0e65c4ef2043ed7f7',
      opta_ID: '2szgrzcosfeifh664tgt3mfvt',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '2000-02-29',
      foot: 'right',
      height: '184',
      opta_first_name: 'Ferran',
      opta_last_name: 'Torres García',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Foios',
      type: 'player',
      weight: '77',
      first_name: 'Ferran',
      first_name_upper: 'FERRAN',
      last_name: 'Torres García',
      last_name_upper: 'TORRES GARCÍA',
    },
    {
      _id: '6166c8f0e65c4ef2043ed813',
      opta_ID: '2t5s4f41jefzuzth0bhcixtcl',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1997-05-14',
      foot: 'both',
      height: '186',
      opta_first_name: 'Rúben',
      opta_last_name: 'dos Santos Gato Alves Dias',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Amadora',
      type: 'player',
      weight: '76',
      first_name: 'Rúben',
      first_name_upper: 'RÚBEN',
      last_name: 'dos Santos Gato Alves Dias',
      last_name_upper: 'DOS SANTOS GATO ALVES DIAS',
    },
    {
      _id: '6166c8f0e65c4ef2043ed836',
      opta_ID: '3gem1jrrkgye0omb88fk1lsk5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-05-28',
      foot: 'right',
      height: '188',
      opta_first_name: 'John',
      opta_last_name: 'Stones',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Barnsley',
      type: 'player',
      weight: '70',
      first_name: 'John',
      first_name_upper: 'JOHN',
      last_name: 'Stones',
      last_name_upper: 'STONES',
    },
    {
      _id: '6166c8f0e65c4ef2043ed871',
      opta_ID: '3l7amac6wdv9yztupg7ur3kl',
      __v: 0,
      country_of_birth: 'Ukraine',
      date_of_birth: '1996-12-15',
      foot: 'left',
      height: '175',
      opta_first_name: 'Oleksandr',
      opta_last_name: 'Zinchenko',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d91e',
      place_of_birth: 'Radomyshl',
      type: 'player',
      weight: '64',
      first_name: 'Oleksandr',
      first_name_upper: 'OLEKSANDR',
      last_name: 'Zinchenko',
      last_name_upper: 'ZINCHENKO',
    },
    {
      _id: '6166c8f0e65c4ef2043ed88b',
      opta_ID: '3r7wi8r14k888ip3y2bzbacid',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1971-01-18',
      foot: '',
      height: '180',
      opta_first_name: 'Josep',
      opta_last_name: 'Guardiola i Sala',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Santpedor',
      type: 'coach',
      weight: '70',
      first_name: 'Josep',
      first_name_upper: 'JOSEP',
      last_name: 'Guardiola i Sala',
      last_name_upper: 'GUARDIOLA I SALA',
    },
    {
      _id: '6166c8f0e65c4ef2043ed8a4',
      opta_ID: '3xq9irm7o2mp3zdlw4qt5plka',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-10-17',
      foot: 'right',
      height: '',
      opta_first_name: 'Thomas Glyn',
      opta_last_name: 'Doyle',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Thomas Glyn',
      first_name_upper: 'THOMAS GLYN',
      last_name: 'Doyle',
      last_name_upper: 'DOYLE',
    },
    {
      _id: '6166c8f1e65c4ef2043ed965',
      opta_ID: '67oh9fkwrut68eegfjf5emaui',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-28',
      foot: '',
      height: '',
      opta_first_name: 'Samuel',
      opta_last_name: 'Edozie',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Samuel',
      first_name_upper: 'SAMUEL',
      last_name: 'Edozie',
      last_name_upper: 'EDOZIE',
    },
    {
      _id: '6166c8f1e65c4ef2043ed985',
      opta_ID: '693z0rzfh3m0h0wsma9v8c9ux',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-05-28',
      foot: 'left',
      height: '171',
      opta_first_name: 'Philip',
      opta_last_name: 'Foden',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stockport',
      type: 'player',
      weight: '70',
      first_name: 'Philip',
      first_name_upper: 'PHILIP',
      last_name: 'Foden',
      last_name_upper: 'FODEN',
    },
    {
      _id: '6166c8f1e65c4ef2043ed9ac',
      opta_ID: '6o74ue6gk2m4qyiq2wimael79',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1985-09-03',
      foot: 'right',
      height: '183',
      opta_first_name: 'Scott',
      opta_last_name: 'Carson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Whitehaven',
      type: 'player',
      weight: '85',
      first_name: 'Scott',
      first_name_upper: 'SCOTT',
      last_name: 'Carson',
      last_name_upper: 'CARSON',
    },
    {
      _id: '6166c8f1e65c4ef2043ed9cc',
      opta_ID: '6pc04puqt3ddwat8rtp3gnka2',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2002-09-15',
      foot: '',
      height: '',
      opta_first_name: 'Cieran',
      opta_last_name: 'Slicker',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Cieran',
      first_name_upper: 'CIERAN',
      last_name: 'Slicker',
      last_name_upper: 'SLICKER',
    },
    {
      _id: '6166c8f1e65c4ef2043ed9e9',
      opta_ID: '6zjzpbshmem98ecmuc852di0a',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-18',
      foot: '',
      height: '',
      opta_first_name: 'James',
      opta_last_name: 'McAtee',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'McAtee',
      last_name_upper: 'MCATEE',
    },
    {
      _id: '6166c8f1e65c4ef2043eda19',
      opta_ID: '7fmpgkx58o9pivo2bbnxxkt05',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-05-27',
      foot: 'left',
      height: '191',
      opta_first_name: 'Aymeric',
      opta_last_name: 'Laporte',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Agen',
      type: 'player',
      weight: '86',
      first_name: 'Aymeric',
      first_name_upper: 'AYMERIC',
      last_name: 'Laporte',
      last_name_upper: 'LAPORTE',
    },
    {
      _id: '6166c8f1e65c4ef2043eda3d',
      opta_ID: '7jn9kl5wq29d41ekc2fz2rs2d',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1995-02-18',
      foot: 'left',
      height: '180',
      opta_first_name: 'Nathan',
      opta_last_name: 'Aké',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Den Haag',
      type: 'player',
      weight: '75',
      first_name: 'Nathan',
      first_name_upper: 'NATHAN',
      last_name: 'Aké',
      last_name_upper: 'AKÉ',
    },
    {
      _id: '6166c8f2e65c4ef2043eda74',
      opta_ID: '913pg8qqcjp99h5fjybbenob9',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1997-02-10',
      foot: 'right',
      height: '188',
      opta_first_name: 'Philippe',
      opta_last_name: 'Sandler',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Amsterdam',
      type: 'player',
      weight: '76',
      first_name: 'Philippe',
      first_name_upper: 'PHILIPPE',
      last_name: 'Sandler',
      last_name_upper: 'SANDLER',
    },
    {
      _id: '6166c8f2e65c4ef2043eda9b',
      opta_ID: '96hgdxqla3prs8zusk58lmdey',
      __v: 0,
      country_of_birth: 'Ghana',
      date_of_birth: '2003-09-18',
      foot: '',
      height: '',
      opta_first_name: 'Luke',
      opta_last_name: 'Mbete-Tabu',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Luke',
      first_name_upper: 'LUKE',
      last_name: 'Mbete-Tabu',
      last_name_upper: 'MBETE-TABU',
    },
    {
      _id: '6166c8f2e65c4ef2043edab4',
      opta_ID: '9q1pgu6p4wcn9qg4idxsipql1',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1994-07-17',
      foot: 'left',
      height: '185',
      opta_first_name: 'Benjamin',
      opta_last_name: 'Mendy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Longjumeau',
      type: 'player',
      weight: '85',
      first_name: 'Benjamin',
      first_name_upper: 'BENJAMIN',
      last_name: 'Mendy',
      last_name_upper: 'MENDY',
    },
    {
      _id: '6166c8f2e65c4ef2043edad0',
      opta_ID: '9wbf00lybk9fqablaqwg5cn2t',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1990-10-24',
      foot: 'right',
      height: '180',
      opta_first_name: 'İlkay',
      opta_last_name: 'Gündoğan',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Gelsenkirchen',
      type: 'player',
      weight: '80',
      first_name: 'İlkay',
      first_name_upper: 'İLKAY',
      last_name: 'Gündoğan',
      last_name_upper: 'GÜNDOĞAN',
    },
    {
      _id: '6166c8f2e65c4ef2043edb59',
      opta_ID: 'ay89se5rytzay8md0yeadykq2',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '2002-06-03',
      foot: 'right',
      height: '168',
      opta_first_name: 'Yan',
      opta_last_name: 'Bueno Couto',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Curitiba',
      type: 'player',
      weight: '60',
      first_name: 'Yan',
      first_name_upper: 'YAN',
      last_name: 'Bueno Couto',
      last_name_upper: 'BUENO COUTO',
    },
    {
      _id: '6166c8f2e65c4ef2043edb86',
      opta_ID: 'b3974hgsmyff5xtq7qf1onhlh',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1994-08-10',
      foot: 'left',
      height: '173',
      opta_first_name: 'Bernardo',
      opta_last_name: 'Mota Veiga de Carvalho e Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Lisboa',
      type: 'player',
      weight: '64',
      first_name: 'Bernardo',
      first_name_upper: 'BERNARDO',
      last_name: 'Mota Veiga de Carvalho e Silva',
      last_name_upper: 'MOTA VEIGA DE CARVALHO E SILVA',
    },
    {
      _id: '6166c8f2e65c4ef2043edbad',
      opta_ID: 'bv45ovp0guqakjoxky5ruriwa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-06-17',
      foot: '',
      height: '',
      opta_first_name: 'Finley',
      opta_last_name: 'Burns',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Finley',
      first_name_upper: 'FINLEY',
      last_name: 'Burns',
      last_name_upper: 'BURNS',
    },
    {
      _id: '6166c8f2e65c4ef2043edbc6',
      opta_ID: 'c2jqa9vozhqu7x79h5rr2n1ed',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1991-06-28',
      foot: 'right',
      height: '181',
      opta_first_name: 'Kevin',
      opta_last_name: 'De Bruyne',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Drongen',
      type: 'player',
      weight: '68',
      first_name: 'Kevin',
      first_name_upper: 'KEVIN',
      last_name: 'De Bruyne',
      last_name_upper: 'DE BRUYNE',
    },
    {
      _id: '6166c8f3e65c4ef2043edbf5',
      opta_ID: 'c3wkdzkvjk3yuffgnnw42wrf9',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1985-05-04',
      foot: 'right',
      height: '179',
      opta_first_name: 'Fernando Luiz',
      opta_last_name: 'Rosa',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Londrina',
      type: 'player',
      weight: '67',
      first_name: 'Fernando Luiz',
      first_name_upper: 'FERNANDO LUIZ',
      last_name: 'Rosa',
      last_name_upper: 'ROSA',
    },
    {
      _id: '6166c8f3e65c4ef2043edc12',
      opta_ID: 'd66tmyj9pt69gub8pcjgo3bh1',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1997-04-03',
      foot: 'right',
      height: '175',
      opta_first_name: 'Gabriel Fernando',
      opta_last_name: 'de Jesus',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'São Paulo',
      type: 'player',
      weight: '73',
      first_name: 'Gabriel Fernando',
      first_name_upper: 'GABRIEL FERNANDO',
      last_name: 'de Jesus',
      last_name_upper: 'DE JESUS',
    },
    {
      _id: '6166c8f3e65c4ef2043edc4b',
      opta_ID: 'dgjl6ks0r7lbh09fe8cuwj7ro',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '2004-01-06',
      foot: '',
      height: '',
      opta_first_name: 'Roméo',
      opta_last_name: 'Lavia',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Roméo',
      first_name_upper: 'ROMÉO',
      last_name: 'Lavia',
      last_name_upper: 'LAVIA',
    },
    {
      _id: '6166c8f3e65c4ef2043edc67',
      opta_ID: 'dl10343h8yopcgerzur5samwa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-05-06',
      foot: '',
      height: '',
      opta_first_name: 'Cole Jermaine',
      opta_last_name: 'Palmer',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Cole Jermaine',
      first_name_upper: 'COLE JERMAINE',
      last_name: 'Palmer',
      last_name_upper: 'PALMER',
    },
    {
      _id: '6166c8f3e65c4ef2043edc9d',
      opta_ID: 'dqfk7czrayirrvonirtk2mk15',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1996-06-22',
      foot: 'right',
      height: '191',
      opta_first_name: 'Rodrigo',
      opta_last_name: 'Hernández Cascante',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '82',
      first_name: 'Rodrigo',
      first_name_upper: 'RODRIGO',
      last_name: 'Hernández Cascante',
      last_name_upper: 'HERNÁNDEZ CASCANTE',
    },
    {
      _id: '6166c8f3e65c4ef2043edcbd',
      opta_ID: 'dua66xdc42wx57vmgh5t4ttxx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-02-05',
      foot: 'left',
      height: '168',
      opta_first_name: 'Patrick',
      opta_last_name: 'Roberts',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Kingston upon Thames',
      type: 'player',
      weight: '58',
      first_name: 'Patrick',
      first_name_upper: 'PATRICK',
      last_name: 'Roberts',
      last_name_upper: 'ROBERTS',
    },
    {
      _id: '6166c8f3e65c4ef2043edcd8',
      opta_ID: 'e9l5r0txzrjbenli05rn3k4wl',
      __v: 0,
      country_of_birth: 'Jamaica',
      date_of_birth: '1994-12-08',
      foot: 'both',
      height: '170',
      opta_first_name: 'Raheem',
      opta_last_name: 'Sterling',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Kingston',
      type: 'player',
      weight: '69',
      first_name: 'Raheem',
      first_name_upper: 'RAHEEM',
      last_name: 'Sterling',
      last_name_upper: 'STERLING',
    },
    {
      _id: '6166c8f3e65c4ef2043edcf4',
      opta_ID: 'e9wu8dcamrtj3ftbx3nmhusid',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-05-28',
      foot: 'right',
      height: '178',
      opta_first_name: 'Kyle',
      opta_last_name: 'Walker',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sheffield',
      type: 'player',
      weight: '70',
      first_name: 'Kyle',
      first_name_upper: 'KYLE',
      last_name: 'Walker',
      last_name_upper: 'WALKER',
    },
    {
      _id: '6166c8f3e65c4ef2043edd11',
      opta_ID: 'exiribcpg9vnuq9ekhlk74dlh',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1991-02-21',
      foot: 'left',
      height: '179',
      opta_first_name: 'Riyad',
      opta_last_name: 'Mahrez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d372',
      place_of_birth: 'Sarcelles',
      type: 'player',
      weight: '67',
      first_name: 'Riyad',
      first_name_upper: 'RIYAD',
      last_name: 'Mahrez',
      last_name_upper: 'MAHREZ',
    },
    {
      _id: '6166c8f3e65c4ef2043edd32',
      opta_ID: 'f2ou2jbp6zpccfa48v52f4dlh',
      __v: 0,
      country_of_birth: 'USA',
      date_of_birth: '1995-04-02',
      foot: 'right',
      height: '191',
      opta_first_name: 'Zack Thomas',
      opta_last_name: 'Steffen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d605',
      place_of_birth: 'Coatesville',
      type: 'player',
      weight: '86',
      first_name: 'Zack Thomas',
      first_name_upper: 'ZACK THOMAS',
      last_name: 'Steffen',
      last_name_upper: 'STEFFEN',
    },
    {
      _id: '6166c8f3e65c4ef2043edd4d',
      opta_ID: 'w541dxowigldl3yvsp55arfu',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-12-26',
      foot: '',
      height: '',
      opta_first_name: 'Joshua Darius',
      opta_last_name: 'Kamani Wilson-Esbrand',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Joshua Darius',
      first_name_upper: 'JOSHUA DARIUS',
      last_name: 'Kamani Wilson-Esbrand',
      last_name_upper: 'KAMANI WILSON-ESBRAND',
    },
    {
      _id: '6166c8f4e65c4ef2043edd6c',
      opta_ID: '15948izygnu32cu1pw6h5wp9l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-03-13',
      foot: 'right',
      height: '182',
      opta_first_name: 'James',
      opta_last_name: 'Garner',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birkenhead',
      type: 'player',
      weight: '',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Garner',
      last_name_upper: 'GARNER',
    },
    {
      _id: '6166c8f4e65c4ef2043edd89',
      opta_ID: '1jt6jnwdiaicjbff0phe6w2xh',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '1973-02-26',
      foot: '',
      height: '',
      opta_first_name: 'Ole Gunnar',
      opta_last_name: 'Solskjær',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Kristiansund',
      type: 'coach',
      weight: '',
      first_name: 'Ole Gunnar',
      first_name_upper: 'OLE GUNNAR',
      last_name: 'Solskjær',
      last_name_upper: 'SOLSKJÆR',
    },
    {
      _id: '6166c8f4e65c4ef2043eddab',
      opta_ID: '1vz038uyzmq8saskeeo0qhm8l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-03-05',
      foot: 'right',
      height: '194',
      opta_first_name: 'Harry',
      opta_last_name: 'Maguire',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Sheffield',
      type: 'player',
      weight: '100',
      first_name: 'Harry',
      first_name_upper: 'HARRY',
      last_name: 'Maguire',
      last_name_upper: 'MAGUIRE',
    },
    {
      _id: '6166c8f4e65c4ef2043ede06',
      opta_ID: '3dph74ixutmbpscaon0b25sl',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-04-25',
      foot: 'right',
      height: '191',
      opta_first_name: 'Raphaël',
      opta_last_name: 'Varane',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Lille',
      type: 'player',
      weight: '81',
      first_name: 'Raphaël',
      first_name_upper: 'RAPHAËL',
      last_name: 'Varane',
      last_name_upper: 'VARANE',
    },
    {
      _id: '6166c8f4e65c4ef2043ede25',
      opta_ID: '3f3jcsa7gk6in9fskp9w5a5t5',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1999-03-18',
      foot: 'right',
      height: '183',
      opta_first_name: 'José Diogo',
      opta_last_name: 'Dalot Teixeira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Braga',
      type: 'player',
      weight: '76',
      first_name: 'José Diogo',
      first_name_upper: 'JOSÉ DIOGO',
      last_name: 'Dalot Teixeira',
      last_name_upper: 'DALOT TEIXEIRA',
    },
    {
      _id: '6166c8f4e65c4ef2043ede49',
      opta_ID: '3ikhefktnj44ys7rqjzr5q09h',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1995-12-05',
      foot: 'right',
      height: '181',
      opta_first_name: 'Anthony',
      opta_last_name: 'Martial',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Massy',
      type: 'player',
      weight: '76',
      first_name: 'Anthony',
      first_name_upper: 'ANTHONY',
      last_name: 'Martial',
      last_name_upper: 'MARTIAL',
    },
    {
      _id: '6166c8f5e65c4ef2043ede62',
      opta_ID: '3vtjjrqof5z2tjj84ehadqc45',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1994-09-08',
      foot: 'right',
      height: '179',
      opta_first_name: 'Bruno Miguel',
      opta_last_name: 'Borges Fernandes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Maia',
      type: 'player',
      weight: '69',
      first_name: 'Bruno Miguel',
      first_name_upper: 'BRUNO MIGUEL',
      last_name: 'Borges Fernandes',
      last_name_upper: 'BORGES FERNANDES',
    },
    {
      _id: '6166c8f5e65c4ef2043ede7d',
      opta_ID: '4l8kwjx02xaep34d743vwvx05',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-02-21',
      foot: 'right',
      height: '180',
      opta_first_name: 'Phil',
      opta_last_name: 'Jones',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Preston',
      type: 'player',
      weight: '71',
      first_name: 'Phil',
      first_name_upper: 'PHIL',
      last_name: 'Jones',
      last_name_upper: 'JONES',
    },
    {
      _id: '6166c8f5e65c4ef2043edeb1',
      opta_ID: '5f72vvw62zdlyrj3mwux2udat',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-12-15',
      foot: 'right',
      height: '175',
      opta_first_name: 'Jesse',
      opta_last_name: 'Lingard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Warrington',
      type: 'player',
      weight: '62',
      first_name: 'Jesse',
      first_name_upper: 'JESSE',
      last_name: 'Lingard',
      last_name_upper: 'LINGARD',
    },
    {
      _id: '6166c8f5e65c4ef2043edee4',
      opta_ID: '5wengf05apke7rv3kms186j56',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '2002-04-27',
      foot: '',
      height: '',
      opta_first_name: 'Anthony David',
      opta_last_name: 'Junior Elanga',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d985',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Anthony David',
      first_name_upper: 'ANTHONY DAVID',
      last_name: 'Junior Elanga',
      last_name_upper: 'JUNIOR ELANGA',
    },
    {
      _id: '6166c8f5e65c4ef2043edf11',
      opta_ID: '5z8ycnd0smdkumi5fgtzf7c9h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-03-12',
      foot: 'right',
      height: '188',
      opta_first_name: 'Dean',
      opta_last_name: 'Henderson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Whitehaven',
      type: 'player',
      weight: '85',
      first_name: 'Dean',
      first_name_upper: 'DEAN',
      last_name: 'Henderson',
      last_name_upper: 'HENDERSON',
    },
    {
      _id: '6166c8f5e65c4ef2043edf31',
      opta_ID: '604osk9a3lxpfpkavuziqmn6x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-31',
      foot: 'right',
      height: '180',
      opta_first_name: 'Marcus',
      opta_last_name: 'Rashford',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Manchester',
      type: 'player',
      weight: '70',
      first_name: 'Marcus',
      first_name_upper: 'MARCUS',
      last_name: 'Rashford',
      last_name_upper: 'RASHFORD',
    },
    {
      _id: '6166c8f5e65c4ef2043edf4d',
      opta_ID: '68e7grrnvoj11rkblfjpvhhat',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1983-01-27',
      foot: 'right',
      height: '193',
      opta_first_name: 'Lee',
      opta_last_name: 'Grant',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Hemel Hempstead',
      type: 'player',
      weight: '83',
      first_name: 'Lee',
      first_name_upper: 'LEE',
      last_name: 'Grant',
      last_name_upper: 'GRANT',
    },
    {
      _id: '6166c8f5e65c4ef2043edf68',
      opta_ID: '6gruusc463fdln5uz1uzhne8l',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1997-04-18',
      foot: 'right',
      height: '184',
      opta_first_name: 'Donny',
      opta_last_name: 'van de Beek',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Nijkerkerveen',
      type: 'player',
      weight: '76',
      first_name: 'Donny',
      first_name_upper: 'DONNY',
      last_name: 'van de Beek',
      last_name_upper: 'VAN DE BEEK',
    },
    {
      _id: '6166c8f5e65c4ef2043edf89',
      opta_ID: '6kicom9a3lzp49urle78bevl6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-10-01',
      foot: 'left',
      height: '181',
      opta_first_name: 'Mason',
      opta_last_name: 'Greenwood',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Bradford',
      type: 'player',
      weight: '70',
      first_name: 'Mason',
      first_name_upper: 'MASON',
      last_name: 'Greenwood',
      last_name_upper: 'GREENWOOD',
    },
    {
      _id: '6166c8f6e65c4ef2043edfc0',
      opta_ID: '6q0c4aylpwtjwb0fonmlyjaax',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-03',
      foot: 'right',
      height: '171',
      opta_first_name: 'Brandon',
      opta_last_name: 'Williams',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '63',
      first_name: 'Brandon',
      first_name_upper: 'BRANDON',
      last_name: 'Williams',
      last_name_upper: 'WILLIAMS',
    },
    {
      _id: '6166c8f6e65c4ef2043edfe4',
      opta_ID: '7uxt9a7t22ij4reg1uire5uqd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-07-12',
      foot: 'left',
      height: '185',
      opta_first_name: 'Luke',
      opta_last_name: 'Shaw',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Kingston upon Thames',
      type: 'player',
      weight: '75',
      first_name: 'Luke',
      first_name_upper: 'LUKE',
      last_name: 'Shaw',
      last_name_upper: 'SHAW',
    },
    {
      _id: '6166c8f6e65c4ef2043ee00b',
      opta_ID: '84hisj17nuwx4scnizuxap239',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1986-04-15',
      foot: 'right',
      height: '188',
      opta_first_name: 'Tom',
      opta_last_name: 'Heaton',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Chester',
      type: 'player',
      weight: '85',
      first_name: 'Tom',
      first_name_upper: 'TOM',
      last_name: 'Heaton',
      last_name_upper: 'HEATON',
    },
    {
      _id: '6166c8f6e65c4ef2043ee02b',
      opta_ID: '9fhb0869tyqrio4u7txbhueax',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-08-05',
      foot: 'right',
      height: '177',
      opta_first_name: 'Ethan',
      opta_last_name: 'Laird',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Basingstoke',
      type: 'player',
      weight: '66',
      first_name: 'Ethan',
      first_name_upper: 'ETHAN',
      last_name: 'Laird',
      last_name_upper: 'LAIRD',
    },
    {
      _id: '6166c8f6e65c4ef2043ee05e',
      opta_ID: '9fvo9v5e9r0ptibied6ejv3zd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-12-08',
      foot: 'right',
      height: '193',
      opta_first_name: 'Scott',
      opta_last_name: 'McTominay',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Lancaster',
      type: 'player',
      weight: '88',
      first_name: 'Scott',
      first_name_upper: 'SCOTT',
      last_name: 'McTominay',
      last_name_upper: 'MCTOMINAY',
    },
    {
      _id: '6166c8f6e65c4ef2043ee07f',
      opta_ID: '9jw0h4escxh3eqz8oh860r3yt',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1994-04-12',
      foot: 'right',
      height: '187',
      opta_first_name: 'Eric Bertrand',
      opta_last_name: 'Bailly',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Bingerville',
      type: 'player',
      weight: '77',
      first_name: 'Eric Bertrand',
      first_name_upper: 'ERIC BERTRAND',
      last_name: 'Bailly',
      last_name_upper: 'BAILLY',
    },
    {
      _id: '6166c8f6e65c4ef2043ee0a0',
      opta_ID: 'a3ms8havadt1y9x381l325txx',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '1994-07-17',
      foot: 'right',
      height: '187',
      opta_first_name: 'Victor Jörgen',
      opta_last_name: 'Nilsson Lindelöf',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d985',
      place_of_birth: 'Västerås',
      type: 'player',
      weight: '80',
      first_name: 'Victor Jörgen',
      first_name_upper: 'VICTOR JÖRGEN',
      last_name: 'Nilsson Lindelöf',
      last_name_upper: 'NILSSON LINDELÖF',
    },
    {
      _id: '6166c8f6e65c4ef2043ee0ba',
      opta_ID: 'afyga3n1mliw0btcuixt6xolx',
      __v: 0,
      country_of_birth: 'Uruguay',
      date_of_birth: '1987-02-14',
      foot: 'right',
      height: '184',
      opta_first_name: 'Edinson Roberto',
      opta_last_name: 'Cavani Gómez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d659',
      place_of_birth: 'Salto',
      type: 'player',
      weight: '71',
      first_name: 'Edinson Roberto',
      first_name_upper: 'EDINSON ROBERTO',
      last_name: 'Cavani Gómez',
      last_name_upper: 'CAVANI GÓMEZ',
    },
    {
      _id: '6166c8f6e65c4ef2043ee0d7',
      opta_ID: 'ah7iqgru0htz9lh1bff94cjbp',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1996-01-01',
      foot: 'right',
      height: '178',
      opta_first_name: 'Andreas Hugo',
      opta_last_name: 'Hoelgebaum Pereira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Duffel',
      type: 'player',
      weight: '71',
      first_name: 'Andreas Hugo',
      first_name_upper: 'ANDREAS HUGO',
      last_name: 'Hoelgebaum Pereira',
      last_name_upper: 'HOELGEBAUM PEREIRA',
    },
    {
      _id: '6166c8f6e65c4ef2043ee0fd',
      opta_ID: 'artzh7xur73vi2kaso97fl756',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2003-01-21',
      foot: '',
      height: '',
      opta_first_name: 'Hannibal',
      opta_last_name: 'Mejbri',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4e1',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Hannibal',
      first_name_upper: 'HANNIBAL',
      last_name: 'Mejbri',
      last_name_upper: 'MEJBRI',
    },
    {
      _id: '6166c8f6e65c4ef2043ee11b',
      opta_ID: 'asweknp9d99safr6p9va75h3p',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1992-12-15',
      foot: 'left',
      height: '181',
      opta_first_name: 'Alex Nicolao',
      opta_last_name: 'Telles',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Caxias do Sul',
      type: 'player',
      weight: '67',
      first_name: 'Alex Nicolao',
      first_name_upper: 'ALEX NICOLAO',
      last_name: 'Telles',
      last_name_upper: 'TELLES',
    },
    {
      _id: '6166c8f7e65c4ef2043ee135',
      opta_ID: 'awsbedog2zaz7zpjucbcba82y',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '2004-02-02',
      foot: 'right',
      height: '',
      opta_first_name: 'Shola Maxwell',
      opta_last_name: 'Shoretire',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Shola Maxwell',
      first_name_upper: 'SHOLA MAXWELL',
      last_name: 'Shoretire',
      last_name_upper: 'SHORETIRE',
    },
    {
      _id: '6166c8f7e65c4ef2043ee15a',
      opta_ID: 'b77x3vpsogztatllq0zf32nhm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-04-30',
      foot: 'right',
      height: '183',
      opta_first_name: 'Teden',
      opta_last_name: 'Mengi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Manchester',
      type: 'player',
      weight: '',
      first_name: 'Teden',
      first_name_upper: 'TEDEN',
      last_name: 'Mengi',
      last_name_upper: 'MENGI',
    },
    {
      _id: '6166c8f7e65c4ef2043ee177',
      opta_ID: 'bbla2bv2m5sddzh1us5n8az85',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1988-04-28',
      foot: 'left',
      height: '170',
      opta_first_name: 'Juan Manuel',
      opta_last_name: 'Mata García',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Burgos',
      type: 'player',
      weight: '63',
      first_name: 'Juan Manuel',
      first_name_upper: 'JUAN MANUEL',
      last_name: 'Mata García',
      last_name_upper: 'MATA GARCÍA',
    },
    {
      _id: '6166c8f7e65c4ef2043ee196',
      opta_ID: 'c5a7z6vphv9tp9ddid5jnrnx1',
      __v: 0,
      country_of_birth: 'Serbia',
      date_of_birth: '1988-08-01',
      foot: 'left',
      height: '194',
      opta_first_name: 'Nemanja',
      opta_last_name: 'Matić',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8ec',
      place_of_birth: 'Šabac',
      type: 'player',
      weight: '85',
      first_name: 'Nemanja',
      first_name_upper: 'NEMANJA',
      last_name: 'Matić',
      last_name_upper: 'MATIĆ',
    },
    {
      _id: '6166c8f7e65c4ef2043ee1dd',
      opta_ID: 'cs4aca71qkt1ru20ppmi6djl6',
      __v: 0,
      country_of_birth: 'Czech Republic',
      date_of_birth: '2000-05-17',
      foot: '',
      height: '',
      opta_first_name: 'Matěj',
      opta_last_name: 'Kovář',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Matěj',
      first_name_upper: 'MATĚJ',
      last_name: 'Kovář',
      last_name_upper: 'KOVÁŘ',
    },
    {
      _id: '6166c8f7e65c4ef2043ee1fa',
      opta_ID: 'cv8egyrq9dlfgeamk6b7fdgq1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-03-25',
      foot: 'right',
      height: '180',
      opta_first_name: 'Jadon',
      opta_last_name: 'Sancho',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '76',
      first_name: 'Jadon',
      first_name_upper: 'JADON',
      last_name: 'Sancho',
      last_name_upper: 'SANCHO',
    },
    {
      _id: '6166c8f7e65c4ef2043ee216',
      opta_ID: 'didsdryngjutw2x6tpwnnju5m',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '2002-07-11',
      foot: 'left',
      height: '173',
      opta_first_name: 'Amad Diallo',
      opta_last_name: 'Traoré',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Amad Diallo',
      first_name_upper: 'AMAD DIALLO',
      last_name: 'Traoré',
      last_name_upper: 'TRAORÉ',
    },
    {
      _id: '6166c8f7e65c4ef2043ee253',
      opta_ID: 'dubi41xs74u89icfnn80vxx3p',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1993-03-05',
      foot: 'left',
      height: '169',
      opta_first_name: 'Frederico',
      opta_last_name: 'Rodrigues de Paula Santos',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Belo Horizonte',
      type: 'player',
      weight: '64',
      first_name: 'Frederico',
      first_name_upper: 'FREDERICO',
      last_name: 'Rodrigues de Paula Santos',
      last_name_upper: 'RODRIGUES DE PAULA SANTOS',
    },
    {
      _id: '6166c8f8e65c4ef2043ee272',
      opta_ID: 'e00004wkpfc8jawioejmpu1n9',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1990-11-07',
      foot: 'right',
      height: '192',
      opta_first_name: 'David',
      opta_last_name: 'de Gea Quintana',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '76',
      first_name: 'David',
      first_name_upper: 'DAVID',
      last_name: 'de Gea Quintana',
      last_name_upper: 'DE GEA QUINTANA',
    },
    {
      _id: '6166c8f8e65c4ef2043ee28f',
      opta_ID: 'e1t5rgvs2hwnldts6vn8agzc9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-11-26',
      foot: 'right',
      height: '183',
      opta_first_name: 'Aaron',
      opta_last_name: 'Wan-Bissaka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '72',
      first_name: 'Aaron',
      first_name_upper: 'AARON',
      last_name: 'Wan-Bissaka',
      last_name_upper: 'WAN-BISSAKA',
    },
    {
      _id: '6166c8f8e65c4ef2043ee2d0',
      opta_ID: 'f482w50k80zbyb2ykh84yijf9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-03-15',
      foot: 'both',
      height: '191',
      opta_first_name: 'Paul',
      opta_last_name: 'Pogba',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Lagny-sur-Marne',
      type: 'player',
      weight: '84',
      first_name: 'Paul',
      first_name_upper: 'PAUL',
      last_name: 'Pogba',
      last_name_upper: 'POGBA',
    },
    {
      _id: '6166c8f8e65c4ef2043ee2f6',
      opta_ID: 'h17s3qts1dz1zqjw19jazzkl',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1985-02-05',
      foot: 'right',
      height: '187',
      opta_first_name: 'Cristiano Ronaldo',
      opta_last_name: 'dos Santos Aveiro',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Funchal',
      type: 'player',
      weight: '83',
      first_name: 'Cristiano Ronaldo',
      first_name_upper: 'CRISTIANO RONALDO',
      last_name: 'dos Santos Aveiro',
      last_name_upper: 'DOS SANTOS AVEIRO',
    },
    {
      _id: '6166c8fce65c4ef2043ee847',
      opta_ID: '4ca71ufcoyg21mmzwq4i9tfit',
      __v: 0,
      country_of_birth: 'Cuba',
      date_of_birth: '1993-02-01',
      foot: 'both',
      height: '172',
      opta_first_name: 'Onel Lázaro',
      opta_last_name: 'Hernández Mayea',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13ae65c4ef20434d577',
      place_of_birth: 'Morón, Ciego de Ávila',
      type: 'player',
      weight: '76',
      first_name: 'Onel Lázaro',
      first_name_upper: 'ONEL LÁZARO',
      last_name: 'Hernández Mayea',
      last_name_upper: 'HERNÁNDEZ MAYEA',
    },
    {
      _id: '6166c907e65c4ef2043ef88c',
      opta_ID: '23oysjfutg5xy1f3o9bapnmxh',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1994-05-05',
      foot: 'right',
      height: '178',
      opta_first_name: 'Javier',
      opta_last_name: 'Manquillo Gaitán',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '76',
      first_name: 'Javier',
      first_name_upper: 'JAVIER',
      last_name: 'Manquillo Gaitán',
      last_name_upper: 'MANQUILLO GAITÁN',
    },
    {
      _id: '6166c907e65c4ef2043ef8ad',
      opta_ID: '2qr47jkfmxw0c4auuiu3uf2l1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-03-22',
      foot: 'right',
      height: '185',
      opta_first_name: 'Isaac',
      opta_last_name: 'Hayden',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Chelmsford',
      type: 'player',
      weight: '79',
      first_name: 'Isaac',
      first_name_upper: 'ISAAC',
      last_name: 'Hayden',
      last_name_upper: 'HAYDEN',
    },
    {
      _id: '6166c907e65c4ef2043ef8cc',
      opta_ID: '2uffjxmtrrp2pjzvwebzy19lh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-03-27',
      foot: '',
      height: '192',
      opta_first_name: 'Mark',
      opta_last_name: 'Gillespie',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Newcastle upon Tyne',
      type: 'player',
      weight: '83',
      first_name: 'Mark',
      first_name_upper: 'MARK',
      last_name: 'Gillespie',
      last_name_upper: 'GILLESPIE',
    },
    {
      _id: '6166c908e65c4ef2043ef8e3',
      opta_ID: '37d7oej48pb143o5eilue1hhx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-02-24',
      foot: 'both',
      height: '179',
      opta_first_name: 'Jacob',
      opta_last_name: 'Murphy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '74',
      first_name: 'Jacob',
      first_name_upper: 'JACOB',
      last_name: 'Murphy',
      last_name_upper: 'MURPHY',
    },
    {
      _id: '6166c908e65c4ef2043ef8fb',
      opta_ID: '5g725u1zyc8og1hesmg30wyxh',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1992-01-31',
      foot: 'right',
      height: '185',
      opta_first_name: 'Jeff Patrick',
      opta_last_name: 'Hendrick',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Dublin',
      type: 'player',
      weight: '79',
      first_name: 'Jeff Patrick',
      first_name_upper: 'JEFF PATRICK',
      last_name: 'Hendrick',
      last_name_upper: 'HENDRICK',
    },
    {
      _id: '6166c908e65c4ef2043ef918',
      opta_ID: '5oyzmrwyt8l0ta31w661nlwfe',
      __v: 0,
      country_of_birth: 'England',
      foot: '',
      height: '',
      opta_first_name: 'Ben',
      opta_last_name: 'Dawson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'assistant coach',
      weight: '',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Dawson',
      last_name_upper: 'DAWSON',
    },
    {
      _id: '6166c908e65c4ef2043ef94a',
      opta_ID: '66r7rq9kcoyow46ovmhzrtec5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-02-27',
      foot: 'both',
      height: '180',
      opta_first_name: 'Callum',
      opta_last_name: 'Wilson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Coventry',
      type: 'player',
      weight: '66',
      first_name: 'Callum',
      first_name_upper: 'CALLUM',
      last_name: 'Wilson',
      last_name_upper: 'WILSON',
    },
    {
      _id: '6166c908e65c4ef2043ef96f',
      opta_ID: '6mra6yxsp1id4bzjweincnkrt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-09-23',
      foot: 'right',
      height: '173',
      opta_first_name: 'Thomas David',
      opta_last_name: 'Allan',
      opta_last_updated: '2021-11-06T15:42:34Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Newcastle upon Tyne',
      type: 'player',
      weight: '78',
      first_name: 'Thomas David',
      first_name_upper: 'THOMAS DAVID',
      last_name: 'Allan',
      last_name_upper: 'ALLAN',
    },
    {
      _id: '6166c908e65c4ef2043ef98b',
      opta_ID: '6otgu2ezmd171skyysl1ku09h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-09-26',
      foot: 'left',
      height: '183',
      opta_first_name: 'Paul',
      opta_last_name: 'Dummett',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Newcastle upon Tyne',
      type: 'player',
      weight: '82',
      first_name: 'Paul',
      first_name_upper: 'PAUL',
      last_name: 'Dummett',
      last_name_upper: 'DUMMETT',
    },
    {
      _id: '6166c908e65c4ef2043ef9a4',
      opta_ID: '7gcvzjdbqxe5jw1zn07ie4dsl',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1994-02-24',
      foot: 'right',
      height: '163',
      opta_first_name: 'Ryan',
      opta_last_name: 'Fraser',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Aberdeen',
      type: 'player',
      weight: '70',
      first_name: 'Ryan',
      first_name_upper: 'RYAN',
      last_name: 'Fraser',
      last_name_upper: 'FRASER',
    },
    {
      _id: '6166c908e65c4ef2043ef9bf',
      opta_ID: '7rnd1uiknmho0s7a0objtnlcl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-03-04',
      foot: 'right',
      height: '188',
      opta_first_name: 'Freddie',
      opta_last_name: 'Woodman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '83',
      first_name: 'Freddie',
      first_name_upper: 'FREDDIE',
      last_name: 'Woodman',
      last_name_upper: 'WOODMAN',
    },
    {
      _id: '6166c908e65c4ef2043ef9d9',
      opta_ID: '7w4l5mrrrg66wvdd8ndb5zb9x',
      __v: 0,
      country_of_birth: 'Slovakia',
      date_of_birth: '1989-01-15',
      foot: 'right',
      height: '190',
      opta_first_name: 'Martin',
      opta_last_name: 'Dúbravka',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8f4',
      place_of_birth: 'Žilina',
      type: 'player',
      weight: '83',
      first_name: 'Martin',
      first_name_upper: 'MARTIN',
      last_name: 'Dúbravka',
      last_name_upper: 'DÚBRAVKA',
    },
    {
      _id: '6166c908e65c4ef2043ef9f9',
      opta_ID: '7w8raplugv51unjcao6cq22tx',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '1994-08-02',
      foot: 'right',
      height: '184',
      opta_first_name: 'Emil Henry Kristoffer',
      opta_last_name: 'Krafth',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d985',
      place_of_birth: 'Stockholm',
      type: 'player',
      weight: '83',
      first_name: 'Emil Henry Kristoffer',
      first_name_upper: 'EMIL HENRY KRISTOFFER',
      last_name: 'Krafth',
      last_name_upper: 'KRAFTH',
    },
    {
      _id: '6166c909e65c4ef2043efa1b',
      opta_ID: '7z5ugeh3ejhwa3c8tg7idkpud',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-11-11',
      foot: 'right',
      height: '188',
      opta_first_name: 'Jamaal',
      opta_last_name: 'Lascelles',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Derby',
      type: 'player',
      weight: '89',
      first_name: 'Jamaal',
      first_name_upper: 'JAMAAL',
      last_name: 'Lascelles',
      last_name_upper: 'LASCELLES',
    },
    {
      _id: '6166c909e65c4ef2043efa32',
      opta_ID: '8lst9t1pyjhibrxh7xxl3wn9x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-09-10',
      foot: 'left',
      height: '172',
      opta_first_name: 'Matt',
      opta_last_name: 'Ritchie',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Gosport',
      type: 'player',
      weight: '76',
      first_name: 'Matt',
      first_name_upper: 'MATT',
      last_name: 'Ritchie',
      last_name_upper: 'RITCHIE',
    },
    {
      _id: '6166c909e65c4ef2043efa4d',
      opta_ID: '96yg5g3b9o4gqbo2p299h4gdl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-01-25',
      foot: 'left',
      height: '178',
      opta_first_name: 'Jamal',
      opta_last_name: 'Lewis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: '',
      type: 'player',
      weight: '70',
      first_name: 'Jamal',
      first_name_upper: 'JAMAL',
      last_name: 'Lewis',
      last_name_upper: 'LEWIS',
    },
    {
      _id: '6166c909e65c4ef2043efa69',
      opta_ID: '9lxfdd4laukytx0bzjdgtc4x5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-03-21',
      foot: '',
      height: '',
      opta_first_name: 'Matthew Ben',
      opta_last_name: 'Longstaff',
      opta_last_updated: '2021-11-05T07:24:46Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Matthew Ben',
      first_name_upper: 'MATTHEW BEN',
      last_name: 'Longstaff',
      last_name_upper: 'LONGSTAFF',
    },
    {
      _id: '6166c909e65c4ef2043efa8b',
      opta_ID: '9pv566tidfz21e00y4t0mbap5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-30',
      foot: '',
      height: '181',
      opta_first_name: 'Sean',
      opta_last_name: 'Longstaff',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Newcastle upon Tyne',
      type: 'player',
      weight: '65',
      first_name: 'Sean',
      first_name_upper: 'SEAN',
      last_name: 'Longstaff',
      last_name_upper: 'LONGSTAFF',
    },
    {
      _id: '6166c909e65c4ef2043efaba',
      opta_ID: 'a28kr2vgqtucg82768v067a39',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-10-17',
      foot: 'right',
      height: '174',
      opta_first_name: 'Dwight',
      opta_last_name: 'Gayle',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '78',
      first_name: 'Dwight',
      first_name_upper: 'DWIGHT',
      last_name: 'Gayle',
      last_name_upper: 'GAYLE',
    },
    {
      _id: '6166c909e65c4ef2043efad0',
      opta_ID: 'ap8zq75e3jozdtv8809iqpgmi',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-12-28',
      foot: '',
      height: '',
      opta_first_name: 'Daniel',
      opta_last_name: 'Langley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Langley',
      last_name_upper: 'LANGLEY',
    },
    {
      _id: '6166c909e65c4ef2043efaea',
      opta_ID: 'awx668jrec0gnqk5gyg7o0oyy',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-01',
      foot: '',
      height: '',
      opta_first_name: 'Joe',
      opta_last_name: 'White',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Joe',
      first_name_upper: 'JOE',
      last_name: 'White',
      last_name_upper: 'WHITE',
    },
    {
      _id: '6166c909e65c4ef2043efb03',
      opta_ID: 'b7eoho4q34nfispye73m8b00l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-02-27',
      foot: 'right',
      height: '184',
      opta_first_name: 'Jonjo',
      opta_last_name: 'Shelvey',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '80',
      first_name: 'Jonjo',
      first_name_upper: 'JONJO',
      last_name: 'Shelvey',
      last_name_upper: 'SHELVEY',
    },
    {
      _id: '6166c909e65c4ef2043efb1d',
      opta_ID: 'bijr1ghojrxc61se7ebt72f9x',
      __v: 0,
      country_of_birth: 'Paraguay',
      date_of_birth: '1994-02-10',
      foot: 'left',
      height: '174',
      opta_first_name: 'Miguel Ángel',
      opta_last_name: 'Almirón Rejala',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d663',
      place_of_birth: 'Asunción',
      type: 'player',
      weight: '63',
      first_name: 'Miguel Ángel',
      first_name_upper: 'MIGUEL ÁNGEL',
      last_name: 'Almirón Rejala',
      last_name_upper: 'ALMIRÓN REJALA',
    },
    {
      _id: '6166c909e65c4ef2043efb37',
      opta_ID: 'bn76qb89rwuedrs4br000e8yd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1960-12-31',
      foot: '',
      height: '183',
      opta_first_name: 'Steve',
      opta_last_name: 'Bruce',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Corbridge',
      type: 'coach',
      weight: '',
      first_name: 'Steve',
      first_name_upper: 'STEVE',
      last_name: 'Bruce',
      last_name_upper: 'BRUCE',
    },
    {
      _id: '6166c90ae65c4ef2043efb55',
      opta_ID: 'c589fk8pfqvbukvtdqu1yhjv9',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1996-08-14',
      foot: 'right',
      height: '186',
      opta_first_name: 'Joelinton Cassio',
      opta_last_name: 'Apolinário de Lira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Aliança',
      type: 'player',
      weight: '81',
      first_name: 'Joelinton Cassio',
      first_name_upper: 'JOELINTON CASSIO',
      last_name: 'Apolinário de Lira',
      last_name_upper: 'APOLINÁRIO DE LIRA',
    },
    {
      _id: '6166c90ae65c4ef2043efb6c',
      opta_ID: 'c7mccelov1pa1yesyw6297u1h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-09-26',
      foot: 'left',
      height: '185',
      opta_first_name: 'Ciaran',
      opta_last_name: 'Clark',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'London',
      type: 'player',
      weight: '88',
      first_name: 'Ciaran',
      first_name_upper: 'CIARAN',
      last_name: 'Clark',
      last_name_upper: 'CLARK',
    },
    {
      _id: '6166c90ae65c4ef2043efbaa',
      opta_ID: 'ci3y5sc505loxliowppioxwfe',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1973-02-19',
      foot: '',
      height: '',
      opta_first_name: 'Francisco',
      opta_last_name: 'de Míguel Moreno',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'assistant coach',
      weight: '',
      first_name: 'Francisco',
      first_name_upper: 'FRANCISCO',
      last_name: 'de Míguel Moreno',
      last_name_upper: 'DE MÍGUEL MORENO',
    },
    {
      _id: '6166c90ae65c4ef2043efbc6',
      opta_ID: 'cnoomjxp6tna0mx2ycuptgixh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-10-08',
      foot: 'right',
      height: '190',
      opta_first_name: 'Karl',
      opta_last_name: 'Darlow',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Northampton',
      type: 'player',
      weight: '88',
      first_name: 'Karl',
      first_name_upper: 'KARL',
      last_name: 'Darlow',
      last_name_upper: 'DARLOW',
    },
    {
      _id: '6166c90ae65c4ef2043efbf7',
      opta_ID: 'doctoq9o6be1yap6je75w0vrp',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1997-03-12',
      foot: 'right',
      height: '173',
      opta_first_name: 'Allan',
      opta_last_name: 'Saint-Maximin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Châtenay-Malabry',
      type: 'player',
      weight: '67',
      first_name: 'Allan',
      first_name_upper: 'ALLAN',
      last_name: 'Saint-Maximin',
      last_name_upper: 'SAINT-MAXIMIN',
    },
    {
      _id: '6166c90ae65c4ef2043efc0e',
      opta_ID: 'dxxc4dioaioidmvamkzqr4b85',
      __v: 0,
      country_of_birth: 'Switzerland',
      date_of_birth: '1991-12-20',
      foot: 'right',
      height: '188',
      opta_first_name: 'Fabian Lukas',
      opta_last_name: 'Schär',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d909',
      place_of_birth: 'Wil',
      type: 'player',
      weight: '84',
      first_name: 'Fabian Lukas',
      first_name_upper: 'FABIAN LUKAS',
      last_name: 'Schär',
      last_name_upper: 'SCHÄR',
    },
    {
      _id: '6166c90ae65c4ef2043efc2a',
      opta_ID: 'exzca2akgjl00jfd2hzh33h7t',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-11-06',
      foot: '',
      height: '',
      opta_first_name: 'Elliott',
      opta_last_name: 'Anderson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Elliott',
      first_name_upper: 'ELLIOTT',
      last_name: 'Anderson',
      last_name_upper: 'ANDERSON',
    },
    {
      _id: '6166c90ae65c4ef2043efc43',
      opta_ID: 'f54pd3cld0qkc8ol15bk9ye39',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1989-02-21',
      foot: 'right',
      height: '190',
      opta_first_name: 'Federico',
      opta_last_name: 'Fernández',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Tres Algarrobos',
      type: 'player',
      weight: '83',
      first_name: 'Federico',
      first_name_upper: 'FEDERICO',
      last_name: 'Fernández',
      last_name_upper: 'FERNÁNDEZ',
    },
    {
      _id: '6166c911e65c4ef2043f0446',
      opta_ID: '1c9edgqsq6j7jlzku2qamu8k5',
      __v: 0,
      country_of_birth: 'Kosovo',
      date_of_birth: '1996-06-28',
      foot: 'right',
      height: '177',
      opta_first_name: 'Milot',
      opta_last_name: 'Rashica',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d900',
      place_of_birth: 'Vushtrri',
      type: 'player',
      weight: '73',
      first_name: 'Milot',
      first_name_upper: 'MILOT',
      last_name: 'Rashica',
      last_name_upper: 'RASHICA',
    },
    {
      _id: '6166c911e65c4ef2043f047d',
      opta_ID: '1z1hy5u37hefynsj9w29vn6qd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-06-04',
      foot: 'right',
      height: '183',
      opta_first_name: 'Jordan',
      opta_last_name: 'Hugill',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Middlesbrough',
      type: 'player',
      weight: '64',
      first_name: 'Jordan',
      first_name_upper: 'JORDAN',
      last_name: 'Hugill',
      last_name_upper: 'HUGILL',
    },
    {
      _id: '6166c911e65c4ef2043f0494',
      opta_ID: '2zjxatr9s9esind5qke4wd3v9',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1991-11-20',
      foot: 'right',
      height: '187',
      opta_first_name: 'Grant',
      opta_last_name: 'Hanley',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Dumfries',
      type: 'player',
      weight: '81',
      first_name: 'Grant',
      first_name_upper: 'GRANT',
      last_name: 'Hanley',
      last_name_upper: 'HANLEY',
    },
    {
      _id: '6166c911e65c4ef2043f04c4',
      opta_ID: '3rtuz9v8j72g7582pqbuaijf9',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-01-22',
      foot: 'right',
      height: '196',
      opta_first_name: 'Angus',
      opta_last_name: 'Gunn',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Norwich',
      type: 'player',
      weight: '77',
      first_name: 'Angus',
      first_name_upper: 'ANGUS',
      last_name: 'Gunn',
      last_name_upper: 'GUNN',
    },
    {
      _id: '6166c911e65c4ef2043f04e2',
      opta_ID: '3yz0kut1bzvlb00jl6qx9awo9',
      __v: 0,
      country_of_birth: 'USA',
      date_of_birth: '2000-02-20',
      foot: 'right',
      height: '185',
      opta_first_name: 'Joshua Thomas',
      opta_last_name: 'Sargent',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d605',
      place_of_birth: "O'Fallon",
      type: 'player',
      weight: '79',
      first_name: 'Joshua Thomas',
      first_name_upper: 'JOSHUA THOMAS',
      last_name: 'Sargent',
      last_name_upper: 'SARGENT',
    },
    {
      _id: '6166c912e65c4ef2043f0506',
      opta_ID: '4lz9m2nwif7tql9hqsv2u7vft',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-10-01',
      foot: '',
      height: '',
      opta_first_name: 'Bali',
      opta_last_name: 'Mumba',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Bali',
      first_name_upper: 'BALI',
      last_name: 'Mumba',
      last_name_upper: 'MUMBA',
    },
    {
      _id: '6166c912e65c4ef2043f0524',
      opta_ID: '5okl8s3e8ad2add75m46ojo4p',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2001-06-11',
      foot: 'right',
      height: '166',
      opta_first_name: 'Billy',
      opta_last_name: 'Gilmour',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '60',
      first_name: 'Billy',
      first_name_upper: 'BILLY',
      last_name: 'Gilmour',
      last_name_upper: 'GILMOUR',
    },
    {
      _id: '6166c912e65c4ef2043f0540',
      opta_ID: '6eeqjr5cqfksw9mgx1b3vvnpx',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1992-01-08',
      foot: 'left',
      height: '183',
      opta_first_name: 'Kenny',
      opta_last_name: 'McLean',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Rutherglen',
      type: 'player',
      weight: '70',
      first_name: 'Kenny',
      first_name_upper: 'KENNY',
      last_name: 'McLean',
      last_name_upper: 'MCLEAN',
    },
    {
      _id: '6166c912e65c4ef2043f055a',
      opta_ID: '6ki6rv564ybctrajr2xtgyeax',
      __v: 0,
      country_of_birth: 'Turkey',
      date_of_birth: '2000-03-25',
      foot: 'right',
      height: '186',
      opta_first_name: 'Ozan Muhammed',
      opta_last_name: 'Kabak',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d93e',
      place_of_birth: 'Ankara',
      type: 'player',
      weight: '86',
      first_name: 'Ozan Muhammed',
      first_name_upper: 'OZAN MUHAMMED',
      last_name: 'Kabak',
      last_name_upper: 'KABAK',
    },
    {
      _id: '6166c912e65c4ef2043f0595',
      opta_ID: '6rp92ygg80tsb2jo1tqlrhoet',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-01-15',
      foot: 'left',
      height: '185',
      opta_first_name: 'Ben',
      opta_last_name: 'Gibson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Nunthorpe',
      type: 'player',
      weight: '78',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Gibson',
      last_name_upper: 'GIBSON',
    },
    {
      _id: '6166c912e65c4ef2043f05ae',
      opta_ID: '6xydddp1yp1hamdxbvbv8veuh',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '1998-03-23',
      foot: 'left',
      height: '177',
      opta_first_name: 'Przemysław',
      opta_last_name: 'Płacheta',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Łowicz',
      type: 'player',
      weight: '72',
      first_name: 'Przemysław',
      first_name_upper: 'PRZEMYSŁAW',
      last_name: 'Płacheta',
      last_name_upper: 'PŁACHETA',
    },
    {
      _id: '6166c912e65c4ef2043f05c6',
      opta_ID: '6yr5rwfdnz02psz22acr43z6d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-05-09',
      foot: '',
      height: '',
      opta_first_name: 'Aston',
      opta_last_name: 'Oxborough',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Aston',
      first_name_upper: 'ASTON',
      last_name: 'Oxborough',
      last_name_upper: 'OXBOROUGH',
    },
    {
      _id: '6166c912e65c4ef2043f05f0',
      opta_ID: '70hdfmna3nvwjlufm9rs92uzu',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2002-06-23',
      foot: 'right',
      height: '188',
      opta_first_name: 'Andrew Abiola',
      opta_last_name: 'Omobamidele',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Dublin',
      type: 'player',
      weight: '',
      first_name: 'Andrew Abiola',
      first_name_upper: 'ANDREW ABIOLA',
      last_name: 'Omobamidele',
      last_name_upper: 'OMOBAMIDELE',
    },
    {
      _id: '6166c912e65c4ef2043f060b',
      opta_ID: '70ursnnskhuf7zkltyrudquax',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-01-04',
      foot: 'right',
      height: '178',
      opta_first_name: 'Maximillian James',
      opta_last_name: 'Aarons',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '69',
      first_name: 'Maximillian James',
      first_name_upper: 'MAXIMILLIAN JAMES',
      last_name: 'Aarons',
      last_name_upper: 'AARONS',
    },
    {
      _id: '6166c912e65c4ef2043f062b',
      opta_ID: '7s0cxkx51fbrl8o6a88okd9ed',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '1996-05-28',
      foot: 'right',
      height: '179',
      opta_first_name: 'Mathias',
      opta_last_name: 'Antonsen Normann',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Svolvær',
      type: 'player',
      weight: '75',
      first_name: 'Mathias',
      first_name_upper: 'MATHIAS',
      last_name: 'Antonsen Normann',
      last_name_upper: 'ANTONSEN NORMANN',
    },
    {
      _id: '6166c913e65c4ef2043f064e',
      opta_ID: '8jb026oewf17pg61vdstcgj4l',
      __v: 0,
      country_of_birth: 'Finland',
      date_of_birth: '1990-03-29',
      foot: 'right',
      height: '180',
      opta_first_name: 'Teemu',
      opta_last_name: 'Pukki',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d731',
      place_of_birth: 'Kotka',
      type: 'player',
      weight: '75',
      first_name: 'Teemu',
      first_name_upper: 'TEEMU',
      last_name: 'Pukki',
      last_name_upper: 'PUKKI',
    },
    {
      _id: '6166c913e65c4ef2043f0667',
      opta_ID: '8sms9gmdoqxv60nckpyqxflqt',
      __v: 0,
      country_of_birth: 'Greece',
      date_of_birth: '1995-10-17',
      foot: 'left',
      height: '175',
      opta_first_name: 'Dimitris',
      opta_last_name: 'Giannoulis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d75f',
      place_of_birth: 'Katerini',
      type: 'player',
      weight: '62',
      first_name: 'Dimitris',
      first_name_upper: 'DIMITRIS',
      last_name: 'Giannoulis',
      last_name_upper: 'GIANNOULIS',
    },
    {
      _id: '6166c913e65c4ef2043f0686',
      opta_ID: '8wp415u3d2culxpmg7vzk33kl',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1988-04-03',
      foot: 'left',
      height: '188',
      opta_first_name: 'Tim',
      opta_last_name: 'Krul',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Den Haag',
      type: 'player',
      weight: '84',
      first_name: 'Tim',
      first_name_upper: 'TIM',
      last_name: 'Krul',
      last_name_upper: 'KRUL',
    },
    {
      _id: '6166c913e65c4ef2043f06a5',
      opta_ID: '9kvp5ddeay1cmfwoao8eo1vbp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-09-16',
      foot: 'right',
      height: '180',
      opta_first_name: 'Sam',
      opta_last_name: 'Byram',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Thurrock',
      type: 'player',
      weight: '72',
      first_name: 'Sam',
      first_name_upper: 'SAM',
      last_name: 'Byram',
      last_name_upper: 'BYRAM',
    },
    {
      _id: '6166c913e65c4ef2043f06f0',
      opta_ID: 'acffs01m2p3b4uemzjrp51d9m',
      __v: 0,
      country_of_birth: 'Greece',
      date_of_birth: '2002-01-30',
      foot: 'right',
      height: '179',
      opta_first_name: 'Christos',
      opta_last_name: 'Tzolis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d75f',
      place_of_birth: 'Thessaloniki',
      type: 'player',
      weight: '70',
      first_name: 'Christos',
      first_name_upper: 'CHRISTOS',
      last_name: 'Tzolis',
      last_name_upper: 'TZOLIS',
    },
    {
      _id: '6166c913e65c4ef2043f0709',
      opta_ID: 'ahdrszmsp39t7c7awbydpuuvp',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1991-01-08',
      foot: 'right',
      height: '178',
      opta_first_name: 'Lukas',
      opta_last_name: 'Rupp',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Heidelberg',
      type: 'player',
      weight: '73',
      first_name: 'Lukas',
      first_name_upper: 'LUKAS',
      last_name: 'Rupp',
      last_name_upper: 'RUPP',
    },
    {
      _id: '6166c913e65c4ef2043f0737',
      opta_ID: 'b4w5c354q8kv59sbav5g0vfmx',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2001-02-11',
      foot: 'right',
      height: '190',
      opta_first_name: 'Adam Uche',
      opta_last_name: 'Idah',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Douglas',
      type: 'player',
      weight: '83',
      first_name: 'Adam Uche',
      first_name_upper: 'ADAM UCHE',
      last_name: 'Idah',
      last_name_upper: 'IDAH',
    },
    {
      _id: '6166c913e65c4ef2043f0750',
      opta_ID: 'boik3ru9i1aghjgmiu0chqfah',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1998-03-03',
      foot: 'left',
      height: '184',
      opta_first_name: 'Jakob Lungi',
      opta_last_name: 'Sørensen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: '',
      type: 'player',
      weight: '77',
      first_name: 'Jakob Lungi',
      first_name_upper: 'JAKOB LUNGI',
      last_name: 'Sørensen',
      last_name_upper: 'SØRENSEN',
    },
    {
      _id: '6166c913e65c4ef2043f0771',
      opta_ID: 'bua1m21ihwr1u7btevhy19dcl',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1984-07-12',
      foot: 'right',
      height: '191',
      opta_first_name: 'Michael',
      opta_last_name: 'McGovern',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Enniskillen',
      type: 'player',
      weight: '89',
      first_name: 'Michael',
      first_name_upper: 'MICHAEL',
      last_name: 'McGovern',
      last_name_upper: 'MCGOVERN',
    },
    {
      _id: '6166c914e65c4ef2043f0793',
      opta_ID: 'bzvr1umoce37zyxq8hvwu1lgp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-02-27',
      foot: 'right',
      height: '177',
      opta_first_name: 'Todd',
      opta_last_name: 'Cantwell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '70',
      first_name: 'Todd',
      first_name_upper: 'TODD',
      last_name: 'Cantwell',
      last_name_upper: 'CANTWELL',
    },
    {
      _id: '6166c914e65c4ef2043f07d5',
      opta_ID: 'cy8xr24x4d4jd5vsqs2d9jcut',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1993-01-12',
      foot: 'right',
      height: '194',
      opta_first_name: 'Christoph',
      opta_last_name: 'Zimmermann',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: 'Düsseldorf',
      type: 'player',
      weight: '92',
      first_name: 'Christoph',
      first_name_upper: 'CHRISTOPH',
      last_name: 'Zimmermann',
      last_name_upper: 'ZIMMERMANN',
    },
    {
      _id: '6166c914e65c4ef2043f07f1',
      opta_ID: 'djo99px7j95m6hzrod3l7yk0l',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '1976-10-30',
      foot: '',
      height: '',
      opta_first_name: 'Daniel',
      opta_last_name: 'Farke',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7a4',
      place_of_birth: '',
      type: 'coach',
      weight: '',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Farke',
      last_name_upper: 'FARKE',
    },
    {
      _id: '6166c914e65c4ef2043f081c',
      opta_ID: 'dl4e5ojyhccj6w0vgksfzvmxh',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-05-25',
      foot: 'right',
      height: '185',
      opta_first_name: 'Pierre',
      opta_last_name: 'Lees Melou',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Langon',
      type: 'player',
      weight: '67',
      first_name: 'Pierre',
      first_name_upper: 'PIERRE',
      last_name: 'Lees Melou',
      last_name_upper: 'LEES MELOU',
    },
    {
      _id: '6166c914e65c4ef2043f0845',
      opta_ID: 'ew2z151h1qotdycgfi3no4gkp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-10-10',
      foot: 'right',
      height: '184',
      opta_first_name: 'Kieran',
      opta_last_name: 'Dowell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Ormskirk',
      type: 'player',
      weight: '59',
      first_name: 'Kieran',
      first_name_upper: 'KIERAN',
      last_name: 'Dowell',
      last_name_upper: 'DOWELL',
    },
    {
      _id: '6166c921e65c4ef2043f1b69',
      opta_ID: 'c9fm58xo515iedprum1zmnp49',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-03-25',
      foot: '',
      height: '',
      opta_first_name: 'Conor',
      opta_last_name: 'Coventry',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Waltham Forest',
      type: 'player',
      weight: '',
      first_name: 'Conor',
      first_name_upper: 'CONOR',
      last_name: 'Coventry',
      last_name_upper: 'COVENTRY',
    },
    {
      _id: '6166c931e65c4ef2043f30ba',
      opta_ID: '7h0gsxgvwctttg7n3r0uxcvrp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1991-06-26',
      foot: 'right',
      height: '178',
      opta_first_name: 'Andre',
      opta_last_name: 'Gray',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13ae65c4ef20434d594',
      place_of_birth: 'Wolverhampton',
      type: 'player',
      weight: '83',
      first_name: 'Andre',
      first_name_upper: 'ANDRE',
      last_name: 'Gray',
      last_name_upper: 'GRAY',
    },
    {
      _id: '6166c945e65c4ef2043f4e9e',
      opta_ID: '55yy0p6johtu9nxk90zsfctt5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-01-27',
      foot: '',
      height: '',
      opta_first_name: 'Morgan',
      opta_last_name: 'Gibbs-White',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Morgan',
      first_name_upper: 'MORGAN',
      last_name: 'Gibbs-White',
      last_name_upper: 'GIBBS-WHITE',
    },
    {
      _id: '6166c94ce65c4ef2043f5785',
      opta_ID: '1tbc8iokapjgu8zjrc1knq28l',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '1996-04-12',
      foot: 'right',
      height: '189',
      opta_first_name: 'Jan',
      opta_last_name: 'Bednarek',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Słupca',
      type: 'player',
      weight: '77',
      first_name: 'Jan',
      first_name_upper: 'JAN',
      last_name: 'Bednarek',
      last_name_upper: 'BEDNAREK',
    },
    {
      _id: '6166c94ce65c4ef2043f57b2',
      opta_ID: '2oijcg6e36mwy3ie1num5wclx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-03-16',
      foot: 'right',
      height: '176',
      opta_first_name: 'Theo',
      opta_last_name: 'Walcott',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '68',
      first_name: 'Theo',
      first_name_upper: 'THEO',
      last_name: 'Walcott',
      last_name_upper: 'WALCOTT',
    },
    {
      _id: '6166c94ce65c4ef2043f57cd',
      opta_ID: '2r1u01vlym0nwcwx86bwbuusq',
      __v: 0,
      country_of_birth: 'Ghana',
      date_of_birth: '1999-04-17',
      foot: 'left',
      height: '191',
      opta_first_name: 'Mohamed',
      opta_last_name: 'Salisu Abdul Karim',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d429',
      place_of_birth: 'Accra',
      type: 'player',
      weight: '82',
      first_name: 'Mohamed',
      first_name_upper: 'MOHAMED',
      last_name: 'Salisu Abdul Karim',
      last_name_upper: 'SALISU ABDUL KARIM',
    },
    {
      _id: '6166c94de65c4ef2043f57ee',
      opta_ID: '2uiza5b6fbum38chxtvmnkzqh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-04-13',
      foot: 'right',
      height: '173',
      opta_first_name: 'Kyle',
      opta_last_name: 'Walker-Peters',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '62',
      first_name: 'Kyle',
      first_name_upper: 'KYLE',
      last_name: 'Walker-Peters',
      last_name_upper: 'WALKER-PETERS',
    },
    {
      _id: '6166c94de65c4ef2043f581c',
      opta_ID: '4042kk7ylrs378rh26b7mh42x',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1997-02-01',
      foot: 'right',
      height: '187',
      opta_first_name: 'Lyanco Evangelista',
      opta_last_name: 'Silveira Neves Vojnović',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Vitória',
      type: 'player',
      weight: '85',
      first_name: 'Lyanco Evangelista',
      first_name_upper: 'LYANCO EVANGELISTA',
      last_name: 'Silveira Neves Vojnović',
      last_name_upper: 'SILVEIRA NEVES VOJNOVIĆ',
    },
    {
      _id: '6166c94de65c4ef2043f588b',
      opta_ID: '59ujnkvl7rlffomi2bqpsmbpx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-07-13',
      foot: 'right',
      height: '175',
      opta_first_name: 'Che',
      opta_last_name: 'Adams',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Leicester',
      type: 'player',
      weight: '70',
      first_name: 'Che',
      first_name_upper: 'CHE',
      last_name: 'Adams',
      last_name_upper: 'ADAMS',
    },
    {
      _id: '6166c94de65c4ef2043f58c2',
      opta_ID: '67qku7vyvbw76086eu05nowd1',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1997-09-22',
      foot: 'left',
      height: '173',
      opta_first_name: 'Romain',
      opta_last_name: 'Perraud',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Toulouse',
      type: 'player',
      weight: '68',
      first_name: 'Romain',
      first_name_upper: 'ROMAIN',
      last_name: 'Perraud',
      last_name_upper: 'PERRAUD',
    },
    {
      _id: '6166c94de65c4ef2043f58e9',
      opta_ID: '6r4adhp9hu4ux7egwgd8j2thx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-03-06',
      foot: 'right',
      height: '173',
      opta_first_name: 'Nathan',
      opta_last_name: 'Redmond',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Birmingham',
      type: 'player',
      weight: '69',
      first_name: 'Nathan',
      first_name_upper: 'NATHAN',
      last_name: 'Redmond',
      last_name_upper: 'REDMOND',
    },
    {
      _id: '6166c94de65c4ef2043f5907',
      opta_ID: '6tckvu2rw7ns18dq7dim0sout',
      __v: 0,
      country_of_birth: 'Morocco',
      date_of_birth: '1994-08-04',
      foot: 'right',
      height: '178',
      opta_first_name: 'Mohamed Amine',
      opta_last_name: 'Elyounoussi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Al Hoceima',
      type: 'player',
      weight: '70',
      first_name: 'Mohamed Amine',
      first_name_upper: 'MOHAMED AMINE',
      last_name: 'Elyounoussi',
      last_name_upper: 'ELYOUNOUSSI',
    },
    {
      _id: '6166c94ee65c4ef2043f5920',
      opta_ID: '6xdgasd4dvfgg4n23jw38473d',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-03-08',
      foot: 'left',
      height: '179',
      opta_first_name: 'Ibrahima',
      opta_last_name: 'Diallo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Tours',
      type: 'player',
      weight: '68',
      first_name: 'Ibrahima',
      first_name_upper: 'IBRAHIMA',
      last_name: 'Diallo',
      last_name_upper: 'DIALLO',
    },
    {
      _id: '6166c94ee65c4ef2043f593d',
      opta_ID: '7r6vds5j65fohi1k28cnqjqvp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-11-01',
      foot: 'right',
      height: '173',
      opta_first_name: 'James',
      opta_last_name: 'Ward-Prowse',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Portsmouth',
      type: 'player',
      weight: '66',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Ward-Prowse',
      last_name_upper: 'WARD-PROWSE',
    },
    {
      _id: '6166c94ee65c4ef2043f5953',
      opta_ID: '8wz5pdydepr5kzcichz17pv4l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1988-03-17',
      foot: 'right',
      height: '201',
      opta_first_name: 'Fraser',
      opta_last_name: 'Forster',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Hexham',
      type: 'player',
      weight: '93',
      first_name: 'Fraser',
      first_name_upper: 'FRASER',
      last_name: 'Forster',
      last_name_upper: 'FORSTER',
    },
    {
      _id: '6166c94ee65c4ef2043f596f',
      opta_ID: '8zzp0r2blbiqn779b5w1wkwd1',
      __v: 0,
      country_of_birth: 'Austria',
      date_of_birth: '1974-01-12',
      foot: '',
      height: '',
      opta_first_name: 'Richard',
      opta_last_name: 'Kitzbichler',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d707',
      place_of_birth: 'Wörgl',
      type: 'assistant coach',
      weight: '',
      first_name: 'Richard',
      first_name_upper: 'RICHARD',
      last_name: 'Kitzbichler',
      last_name_upper: 'KITZBICHLER',
    },
    {
      _id: '6166c94ee65c4ef2043f5992',
      opta_ID: '9tdb905kq5becch102boicdex',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-02-22',
      foot: 'right',
      height: '180',
      opta_first_name: 'Yan',
      opta_last_name: 'Valery',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Champigny-sur-Marne',
      type: 'player',
      weight: '85',
      first_name: 'Yan',
      first_name_upper: 'YAN',
      last_name: 'Valery',
      last_name_upper: 'VALERY',
    },
    {
      _id: '6166c94ee65c4ef2043f59b5',
      opta_ID: 'aqvkylme8y0hfk6zomed6wlwp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-02-21',
      foot: '',
      height: '',
      opta_first_name: 'William Anthony Patrick',
      opta_last_name: 'Smallbone',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Basingstoke',
      type: 'player',
      weight: '',
      first_name: 'William Anthony Patrick',
      first_name_upper: 'WILLIAM ANTHONY PATRICK',
      last_name: 'Smallbone',
      last_name_upper: 'SMALLBONE',
    },
    {
      _id: '6166c94ee65c4ef2043f59d0',
      opta_ID: 'ar0ciexd4iv7hcce44ryod3v9',
      __v: 0,
      country_of_birth: 'Austria',
      date_of_birth: '1967-08-09',
      foot: '',
      height: '191',
      opta_first_name: 'Ralph',
      opta_last_name: 'Hasenhüttl',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d707',
      place_of_birth: 'Graz',
      type: 'coach',
      weight: '89',
      first_name: 'Ralph',
      first_name_upper: 'RALPH',
      last_name: 'Hasenhüttl',
      last_name_upper: 'HASENHÜTTL',
    },
    {
      _id: '6166c94ee65c4ef2043f5a09',
      opta_ID: 'axnmil0bg1giwhoa31tks7ljp',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1992-03-30',
      foot: 'right',
      height: '183',
      opta_first_name: 'Stuart',
      opta_last_name: 'Armstrong',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Inverness',
      type: 'player',
      weight: '75',
      first_name: 'Stuart',
      first_name_upper: 'STUART',
      last_name: 'Armstrong',
      last_name_upper: 'ARMSTRONG',
    },
    {
      _id: '6166c94ee65c4ef2043f5a23',
      opta_ID: 'bdmomasg7lrn5pwihecvgpuga',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-11-12',
      foot: '',
      height: '',
      opta_first_name: 'Valentino',
      opta_last_name: 'Livramento',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Valentino',
      first_name_upper: 'VALENTINO',
      last_name: 'Livramento',
      last_name_upper: 'LIVRAMENTO',
    },
    {
      _id: '6166c94ee65c4ef2043f5a42',
      opta_ID: 'c98b7ch14jco5cncma6kpa86h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-12-20',
      foot: '',
      height: '191',
      opta_first_name: 'Harry',
      opta_last_name: 'Lewis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Shrewsbury',
      type: 'player',
      weight: '77',
      first_name: 'Harry',
      first_name_upper: 'HARRY',
      last_name: 'Lewis',
      last_name_upper: 'LEWIS',
    },
    {
      _id: '6166c94fe65c4ef2043f5a5d',
      opta_ID: 'cuqawmfekh86mgo758udxw7v9',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1991-09-24',
      foot: 'right',
      height: '183',
      opta_first_name: 'Oriol',
      opta_last_name: 'Romeu Vidal',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Ulldecona',
      type: 'player',
      weight: '83',
      first_name: 'Oriol',
      first_name_upper: 'ORIOL',
      last_name: 'Romeu Vidal',
      last_name_upper: 'ROMEU VIDAL',
    },
    {
      _id: '6166c94fe65c4ef2043f5a79',
      opta_ID: 'd3p7p18i2ybzlwz5p5gjdd239',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-12-03',
      foot: 'right',
      height: '193',
      opta_first_name: 'Alex',
      opta_last_name: 'McCarthy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Guildford',
      type: 'player',
      weight: '79',
      first_name: 'Alex',
      first_name_upper: 'ALEX',
      last_name: 'McCarthy',
      last_name_upper: 'MCCARTHY',
    },
    {
      _id: '6166c94fe65c4ef2043f5a94',
      opta_ID: 'dt8k9pwcj3zktd8xk3ai98vyt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-07-05',
      foot: '',
      height: '',
      opta_first_name: 'Nathan',
      opta_last_name: 'Tella',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Nathan',
      first_name_upper: 'NATHAN',
      last_name: 'Tella',
      last_name_upper: 'TELLA',
    },
    {
      _id: '6166c94fe65c4ef2043f5ad7',
      opta_ID: 'e1m3awfq2q04hmckogkiyuitx',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1987-01-22',
      foot: 'right',
      height: '178',
      opta_first_name: 'Shane Patrick',
      opta_last_name: 'Long',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Gortnahoe',
      type: 'player',
      weight: '81',
      first_name: 'Shane Patrick',
      first_name_upper: 'SHANE PATRICK',
      last_name: 'Long',
      last_name_upper: 'LONG',
    },
    {
      _id: '6166c94fe65c4ef2043f5af8',
      opta_ID: 'e382powtgetwn8npxf9nqeaga',
      __v: 0,
      country_of_birth: 'Mali',
      date_of_birth: '1998-06-15',
      foot: 'right',
      height: '177',
      opta_first_name: 'Moussa',
      opta_last_name: 'Djenepo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d47b',
      place_of_birth: 'Bamako',
      type: 'player',
      weight: '65',
      first_name: 'Moussa',
      first_name_upper: 'MOUSSA',
      last_name: 'Djenepo',
      last_name_upper: 'DJENEPO',
    },
    {
      _id: '6166c94fe65c4ef2043f5b14',
      opta_ID: 'e6la6wx7o6x7k4srpq8tdsafp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-01-27',
      foot: 'right',
      height: '185',
      opta_first_name: 'Jack',
      opta_last_name: 'Stephens',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Plymouth',
      type: 'player',
      weight: '75',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Stephens',
      last_name_upper: 'STEPHENS',
    },
    {
      _id: '6166c94fe65c4ef2043f5b42',
      opta_ID: 'ub3g41w6l3yfyzs4h24vpn8p',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '2000-07-06',
      foot: 'right',
      height: '169',
      opta_first_name: 'Michael',
      opta_last_name: 'Obafemi',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Dublin',
      type: 'player',
      weight: '71',
      first_name: 'Michael',
      first_name_upper: 'MICHAEL',
      last_name: 'Obafemi',
      last_name_upper: 'OBAFEMI',
    },
    {
      _id: '6166c963e65c4ef2043f73f0',
      opta_ID: '12ekwo6gbza410blv4b4r7ydx',
      __v: 0,
      country_of_birth: 'Korea Republic',
      date_of_birth: '1992-07-08',
      foot: 'both',
      height: '183',
      opta_first_name: 'Heung-Min',
      opta_last_name: 'Son',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d28e',
      place_of_birth: 'Chuncheon',
      type: 'player',
      weight: '78',
      first_name: 'Heung-Min',
      first_name_upper: 'HEUNG-MIN',
      last_name: 'Son',
      last_name_upper: 'SON',
    },
    {
      _id: '6166c963e65c4ef2043f7437',
      opta_ID: '17grqvetukbs4dbbui99604ix',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1999-12-17',
      foot: 'right',
      height: '187',
      opta_first_name: 'Tobi',
      opta_last_name: 'Omole',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Tobi',
      first_name_upper: 'TOBI',
      last_name: 'Omole',
      last_name_upper: 'OMOLE',
    },
    {
      _id: '6166c963e65c4ef2043f7476',
      opta_ID: '2w7i4jfw5wgh3j2wuworoumeh',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1998-04-27',
      foot: 'right',
      height: '185',
      opta_first_name: 'Cristian Gabriel',
      opta_last_name: 'Romero',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Córdoba',
      type: 'player',
      weight: '79',
      first_name: 'Cristian Gabriel',
      first_name_upper: 'CRISTIAN GABRIEL',
      last_name: 'Romero',
      last_name_upper: 'ROMERO',
    },
    {
      _id: '6166c963e65c4ef2043f74ab',
      opta_ID: '2ws74z0vjuq4vdt4575cgjbsa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-08-20',
      foot: '',
      height: '',
      opta_first_name: 'Dilan Kumar',
      opta_last_name: 'Markanday',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Dilan Kumar',
      first_name_upper: 'DILAN KUMAR',
      last_name: 'Markanday',
      last_name_upper: 'MARKANDAY',
    },
    {
      _id: '6166c963e65c4ef2043f74d6',
      opta_ID: '31wf775s975qy3il33oora8k5',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1992-01-16',
      foot: 'right',
      height: '183',
      opta_first_name: 'Matthew James',
      opta_last_name: 'Doherty',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Dublin',
      type: 'player',
      weight: '90',
      first_name: 'Matthew James',
      first_name_upper: 'MATTHEW JAMES',
      last_name: 'Doherty',
      last_name_upper: 'DOHERTY',
    },
    {
      _id: '6166c963e65c4ef2043f7502',
      opta_ID: '33h8s55zcj0j535g2dyz9tnqi',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2004-03-24',
      foot: '',
      height: '',
      opta_first_name: 'Dane Pharrell',
      opta_last_name: 'Scarlett',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Dane Pharrell',
      first_name_upper: 'DANE PHARRELL',
      last_name: 'Scarlett',
      last_name_upper: 'SCARLETT',
    },
    {
      _id: '6166c964e65c4ef2043f7547',
      opta_ID: '48hur5fmszaqltysykkb4js2d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-04-11',
      foot: 'right',
      height: '188',
      opta_first_name: 'Bamidele',
      opta_last_name: 'Alli',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Milton Keynes',
      type: 'player',
      weight: '76',
      first_name: 'Bamidele',
      first_name_upper: 'BAMIDELE',
      last_name: 'Alli',
      last_name_upper: 'ALLI',
    },
    {
      _id: '6166c964e65c4ef2043f7570',
      opta_ID: '4k0ni039sbjs4sqipk4v7k79x',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '1996-06-12',
      foot: 'right',
      height: '187',
      opta_first_name: 'Davinson',
      opta_last_name: 'Sánchez Mina',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'Caloto',
      type: 'player',
      weight: '81',
      first_name: 'Davinson',
      first_name_upper: 'DAVINSON',
      last_name: 'Sánchez Mina',
      last_name_upper: 'SÁNCHEZ MINA',
    },
    {
      _id: '6166c964e65c4ef2043f75b1',
      opta_ID: '4zxrdmwphkxvkvo6bt30uanrp',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '1995-08-05',
      foot: 'right',
      height: '187',
      opta_first_name: 'Pierre-Emile',
      opta_last_name: 'Kordt Højbjerg',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: 'København',
      type: 'player',
      weight: '84',
      first_name: 'Pierre-Emile',
      first_name_upper: 'PIERRE-EMILE',
      last_name: 'Kordt Højbjerg',
      last_name_upper: 'KORDT HØJBJERG',
    },
    {
      _id: '6166c964e65c4ef2043f75ee',
      opta_ID: '5gb7v3m0qisn24c7h49xn250p',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-03-31',
      foot: 'right',
      height: '184',
      opta_first_name: 'Japhet',
      opta_last_name: 'Tanganga',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Japhet',
      first_name_upper: 'JAPHET',
      last_name: 'Tanganga',
      last_name_upper: 'TANGANGA',
    },
    {
      _id: '6166c964e65c4ef2043f7670',
      opta_ID: '5qfa3h4gut5jlnfkexq5m1djp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-02-02',
      foot: 'right',
      height: '178',
      opta_first_name: 'Harry',
      opta_last_name: 'Winks',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Hemel Hempstead',
      type: 'player',
      weight: '65',
      first_name: 'Harry',
      first_name_upper: 'HARRY',
      last_name: 'Winks',
      last_name_upper: 'WINKS',
    },
    {
      _id: '6166c964e65c4ef2043f7698',
      opta_ID: '5v5n8dvhqf1995r4ayrjo76ui',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-03-06',
      foot: '',
      height: '',
      opta_first_name: 'Nile',
      opta_last_name: 'Omari Mckenzi John',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Nile',
      first_name_upper: 'NILE',
      last_name: 'Omari Mckenzi John',
      last_name_upper: 'OMARI MCKENZI JOHN',
    },
    {
      _id: '6166c964e65c4ef2043f76cf',
      opta_ID: '6ge15340tp9n57m88z6rli6i2',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-09-19',
      foot: '',
      height: '171',
      opta_first_name: 'Harvey David',
      opta_last_name: 'White',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Maidstone',
      type: 'player',
      weight: '60',
      first_name: 'Harvey David',
      first_name_upper: 'HARVEY DAVID',
      last_name: 'White',
      last_name_upper: 'WHITE',
    },
    {
      _id: '6166c965e65c4ef2043f770b',
      opta_ID: '6hrp4zuxh40i3tccmk5s0j5xx',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1997-10-08',
      foot: 'right',
      height: '178',
      opta_first_name: 'Steven Charles',
      opta_last_name: 'Bergwijn',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Amsterdam',
      type: 'player',
      weight: '78',
      first_name: 'Steven Charles',
      first_name_upper: 'STEVEN CHARLES',
      last_name: 'Bergwijn',
      last_name_upper: 'BERGWIJN',
    },
    {
      _id: '6166c965e65c4ef2043f7742',
      opta_ID: '72ktbetrzo54vpcs518wcax62',
      __v: 0,
      country_of_birth: 'Estonia',
      date_of_birth: '2003-01-19',
      foot: 'right',
      height: '',
      opta_first_name: 'Maksim',
      opta_last_name: 'Paskotši',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d738',
      place_of_birth: 'Tallinn',
      type: 'player',
      weight: '',
      first_name: 'Maksim',
      first_name_upper: 'MAKSIM',
      last_name: 'Paskotši',
      last_name_upper: 'PASKOTŠI',
    },
    {
      _id: '6166c965e65c4ef2043f775f',
      opta_ID: '7jpzrq4bpes3uc1pkh56sc0oa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-04-24',
      foot: '',
      height: '',
      opta_first_name: 'Romaine Lee',
      opta_last_name: 'Mundle',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Romaine Lee',
      first_name_upper: 'ROMAINE LEE',
      last_name: 'Mundle',
      last_name_upper: 'MUNDLE',
    },
    {
      _id: '6166c965e65c4ef2043f778f',
      opta_ID: '7qy1hiitksyuaa9l0irxg240l',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1993-04-24',
      foot: 'left',
      height: '181',
      opta_first_name: 'Benjamin Thomas',
      opta_last_name: 'Davies',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Neath',
      type: 'player',
      weight: '76',
      first_name: 'Benjamin Thomas',
      first_name_upper: 'BENJAMIN THOMAS',
      last_name: 'Davies',
      last_name_upper: 'DAVIES',
    },
    {
      _id: '6166c965e65c4ef2043f77b0',
      opta_ID: '8c1r97aweyfc0woefvlfy2l05',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1992-08-13',
      foot: 'right',
      height: '172',
      opta_first_name: 'Lucas',
      opta_last_name: 'Rodrigues Moura da Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'São Paulo',
      type: 'player',
      weight: '72',
      first_name: 'Lucas',
      first_name_upper: 'LUCAS',
      last_name: 'Rodrigues Moura da Silva',
      last_name_upper: 'RODRIGUES MOURA DA SILVA',
    },
    {
      _id: '6166c965e65c4ef2043f77fe',
      opta_ID: '8pb6iiphpyxdlpbs4dpunr8wl',
      __v: 0,
      country_of_birth: "Côte d'Ivoire",
      date_of_birth: '1992-12-24',
      foot: 'right',
      height: '176',
      opta_first_name: 'Serge Alain Stéphane',
      opta_last_name: 'Aurier',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Ouragahio',
      type: 'player',
      weight: '76',
      first_name: 'Serge Alain Stéphane',
      first_name_upper: 'SERGE ALAIN STÉPHANE',
      last_name: 'Aurier',
      last_name_upper: 'AURIER',
    },
    {
      _id: '6166c965e65c4ef2043f7816',
      opta_ID: '8y8d2dd469x9q0lc7qfyxypyh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-01-08',
      foot: '',
      height: '188',
      opta_first_name: 'Brandon Anthony',
      opta_last_name: 'Austin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '82',
      first_name: 'Brandon Anthony',
      first_name_upper: 'BRANDON ANTHONY',
      last_name: 'Austin',
      last_name_upper: 'AUSTIN',
    },
    {
      _id: '6166c965e65c4ef2043f783a',
      opta_ID: '9bamtyvun46xn9e64lqn9ogyx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-16',
      foot: 'right',
      height: '175',
      opta_first_name: 'Oliver',
      opta_last_name: 'Skipp',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '70',
      first_name: 'Oliver',
      first_name_upper: 'OLIVER',
      last_name: 'Skipp',
      last_name_upper: 'SKIPP',
    },
    {
      _id: '6166c965e65c4ef2043f7851',
      opta_ID: 'anxby26pfxpu4u5bfy9nbuvah',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1999-01-14',
      foot: 'right',
      height: '181',
      opta_first_name: 'Emerson Aparecido',
      opta_last_name: 'Leite de Souza Junior',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'São Paulo',
      type: 'player',
      weight: '79',
      first_name: 'Emerson Aparecido',
      first_name_upper: 'EMERSON APARECIDO',
      last_name: 'Leite de Souza Junior',
      last_name_upper: 'LEITE DE SOUZA JUNIOR',
    },
    {
      _id: '6166c966e65c4ef2043f786e',
      opta_ID: 'ask6ix1zc4xgj2dvczaofelbe',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-03-11',
      foot: 'right',
      height: '',
      opta_first_name: 'Malachi',
      opta_last_name: 'Fagan-Walcott',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Malachi',
      first_name_upper: 'MALACHI',
      last_name: 'Fagan-Walcott',
      last_name_upper: 'FAGAN-WALCOTT',
    },
    {
      _id: '6166c966e65c4ef2043f788b',
      opta_ID: 'az7wa6km1zhvsb8y4mndlogyd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1994-01-15',
      foot: 'right',
      height: '188',
      opta_first_name: 'Eric',
      opta_last_name: 'Dier',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Cheltenham',
      type: 'player',
      weight: '88',
      first_name: 'Eric',
      first_name_upper: 'ERIC',
      last_name: 'Dier',
      last_name_upper: 'DIER',
    },
    {
      _id: '6166c966e65c4ef2043f78ae',
      opta_ID: 'b6gclblu9lz3sstpla966ik7p',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1996-12-16',
      foot: 'left',
      height: '178',
      opta_first_name: 'Sergio',
      opta_last_name: 'Reguilón Rodríguez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Madrid',
      type: 'player',
      weight: '68',
      first_name: 'Sergio',
      first_name_upper: 'SERGIO',
      last_name: 'Reguilón Rodríguez',
      last_name_upper: 'REGUILÓN RODRÍGUEZ',
    },
    {
      _id: '6166c966e65c4ef2043f78e6',
      opta_ID: 'b9g4qurpll4wizajo95c406hh',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-07-28',
      foot: 'right',
      height: '188',
      opta_first_name: 'Harry',
      opta_last_name: 'Kane',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '86',
      first_name: 'Harry',
      first_name_upper: 'HARRY',
      last_name: 'Kane',
      last_name_upper: 'KANE',
    },
    {
      _id: '6166c966e65c4ef2043f7912',
      opta_ID: 'bcqutyzafpahp2ni41u2xt4gl',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1996-12-28',
      foot: 'right',
      height: '181',
      opta_first_name: 'Tanguy',
      opta_last_name: 'Ndombèlé Alvaro',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Longjumeau',
      type: 'player',
      weight: '76',
      first_name: 'Tanguy',
      first_name_upper: 'TANGUY',
      last_name: 'Ndombèlé Alvaro',
      last_name_upper: 'NDOMBÈLÉ ALVARO',
    },
    {
      _id: '6166c966e65c4ef2043f792b',
      opta_ID: 'bgsyapaoqduvrt11bz8sjn949',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1998-10-02',
      foot: 'right',
      height: '189',
      opta_first_name: 'Alfie',
      opta_last_name: 'Malik Whiteman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '84',
      first_name: 'Alfie',
      first_name_upper: 'ALFIE',
      last_name: 'Malik Whiteman',
      last_name_upper: 'MALIK WHITEMAN',
    },
    {
      _id: '6166c966e65c4ef2043f7959',
      opta_ID: 'btf0dch35aww78bzillh2jgx6',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '2002-09-14',
      foot: '',
      height: '',
      opta_first_name: 'Pape Matar',
      opta_last_name: 'Sarr',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Pape Matar',
      first_name_upper: 'PAPE MATAR',
      last_name: 'Sarr',
      last_name_upper: 'SARR',
    },
    {
      _id: '6166c966e65c4ef2043f796f',
      opta_ID: 'bu8y4cyvqj7dzru1qip5tbbze',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-04-16',
      foot: '',
      height: '',
      opta_first_name: 'Matthew George',
      opta_last_name: 'Craig',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Matthew George',
      first_name_upper: 'MATTHEW GEORGE',
      last_name: 'Craig',
      last_name_upper: 'CRAIG',
    },
    {
      _id: '6166c966e65c4ef2043f7988',
      opta_ID: 'bv7p14kf6v3xlxyjquswgukbu',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-04-16',
      foot: '',
      height: '',
      opta_first_name: 'Michael',
      opta_last_name: 'Craig',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Michael',
      first_name_upper: 'MICHAEL',
      last_name: 'Craig',
      last_name_upper: 'CRAIG',
    },
    {
      _id: '6166c967e65c4ef2043f79c5',
      opta_ID: 'c4dvhy1z0op9iwj9aqdgzh0q1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-12-31',
      foot: 'right',
      height: '183',
      opta_first_name: 'Cameron',
      opta_last_name: 'Carter-Vickers',
      opta_last_updated: '2021-11-05T07:24:46Z',
      opta_nationality: '6166c13ae65c4ef20434d605',
      place_of_birth: 'Southend',
      type: 'player',
      weight: '92',
      first_name: 'Cameron',
      first_name_upper: 'CAMERON',
      last_name: 'Carter-Vickers',
      last_name_upper: 'CARTER-VICKERS',
    },
    {
      _id: '6166c967e65c4ef2043f79de',
      opta_ID: 'cag3gzjcv8erx3zpp2lidzcvd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-05-18',
      foot: 'left',
      height: '178',
      opta_first_name: 'Kouassi Ryan',
      opta_last_name: 'Sessegnon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '70',
      first_name: 'Kouassi Ryan',
      first_name_upper: 'KOUASSI RYAN',
      last_name: 'Sessegnon',
      last_name_upper: 'SESSEGNON',
    },
    {
      _id: '6166c967e65c4ef2043f7a0d',
      opta_ID: 'cjd62gwj9ptwrrtu6y6xnpeui',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '2001-02-11',
      foot: 'left',
      height: '175',
      opta_first_name: 'Bryan',
      opta_last_name: 'Gil Salvatierra',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Barbate',
      type: 'player',
      weight: '60',
      first_name: 'Bryan',
      first_name_upper: 'BRYAN',
      last_name: 'Gil Salvatierra',
      last_name_upper: 'GIL SALVATIERRA',
    },
    {
      _id: '6166c968e65c4ef2043f7af5',
      opta_ID: 'dhtb9gizk25k1u3h0b7xgy4tl',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1996-04-09',
      foot: 'left',
      height: '177',
      opta_first_name: 'Giovani',
      opta_last_name: 'Lo Celso',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Rosario',
      type: 'player',
      weight: '68',
      first_name: 'Giovani',
      first_name_upper: 'GIOVANI',
      last_name: 'Lo Celso',
      last_name_upper: 'LO CELSO',
    },
    {
      _id: '6166c968e65c4ef2043f7b1a',
      opta_ID: 'e0p7anuqsx00gybhm4wnkoesp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-12-01',
      foot: '',
      height: '',
      opta_first_name: 'Brooklyn',
      opta_last_name: 'Lyons-Foster',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Brooklyn',
      first_name_upper: 'BROOKLYN',
      last_name: 'Lyons-Foster',
      last_name_upper: 'LYONS-FOSTER',
    },
    {
      _id: '6166c968e65c4ef2043f7b43',
      opta_ID: 'eby6dz8mhy1e1hzprw2xpvq0a',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-09-04',
      foot: '',
      height: '',
      opta_first_name: 'Kallum Xzeiba Tracey',
      opta_last_name: 'Cesay',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Kallum Xzeiba Tracey',
      first_name_upper: 'KALLUM XZEIBA TRACEY',
      last_name: 'Cesay',
      last_name_upper: 'CESAY',
    },
    {
      _id: '6166c968e65c4ef2043f7b5c',
      opta_ID: 'etvw30dss13l5ccow9w49pvit',
      __v: 0,
      country_of_birth: 'São Tomé e Príncipe',
      date_of_birth: '1974-01-25',
      foot: '',
      height: '',
      opta_first_name: 'Nuno Herlander',
      opta_last_name: 'Simões Espírito Santo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'São Tomé',
      type: 'coach',
      weight: '',
      first_name: 'Nuno Herlander',
      first_name_upper: 'NUNO HERLANDER',
      last_name: 'Simões Espírito Santo',
      last_name_upper: 'SIMÕES ESPÍRITO SANTO',
    },
    {
      _id: '6166c968e65c4ef2043f7b78',
      opta_ID: 'eybpkhnd5ldeiau8niu1gg57u',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-11-23',
      foot: '',
      height: '',
      opta_first_name: 'Jack',
      opta_last_name: 'Clarke',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'York',
      type: 'player',
      weight: '',
      first_name: 'Jack',
      first_name_upper: 'JACK',
      last_name: 'Clarke',
      last_name_upper: 'CLARKE',
    },
    {
      _id: '6166c968e65c4ef2043f7b9b',
      opta_ID: 'oc04nz0mrmzeqpcbql3rbhlh',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1989-08-16',
      foot: 'right',
      height: '187',
      opta_first_name: 'Moussa',
      opta_last_name: 'Sissoko',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Le Blanc-Mesnil',
      type: 'player',
      weight: '83',
      first_name: 'Moussa',
      first_name_upper: 'MOUSSA',
      last_name: 'Sissoko',
      last_name_upper: 'SISSOKO',
    },
    {
      _id: '6166c968e65c4ef2043f7bbb',
      opta_ID: 'ombvyo6p3p5khkn91vubvpat',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1986-12-26',
      foot: 'left',
      height: '188',
      opta_first_name: 'Hugo',
      opta_last_name: 'Lloris',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Nice',
      type: 'player',
      weight: '82',
      first_name: 'Hugo',
      first_name_upper: 'HUGO',
      last_name: 'Lloris',
      last_name_upper: 'LLORIS',
    },
    {
      _id: '6166c969e65c4ef2043f7bef',
      opta_ID: 'otan631zzfnq2sektkl3ywo9',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '1997-10-22',
      foot: 'right',
      height: '180',
      opta_first_name: 'Joe',
      opta_last_name: 'Rodon',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: 'Swansea',
      type: 'player',
      weight: '70',
      first_name: 'Joe',
      first_name_upper: 'JOE',
      last_name: 'Rodon',
      last_name_upper: 'RODON',
    },
    {
      _id: '6166c969e65c4ef2043f7c10',
      opta_ID: 'u60zu48a4zqnupyxp2d0mqqd',
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '1995-03-18',
      foot: 'right',
      height: '194',
      opta_first_name: 'Pierluigi',
      opta_last_name: 'Gollini',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Bologna',
      type: 'player',
      weight: '94',
      first_name: 'Pierluigi',
      first_name_upper: 'PIERLUIGI',
      last_name: 'Gollini',
      last_name_upper: 'GOLLINI',
    },
    {
      _id: '6166c96ee65c4ef2043f909b',
      opta_ID: '28cmxk2w0q2ym72gvxq7ak0d1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1986-04-30',
      foot: 'right',
      height: '190',
      opta_first_name: 'Rob',
      opta_last_name: 'Elliot',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Chatham',
      type: 'player',
      weight: '94',
      first_name: 'Rob',
      first_name_upper: 'ROB',
      last_name: 'Elliot',
      last_name_upper: 'ELLIOT',
    },
    {
      _id: '6166c96ee65c4ef2043f90e9',
      opta_ID: '2kte5o0a3o1wx9fepazzstqs5',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1995-11-09',
      foot: 'right',
      height: '172',
      opta_first_name: 'Oghenekaro Peter',
      opta_last_name: 'Etebo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Lagos',
      type: 'player',
      weight: '70',
      first_name: 'Oghenekaro Peter',
      first_name_upper: 'OGHENEKARO PETER',
      last_name: 'Etebo',
      last_name_upper: 'ETEBO',
    },
    {
      _id: '6166c96ee65c4ef2043f9145',
      opta_ID: '2tjqsh6m3neu2afie2u7yj6c5',
      __v: 0,
      country_of_birth: 'Austria',
      date_of_birth: '1994-07-09',
      foot: 'right',
      height: '191',
      opta_first_name: 'Daniel',
      opta_last_name: 'Bachmann',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d707',
      place_of_birth: 'Wien',
      type: 'player',
      weight: '81',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Bachmann',
      last_name_upper: 'BACHMANN',
    },
    {
      _id: '6166c96ee65c4ef2043f91dc',
      opta_ID: '36oeunwev16uv98qh0id4qaqx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-09-07',
      foot: '',
      height: '',
      opta_first_name: 'Jeremy',
      opta_last_name: 'Ngakia',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Jeremy',
      first_name_upper: 'JEREMY',
      last_name: 'Ngakia',
      last_name_upper: 'NGAKIA',
    },
    {
      _id: '6166c96fe65c4ef2043f925f',
      opta_ID: '3cxuoubom7dryuebyn850xvmy',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-01-15',
      foot: '',
      height: '',
      opta_first_name: 'Joseph',
      opta_last_name: 'Hungbo',
      opta_last_updated: '2021-11-05T07:24:46Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Joseph',
      first_name_upper: 'JOSEPH',
      last_name: 'Hungbo',
      last_name_upper: 'HUNGBO',
    },
    {
      _id: '6166c96fe65c4ef2043f92ff',
      opta_ID: '3fzhzvsdd41o4b1lq4ozydgwl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1983-04-03',
      foot: 'right',
      height: '193',
      opta_first_name: 'Ben',
      opta_last_name: 'Foster',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leamington Spa',
      type: 'player',
      weight: '79',
      first_name: 'Ben',
      first_name_upper: 'BEN',
      last_name: 'Foster',
      last_name_upper: 'FOSTER',
    },
    {
      _id: '6166c96fe65c4ef2043f9398',
      opta_ID: '3ztcz2dgfniqv141w7xsky7zd',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1997-11-15',
      foot: 'right',
      height: '174',
      opta_first_name: 'Emmanuel Bonaventure',
      opta_last_name: 'Dennis',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Abuja',
      type: 'player',
      weight: '67',
      first_name: 'Emmanuel Bonaventure',
      first_name_upper: 'EMMANUEL BONAVENTURE',
      last_name: 'Dennis',
      last_name_upper: 'DENNIS',
    },
    {
      _id: '6166c96fe65c4ef2043f940f',
      opta_ID: '44s112hd02w1i2ucvai9jy7e1',
      __v: 0,
      country_of_birth: 'Senegal',
      date_of_birth: '1998-02-25',
      foot: 'right',
      height: '185',
      opta_first_name: 'Ismaïla',
      opta_last_name: 'Sarr',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d4cc',
      place_of_birth: 'Saint-Louis',
      type: 'player',
      weight: '76',
      first_name: 'Ismaïla',
      first_name_upper: 'ISMAÏLA',
      last_name: 'Sarr',
      last_name_upper: 'SARR',
    },
    {
      _id: '6166c96fe65c4ef2043f9491',
      opta_ID: '5152de1kfmrihdzbdotpeo8ut',
      __v: 0,
      country_of_birth: 'Nigeria',
      date_of_birth: '1996-01-07',
      foot: 'right',
      height: '186',
      opta_first_name: 'Isaac',
      opta_last_name: 'Ajayi Success',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Benin City',
      type: 'player',
      weight: '86',
      first_name: 'Isaac',
      first_name_upper: 'ISAAC',
      last_name: 'Ajayi Success',
      last_name_upper: 'AJAYI SUCCESS',
    },
    {
      _id: '6166c96fe65c4ef2043f95db',
      opta_ID: '5ljs2bj3wwiurcyxiwka2i8d1',
      __v: 0,
      country_of_birth: 'Congo DR',
      date_of_birth: '1991-02-24',
      foot: 'right',
      height: '187',
      opta_first_name: 'Christian',
      opta_last_name: 'Kabasele',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Lubumbashi',
      type: 'player',
      weight: '84',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Kabasele',
      last_name_upper: 'KABASELE',
    },
    {
      _id: '6166c970e65c4ef2043f96e4',
      opta_ID: '6a0e28nxlyyw7ghe4wzumlzh1',
      __v: 0,
      country_of_birth: 'Croatia',
      date_of_birth: '1995-07-07',
      foot: 'right',
      height: '192',
      opta_first_name: 'Stipe',
      opta_last_name: 'Perica',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d728',
      place_of_birth: 'Zadar',
      type: 'player',
      weight: '76',
      first_name: 'Stipe',
      first_name_upper: 'STIPE',
      last_name: 'Perica',
      last_name_upper: 'PERICA',
    },
    {
      _id: '6166c970e65c4ef2043f9743',
      opta_ID: '6gzi8qf8szxn99dnwh3h6gkb9',
      __v: 0,
      country_of_birth: 'Morocco',
      date_of_birth: '1994-01-02',
      foot: 'left',
      height: '189',
      opta_first_name: 'Adam',
      opta_last_name: 'Masina',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d48b',
      place_of_birth: 'Khouribga',
      type: 'player',
      weight: '78',
      first_name: 'Adam',
      first_name_upper: 'ADAM',
      last_name: 'Masina',
      last_name_upper: 'MASINA',
    },
    {
      _id: '6166c970e65c4ef2043f9798',
      opta_ID: '6h9nv8wfblox17wkudac1knh1',
      __v: 0,
      country_of_birth: 'Turkey',
      date_of_birth: '1995-03-23',
      foot: 'right',
      height: '179',
      opta_first_name: 'Ozan',
      opta_last_name: 'Tufan',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d93e',
      place_of_birth: 'Bursa',
      type: 'player',
      weight: '70',
      first_name: 'Ozan',
      first_name_upper: 'OZAN',
      last_name: 'Tufan',
      last_name_upper: 'TUFAN',
    },
    {
      _id: '6166c970e65c4ef2043f9807',
      opta_ID: '6l046k4t48abc079efzg2oic5',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '1993-09-01',
      foot: 'right',
      height: '191',
      opta_first_name: 'William',
      opta_last_name: 'Troost-Ekong',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d493',
      place_of_birth: 'Haarlem',
      type: 'player',
      weight: '82',
      first_name: 'William',
      first_name_upper: 'WILLIAM',
      last_name: 'Troost-Ekong',
      last_name_upper: 'TROOST-EKONG',
    },
    {
      _id: '6166c970e65c4ef2043f9885',
      opta_ID: '6sv29rmngsiyx4pv6zto3mklx',
      __v: 0,
      country_of_birth: 'Sweden',
      date_of_birth: '1993-09-30',
      foot: 'both',
      height: '180',
      opta_first_name: 'Ken Nlata',
      opta_last_name: 'Sema',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d985',
      place_of_birth: 'Norrköping',
      type: 'player',
      weight: '69',
      first_name: 'Ken Nlata',
      first_name_upper: 'KEN NLATA',
      last_name: 'Sema',
      last_name_upper: 'SEMA',
    },
    {
      _id: '6166c970e65c4ef2043f98ea',
      opta_ID: '6wpnjdlg2p8f19y3gjpz1su6t',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1991-02-02',
      foot: 'right',
      height: '176',
      opta_first_name: 'Francisco',
      opta_last_name: 'Femenía Far',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Alicante',
      type: 'player',
      weight: '61',
      first_name: 'Francisco',
      first_name_upper: 'FRANCISCO',
      last_name: 'Femenía Far',
      last_name_upper: 'FEMENÍA FAR',
    },
    {
      _id: '6166c971e65c4ef2043f99a6',
      opta_ID: '7ws6w5ad1qkd8wffyb74m28l',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '1989-02-06',
      foot: 'right',
      height: '188',
      opta_first_name: 'Craig George',
      opta_last_name: 'Cathcart',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: 'Belfast',
      type: 'player',
      weight: '73',
      first_name: 'Craig George',
      first_name_upper: 'CRAIG GEORGE',
      last_name: 'Cathcart',
      last_name_upper: 'CATHCART',
    },
    {
      _id: '6166c971e65c4ef2043f99f8',
      opta_ID: '80y7k4ht4s4nkta9wnf30y40l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-02-01',
      foot: 'both',
      height: '183',
      opta_first_name: 'Dan',
      opta_last_name: 'Gosling',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Brixham',
      type: 'player',
      weight: '79',
      first_name: 'Dan',
      first_name_upper: 'DAN',
      last_name: 'Gosling',
      last_name_upper: 'GOSLING',
    },
    {
      _id: '6166c971e65c4ef2043f9a62',
      opta_ID: '8aao79b1g77c5jhp1n0w60xrd',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '1999-04-20',
      foot: 'right',
      height: '176',
      opta_first_name: 'Juan Camilo',
      opta_last_name: 'Hernández Suárez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'Pereira',
      type: 'player',
      weight: '73',
      first_name: 'Juan Camilo',
      first_name_upper: 'JUAN CAMILO',
      last_name: 'Hernández Suárez',
      last_name_upper: 'HERNÁNDEZ SUÁREZ',
    },
    {
      _id: '6166c971e65c4ef2043f9abd',
      opta_ID: '8f0y825dozn5a9tm74br43d1x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-08-12',
      foot: 'both',
      height: '175',
      opta_first_name: 'Tom',
      opta_last_name: 'Cleverley',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Basingstoke',
      type: 'player',
      weight: '67',
      first_name: 'Tom',
      first_name_upper: 'TOM',
      last_name: 'Cleverley',
      last_name_upper: 'CLEVERLEY',
    },
    {
      _id: '6166c971e65c4ef2043f9b68',
      opta_ID: '8jtgnwat07bjub57femcqjmxh',
      __v: 0,
      country_of_birth: 'Norway',
      date_of_birth: '1992-01-15',
      foot: 'both',
      height: '180',
      opta_first_name: 'Joshua Christian',
      opta_last_name: 'Kojo King',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d80c',
      place_of_birth: 'Oslo',
      type: 'player',
      weight: '74',
      first_name: 'Joshua Christian',
      first_name_upper: 'JOSHUA CHRISTIAN',
      last_name: 'Kojo King',
      last_name_upper: 'KOJO KING',
    },
    {
      _id: '6166c971e65c4ef2043f9bd3',
      opta_ID: '8n3v1npbgeparornazwrwxbkl',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1995-07-02',
      foot: 'right',
      height: '188',
      opta_first_name: 'Marc',
      opta_last_name: 'Navarro Ceciliano',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Barcelona',
      type: 'player',
      weight: '79',
      first_name: 'Marc',
      first_name_upper: 'MARC',
      last_name: 'Navarro Ceciliano',
      last_name_upper: 'NAVARRO CECILIANO',
    },
    {
      _id: '6166c972e65c4ef2043f9d11',
      opta_ID: '8xphxxo2xonjv7t3g16rj0zfu',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1999-05-01',
      foot: 'left',
      height: '178',
      opta_first_name: 'Imran',
      opta_last_name: 'Louza',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d48b',
      place_of_birth: 'Nantes',
      type: 'player',
      weight: '65',
      first_name: 'Imran',
      first_name_upper: 'IMRAN',
      last_name: 'Louza',
      last_name_upper: 'LOUZA',
    },
    {
      _id: '6166c972e65c4ef2043f9e35',
      opta_ID: 'a1oi2uvchhnwi4kqw9npdp0q1',
      __v: 0,
      country_of_birth: 'Chile',
      date_of_birth: '1997-05-06',
      foot: 'right',
      height: '190',
      opta_first_name: 'Francisco Andrés',
      opta_last_name: 'Sierralta Carvallo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d667',
      place_of_birth: 'Santiago',
      type: 'player',
      weight: '83',
      first_name: 'Francisco Andrés',
      first_name_upper: 'FRANCISCO ANDRÉS',
      last_name: 'Sierralta Carvallo',
      last_name_upper: 'SIERRALTA CARVALLO',
    },
    {
      _id: '6166c972e65c4ef2043f9eab',
      opta_ID: 'agjxdcac13kve5l844xgbd4dm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-11-23',
      foot: '',
      height: '',
      opta_first_name: 'James',
      opta_last_name: 'Morris',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'James',
      first_name_upper: 'JAMES',
      last_name: 'Morris',
      last_name_upper: 'MORRIS',
    },
    {
      _id: '6166c972e65c4ef2043f9f13',
      opta_ID: 'b1w59l02v6sg991j7xuw21f4l',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1995-10-02',
      foot: 'right',
      height: '185',
      opta_first_name: 'Ashley',
      opta_last_name: 'Fletcher',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Keighley',
      type: 'player',
      weight: '78',
      first_name: 'Ashley',
      first_name_upper: 'ASHLEY',
      last_name: 'Fletcher',
      last_name_upper: 'FLETCHER',
    },
    {
      _id: '6166c973e65c4ef2043fa082',
      opta_ID: 'bxxb6qww6x0pddd08kj0ydhx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-07-02',
      foot: 'left',
      height: '173',
      opta_first_name: 'Danny',
      opta_last_name: 'Rose',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Doncaster',
      type: 'player',
      weight: '72',
      first_name: 'Danny',
      first_name_upper: 'DANNY',
      last_name: 'Rose',
      last_name_upper: 'ROSE',
    },
    {
      _id: '6166c973e65c4ef2043fa1a6',
      opta_ID: 'd73maq8id4xflzaj6jfxlduj9',
      __v: 0,
      country_of_birth: 'Cameroon',
      date_of_birth: '1990-03-27',
      foot: 'right',
      height: '184',
      opta_first_name: 'Nicolas',
      opta_last_name: "N'Koulou",
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3d1',
      place_of_birth: 'Yaoundé',
      type: 'player',
      weight: '77',
      first_name: 'Nicolas',
      first_name_upper: 'NICOLAS',
      last_name: "N'Koulou",
      last_name_upper: "N'KOULOU",
    },
    {
      _id: '6166c973e65c4ef2043fa2bf',
      opta_ID: 'dhc0ecmk5ddpdadm7ziv61y39',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1980-09-05',
      foot: '',
      height: '175',
      opta_first_name: 'Francisco Javier',
      opta_last_name: 'Muñoz Llompart',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Manacor',
      type: 'coach',
      weight: '69',
      first_name: 'Francisco Javier',
      first_name_upper: 'FRANCISCO JAVIER',
      last_name: 'Muñoz Llompart',
      last_name_upper: 'MUÑOZ LLOMPART',
    },
    {
      _id: '6166c973e65c4ef2043fa355',
      opta_ID: 'dtjqvdkylgslc6r3di13cduqd',
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '1951-10-20',
      foot: '',
      height: '182',
      opta_first_name: 'Claudio',
      opta_last_name: 'Ranieri',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Roma',
      type: 'coach',
      weight: '',
      first_name: 'Claudio',
      first_name_upper: 'CLAUDIO',
      last_name: 'Ranieri',
      last_name_upper: 'RANIERI',
    },
    {
      _id: '6166c973e65c4ef2043fa382',
      opta_ID: 'e0zee8etv9zm340jiq3yu4jmy',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '1999-07-26',
      foot: '',
      height: '',
      opta_first_name: 'Jaime Alberto',
      opta_last_name: 'Alvarado Hoyos',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: 'Santa Marta',
      type: 'player',
      weight: '',
      first_name: 'Jaime Alberto',
      first_name_upper: 'JAIME ALBERTO',
      last_name: 'Alvarado Hoyos',
      last_name_upper: 'ALVARADO HOYOS',
    },
    {
      _id: '6166c973e65c4ef2043fa3cd',
      opta_ID: 'eaqqezw2ed1u0tt3zc128mr1m',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '2001-09-26',
      foot: 'right',
      height: '182',
      opta_first_name: 'João Pedro',
      opta_last_name: 'Junqueira de Jesus',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'Ribeirão Preto',
      type: 'player',
      weight: '70',
      first_name: 'João Pedro',
      first_name_upper: 'JOÃO PEDRO',
      last_name: 'Junqueira de Jesus',
      last_name_upper: 'JUNQUEIRA DE JESUS',
    },
    {
      _id: '6166c973e65c4ef2043fa490',
      opta_ID: 'earki4hdejtjz2fmlmt2z60ut',
      __v: 0,
      country_of_birth: 'Slovakia',
      date_of_birth: '1987-02-26',
      foot: 'right',
      height: '186',
      opta_first_name: 'Juraj',
      opta_last_name: 'Kucka',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13ce65c4ef20434d8f4',
      place_of_birth: 'Bojnice',
      type: 'player',
      weight: '86',
      first_name: 'Juraj',
      first_name_upper: 'JURAJ',
      last_name: 'Kucka',
      last_name_upper: 'KUCKA',
    },
    {
      _id: '6166c974e65c4ef2043fa546',
      opta_ID: 'er6ljxc8ajcqgohnpvxipg5t6',
      __v: 0,
      country_of_birth: 'Germany',
      date_of_birth: '2003-01-27',
      foot: 'right',
      height: '183',
      opta_first_name: 'Kwadwo',
      opta_last_name: 'Baah',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Stuttgart',
      type: 'player',
      weight: '75',
      first_name: 'Kwadwo',
      first_name_upper: 'KWADWO',
      last_name: 'Baah',
      last_name_upper: 'BAAH',
    },
    {
      _id: '6166c977e65c4ef2043fb77d',
      opta_ID: '28f271b9dr7hknp0s82mlu5lh',
      __v: 0,
      country_of_birth: 'Republic of Ireland',
      date_of_birth: '1987-05-12',
      foot: 'right',
      height: '188',
      opta_first_name: 'Darren',
      opta_last_name: 'Randolph',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d768',
      place_of_birth: 'Bray',
      type: 'player',
      weight: '98',
      first_name: 'Darren',
      first_name_upper: 'DARREN',
      last_name: 'Randolph',
      last_name_upper: 'RANDOLPH',
    },
    {
      _id: '6166c978e65c4ef2043fb845',
      opta_ID: '2unorlgh6lxy19g0pgydg65ed',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-05-06',
      foot: 'right',
      height: '188',
      opta_first_name: 'Craig',
      opta_last_name: 'Dawson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Rochdale',
      type: 'player',
      weight: '82',
      first_name: 'Craig',
      first_name_upper: 'CRAIG',
      last_name: 'Dawson',
      last_name_upper: 'DAWSON',
    },
    {
      _id: '6166c978e65c4ef2043fb901',
      opta_ID: '3c7vnmec038bhz5rq36ywi2fp',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1990-03-28',
      foot: 'right',
      height: '180',
      opta_first_name: 'Michail',
      opta_last_name: 'Antonio',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d594',
      place_of_birth: 'London',
      type: 'player',
      weight: '82',
      first_name: 'Michail',
      first_name_upper: 'MICHAIL',
      last_name: 'Antonio',
      last_name_upper: 'ANTONIO',
    },
    {
      _id: '6166c978e65c4ef2043fb9b3',
      opta_ID: '4nnedggnklr76eiqoc9qb6ok5',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1986-01-22',
      foot: 'right',
      height: '186',
      opta_first_name: 'David Edward',
      opta_last_name: 'Martin',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Romford',
      type: 'player',
      weight: '86',
      first_name: 'David Edward',
      first_name_upper: 'DAVID EDWARD',
      last_name: 'Martin',
      last_name_upper: 'MARTIN',
    },
    {
      _id: '6166c978e65c4ef2043fba5f',
      opta_ID: '4ryipy52oscxkv71ws0os5w5',
      __v: 0,
      country_of_birth: 'Czech Republic',
      date_of_birth: '1992-08-22',
      foot: 'right',
      height: '179',
      opta_first_name: 'Vladimír',
      opta_last_name: 'Coufal',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: 'Ostrava',
      type: 'player',
      weight: '76',
      first_name: 'Vladimír',
      first_name_upper: 'VLADIMÍR',
      last_name: 'Coufal',
      last_name_upper: 'COUFAL',
    },
    {
      _id: '6166c978e65c4ef2043fbb05',
      opta_ID: '55bgi895g03zhc7vdh7e7yvv9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-11-07',
      foot: 'left',
      height: '179',
      opta_first_name: 'Arthur',
      opta_last_name: 'Masuaku',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d3bc',
      place_of_birth: 'Lille',
      type: 'player',
      weight: '70',
      first_name: 'Arthur',
      first_name_upper: 'ARTHUR',
      last_name: 'Masuaku',
      last_name_upper: 'MASUAKU',
    },
    {
      _id: '6166c978e65c4ef2043fbb33',
      opta_ID: '5aalbis454fhya4jj7s4jonze',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-11-11',
      foot: '',
      height: '',
      opta_first_name: 'Jamal',
      opta_last_name: 'Baptiste',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Jamal',
      first_name_upper: 'JAMAL',
      last_name: 'Baptiste',
      last_name_upper: 'BAPTISTE',
    },
    {
      _id: '6166c978e65c4ef2043fbb56',
      opta_ID: '6aix9yu384pv1caanqiol231l',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1996-02-22',
      foot: 'right',
      height: '178',
      opta_first_name: 'Pablo',
      opta_last_name: 'Fornals Malla',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Castellón de la Plana',
      type: 'player',
      weight: '67',
      first_name: 'Pablo',
      first_name_upper: 'PABLO',
      last_name: 'Fornals Malla',
      last_name_upper: 'FORNALS MALLA',
    },
    {
      _id: '6166c978e65c4ef2043fbba3',
      opta_ID: '6fz5d1z4587tqdldr261cp1p1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1987-05-08',
      foot: 'right',
      height: '180',
      opta_first_name: 'Mark',
      opta_last_name: 'Noble',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Canning Town',
      type: 'player',
      weight: '70',
      first_name: 'Mark',
      first_name_upper: 'MARK',
      last_name: 'Noble',
      last_name_upper: 'NOBLE',
    },
    {
      _id: '6166c978e65c4ef2043fbbdf',
      opta_ID: '6gq38x5xbyz0moa1sk0aw082x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2000-01-24',
      foot: '',
      height: '175',
      opta_first_name: 'Benjamin',
      opta_last_name: 'Johnson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '67',
      first_name: 'Benjamin',
      first_name_upper: 'BENJAMIN',
      last_name: 'Johnson',
      last_name_upper: 'JOHNSON',
    },
    {
      _id: '6166c978e65c4ef2043fbc7b',
      opta_ID: '6jgocc12bori2wygyu07c082x',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1999-01-14',
      foot: '',
      height: '185',
      opta_first_name: 'Declan',
      opta_last_name: 'Rice',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '76',
      first_name: 'Declan',
      first_name_upper: 'DECLAN',
      last_name: 'Rice',
      last_name_upper: 'RICE',
    },
    {
      _id: '6166c979e65c4ef2043fbd2d',
      opta_ID: '6wwagkprtmcadu8p6klcu2uhh',
      __v: 0,
      country_of_birth: 'Czech Republic',
      date_of_birth: '1995-02-27',
      foot: 'right',
      height: '192',
      opta_first_name: 'Tomáš',
      opta_last_name: 'Souček',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: 'Havlíčkův Brod',
      type: 'player',
      weight: '86',
      first_name: 'Tomáš',
      first_name_upper: 'TOMÁŠ',
      last_name: 'Souček',
      last_name_upper: 'SOUČEK',
    },
    {
      _id: '6166c979e65c4ef2043fbde6',
      opta_ID: '8x50e0dyse2qecpv7gtku66l1',
      __v: 0,
      country_of_birth: 'Russia',
      date_of_birth: '1989-10-23',
      foot: 'left',
      height: '189',
      opta_first_name: 'Andrii',
      opta_last_name: 'Yarmolenko',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d91e',
      place_of_birth: 'Sankt-Peterburg',
      type: 'player',
      weight: '81',
      first_name: 'Andrii',
      first_name_upper: 'ANDRII',
      last_name: 'Yarmolenko',
      last_name_upper: 'YARMOLENKO',
    },
    {
      _id: '6166c979e65c4ef2043fbe90',
      opta_ID: '9eaevg9okpy4gcm6yofg7vbv9',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1993-02-27',
      foot: 'right',
      height: '195',
      opta_first_name: 'Alphonse',
      opta_last_name: 'Areola',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Paris',
      type: 'player',
      weight: '94',
      first_name: 'Alphonse',
      first_name_upper: 'ALPHONSE',
      last_name: 'Areola',
      last_name_upper: 'AREOLA',
    },
    {
      _id: '6166c979e65c4ef2043fbfd9',
      opta_ID: '9taxidttdt94nmvc3mqhqq0ut',
      __v: 0,
      country_of_birth: 'Slovakia',
      date_of_birth: '1998-05-19',
      foot: 'right',
      height: '185',
      opta_first_name: 'Alex',
      opta_last_name: 'Král',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d717',
      place_of_birth: 'Košice',
      type: 'player',
      weight: '72',
      first_name: 'Alex',
      first_name_upper: 'ALEX',
      last_name: 'Král',
      last_name_upper: 'KRÁL',
    },
    {
      _id: '6166c979e65c4ef2043fbfff',
      opta_ID: '9w5o8b18ffu4qr4bdnfrk4ogl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1989-12-15',
      foot: 'left',
      height: '170',
      opta_first_name: 'Aaron',
      opta_last_name: 'Cresswell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Liverpool',
      type: 'player',
      weight: '66',
      first_name: 'Aaron',
      first_name_upper: 'AARON',
      last_name: 'Cresswell',
      last_name_upper: 'CRESSWELL',
    },
    {
      _id: '6166c979e65c4ef2043fc02b',
      opta_ID: 'b739zmaac729cxapqvb7jwc7p',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1963-04-25',
      foot: '',
      height: '',
      opta_first_name: 'David',
      opta_last_name: 'Moyes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'coach',
      weight: '',
      first_name: 'David',
      first_name_upper: 'DAVID',
      last_name: 'Moyes',
      last_name_upper: 'MOYES',
    },
    {
      _id: '6166c979e65c4ef2043fc057',
      opta_ID: 'b9r05m1ycqz9flphsbnf56i5m',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '2001-11-14',
      foot: 'right',
      height: '',
      opta_first_name: 'Harrison',
      opta_last_name: 'Ashby',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Harrison',
      first_name_upper: 'HARRISON',
      last_name: 'Ashby',
      last_name_upper: 'ASHBY',
    },
    {
      _id: '6166c979e65c4ef2043fc071',
      opta_ID: 'bmk0efhzrno1sgr3g8yijoa8l',
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '1988-05-23',
      foot: 'left',
      height: '191',
      opta_first_name: 'Angelo',
      opta_last_name: 'Obinze Ogbonna',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Cassino',
      type: 'player',
      weight: '86',
      first_name: 'Angelo',
      first_name_upper: 'ANGELO',
      last_name: 'Obinze Ogbonna',
      last_name_upper: 'OBINZE OGBONNA',
    },
    {
      _id: '6166c979e65c4ef2043fc12c',
      opta_ID: 'c57d7soazhth4mn2e1ops8ve1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-01-17',
      foot: 'right',
      height: '',
      opta_first_name: 'Aji',
      opta_last_name: 'Alese',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Aji',
      first_name_upper: 'AJI',
      last_name: 'Alese',
      last_name_upper: 'ALESE',
    },
    {
      _id: '6166c97ae65c4ef2043fc267',
      opta_ID: 'chn2v9j6dqmuz2haoiuulbd91',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1997-01-09',
      foot: 'right',
      height: '194',
      opta_first_name: 'Issa',
      opta_last_name: 'Diop',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Toulouse',
      type: 'player',
      weight: '92',
      first_name: 'Issa',
      first_name_upper: 'ISSA',
      last_name: 'Diop',
      last_name_upper: 'DIOP',
    },
    {
      _id: '6166c97ae65c4ef2043fc31e',
      opta_ID: 'd542ijpf3j9hdrtt1mfe6vwlx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1996-12-20',
      foot: '',
      height: '',
      opta_first_name: 'Jarrod',
      opta_last_name: 'Bowen',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leominster',
      type: 'player',
      weight: '',
      first_name: 'Jarrod',
      first_name_upper: 'JARROD',
      last_name: 'Bowen',
      last_name_upper: 'BOWEN',
    },
    {
      _id: '6166c97ae65c4ef2043fc47f',
      opta_ID: 'eagrmpe9ft8daly57l1ja8lhx',
      __v: 0,
      country_of_birth: 'Poland',
      date_of_birth: '1985-04-18',
      foot: 'right',
      height: '190',
      opta_first_name: 'Łukasz',
      opta_last_name: 'Fabiański',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d810',
      place_of_birth: 'Kostrzyn nad Odrą',
      type: 'player',
      weight: '83',
      first_name: 'Łukasz',
      first_name_upper: 'ŁUKASZ',
      last_name: 'Fabiański',
      last_name_upper: 'FABIAŃSKI',
    },
    {
      _id: '6166c97ae65c4ef2043fc498',
      opta_ID: 'ebgkv548xih19m8uza7bgrvmd',
      __v: 0,
      country_of_birth: 'Croatia',
      date_of_birth: '1997-10-04',
      foot: 'right',
      height: '178',
      opta_first_name: 'Nikola',
      opta_last_name: 'Vlašić',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d728',
      place_of_birth: 'Split',
      type: 'player',
      weight: '72',
      first_name: 'Nikola',
      first_name_upper: 'NIKOLA',
      last_name: 'Vlašić',
      last_name_upper: 'VLAŠIĆ',
    },
    {
      _id: '6166c97ae65c4ef2043fc4cc',
      opta_ID: 'ebj88k2kxp7r1x6lmrqacuaj9',
      __v: 0,
      country_of_birth: 'Argentina',
      date_of_birth: '1993-02-15',
      foot: 'right',
      height: '167',
      opta_first_name: 'Manuel',
      opta_last_name: 'Lanzini',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d63e',
      place_of_birth: 'Ituzaingó',
      type: 'player',
      weight: '59',
      first_name: 'Manuel',
      first_name_upper: 'MANUEL',
      last_name: 'Lanzini',
      last_name_upper: 'LANZINI',
    },
    {
      _id: '6166c97ae65c4ef2043fc506',
      opta_ID: 'eiadg033l40maayvtee9jgkwl',
      __v: 0,
      country_of_birth: 'New Zealand',
      date_of_birth: '1988-07-03',
      foot: 'right',
      height: '190',
      opta_first_name: 'Winston Wiremu',
      opta_last_name: 'Reid',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d69f',
      place_of_birth: 'Auckland',
      type: 'player',
      weight: '87',
      first_name: 'Winston Wiremu',
      first_name_upper: 'WINSTON WIREMU',
      last_name: 'Reid',
      last_name_upper: 'REID',
    },
    {
      _id: '6166c97ae65c4ef2043fc522',
      opta_ID: 'exviw7gzg5p3vaiwaq4ujw9ud',
      __v: 0,
      country_of_birth: 'Algeria',
      date_of_birth: '1995-08-10',
      foot: 'right',
      height: '172',
      opta_first_name: 'Mohamed Saïd',
      opta_last_name: 'Benrahma',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d372',
      place_of_birth: 'Aïn Témouchent',
      type: 'player',
      weight: '67',
      first_name: 'Mohamed Saïd',
      first_name_upper: 'MOHAMED SAÏD',
      last_name: 'Benrahma',
      last_name_upper: 'BENRAHMA',
    },
    {
      _id: '6166c97ae65c4ef2043fc59b',
      opta_ID: 'f1xcno1x1t9y55wxcglm5fxlh',
      __v: 0,
      country_of_birth: 'Scotland',
      date_of_birth: '1958-07-12',
      foot: '',
      height: '',
      opta_first_name: 'Alan',
      opta_last_name: 'Irvine',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d980',
      place_of_birth: 'Glasgow',
      type: 'assistant coach',
      weight: '',
      first_name: 'Alan',
      first_name_upper: 'ALAN',
      last_name: 'Irvine',
      last_name_upper: 'IRVINE',
    },
    {
      _id: '6166c97ae65c4ef2043fc663',
      opta_ID: 'y51k2gwbs4xmkao0mnaaa76d',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1992-10-10',
      foot: 'right',
      height: '181',
      opta_first_name: 'Ryan',
      opta_last_name: 'Fredericks',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '74',
      first_name: 'Ryan',
      first_name_upper: 'RYAN',
      last_name: 'Fredericks',
      last_name_upper: 'FREDERICKS',
    },
    {
      _id: '6166c97fe65c4ef2043fd913',
      opta_ID: '1emj0rlthc8syfleox4c39kut',
      __v: 0,
      country_of_birth: 'Belgium',
      date_of_birth: '1995-04-15',
      foot: 'right',
      height: '188',
      opta_first_name: 'Leander',
      opta_last_name: 'Dendoncker',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d709',
      place_of_birth: 'Passendale',
      type: 'player',
      weight: '83',
      first_name: 'Leander',
      first_name_upper: 'LEANDER',
      last_name: 'Dendoncker',
      last_name_upper: 'DENDONCKER',
    },
    {
      _id: '6166c97fe65c4ef2043fd995',
      opta_ID: '1w47mqm235nxyn6zu1vxis5ih',
      __v: 0,
      country_of_birth: 'USA',
      date_of_birth: '2001-01-06',
      foot: 'right',
      height: '191',
      opta_first_name: 'Ebeguowen Owen',
      opta_last_name: 'Otasowie',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d605',
      place_of_birth: 'New York',
      type: 'player',
      weight: '88',
      first_name: 'Ebeguowen Owen',
      first_name_upper: 'EBEGUOWEN OWEN',
      last_name: 'Otasowie',
      last_name_upper: 'OTASOWIE',
    },
    {
      _id: '6166c97fe65c4ef2043fda0d',
      opta_ID: '2nz313c0ot91untg011l3pc6h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1997-05-23',
      foot: '',
      height: '',
      opta_first_name: 'Maximilian',
      opta_last_name: 'Kilman',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
      first_name: 'Maximilian',
      first_name_upper: 'MAXIMILIAN',
      last_name: 'Kilman',
      last_name_upper: 'KILMAN',
    },
    {
      _id: '6166c97fe65c4ef2043fda70',
      opta_ID: '3fhoof7n4sjaizvotcmypb9at',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1993-11-16',
      foot: 'right',
      height: '177',
      opta_first_name: 'Nélson',
      opta_last_name: 'Cabral Semedo',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Lisboa',
      type: 'player',
      weight: '67',
      first_name: 'Nélson',
      first_name_upper: 'NÉLSON',
      last_name: 'Cabral Semedo',
      last_name_upper: 'CABRAL SEMEDO',
    },
    {
      _id: '6166c97fe65c4ef2043fdaf1',
      opta_ID: '3uzk209u7n5oc67wwts5grkk5',
      __v: 0,
      country_of_birth: 'Brazil',
      date_of_birth: '1989-02-19',
      foot: 'left',
      height: '178',
      opta_first_name: 'Fernando',
      opta_last_name: 'Marçal de Oliveira',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d642',
      place_of_birth: 'São Paulo',
      type: 'player',
      weight: '74',
      first_name: 'Fernando',
      first_name_upper: 'FERNANDO',
      last_name: 'Marçal de Oliveira',
      last_name_upper: 'MARÇAL DE OLIVEIRA',
    },
    {
      _id: '6166c97fe65c4ef2043fdd78',
      opta_ID: '50y6nq2eok9lnmldevkamkkwl',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1995-10-21',
      foot: 'right',
      height: '165',
      opta_first_name: 'Daniel',
      opta_last_name: 'Castelo Podence',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Oeiras',
      type: 'player',
      weight: '58',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Castelo Podence',
      last_name_upper: 'CASTELO PODENCE',
    },
    {
      _id: '6166c980e65c4ef2043fded9',
      opta_ID: '5wa8lzp50ccfhxg3j4f65gt79',
      __v: 0,
      country_of_birth: 'Mexico',
      date_of_birth: '1991-05-05',
      foot: 'right',
      height: '187',
      opta_first_name: 'Raúl Alonso',
      opta_last_name: 'Jiménez Rodríguez',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d593',
      place_of_birth: 'Tepeji del Río Ocampo',
      type: 'player',
      weight: '76',
      first_name: 'Raúl Alonso',
      first_name_upper: 'RAÚL ALONSO',
      last_name: 'Jiménez Rodríguez',
      last_name_upper: 'JIMÉNEZ RODRÍGUEZ',
    },
    {
      _id: '6166c980e65c4ef2043fdf87',
      opta_ID: '6psklvi66inwws7buzf16yjft',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '2000-03-09',
      foot: 'left',
      height: '173',
      opta_first_name: 'Pedro',
      opta_last_name: 'Lomba Neto',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Viana do Castelo',
      type: 'player',
      weight: '69',
      first_name: 'Pedro',
      first_name_upper: 'PEDRO',
      last_name: 'Lomba Neto',
      last_name_upper: 'LOMBA NETO',
    },
    {
      _id: '6166c980e65c4ef2043fe028',
      opta_ID: '6w1s9fojh50ecti3rz8jv6jca',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2002-01-18',
      foot: 'right',
      height: '180',
      opta_first_name: 'Ki-Jana Delano',
      opta_last_name: 'Hoever',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: 'Amsterdam',
      type: 'player',
      weight: '65',
      first_name: 'Ki-Jana Delano',
      first_name_upper: 'KI-JANA DELANO',
      last_name: 'Hoever',
      last_name_upper: 'HOEVER',
    },
    {
      _id: '6166c980e65c4ef2043fe0cf',
      opta_ID: '71rob1lsnmdviy1siakkzpqdx',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1993-01-17',
      foot: 'right',
      height: '192',
      opta_first_name: 'José Pedro',
      opta_last_name: 'Malheiro de Sá',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Braga',
      type: 'player',
      weight: '84',
      first_name: 'José Pedro',
      first_name_upper: 'JOSÉ PEDRO',
      last_name: 'Malheiro de Sá',
      last_name_upper: 'MALHEIRO DE SÁ',
    },
    {
      _id: '6166c980e65c4ef2043fe190',
      opta_ID: '7jwljxbzzuoeh588uy4ov457u',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-01-06',
      foot: '',
      height: '',
      opta_first_name: 'Louie',
      opta_last_name: 'Moulden',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Louie',
      first_name_upper: 'LOUIE',
      last_name: 'Moulden',
      last_name_upper: 'MOULDEN',
    },
    {
      _id: '6166c980e65c4ef2043fe1ad',
      opta_ID: '7snt4uu7f2d0g7utjm2xfis8a',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '2002-07-19',
      foot: 'right',
      height: '185',
      opta_first_name: 'Fábio Daniel',
      opta_last_name: 'Soares Silva',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Porto',
      type: 'player',
      weight: '75',
      first_name: 'Fábio Daniel',
      first_name_upper: 'FÁBIO DANIEL',
      last_name: 'Soares Silva',
      last_name_upper: 'SOARES SILVA',
    },
    {
      _id: '6166c980e65c4ef2043fe1c9',
      opta_ID: '7wzwqegxhekjwq361dd2nidp1',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1994-03-03',
      foot: 'both',
      height: '175',
      opta_first_name: 'Jonathan',
      opta_last_name: 'Castro Otto',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: 'Vigo',
      type: 'player',
      weight: '70',
      first_name: 'Jonathan',
      first_name_upper: 'JONATHAN',
      last_name: 'Castro Otto',
      last_name_upper: 'CASTRO OTTO',
    },
    {
      _id: '6166c980e65c4ef2043fe210',
      opta_ID: '83ekq1r2omnsazmp41pio8s89',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1999-12-29',
      foot: 'left',
      height: '183',
      opta_first_name: 'Francisco António',
      opta_last_name: 'Machado Mota Castro Trincão',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Viana do Castelo',
      type: 'player',
      weight: '77',
      first_name: 'Francisco António',
      first_name_upper: 'FRANCISCO ANTÓNIO',
      last_name: 'Machado Mota Castro Trincão',
      last_name_upper: 'MACHADO MOTA CASTRO TRINCÃO',
    },
    {
      _id: '6166c981e65c4ef2043fe37b',
      opta_ID: '8i3y6fo1y3gizq05j2im17c6i',
      __v: 0,
      country_of_birth: 'Colombia',
      date_of_birth: '2001-05-02',
      foot: '',
      height: '187',
      opta_first_name: 'Yerson',
      opta_last_name: 'Mosquera Valdelamar',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d65f',
      place_of_birth: '',
      type: 'player',
      weight: '76',
      first_name: 'Yerson',
      first_name_upper: 'YERSON',
      last_name: 'Mosquera Valdelamar',
      last_name_upper: 'MOSQUERA VALDELAMAR',
    },
    {
      _id: '6166c981e65c4ef2043fe433',
      opta_ID: '8kw1y8mzxtwl4fv8qkc8hoc7p',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1997-03-13',
      foot: 'right',
      height: '180',
      opta_first_name: 'Rúben Diogo',
      opta_last_name: 'da Silva Neves',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Santa Maria da Feira',
      type: 'player',
      weight: '80',
      first_name: 'Rúben Diogo',
      first_name_upper: 'RÚBEN DIOGO',
      last_name: 'da Silva Neves',
      last_name_upper: 'DA SILVA NEVES',
    },
    {
      _id: '6166c981e65c4ef2043fe4f0',
      opta_ID: '8u22rvvtolr317zz3wblx2ctm',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1976-05-12',
      foot: '',
      height: '',
      opta_first_name: 'Bruno Miguel',
      opta_last_name: 'Silva do Nascimento',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Setúbal',
      type: 'coach',
      weight: '',
      first_name: 'Bruno Miguel',
      first_name_upper: 'BRUNO MIGUEL',
      last_name: 'Silva do Nascimento',
      last_name_upper: 'SILVA DO NASCIMENTO',
    },
    {
      _id: '6166c981e65c4ef2043fe5c2',
      opta_ID: '9jxhgh1vhc531ho0ez5alfazu',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '2001-06-06',
      foot: 'left',
      height: '179',
      opta_first_name: 'Rayan',
      opta_last_name: 'Aït Nouri',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d72b',
      place_of_birth: 'Montreuil',
      type: 'player',
      weight: '70',
      first_name: 'Rayan',
      first_name_upper: 'RAYAN',
      last_name: 'Aït Nouri',
      last_name_upper: 'AÏT NOURI',
    },
    {
      _id: '6166c981e65c4ef2043fe627',
      opta_ID: '9l7ph5603tbb7xwyxpvjg22fp',
      __v: 0,
      country_of_birth: 'Portugal',
      date_of_birth: '1986-09-08',
      foot: 'right',
      height: '170',
      opta_first_name: 'João Filipe',
      opta_last_name: 'Iria Santos Moutinho',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Portimão',
      type: 'player',
      weight: '61',
      first_name: 'João Filipe',
      first_name_upper: 'JOÃO FILIPE',
      last_name: 'Iria Santos Moutinho',
      last_name_upper: 'IRIA SANTOS MOUTINHO',
    },
    {
      _id: '6166c981e65c4ef2043fe64a',
      opta_ID: 'cfpqqzqrpzv6dq89yw7nfd1p1',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1991-02-03',
      foot: 'right',
      height: '185',
      opta_first_name: 'Willy-Arnaud',
      opta_last_name: 'Zobo Boly',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d44b',
      place_of_birth: 'Melun',
      type: 'player',
      weight: '81',
      first_name: 'Willy-Arnaud',
      first_name_upper: 'WILLY-ARNAUD',
      last_name: 'Zobo Boly',
      last_name_upper: 'ZOBO BOLY',
    },
    {
      _id: '6166c981e65c4ef2043fe667',
      opta_ID: 'cpkhrnagjt43vjzf058psq0gl',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1986-10-24',
      foot: 'right',
      height: '192',
      opta_first_name: 'John',
      opta_last_name: 'Ruddy',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'St Ives',
      type: 'player',
      weight: '96',
      first_name: 'John',
      first_name_upper: 'JOHN',
      last_name: 'Ruddy',
      last_name_upper: 'RUDDY',
    },
    {
      _id: '6166c981e65c4ef2043fe685',
      opta_ID: 'dez3rm7q89xk157fwnc3tgqcq',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '2002-12-30',
      foot: '',
      height: '',
      opta_first_name: 'Chem',
      opta_last_name: 'Campbell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Chem',
      first_name_upper: 'CHEM',
      last_name: 'Campbell',
      last_name_upper: 'CAMPBELL',
    },
    {
      _id: '6166c981e65c4ef2043fe6e1',
      opta_ID: 'dl8x6fzdexn8r0sj65vgcgq8l',
      __v: 0,
      country_of_birth: 'Korea Republic',
      date_of_birth: '1996-01-26',
      foot: 'right',
      height: '177',
      opta_first_name: 'Hee-Chan',
      opta_last_name: 'Hwang',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c139e65c4ef20434d28e',
      place_of_birth: 'Chuncheon',
      type: 'player',
      weight: '77',
      first_name: 'Hee-Chan',
      first_name_upper: 'HEE-CHAN',
      last_name: 'Hwang',
      last_name_upper: 'HWANG',
    },
    {
      _id: '6166c982e65c4ef2043fe7a2',
      opta_ID: 'dp3cwbaz62y3kemf4b6dg08vd',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-04-26',
      foot: '',
      height: '',
      opta_first_name: 'Luke James',
      opta_last_name: 'Cundle',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Luke James',
      first_name_upper: 'LUKE JAMES',
      last_name: 'Cundle',
      last_name_upper: 'CUNDLE',
    },
    {
      _id: '6166c982e65c4ef2043fe83c',
      opta_ID: 'dv9gm9ueox7uah9yt3fstc8dm',
      __v: 0,
      country_of_birth: 'Switzerland',
      date_of_birth: '2003-01-15',
      foot: 'right',
      height: '187',
      opta_first_name: 'Christian',
      opta_last_name: 'Fernandes Marques',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d7eb',
      place_of_birth: 'Uster',
      type: 'player',
      weight: '',
      first_name: 'Christian',
      first_name_upper: 'CHRISTIAN',
      last_name: 'Fernandes Marques',
      last_name_upper: 'FERNANDES MARQUES',
    },
    {
      _id: '6166c982e65c4ef2043fe8f3',
      opta_ID: 'dxv40hh07gxq4hn0i6dwb2l4a',
      __v: 0,
      country_of_birth: 'Denmark',
      date_of_birth: '2001-01-17',
      foot: '',
      height: '',
      opta_first_name: 'Andreas',
      opta_last_name: 'Søndergaard',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d725',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Andreas',
      first_name_upper: 'ANDREAS',
      last_name: 'Søndergaard',
      last_name_upper: 'SØNDERGAARD',
    },
    {
      _id: '6166c982e65c4ef2043fe9c0',
      opta_ID: 'e83zd56y74k8uecxqyo5xw7v9',
      __v: 0,
      country_of_birth: 'Spain',
      date_of_birth: '1996-01-25',
      foot: 'right',
      height: '178',
      opta_first_name: 'Adama',
      opta_last_name: 'Traoré Diarra',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ce65c4ef20434d8e6',
      place_of_birth: "L'Hospitalet de Llobregat",
      type: 'player',
      weight: '72',
      first_name: 'Adama',
      first_name_upper: 'ADAMA',
      last_name: 'Traoré Diarra',
      last_name_upper: 'TRAORÉ DIARRA',
    },
    {
      _id: '6166c982e65c4ef2043fea91',
      opta_ID: 'ed4w2l6hglrrxu2a75bn0tt5h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1993-02-25',
      foot: 'right',
      height: '185',
      opta_first_name: 'Conor',
      opta_last_name: 'Coady',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'St Helens',
      type: 'player',
      weight: '75',
      first_name: 'Conor',
      first_name_upper: 'CONOR',
      last_name: 'Coady',
      last_name_upper: 'COADY',
    },
    {
      _id: '6166c982e65c4ef2043feadb',
      opta_ID: 'ev2w2huskuta9v2bgjm4573yt',
      __v: 0,
      country_of_birth: 'France',
      date_of_birth: '1990-03-26',
      foot: 'right',
      height: '190',
      opta_first_name: 'Romain',
      opta_last_name: 'Saïss',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13ae65c4ef20434d48b',
      place_of_birth: 'Bourg-de-Peage',
      type: 'player',
      weight: '80',
      first_name: 'Romain',
      first_name_upper: 'ROMAIN',
      last_name: 'Saïss',
      last_name_upper: 'SAÏSS',
    },
    {
      _id: '6166e5cbe65c4ef204657a95',
      opta_ID: '42wj08p5qpcj3e3ppn4bekodm',
      __v: 0,
      country_of_birth: 'Netherlands',
      date_of_birth: '2003-08-20',
      foot: '',
      height: '',
      opta_first_name: 'Salah',
      opta_last_name: "Oulad M'Hand",
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77e',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Salah',
      first_name_upper: 'SALAH',
      last_name: "Oulad M'Hand",
      last_name_upper: "OULAD M'HAND",
    },
    {
      _id: '6166e5f2e65c4ef20465aa75',
      opta_ID: '9i1gqo2wugpaygigl5knhdjsa',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-09-11',
      foot: '',
      height: '',
      opta_first_name: 'Harvey James',
      opta_last_name: 'Vale',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Harvey James',
      first_name_upper: 'HARVEY JAMES',
      last_name: 'Vale',
      last_name_upper: 'VALE',
    },
    {
      _id: '6166e5f3e65c4ef20465abdb',
      opta_ID: 'dgthbtwjv3s2sbeq32844eqne',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-03-30',
      foot: 'right',
      height: '189',
      opta_first_name: 'Teddy',
      opta_last_name: 'Sharman-Lowe',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Leicester',
      type: 'player',
      weight: '73',
      first_name: 'Teddy',
      first_name_upper: 'TEDDY',
      last_name: 'Sharman-Lowe',
      last_name_upper: 'SHARMAN-LOWE',
    },
    {
      _id: '6166e624e65c4ef20465e7ee',
      opta_ID: '1956qrvkxjyixuehqlnd8ndnt',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-04-02',
      foot: '',
      height: '',
      opta_first_name: 'Vontae',
      opta_last_name: 'Daley-Campbell',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Vontae',
      first_name_upper: 'VONTAE',
      last_name: 'Daley-Campbell',
      last_name_upper: 'DALEY-CAMPBELL',
    },
    {
      _id: '6166e626e65c4ef20465e9e2',
      opta_ID: '8jifj5bh16pgre8cmtzbdw6lm',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2004-03-18',
      foot: '',
      height: '',
      opta_first_name: 'Benjamin Harvey',
      opta_last_name: 'Nelson',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Benjamin Harvey',
      first_name_upper: 'BENJAMIN HARVEY',
      last_name: 'Nelson',
      last_name_upper: 'NELSON',
    },
    {
      _id: '6166e626e65c4ef20465ea65',
      opta_ID: 'b6gva8y8bdyy7bmv6se449ap6',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-10-19',
      foot: '',
      height: '',
      opta_first_name: 'Wanya',
      opta_last_name: 'Marcal-Madivadua',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Wanya',
      first_name_upper: 'WANYA',
      last_name: 'Marcal-Madivadua',
      last_name_upper: 'MARCAL-MADIVADUA',
    },
    {
      _id: '6166e62de65c4ef20465f416',
      opta_ID: '5auce3x81f1kwok1aq7m4o2y1',
      __v: 0,
      country_of_birth: 'Northern Ireland',
      date_of_birth: '2001-08-19',
      foot: '',
      height: '',
      opta_first_name: 'Liam John',
      opta_last_name: 'Hughes',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d77f',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Liam John',
      first_name_upper: 'LIAM JOHN',
      last_name: 'Hughes',
      last_name_upper: 'HUGHES',
    },
    {
      _id: '6166e62fe65c4ef20465f65b',
      opta_ID: 'c9dpo6x6s0jkgz4oumt756o6h',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-01-01',
      foot: '',
      height: '',
      opta_first_name: 'Elijah',
      opta_last_name: 'Dixon-Bonner',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Elijah',
      first_name_upper: 'ELIJAH',
      last_name: 'Dixon-Bonner',
      last_name_upper: 'DIXON-BONNER',
    },
    {
      _id: '6166e68be65c4ef20466d76e',
      opta_ID: '25jh9gt2e68fih76n8vrifh4a',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2002-04-04',
      foot: '',
      height: '',
      opta_first_name: 'Daniel',
      opta_last_name: 'Chesters',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      last_name: 'Chesters',
      last_name_upper: 'CHESTERS',
    },
    {
      _id: '6166e748e65c4ef204683133',
      opta_ID: '6mojdfqgeq269tmk5kmxj3etx',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '1970-03-13',
      foot: '',
      height: '',
      opta_first_name: 'Graeme',
      opta_last_name: 'Jones',
      opta_last_updated: '2021-11-05T14:32:36Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'Gateshead',
      type: 'assistant coach',
      weight: '',
      first_name: 'Graeme',
      first_name_upper: 'GRAEME',
      last_name: 'Jones',
      last_name_upper: 'JONES',
    },
    {
      _id: '6186b4dea4737fae26aa3e35',
      opta_ID: '42qiblnxr02ku269z7urh0844',
      __v: 0,
      country_of_birth: 'Wales',
      date_of_birth: '2004-07-02',
      first_name: 'Jordan',
      first_name_upper: 'JORDAN',
      foot: '',
      height: '',
      last_name: 'James',
      last_name_upper: 'JAMES',
      opta_first_name: 'Jordan',
      opta_last_name: 'James',
      opta_last_updated: '2021-11-06T14:21:27Z',
      opta_nationality: '6166c13ce65c4ef20434d90c',
      place_of_birth: '',
      type: 'player',
      weight: '',
    },
    {
      _id: '6186b4efa4737fae26aa5747',
      opta_ID: '3nwvlft28lt3fiirchylvwtm1',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2001-05-08',
      first_name: 'Edmond-Paris',
      first_name_upper: 'EDMOND-PARIS',
      foot: 'right',
      height: '',
      last_name: 'Maghoma',
      last_name_upper: 'MAGHOMA',
      opta_first_name: 'Edmond-Paris',
      opta_last_name: 'Maghoma',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '',
    },
    {
      _id: '6186b4f0a4737fae26aa5869',
      opta_ID: '6o4pt3ffe9ogxcbdv694sr5uy',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-05-02',
      first_name: 'Matthew',
      first_name_upper: 'MATTHEW',
      foot: '',
      height: '182',
      last_name: 'Cox',
      last_name_upper: 'COX',
      opta_first_name: 'Matthew',
      opta_last_name: 'Cox',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: 'London',
      type: 'player',
      weight: '64',
    },
    {
      _id: '6186b4f1a4737fae26aa5a19',
      opta_ID: 'am1da6dm0zympt2yt120h94yy',
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-01-03',
      first_name: 'Daniel',
      first_name_upper: 'DANIEL',
      foot: '',
      height: '',
      last_name: 'Oyegoke',
      last_name_upper: 'OYEGOKE',
      opta_first_name: 'Daniel',
      opta_last_name: 'Oyegoke',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
    },
    {
      _id: '6186c89fa4737fae26c54048',
      opta_ID: 'ejs5z02c5ei9n7g25994xkacq',
      user: null,
      __v: 0,
      country_of_birth: 'England',
      date_of_birth: '2003-09-14',
      first_name: 'Harvey',
      first_name_upper: 'HARVEY',
      foot: '',
      height: '',
      last_name: 'Blair',
      last_name_upper: 'BLAIR',
      opta_first_name: 'Harvey',
      opta_last_name: 'Blair',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
    },
    {
      _id: '6186c91ba4737fae26c660b9',
      opta_ID: '2t14kex330qljt5vlmssvo6ad',
      user: null,
      __v: 0,
      country_of_birth: 'Italy',
      date_of_birth: '1969-07-31',
      first_name: 'Antonio',
      first_name_upper: 'ANTONIO',
      foot: '',
      height: '178',
      last_name: 'Conte',
      last_name_upper: 'CONTE',
      opta_first_name: 'Antonio',
      opta_last_name: 'Conte',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d771',
      place_of_birth: 'Lecce',
      type: 'coach',
      weight: '73',
    },
    {
      _id: '6186c936a4737fae26c68842',
      opta_ID: '88j19lbs02pr0y6h6payu35sk',
      user: null,
      __v: 0,
      country_of_birth: 'England',
      first_name: 'James',
      first_name_upper: 'JAMES',
      foot: '',
      height: '',
      last_name: 'Storer',
      last_name_upper: 'STORER',
      opta_first_name: 'James',
      opta_last_name: 'Storer',
      opta_last_updated: '2021-11-04T19:00:48Z',
      opta_nationality: '6166c13be65c4ef20434d711',
      place_of_birth: '',
      type: 'player',
      weight: '',
    },
  ],
  games: [
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e098b8b115f06403da',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e098b8b115f06403db',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e098b8b115f06403dc',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0e098b8b115f06403dd',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e098b8b115f06403de',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e098b8b115f06403df',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 8,
            _id: '6186e0e098b8b115f06403e0',
          },
          {
            squad: '6166c8f5e65c4ef2043edf3f',
            order: 11,
            _id: '6186e0e098b8b115f06403e1',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e098b8b115f06403e2',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e098b8b115f06403e3',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0e098b8b115f06403e4',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0e098b8b115f06403e5',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0c6',
            order: 13,
            _id: '6186e0e098b8b115f06403e6',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 14,
            _id: '6186e0e098b8b115f06403e7',
          },
          {
            squad: '6166c8f5e65c4ef2043edf20',
            order: 15,
            _id: '6186e0e098b8b115f06403e8',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 16,
            _id: '6186e0e098b8b115f06403e9',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 17,
            _id: '6186e0e098b8b115f06403ea',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 18,
            _id: '6186e0e098b8b115f06403eb',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 19,
            _id: '6186e0e098b8b115f06403ec',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e098b8b115f06403ed',
          },
        ],
        away: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e098b8b115f06403c6',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e098b8b115f06403c7',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e098b8b115f06403c8',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 5,
            _id: '6186e0e098b8b115f06403c9',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e098b8b115f06403ca',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 8,
            _id: '6186e0e098b8b115f06403cb',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 4,
            _id: '6186e0e098b8b115f06403cc',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 7,
            _id: '6186e0e098b8b115f06403cd',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 11,
            _id: '6186e0e098b8b115f06403ce',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 9,
            _id: '6186e0e098b8b115f06403cf',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e098b8b115f06403d0',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 12,
            _id: '6186e0e098b8b115f06403d1',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 13,
            _id: '6186e0e098b8b115f06403d2',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 14,
            _id: '6186e0e098b8b115f06403d3',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 15,
            _id: '6186e0e098b8b115f06403d4',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 16,
            _id: '6186e0e098b8b115f06403d5',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 17,
            _id: '6186e0e098b8b115f06403d6',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 18,
            _id: '6186e0e098b8b115f06403d7',
          },
          {
            squad: '6166c8e8e65c4ef2043ece67',
            order: 19,
            _id: '6186e0e098b8b115f06403d8',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 20,
            _id: '6186e0e098b8b115f06403d9',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef20446678d',
      opta_ID: 'e0widu1f4tla82z0h6dazgx04',
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-24Z',
      description: 'Manchester United vs Liverpool',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e098b8b115f06403c2',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06403c3',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06403c4',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06403c5',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e098b8b115f0640407',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e098b8b115f0640408',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e098b8b115f0640409',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0e098b8b115f064040a',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 2,
            _id: '6186e0e098b8b115f064040b',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e098b8b115f064040c',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e098b8b115f064040d',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 11,
            _id: '6186e0e098b8b115f064040e',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 10,
            _id: '6186e0e098b8b115f064040f',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e098b8b115f0640410',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e098b8b115f0640411',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e098b8b115f0640412',
          },
          {
            squad: '6166c979e65c4ef2043fc062',
            order: 13,
            _id: '6186e0e098b8b115f0640413',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 14,
            _id: '6186e0e098b8b115f0640414',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 15,
            _id: '6186e0e098b8b115f0640415',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 16,
            _id: '6186e0e098b8b115f0640416',
          },
          {
            squad: '6166c978e65c4ef2043fbb13',
            order: 17,
            _id: '6186e0e098b8b115f0640417',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 18,
            _id: '6186e0e098b8b115f0640418',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 19,
            _id: '6186e0e098b8b115f0640419',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e098b8b115f064041a',
          },
        ],
        away: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e098b8b115f06403f3',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e098b8b115f06403f4',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e098b8b115f06403f5',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 5,
            _id: '6186e0e098b8b115f06403f6',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0e098b8b115f06403f7',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e098b8b115f06403f8',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 8,
            _id: '6186e0e098b8b115f06403f9',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 11,
            _id: '6186e0e098b8b115f06403fa',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 10,
            _id: '6186e0e098b8b115f06403fb',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 7,
            _id: '6186e0e098b8b115f06403fc',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e098b8b115f06403fd',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 12,
            _id: '6186e0e098b8b115f06403fe',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 13,
            _id: '6186e0e098b8b115f06403ff',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 14,
            _id: '6186e0e098b8b115f0640400',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 15,
            _id: '6186e0e098b8b115f0640401',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 16,
            _id: '6186e0e098b8b115f0640402',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 17,
            _id: '6186e0e098b8b115f0640403',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 18,
            _id: '6186e0e098b8b115f0640404',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 19,
            _id: '6186e0e098b8b115f0640405',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 20,
            _id: '6186e0e098b8b115f0640406',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef2044667b1',
      opta_ID: 'e14bzt28389kf6txooc8sbn6c',
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-24Z',
      description: 'West Ham United vs Tottenham Hotspur',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e098b8b115f06403ef',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06403f0',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06403f1',
        },
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06403f2',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e098b8b115f0640434',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e098b8b115f0640435',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e098b8b115f0640436',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 6,
            _id: '6186e0e098b8b115f0640437',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e098b8b115f0640438',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 8,
            _id: '6186e0e098b8b115f0640439',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e098b8b115f064043a',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0e098b8b115f064043b',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e098b8b115f064043c',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 9,
            _id: '6186e0e098b8b115f064043d',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 10,
            _id: '6186e0e098b8b115f064043e',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 12,
            _id: '6186e0e098b8b115f064043f',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 13,
            _id: '6186e0e098b8b115f0640440',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 14,
            _id: '6186e0e098b8b115f0640441',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 15,
            _id: '6186e0e098b8b115f0640442',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 16,
            _id: '6186e0e098b8b115f0640443',
          },
          {
            squad: '6186b4efa4737fae26aa5754',
            order: 17,
            _id: '6186e0e098b8b115f0640444',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0e098b8b115f0640445',
          },
          {
            squad: '6166c87be65c4ef2043dc9f4',
            order: 19,
            _id: '6186e0e098b8b115f0640446',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 20,
            _id: '6186e0e098b8b115f0640447',
          },
        ],
        away: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e098b8b115f0640420',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 4,
            _id: '6186e0e098b8b115f0640421',
          },
          {
            squad: '6166c8e1e65c4ef2043ec4b0',
            order: 5,
            _id: '6186e0e098b8b115f0640422',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 6,
            _id: '6186e0e098b8b115f0640423',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 3,
            _id: '6186e0e098b8b115f0640424',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 8,
            _id: '6186e0e098b8b115f0640425',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 7,
            _id: '6186e0e098b8b115f0640426',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e098b8b115f0640427',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 9,
            _id: '6186e0e098b8b115f0640428',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 11,
            _id: '6186e0e098b8b115f0640429',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 10,
            _id: '6186e0e098b8b115f064042a',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 12,
            _id: '6186e0e098b8b115f064042b',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 13,
            _id: '6186e0e098b8b115f064042c',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 14,
            _id: '6186e0e098b8b115f064042d',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 15,
            _id: '6186e0e098b8b115f064042e',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 16,
            _id: '6186e0e098b8b115f064042f',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 17,
            _id: '6186e0e098b8b115f0640430',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 18,
            _id: '6186e0e098b8b115f0640431',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 19,
            _id: '6186e0e098b8b115f0640432',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e098b8b115f0640433',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef2044667de',
      opta_ID: 'e083bp9mu6jsa76qral86tl3o',
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-24Z',
      description: 'Brentford vs Leicester City',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Main',
          _id: '6186e0e098b8b115f064041c',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f064041d',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f064041e',
        },
        {
          official: '6166cd55e65c4ef204465cb4',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f064041f',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e098b8b115f0640461',
          },
          {
            squad: '6166c87ee65c4ef2043dce78',
            order: 4,
            _id: '6186e0e098b8b115f0640462',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 5,
            _id: '6186e0e098b8b115f0640463',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 6,
            _id: '6186e0e098b8b115f0640464',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e098b8b115f0640465',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 8,
            _id: '6186e0e098b8b115f0640466',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 11,
            _id: '6186e0e098b8b115f0640467',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 7,
            _id: '6186e0e098b8b115f0640468',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 2,
            _id: '6186e0e098b8b115f0640469',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e098b8b115f064046a',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e098b8b115f064046b',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 12,
            _id: '6186e0e098b8b115f064046c',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 13,
            _id: '6186e0e098b8b115f064046d',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 14,
            _id: '6186e0e098b8b115f064046e',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 15,
            _id: '6186e0e098b8b115f064046f',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 16,
            _id: '6186e0e098b8b115f0640470',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 17,
            _id: '6186e0e098b8b115f0640471',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 18,
            _id: '6186e0e098b8b115f0640472',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 19,
            _id: '6186e0e098b8b115f0640473',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 20,
            _id: '6186e0e098b8b115f0640474',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e098b8b115f064044d',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e098b8b115f064044e',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e098b8b115f064044f',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e098b8b115f0640450',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e098b8b115f0640451',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 8,
            _id: '6186e0e098b8b115f0640452',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e098b8b115f0640453',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 7,
            _id: '6186e0e098b8b115f0640454',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e098b8b115f0640455',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 9,
            _id: '6186e0e098b8b115f0640456',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e098b8b115f0640457',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e098b8b115f0640458',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 13,
            _id: '6186e0e098b8b115f0640459',
          },
          {
            squad: '6166c8f1e65c4ef2043ed972',
            order: 14,
            _id: '6186e0e098b8b115f064045a',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 15,
            _id: '6186e0e098b8b115f064045b',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 16,
            _id: '6186e0e098b8b115f064045c',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 17,
            _id: '6186e0e098b8b115f064045d',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 18,
            _id: '6186e0e098b8b115f064045e',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0e098b8b115f064045f',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0e098b8b115f0640460',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef2044667fe',
      opta_ID: 'e0e0o8nmfyr6b04f1hk4rvxg4',
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Brighton & Hove Albion vs Manchester City',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Main',
          _id: '6186e0e098b8b115f0640449',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f064044a',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f064044b',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f064044c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e098b8b115f064048e',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 3,
            _id: '6186e0e098b8b115f064048f',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e098b8b115f0640490',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0e098b8b115f0640491',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e098b8b115f0640492',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 11,
            _id: '6186e0e098b8b115f0640493',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 8,
            _id: '6186e0e098b8b115f0640494',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 4,
            _id: '6186e0e098b8b115f0640495',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 7,
            _id: '6186e0e098b8b115f0640496',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 9,
            _id: '6186e0e098b8b115f0640497',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 10,
            _id: '6186e0e098b8b115f0640498',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 12,
            _id: '6186e0e098b8b115f0640499',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 13,
            _id: '6186e0e098b8b115f064049a',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 14,
            _id: '6186e0e098b8b115f064049b',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 15,
            _id: '6186e0e098b8b115f064049c',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 16,
            _id: '6186e0e098b8b115f064049d',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 17,
            _id: '6186e0e098b8b115f064049e',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0e098b8b115f064049f',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0e098b8b115f06404a0',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 20,
            _id: '6186e0e098b8b115f06404a1',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e098b8b115f064047a',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e098b8b115f064047b',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 6,
            _id: '6186e0e098b8b115f064047c',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 5,
            _id: '6186e0e098b8b115f064047d',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e098b8b115f064047e',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 11,
            _id: '6186e0e098b8b115f064047f',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 8,
            _id: '6186e0e098b8b115f0640480',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e098b8b115f0640481',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 7,
            _id: '6186e0e098b8b115f0640482',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0e098b8b115f0640483',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 10,
            _id: '6186e0e098b8b115f0640484',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e098b8b115f0640485',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0e098b8b115f0640486',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 14,
            _id: '6186e0e098b8b115f0640487',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0e098b8b115f0640488',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 16,
            _id: '6186e0e098b8b115f0640489',
          },
          {
            squad: '6166c88ae65c4ef2043ddf0e',
            order: 17,
            _id: '6186e0e098b8b115f064048a',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 18,
            _id: '6186e0e098b8b115f064048b',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 19,
            _id: '6186e0e098b8b115f064048c',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0e098b8b115f064048d',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef204466819',
      opta_ID: 'e10o1z9zvf3hdgqtbd67k5qms',
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Southampton vs Burnley',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Main',
          _id: '6186e0e098b8b115f0640476',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f0640477',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f0640478',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f0640479',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e098b8b115f06404bb',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 3,
            _id: '6186e0e098b8b115f06404bc',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e098b8b115f06404bd',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 5,
            _id: '6186e0e098b8b115f06404be',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 2,
            _id: '6186e0e098b8b115f06404bf',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 11,
            _id: '6186e0e098b8b115f06404c0',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 8,
            _id: '6186e0e098b8b115f06404c1',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 4,
            _id: '6186e0e098b8b115f06404c2',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e098b8b115f06404c3',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 10,
            _id: '6186e0e098b8b115f06404c4',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 9,
            _id: '6186e0e098b8b115f06404c5',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e098b8b115f06404c6',
          },
          {
            squad: '6166c8dfe65c4ef2043ec268',
            order: 13,
            _id: '6186e0e098b8b115f06404c7',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 14,
            _id: '6186e0e098b8b115f06404c8',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 15,
            _id: '6186e0e098b8b115f06404c9',
          },
          {
            squad: '6166c8dfe65c4ef2043ec339',
            order: 16,
            _id: '6186e0e098b8b115f06404ca',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 17,
            _id: '6186e0e098b8b115f06404cb',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 18,
            _id: '6186e0e098b8b115f06404cc',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e098b8b115f06404cd',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e098b8b115f06404ce',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e098b8b115f06404a7',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e098b8b115f06404a8',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e098b8b115f06404a9',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e098b8b115f06404aa',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 3,
            _id: '6186e0e098b8b115f06404ab',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e098b8b115f06404ac',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 7,
            _id: '6186e0e098b8b115f06404ad',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e098b8b115f06404ae',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 11,
            _id: '6186e0e098b8b115f06404af',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 10,
            _id: '6186e0e098b8b115f06404b0',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e098b8b115f06404b1',
          },
          {
            squad: '6166c981e65c4ef2043fe690',
            order: 12,
            _id: '6186e0e098b8b115f06404b2',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e098b8b115f06404b3',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 14,
            _id: '6186e0e098b8b115f06404b4',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0e098b8b115f06404b5',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 16,
            _id: '6186e0e098b8b115f06404b6',
          },
          {
            squad: '6166c980e65c4ef2043fe19f',
            order: 17,
            _id: '6186e0e098b8b115f06404b7',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 18,
            _id: '6186e0e098b8b115f06404b8',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 19,
            _id: '6186e0e098b8b115f06404b9',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 20,
            _id: '6186e0e098b8b115f06404ba',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef204466833',
      opta_ID: 'e0slfmz5oq73c65xec80l2wic',
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Leeds United vs Wolverhampton Wanderers',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Main',
          _id: '6186e0e098b8b115f06404a3',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06404a4',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06404a5',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06404a6',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e098b8b115f06404e8',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e098b8b115f06404e9',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 6,
            _id: '6186e0e098b8b115f06404ea',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 5,
            _id: '6186e0e098b8b115f06404eb',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e098b8b115f06404ec',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 11,
            _id: '6186e0e098b8b115f06404ed',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e098b8b115f06404ee',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 4,
            _id: '6186e0e098b8b115f06404ef',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e098b8b115f06404f0',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e098b8b115f06404f1',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 9,
            _id: '6186e0e098b8b115f06404f2',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e098b8b115f06404f3',
          },
          {
            squad: '6166c8bbe65c4ef2043e32e1',
            order: 13,
            _id: '6186e0e098b8b115f06404f4',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 14,
            _id: '6186e0e098b8b115f06404f5',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 15,
            _id: '6186e0e098b8b115f06404f6',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 16,
            _id: '6186e0e098b8b115f06404f7',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 17,
            _id: '6186e0e098b8b115f06404f8',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 18,
            _id: '6186e0e098b8b115f06404f9',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 19,
            _id: '6186e0e098b8b115f06404fa',
          },
        ],
        away: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0e098b8b115f06404d4',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0e098b8b115f06404d5',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 6,
            _id: '6186e0e098b8b115f06404d6',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e098b8b115f06404d7',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 2,
            _id: '6186e0e098b8b115f06404d8',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 4,
            _id: '6186e0e098b8b115f06404d9',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 11,
            _id: '6186e0e098b8b115f06404da',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 10,
            _id: '6186e0e098b8b115f06404db',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 8,
            _id: '6186e0e098b8b115f06404dc',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e098b8b115f06404dd',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e098b8b115f06404de',
          },
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 12,
            _id: '6186e0e098b8b115f06404df',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 13,
            _id: '6186e0e098b8b115f06404e0',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 14,
            _id: '6186e0e098b8b115f06404e1',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 15,
            _id: '6186e0e098b8b115f06404e2',
          },
          {
            squad: '6166c971e65c4ef2043f9a14',
            order: 16,
            _id: '6186e0e098b8b115f06404e3',
          },
          {
            squad: '6166c973e65c4ef2043fa40f',
            order: 17,
            _id: '6186e0e098b8b115f06404e4',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 18,
            _id: '6186e0e098b8b115f06404e5',
          },
          {
            squad: '6166c973e65c4ef2043fa1d9',
            order: 19,
            _id: '6186e0e098b8b115f06404e6',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 20,
            _id: '6186e0e098b8b115f06404e7',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef20446684e',
      opta_ID: 'e0ozl6lzifo0ysf2ai1n4tfys',
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Everton vs Watford',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Main',
          _id: '6186e0e098b8b115f06404d0',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06404d1',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06404d2',
        },
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06404d3',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e098b8b115f0640514',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e098b8b115f0640515',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e098b8b115f0640516',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e098b8b115f0640517',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e098b8b115f0640518',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e098b8b115f0640519',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 4,
            _id: '6186e0e098b8b115f064051a',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e098b8b115f064051b',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 11,
            _id: '6186e0e098b8b115f064051c',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e098b8b115f064051d',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 10,
            _id: '6186e0e098b8b115f064051e',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 12,
            _id: '6186e0e098b8b115f064051f',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0e098b8b115f0640520',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 14,
            _id: '6186e0e098b8b115f0640521',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 15,
            _id: '6186e0e098b8b115f0640522',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 16,
            _id: '6186e0e098b8b115f0640523',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 17,
            _id: '6186e0e098b8b115f0640524',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 18,
            _id: '6186e0e098b8b115f0640525',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 19,
            _id: '6186e0e098b8b115f0640526',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 20,
            _id: '6186e0e098b8b115f0640527',
          },
        ],
        away: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0e098b8b115f0640500',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0e098b8b115f0640501',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e098b8b115f0640502',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 6,
            _id: '6186e0e098b8b115f0640503',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e098b8b115f0640504',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e098b8b115f0640505',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 11,
            _id: '6186e0e098b8b115f0640506',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 8,
            _id: '6186e0e098b8b115f0640507',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 7,
            _id: '6186e0e098b8b115f0640508',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e098b8b115f0640509',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 10,
            _id: '6186e0e098b8b115f064050a',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 12,
            _id: '6186e0e098b8b115f064050b',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 13,
            _id: '6186e0e098b8b115f064050c',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e098b8b115f064050d',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e098b8b115f064050e',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e098b8b115f064050f',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 17,
            _id: '6186e0e098b8b115f0640510',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 18,
            _id: '6186e0e098b8b115f0640511',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 19,
            _id: '6186e0e098b8b115f0640512',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 20,
            _id: '6186e0e098b8b115f0640513',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd60e65c4ef20446686b',
      opta_ID: 'e0l696f3jy9p46pjmccrp007o',
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Crystal Palace vs Newcastle United',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd55e65c4ef204465cb4',
          type: 'Main',
          _id: '6186e0e098b8b115f06404fc',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06404fd',
        },
        {
          official: '6166cd55e65c4ef204465cc7',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06404fe',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06404ff',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e098b8b115f0640541',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e098b8b115f0640542',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0e098b8b115f0640543',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 6,
            _id: '6186e0e098b8b115f0640544',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 3,
            _id: '6186e0e098b8b115f0640545',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e098b8b115f0640546',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 7,
            _id: '6186e0e098b8b115f0640547',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0e098b8b115f0640548',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 11,
            _id: '6186e0e098b8b115f0640549',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 10,
            _id: '6186e0e098b8b115f064054a',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 9,
            _id: '6186e0e098b8b115f064054b',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 12,
            _id: '6186e0e098b8b115f064054c',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 13,
            _id: '6186e0e098b8b115f064054d',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 14,
            _id: '6186e0e098b8b115f064054e',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 15,
            _id: '6186e0e098b8b115f064054f',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 16,
            _id: '6186e0e098b8b115f0640550',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 17,
            _id: '6186e0e098b8b115f0640551',
          },
          {
            squad: '6166c89be65c4ef2043df798',
            order: 18,
            _id: '6186e0e098b8b115f0640552',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 19,
            _id: '6186e0e098b8b115f0640553',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0e098b8b115f0640554',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e098b8b115f064052d',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 4,
            _id: '6186e0e098b8b115f064052e',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e098b8b115f064052f',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 6,
            _id: '6186e0e098b8b115f0640530',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 3,
            _id: '6186e0e098b8b115f0640531',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0e098b8b115f0640532',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 11,
            _id: '6186e0e098b8b115f0640533',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e098b8b115f0640534',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e098b8b115f0640535',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e098b8b115f0640536',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 10,
            _id: '6186e0e098b8b115f0640537',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e098b8b115f0640538',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 13,
            _id: '6186e0e098b8b115f0640539',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 14,
            _id: '6186e0e098b8b115f064053a',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 15,
            _id: '6186e0e098b8b115f064053b',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 16,
            _id: '6186e0e098b8b115f064053c',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 17,
            _id: '6186e0e098b8b115f064053d',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 18,
            _id: '6186e0e098b8b115f064053e',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 19,
            _id: '6186e0e098b8b115f064053f',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 20,
            _id: '6186e0e098b8b115f0640540',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd61e65c4ef204466881',
      opta_ID: 'e0hl3kplty7p648458ulbj02c',
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-23Z',
      description: 'Chelsea vs Norwich City',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Main',
          _id: '6186e0e098b8b115f0640529',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f064052a',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f064052b',
        },
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f064052c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e098b8b115f064056e',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 3,
            _id: '6186e0e098b8b115f064056f',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e098b8b115f0640570',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e098b8b115f0640571',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e098b8b115f0640572',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 4,
            _id: '6186e0e098b8b115f0640573',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 8,
            _id: '6186e0e098b8b115f0640574',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 11,
            _id: '6186e0e098b8b115f0640575',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 10,
            _id: '6186e0e098b8b115f0640576',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 7,
            _id: '6186e0e098b8b115f0640577',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e098b8b115f0640578',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e098b8b115f0640579',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 13,
            _id: '6186e0e098b8b115f064057a',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 14,
            _id: '6186e0e098b8b115f064057b',
          },
          {
            squad: '6166c85ce65c4ef2043d9c2c',
            order: 15,
            _id: '6186e0e098b8b115f064057c',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 16,
            _id: '6186e0e098b8b115f064057d',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e098b8b115f064057e',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 18,
            _id: '6186e0e098b8b115f064057f',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 19,
            _id: '6186e0e098b8b115f0640580',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 20,
            _id: '6186e0e098b8b115f0640581',
          },
        ],
        away: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e098b8b115f064055a',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e098b8b115f064055b',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 5,
            _id: '6186e0e098b8b115f064055c',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e098b8b115f064055d',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e098b8b115f064055e',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 8,
            _id: '6186e0e098b8b115f064055f',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0e098b8b115f0640560',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e098b8b115f0640561',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 9,
            _id: '6186e0e098b8b115f0640562',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 11,
            _id: '6186e0e098b8b115f0640563',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 10,
            _id: '6186e0e098b8b115f0640564',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e098b8b115f0640565',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 13,
            _id: '6186e0e098b8b115f0640566',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 14,
            _id: '6186e0e098b8b115f0640567',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 15,
            _id: '6186e0e098b8b115f0640568',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 16,
            _id: '6186e0e098b8b115f0640569',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 17,
            _id: '6186e0e098b8b115f064056a',
          },
          {
            squad: '6166c862e65c4ef2043da5ef',
            order: 18,
            _id: '6186e0e098b8b115f064056b',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 19,
            _id: '6186e0e098b8b115f064056c',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e098b8b115f064056d',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd61e65c4ef2044668aa',
      opta_ID: 'e04ffl5au5njrf8d8c7rofdhw',
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-22Z',
      description: 'Arsenal vs Aston Villa',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e098b8b115f0640556',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f0640557',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f0640558',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f0640559',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '9',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e098b8b115f064059b',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e098b8b115f064059c',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e098b8b115f064059d',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e098b8b115f064059e',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e098b8b115f064059f',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 4,
            _id: '6186e0e098b8b115f06405a0',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 11,
            _id: '6186e0e098b8b115f06405a1',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 10,
            _id: '6186e0e098b8b115f06405a2',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 8,
            _id: '6186e0e098b8b115f06405a3',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 7,
            _id: '6186e0e098b8b115f06405a4',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e098b8b115f06405a5',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e098b8b115f06405a6',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 13,
            _id: '6186e0e098b8b115f06405a7',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 14,
            _id: '6186e0e098b8b115f06405a8',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 15,
            _id: '6186e0e098b8b115f06405a9',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 16,
            _id: '6186e0e098b8b115f06405aa',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e098b8b115f06405ab',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 18,
            _id: '6186e0e098b8b115f06405ac',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 19,
            _id: '6186e0e098b8b115f06405ad',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e098b8b115f06405ae',
          },
        ],
        away: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e098b8b115f0640587',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e098b8b115f0640588',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e098b8b115f0640589',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e098b8b115f064058a',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e098b8b115f064058b',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 4,
            _id: '6186e0e098b8b115f064058c',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 8,
            _id: '6186e0e098b8b115f064058d',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 11,
            _id: '6186e0e098b8b115f064058e',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 10,
            _id: '6186e0e098b8b115f064058f',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 7,
            _id: '6186e0e098b8b115f0640590',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e098b8b115f0640591',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 12,
            _id: '6186e0e098b8b115f0640592',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 13,
            _id: '6186e0e098b8b115f0640593',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 14,
            _id: '6186e0e098b8b115f0640594',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 15,
            _id: '6186e0e098b8b115f0640595',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 16,
            _id: '6186e0e098b8b115f0640596',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 17,
            _id: '6186e0e098b8b115f0640597',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 18,
            _id: '6186e0e098b8b115f0640598',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 19,
            _id: '6186e0e098b8b115f0640599',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 20,
            _id: '6186e0e098b8b115f064059a',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      _id: '6166cd61e65c4ef20446690e',
      opta_ID: 'dz2ih569ymaibab6tckhyy2ac',
      __v: 1,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-18Z',
      description: 'Arsenal vs Crystal Palace',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e098b8b115f0640583',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f0640584',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f0640585',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f0640586',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '8',
      live_data: {
        __typename: 'Game',
        _id: '6166cd61e65c4ef20446690e',
        time: '19:00:00Z',
        description: 'Arsenal vs Crystal Palace',
        date: '2021-10-18Z',
        stadium: { __typename: 'Stadium', opta_name: 'Emirates Stadium' },
        competition: { __typename: 'Competition', name: 'Premier League' },
        opta_lineup: { __typename: 'LineupObject', home: [], away: [] },
        home_team: { __typename: 'Team', name: 'Arsenal' },
        away_team: { __typename: 'Team', name: 'Crystal Palace' },
        periods: [{ kick_off: 1634900710923, number: 1 }],
      },
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0e098b8b115f06405c8',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e098b8b115f06405c9',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 6,
            _id: '6186e0e098b8b115f06405ca',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e098b8b115f06405cb',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e098b8b115f06405cc',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 4,
            _id: '6186e0e098b8b115f06405cd',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 11,
            _id: '6186e0e098b8b115f06405ce',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 10,
            _id: '6186e0e098b8b115f06405cf',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 8,
            _id: '6186e0e098b8b115f06405d0',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 7,
            _id: '6186e0e098b8b115f06405d1',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 9,
            _id: '6186e0e098b8b115f06405d2',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 12,
            _id: '6186e0e098b8b115f06405d3',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 13,
            _id: '6186e0e098b8b115f06405d4',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e098b8b115f06405d5',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e098b8b115f06405d6',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e098b8b115f06405d7',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 17,
            _id: '6186e0e098b8b115f06405d8',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 18,
            _id: '6186e0e098b8b115f06405d9',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 19,
            _id: '6186e0e098b8b115f06405da',
          },
          {
            squad: '6166c909e65c4ef2043efb0e',
            order: 20,
            _id: '6186e0e098b8b115f06405db',
          },
        ],
        away: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e098b8b115f06405b4',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e098b8b115f06405b5',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e098b8b115f06405b6',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 5,
            _id: '6186e0e098b8b115f06405b7',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0e098b8b115f06405b8',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e098b8b115f06405b9',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 8,
            _id: '6186e0e098b8b115f06405ba',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 11,
            _id: '6186e0e098b8b115f06405bb',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 10,
            _id: '6186e0e098b8b115f06405bc',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 7,
            _id: '6186e0e098b8b115f06405bd',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e098b8b115f06405be',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 12,
            _id: '6186e0e098b8b115f06405bf',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 13,
            _id: '6186e0e098b8b115f06405c0',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 14,
            _id: '6186e0e098b8b115f06405c1',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 15,
            _id: '6186e0e098b8b115f06405c2',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 16,
            _id: '6186e0e098b8b115f06405c3',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 17,
            _id: '6186e0e098b8b115f06405c4',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 18,
            _id: '6186e0e098b8b115f06405c5',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 19,
            _id: '6186e0e098b8b115f06405c6',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e098b8b115f06405c7',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd61e65c4ef204466950',
      opta_ID: 'dzpefaomqtisefazgk3m2jztg',
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-17Z',
      description: 'Newcastle United vs Tottenham Hotspur',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0e098b8b115f06405b0',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f06405b1',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f06405b2',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f06405b3',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e198b8b115f06405f5',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e198b8b115f06405f6',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e198b8b115f06405f7',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 5,
            _id: '6186e0e198b8b115f06405f8',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e198b8b115f06405f9',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 11,
            _id: '6186e0e198b8b115f06405fa',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e198b8b115f06405fb',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e198b8b115f06405fc',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e198b8b115f06405fd',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e198b8b115f06405fe',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 9,
            _id: '6186e0e198b8b115f06405ff',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e198b8b115f0640600',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 13,
            _id: '6186e0e198b8b115f0640601',
          },
          {
            squad: '6166c8bbe65c4ef2043e32e1',
            order: 14,
            _id: '6186e0e198b8b115f0640602',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 15,
            _id: '6186e0e198b8b115f0640603',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 16,
            _id: '6186e0e198b8b115f0640604',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 17,
            _id: '6186e0e198b8b115f0640605',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 18,
            _id: '6186e0e198b8b115f0640606',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 19,
            _id: '6186e0e198b8b115f0640607',
          },
          {
            squad: '6166c8bde65c4ef2043e39f7',
            order: 20,
            _id: '6186e0e198b8b115f0640608',
          },
        ],
        away: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e198b8b115f06405e1',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e198b8b115f06405e2',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e198b8b115f06405e3',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0e198b8b115f06405e4',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 2,
            _id: '6186e0e198b8b115f06405e5',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e198b8b115f06405e6',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e198b8b115f06405e7',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 11,
            _id: '6186e0e198b8b115f06405e8',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 10,
            _id: '6186e0e198b8b115f06405e9',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e198b8b115f06405ea',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e198b8b115f06405eb',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e198b8b115f06405ec',
          },
          {
            squad: '6166c979e65c4ef2043fc062',
            order: 13,
            _id: '6186e0e198b8b115f06405ed',
          },
          {
            squad: '6166c978e65c4ef2043fbb3e',
            order: 14,
            _id: '6186e0e198b8b115f06405ee',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 15,
            _id: '6186e0e198b8b115f06405ef',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 16,
            _id: '6186e0e198b8b115f06405f0',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 17,
            _id: '6186e0e198b8b115f06405f1',
          },
          {
            squad: '6166c978e65c4ef2043fbb13',
            order: 18,
            _id: '6186e0e198b8b115f06405f2',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 19,
            _id: '6186e0e198b8b115f06405f3',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e198b8b115f06405f4',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd61e65c4ef204466998',
      opta_ID: 'dzdiqjulus4cou7vgat441xxw',
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-17Z',
      description: 'Everton vs West Ham United',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e198b8b115f06405dd',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f06405de',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06405df',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06405e0',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e198b8b115f0640622',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e198b8b115f0640623',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e198b8b115f0640624',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 6,
            _id: '6186e0e198b8b115f0640625',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e198b8b115f0640626',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 8,
            _id: '6186e0e198b8b115f0640627',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e198b8b115f0640628',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0e198b8b115f0640629',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e198b8b115f064062a',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e198b8b115f064062b',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e198b8b115f064062c',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 12,
            _id: '6186e0e198b8b115f064062d',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 13,
            _id: '6186e0e198b8b115f064062e',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 14,
            _id: '6186e0e198b8b115f064062f',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 15,
            _id: '6186e0e198b8b115f0640630',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 16,
            _id: '6186e0e198b8b115f0640631',
          },
          {
            squad: '6186b4efa4737fae26aa5754',
            order: 17,
            _id: '6186e0e198b8b115f0640632',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0e198b8b115f0640633',
          },
          {
            squad: '6166c87be65c4ef2043dc94a',
            order: 19,
            _id: '6186e0e198b8b115f0640634',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 20,
            _id: '6186e0e198b8b115f0640635',
          },
        ],
        away: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e198b8b115f064060e',
          },
          {
            squad: '6166c89be65c4ef2043df798',
            order: 4,
            _id: '6186e0e198b8b115f064060f',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 5,
            _id: '6186e0e198b8b115f0640610',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 6,
            _id: '6186e0e198b8b115f0640611',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 3,
            _id: '6186e0e198b8b115f0640612',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e198b8b115f0640613',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 11,
            _id: '6186e0e198b8b115f0640614',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 7,
            _id: '6186e0e198b8b115f0640615',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 2,
            _id: '6186e0e198b8b115f0640616',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e198b8b115f0640617',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 10,
            _id: '6186e0e198b8b115f0640618',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 12,
            _id: '6186e0e198b8b115f0640619',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 13,
            _id: '6186e0e198b8b115f064061a',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 14,
            _id: '6186e0e198b8b115f064061b',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 15,
            _id: '6186e0e198b8b115f064061c',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 16,
            _id: '6186e0e198b8b115f064061d',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 17,
            _id: '6186e0e198b8b115f064061e',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 18,
            _id: '6186e0e198b8b115f064061f',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 19,
            _id: '6186e0e198b8b115f0640620',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0e198b8b115f0640621',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd62e65c4ef2044669d7',
      opta_ID: 'dz9p6oimysif9dllowtm1ttzo',
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Brentford vs Chelsea',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e198b8b115f064060a',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f064060b',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f064060c',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f064060d',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e198b8b115f064064f',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 3,
            _id: '6186e0e198b8b115f0640650',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e198b8b115f0640651',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0e198b8b115f0640652',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e198b8b115f0640653',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 11,
            _id: '6186e0e198b8b115f0640654',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0e198b8b115f0640655',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 4,
            _id: '6186e0e198b8b115f0640656',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 7,
            _id: '6186e0e198b8b115f0640657',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 9,
            _id: '6186e0e198b8b115f0640658',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 10,
            _id: '6186e0e198b8b115f0640659',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 12,
            _id: '6186e0e198b8b115f064065a',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 13,
            _id: '6186e0e198b8b115f064065b',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 14,
            _id: '6186e0e198b8b115f064065c',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 15,
            _id: '6186e0e198b8b115f064065d',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 16,
            _id: '6186e0e198b8b115f064065e',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 17,
            _id: '6186e0e198b8b115f064065f',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 18,
            _id: '6186e0e198b8b115f0640660',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 19,
            _id: '6186e0e198b8b115f0640661',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 20,
            _id: '6186e0e198b8b115f0640662',
          },
        ],
        away: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e198b8b115f064063b',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 4,
            _id: '6186e0e198b8b115f064063c',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 5,
            _id: '6186e0e198b8b115f064063d',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 6,
            _id: '6186e0e198b8b115f064063e',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 3,
            _id: '6186e0e198b8b115f064063f',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 8,
            _id: '6186e0e198b8b115f0640640',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 2,
            _id: '6186e0e198b8b115f0640641',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 11,
            _id: '6186e0e198b8b115f0640642',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 7,
            _id: '6186e0e198b8b115f0640643',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 10,
            _id: '6186e0e198b8b115f0640644',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 9,
            _id: '6186e0e198b8b115f0640645',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e198b8b115f0640646',
          },
          {
            squad: '6166c8dfe65c4ef2043ec268',
            order: 13,
            _id: '6186e0e198b8b115f0640647',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 14,
            _id: '6186e0e198b8b115f0640648',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 15,
            _id: '6186e0e198b8b115f0640649',
          },
          {
            squad: '6166c8dee65c4ef2043ec138',
            order: 16,
            _id: '6186e0e198b8b115f064064a',
          },
          {
            squad: '6166c8dfe65c4ef2043ec339',
            order: 17,
            _id: '6186e0e198b8b115f064064b',
          },
          {
            squad: '6166c8dde65c4ef2043ec0a1',
            order: 18,
            _id: '6186e0e198b8b115f064064c',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e198b8b115f064064d',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e198b8b115f064064e',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd62e65c4ef204466a42',
      opta_ID: 'dzww3ttd4vl2fix0h68fduvx0',
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Southampton vs Leeds United',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0e198b8b115f0640637',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640638',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f0640639',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f064063a',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e198b8b115f064067c',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 4,
            _id: '6186e0e198b8b115f064067d',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e198b8b115f064067e',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 6,
            _id: '6186e0e198b8b115f064067f',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 3,
            _id: '6186e0e198b8b115f0640680',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e198b8b115f0640681',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 11,
            _id: '6186e0e198b8b115f0640682',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 8,
            _id: '6186e0e198b8b115f0640683',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e198b8b115f0640684',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e198b8b115f0640685',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 10,
            _id: '6186e0e198b8b115f0640686',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e198b8b115f0640687',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 13,
            _id: '6186e0e198b8b115f0640688',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 14,
            _id: '6186e0e198b8b115f0640689',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 15,
            _id: '6186e0e198b8b115f064068a',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 16,
            _id: '6186e0e198b8b115f064068b',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 17,
            _id: '6186e0e198b8b115f064068c',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 18,
            _id: '6186e0e198b8b115f064068d',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 19,
            _id: '6186e0e198b8b115f064068e',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 20,
            _id: '6186e0e198b8b115f064068f',
          },
        ],
        away: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e198b8b115f0640668',
          },
          {
            squad: '6166c87ee65c4ef2043dce78',
            order: 4,
            _id: '6186e0e198b8b115f0640669',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 5,
            _id: '6186e0e198b8b115f064066a',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 6,
            _id: '6186e0e198b8b115f064066b',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e198b8b115f064066c',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 8,
            _id: '6186e0e198b8b115f064066d',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 11,
            _id: '6186e0e198b8b115f064066e',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 7,
            _id: '6186e0e198b8b115f064066f',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0e198b8b115f0640670',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e198b8b115f0640671',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e198b8b115f0640672',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 12,
            _id: '6186e0e198b8b115f0640673',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 13,
            _id: '6186e0e198b8b115f0640674',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 14,
            _id: '6186e0e198b8b115f0640675',
          },
          {
            squad: '6166c87fe65c4ef2043dd07a',
            order: 15,
            _id: '6186e0e198b8b115f0640676',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 16,
            _id: '6186e0e198b8b115f0640677',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 17,
            _id: '6186e0e198b8b115f0640678',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 18,
            _id: '6186e0e198b8b115f0640679',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 19,
            _id: '6186e0e198b8b115f064067a',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 20,
            _id: '6186e0e198b8b115f064067b',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd62e65c4ef204466a8c',
      opta_ID: 'dztatvphcbii8ifi6x28ae0wk',
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Norwich City vs Brighton & Hove Albion',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Main',
          _id: '6186e0e198b8b115f0640664',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640665',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f0640666',
        },
        {
          official: '6166cd56e65c4ef204465e1d',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f0640667',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 1,
            _id: '6186e0e198b8b115f06406a9',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 3,
            _id: '6186e0e198b8b115f06406aa',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e198b8b115f06406ab',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 5,
            _id: '6186e0e198b8b115f06406ac',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 2,
            _id: '6186e0e198b8b115f06406ad',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 8,
            _id: '6186e0e198b8b115f06406ae',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e198b8b115f06406af',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 7,
            _id: '6186e0e198b8b115f06406b0',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 11,
            _id: '6186e0e198b8b115f06406b1',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 9,
            _id: '6186e0e198b8b115f06406b2',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 10,
            _id: '6186e0e198b8b115f06406b3',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9bb',
            order: 12,
            _id: '6186e0e198b8b115f06406b4',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 13,
            _id: '6186e0e198b8b115f06406b5',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 14,
            _id: '6186e0e198b8b115f06406b6',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 15,
            _id: '6186e0e198b8b115f06406b7',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 16,
            _id: '6186e0e198b8b115f06406b8',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9da',
            order: 17,
            _id: '6186e0e198b8b115f06406b9',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 18,
            _id: '6186e0e198b8b115f06406ba',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 19,
            _id: '6186e0e198b8b115f06406bb',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 20,
            _id: '6186e0e198b8b115f06406bc',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e198b8b115f0640695',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 3,
            _id: '6186e0e198b8b115f0640696',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 6,
            _id: '6186e0e198b8b115f0640697',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 5,
            _id: '6186e0e198b8b115f0640698',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e198b8b115f0640699',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e198b8b115f064069a',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 8,
            _id: '6186e0e198b8b115f064069b',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e198b8b115f064069c',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 7,
            _id: '6186e0e198b8b115f064069d',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0e198b8b115f064069e',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 10,
            _id: '6186e0e198b8b115f064069f',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e198b8b115f06406a0',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0e198b8b115f06406a1',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 14,
            _id: '6186e0e198b8b115f06406a2',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0e198b8b115f06406a3',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 16,
            _id: '6186e0e198b8b115f06406a4',
          },
          {
            squad: '6166c88ae65c4ef2043ddf0e',
            order: 17,
            _id: '6186e0e198b8b115f06406a5',
          },
          {
            squad: '6166c888e65c4ef2043ddbd3',
            order: 18,
            _id: '6186e0e198b8b115f06406a6',
          },
          {
            squad: '6166c889e65c4ef2043ddd94',
            order: 19,
            _id: '6186e0e198b8b115f06406a7',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 20,
            _id: '6186e0e198b8b115f06406a8',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd62e65c4ef204466ace',
      opta_ID: 'dzl8oalo4xcxv94shtyavkd1w',
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Manchester City vs Burnley',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e198b8b115f0640691',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640692',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f0640693',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f0640694',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e198b8b115f06406d6',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 4,
            _id: '6186e0e198b8b115f06406d7',
          },
          {
            squad: '6166c8e1e65c4ef2043ec4b0',
            order: 5,
            _id: '6186e0e198b8b115f06406d8',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 6,
            _id: '6186e0e198b8b115f06406d9',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 3,
            _id: '6186e0e198b8b115f06406da',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 8,
            _id: '6186e0e198b8b115f06406db',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 7,
            _id: '6186e0e198b8b115f06406dc',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e198b8b115f06406dd',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 9,
            _id: '6186e0e198b8b115f06406de',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 11,
            _id: '6186e0e198b8b115f06406df',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 10,
            _id: '6186e0e198b8b115f06406e0',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e198b8b115f06406e1',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 13,
            _id: '6186e0e198b8b115f06406e2',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 14,
            _id: '6186e0e198b8b115f06406e3',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 15,
            _id: '6186e0e198b8b115f06406e4',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 16,
            _id: '6186e0e198b8b115f06406e5',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 17,
            _id: '6186e0e198b8b115f06406e6',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 18,
            _id: '6186e0e198b8b115f06406e7',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 19,
            _id: '6186e0e198b8b115f06406e8',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e198b8b115f06406e9',
          },
        ],
        away: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e198b8b115f06406c2',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e198b8b115f06406c3',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e198b8b115f06406c4',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0e198b8b115f06406c5',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e198b8b115f06406c6',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 4,
            _id: '6186e0e198b8b115f06406c7',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 8,
            _id: '6186e0e198b8b115f06406c8',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 11,
            _id: '6186e0e198b8b115f06406c9',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e198b8b115f06406ca',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e198b8b115f06406cb',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0e198b8b115f06406cc',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0e198b8b115f06406cd',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0e198b8b115f06406ce',
          },
          {
            squad: '6166c8f5e65c4ef2043edf20',
            order: 14,
            _id: '6186e0e198b8b115f06406cf',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 15,
            _id: '6186e0e198b8b115f06406d0',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 16,
            _id: '6186e0e198b8b115f06406d1',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 17,
            _id: '6186e0e198b8b115f06406d2',
          },
          {
            squad: '6166c8f5e65c4ef2043edf3f',
            order: 18,
            _id: '6186e0e198b8b115f06406d3',
          },
          {
            squad: '6166c8f7e65c4ef2043ee126',
            order: 19,
            _id: '6186e0e198b8b115f06406d4',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e198b8b115f06406d5',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd62e65c4ef204466b0e',
      opta_ID: 'dzhcepg5jppr6zfqeht83q9lg',
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Leicester City vs Manchester United',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e198b8b115f06406be',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f06406bf',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06406c0',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06406c1',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e198b8b115f0640703',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e198b8b115f0640704',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 5,
            _id: '6186e0e198b8b115f0640705',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e198b8b115f0640706',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e198b8b115f0640707',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 8,
            _id: '6186e0e198b8b115f0640708',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0e198b8b115f0640709',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e198b8b115f064070a',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 9,
            _id: '6186e0e198b8b115f064070b',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 11,
            _id: '6186e0e198b8b115f064070c',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 10,
            _id: '6186e0e198b8b115f064070d',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e198b8b115f064070e',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 13,
            _id: '6186e0e198b8b115f064070f',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 14,
            _id: '6186e0e198b8b115f0640710',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 15,
            _id: '6186e0e198b8b115f0640711',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 16,
            _id: '6186e0e198b8b115f0640712',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 17,
            _id: '6186e0e198b8b115f0640713',
          },
          {
            squad: '6166c862e65c4ef2043da5ef',
            order: 18,
            _id: '6186e0e198b8b115f0640714',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 19,
            _id: '6186e0e198b8b115f0640715',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e198b8b115f0640716',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e198b8b115f06406ef',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e198b8b115f06406f0',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e198b8b115f06406f1',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e198b8b115f06406f2',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e198b8b115f06406f3',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e198b8b115f06406f4',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e198b8b115f06406f5',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e198b8b115f06406f6',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e198b8b115f06406f7',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 10,
            _id: '6186e0e198b8b115f06406f8',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 9,
            _id: '6186e0e198b8b115f06406f9',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e198b8b115f06406fa',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e198b8b115f06406fb',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 14,
            _id: '6186e0e198b8b115f06406fc',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0e198b8b115f06406fd',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 16,
            _id: '6186e0e198b8b115f06406fe',
          },
          {
            squad: '6166c980e65c4ef2043fe19f',
            order: 17,
            _id: '6186e0e198b8b115f06406ff',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 18,
            _id: '6186e0e198b8b115f0640700',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 19,
            _id: '6186e0e198b8b115f0640701',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0e198b8b115f0640702',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd63e65c4ef204466b5b',
      opta_ID: 'dz63plq5jx05n570ezf2ypvkk',
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Aston Villa vs Wolverhampton Wanderers',
      home_team: '6166c3dee65c4ef204380414',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e198b8b115f06406eb',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f06406ec',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06406ed',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06406ee',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0e198b8b115f0640730',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 4,
            _id: '6186e0e198b8b115f0640731',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e198b8b115f0640732',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 6,
            _id: '6186e0e198b8b115f0640733',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0e198b8b115f0640734',
          },
          {
            squad: '6166c970e65c4ef2043f991b',
            order: 2,
            _id: '6186e0e198b8b115f0640735',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 11,
            _id: '6186e0e198b8b115f0640736',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 10,
            _id: '6186e0e198b8b115f0640737',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 8,
            _id: '6186e0e198b8b115f0640738',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 7,
            _id: '6186e0e198b8b115f0640739',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 9,
            _id: '6186e0e198b8b115f064073a',
          },
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 12,
            _id: '6186e0e198b8b115f064073b',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 13,
            _id: '6186e0e198b8b115f064073c',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 14,
            _id: '6186e0e198b8b115f064073d',
          },
          {
            squad: '6166c971e65c4ef2043f9a14',
            order: 15,
            _id: '6186e0e198b8b115f064073e',
          },
          {
            squad: '6166c973e65c4ef2043fa40f',
            order: 16,
            _id: '6186e0e198b8b115f064073f',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 17,
            _id: '6186e0e198b8b115f0640740',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 18,
            _id: '6186e0e198b8b115f0640741',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 19,
            _id: '6186e0e198b8b115f0640742',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 20,
            _id: '6186e0e198b8b115f0640743',
          },
        ],
        away: [
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 1,
            _id: '6186e0e198b8b115f064071c',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e198b8b115f064071d',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e198b8b115f064071e',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e198b8b115f064071f',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e198b8b115f0640720',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 8,
            _id: '6186e0e198b8b115f0640721',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 4,
            _id: '6186e0e198b8b115f0640722',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 7,
            _id: '6186e0e198b8b115f0640723',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e198b8b115f0640724',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 9,
            _id: '6186e0e198b8b115f0640725',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e198b8b115f0640726',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 12,
            _id: '6186e0e198b8b115f0640727',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 13,
            _id: '6186e0e198b8b115f0640728',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 14,
            _id: '6186e0e198b8b115f0640729',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 15,
            _id: '6186e0e198b8b115f064072a',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 16,
            _id: '6186e0e198b8b115f064072b',
          },
          {
            squad: '6166c8e8e65c4ef2043ece67',
            order: 17,
            _id: '6186e0e198b8b115f064072c',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 18,
            _id: '6186e0e198b8b115f064072d',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 19,
            _id: '6186e0e198b8b115f064072e',
          },
          {
            squad: '6166c8e9e65c4ef2043ecede',
            order: 20,
            _id: '6186e0e198b8b115f064072f',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd63e65c4ef204466bae',
      opta_ID: 'e00la4id6j8eh0hkzjbo5f6z8',
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-16Z',
      description: 'Watford vs Liverpool',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e198b8b115f0640718',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640719',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f064071a',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f064071b',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '8',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e198b8b115f064075d',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e198b8b115f064075e',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e198b8b115f064075f',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e198b8b115f0640760',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 2,
            _id: '6186e0e198b8b115f0640761',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 8,
            _id: '6186e0e198b8b115f0640762',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 4,
            _id: '6186e0e198b8b115f0640763',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 7,
            _id: '6186e0e198b8b115f0640764',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e198b8b115f0640765',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e198b8b115f0640766',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e198b8b115f0640767',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 12,
            _id: '6186e0e198b8b115f0640768',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 13,
            _id: '6186e0e198b8b115f0640769',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 14,
            _id: '6186e0e198b8b115f064076a',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 15,
            _id: '6186e0e198b8b115f064076b',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 16,
            _id: '6186e0e198b8b115f064076c',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 17,
            _id: '6186e0e198b8b115f064076d',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 18,
            _id: '6186e0e198b8b115f064076e',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 19,
            _id: '6186e0e198b8b115f064076f',
          },
          {
            squad: '6166c8e9e65c4ef2043ecede',
            order: 20,
            _id: '6186e0e198b8b115f0640770',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e198b8b115f0640749',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e198b8b115f064074a',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e198b8b115f064074b',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e198b8b115f064074c',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e198b8b115f064074d',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 8,
            _id: '6186e0e198b8b115f064074e',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e198b8b115f064074f',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 7,
            _id: '6186e0e198b8b115f0640750',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 11,
            _id: '6186e0e198b8b115f0640751',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 9,
            _id: '6186e0e198b8b115f0640752',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e198b8b115f0640753',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e198b8b115f0640754',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9bb',
            order: 13,
            _id: '6186e0e198b8b115f0640755',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 14,
            _id: '6186e0e198b8b115f0640756',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 15,
            _id: '6186e0e198b8b115f0640757',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 16,
            _id: '6186e0e198b8b115f0640758',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 17,
            _id: '6186e0e198b8b115f0640759',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 18,
            _id: '6186e0e198b8b115f064075a',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0e198b8b115f064075b',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 20,
            _id: '6186e0e198b8b115f064075c',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd63e65c4ef204466be0',
      opta_ID: 'dyhrw4hnrinl0ikwwbkd80kyc',
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-03Z',
      description: 'Liverpool vs Manchester City',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e198b8b115f0640745',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640746',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f0640747',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f0640748',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e198b8b115f0640789',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e198b8b115f064078a',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e198b8b115f064078b',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0e198b8b115f064078c',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e198b8b115f064078d',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e198b8b115f064078e',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e198b8b115f064078f',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 11,
            _id: '6186e0e198b8b115f0640790',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 10,
            _id: '6186e0e198b8b115f0640791',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e198b8b115f0640792',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e198b8b115f0640793',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e198b8b115f0640794',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 13,
            _id: '6186e0e198b8b115f0640795',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 14,
            _id: '6186e0e198b8b115f0640796',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 15,
            _id: '6186e0e198b8b115f0640797',
          },
          {
            squad: '6166c979e65c4ef2043fbfe8',
            order: 16,
            _id: '6186e0e198b8b115f0640798',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 17,
            _id: '6186e0e198b8b115f0640799',
          },
          {
            squad: '6166c978e65c4ef2043fbb13',
            order: 18,
            _id: '6186e0e198b8b115f064079a',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 19,
            _id: '6186e0e198b8b115f064079b',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e198b8b115f064079c',
          },
        ],
        away: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e198b8b115f0640776',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e198b8b115f0640777',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e198b8b115f0640778',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 6,
            _id: '6186e0e198b8b115f0640779',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e198b8b115f064077a',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 8,
            _id: '6186e0e198b8b115f064077b',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e198b8b115f064077c',
          },
          {
            squad: '6166c87be65c4ef2043dc970',
            order: 7,
            _id: '6186e0e198b8b115f064077d',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e198b8b115f064077e',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e198b8b115f064077f',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e198b8b115f0640780',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 12,
            _id: '6186e0e198b8b115f0640781',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 13,
            _id: '6186e0e198b8b115f0640782',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 14,
            _id: '6186e0e198b8b115f0640783',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 15,
            _id: '6186e0e198b8b115f0640784',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 16,
            _id: '6186e0e198b8b115f0640785',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 17,
            _id: '6186e0e198b8b115f0640786',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0e198b8b115f0640787',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 19,
            _id: '6186e0e198b8b115f0640788',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd63e65c4ef204466c11',
      opta_ID: 'dyuy2wv1g803l98pyah4s72tw',
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-03Z',
      description: 'West Ham United vs Brentford',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Main',
          _id: '6186e0e198b8b115f0640772',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f0640773',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f0640774',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f0640775',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e198b8b115f06407b6',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e198b8b115f06407b7',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e198b8b115f06407b8',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 5,
            _id: '6186e0e198b8b115f06407b9',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0e198b8b115f06407ba',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e198b8b115f06407bb',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 8,
            _id: '6186e0e198b8b115f06407bc',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 11,
            _id: '6186e0e198b8b115f06407bd',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 10,
            _id: '6186e0e198b8b115f06407be',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 7,
            _id: '6186e0e198b8b115f06407bf',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e198b8b115f06407c0',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 12,
            _id: '6186e0e198b8b115f06407c1',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e198b8b115f06407c2',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e198b8b115f06407c3',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e198b8b115f06407c4',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 16,
            _id: '6186e0e198b8b115f06407c5',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 17,
            _id: '6186e0e198b8b115f06407c6',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 18,
            _id: '6186e0e198b8b115f06407c7',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 19,
            _id: '6186e0e198b8b115f06407c8',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e198b8b115f06407c9',
          },
        ],
        away: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e198b8b115f06407a2',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e198b8b115f06407a3',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 5,
            _id: '6186e0e198b8b115f06407a4',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e198b8b115f06407a5',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e198b8b115f06407a6',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 8,
            _id: '6186e0e198b8b115f06407a7',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 11,
            _id: '6186e0e198b8b115f06407a8',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0e198b8b115f06407a9',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e198b8b115f06407aa',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 9,
            _id: '6186e0e198b8b115f06407ab',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 10,
            _id: '6186e0e198b8b115f06407ac',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e198b8b115f06407ad',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 13,
            _id: '6186e0e198b8b115f06407ae',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 14,
            _id: '6186e0e198b8b115f06407af',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 15,
            _id: '6186e0e198b8b115f06407b0',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 16,
            _id: '6186e0e198b8b115f06407b1',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 17,
            _id: '6186e0e198b8b115f06407b2',
          },
          {
            squad: '6166c860e65c4ef2043da3df',
            order: 18,
            _id: '6186e0e198b8b115f06407b3',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 19,
            _id: '6186e0e198b8b115f06407b4',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e198b8b115f06407b5',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd64e65c4ef204466c42',
      opta_ID: 'dyr44cdgcgwb10t27r7q2nf2s',
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-03Z',
      description: 'Tottenham Hotspur vs Aston Villa',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Main',
          _id: '6186e0e198b8b115f064079e',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f064079f',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06407a0',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06407a1',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e198b8b115f06407e3',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e198b8b115f06407e4',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e198b8b115f06407e5',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e198b8b115f06407e6',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e198b8b115f06407e7',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e198b8b115f06407e8',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 4,
            _id: '6186e0e198b8b115f06407e9',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e198b8b115f06407ea',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 11,
            _id: '6186e0e198b8b115f06407eb',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 9,
            _id: '6186e0e198b8b115f06407ec',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 10,
            _id: '6186e0e198b8b115f06407ed',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 12,
            _id: '6186e0e198b8b115f06407ee',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0e198b8b115f06407ef',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 14,
            _id: '6186e0e198b8b115f06407f0',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 15,
            _id: '6186e0e198b8b115f06407f1',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 16,
            _id: '6186e0e198b8b115f06407f2',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 17,
            _id: '6186e0e198b8b115f06407f3',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 18,
            _id: '6186e0e198b8b115f06407f4',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 19,
            _id: '6186e0e198b8b115f06407f5',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 20,
            _id: '6186e0e198b8b115f06407f6',
          },
        ],
        away: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e198b8b115f06407cf',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 3,
            _id: '6186e0e198b8b115f06407d0',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 6,
            _id: '6186e0e198b8b115f06407d1',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 5,
            _id: '6186e0e198b8b115f06407d2',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 2,
            _id: '6186e0e198b8b115f06407d3',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e198b8b115f06407d4',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 8,
            _id: '6186e0e198b8b115f06407d5',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 4,
            _id: '6186e0e198b8b115f06407d6',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 7,
            _id: '6186e0e198b8b115f06407d7',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e198b8b115f06407d8',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 10,
            _id: '6186e0e198b8b115f06407d9',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e198b8b115f06407da',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 13,
            _id: '6186e0e198b8b115f06407db',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 14,
            _id: '6186e0e198b8b115f06407dc',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 15,
            _id: '6186e0e198b8b115f06407dd',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 16,
            _id: '6186e0e198b8b115f06407de',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 17,
            _id: '6186e0e198b8b115f06407df',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 18,
            _id: '6186e0e198b8b115f06407e0',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 19,
            _id: '6186e0e198b8b115f06407e1',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e198b8b115f06407e2',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd64e65c4ef204466c70',
      opta_ID: 'dyag4jakn9znul0ncluad4b2s',
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-03Z',
      description: 'Crystal Palace vs Leicester City',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e198b8b115f06407cb',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f06407cc',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06407cd',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06407ce',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e198b8b115f0640810',
          },
          {
            squad: '6166c87ee65c4ef2043dce78',
            order: 4,
            _id: '6186e0e198b8b115f0640811',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 5,
            _id: '6186e0e198b8b115f0640812',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 6,
            _id: '6186e0e198b8b115f0640813',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e198b8b115f0640814',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 8,
            _id: '6186e0e198b8b115f0640815',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 11,
            _id: '6186e0e198b8b115f0640816',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 7,
            _id: '6186e0e198b8b115f0640817',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0e198b8b115f0640818',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e198b8b115f0640819',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e198b8b115f064081a',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 12,
            _id: '6186e0e198b8b115f064081b',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 13,
            _id: '6186e0e198b8b115f064081c',
          },
          {
            squad: '6166c87fe65c4ef2043dd07a',
            order: 14,
            _id: '6186e0e198b8b115f064081d',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 15,
            _id: '6186e0e198b8b115f064081e',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 16,
            _id: '6186e0e198b8b115f064081f',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 17,
            _id: '6186e0e198b8b115f0640820',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 18,
            _id: '6186e0e198b8b115f0640821',
          },
          {
            squad: '6166c87fe65c4ef2043dcf54',
            order: 19,
            _id: '6186e0e198b8b115f0640822',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 20,
            _id: '6186e0e198b8b115f0640823',
          },
        ],
        away: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e198b8b115f06407fc',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e198b8b115f06407fd',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e198b8b115f06407fe',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e198b8b115f06407ff',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e198b8b115f0640800',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 4,
            _id: '6186e0e198b8b115f0640801',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 8,
            _id: '6186e0e198b8b115f0640802',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 11,
            _id: '6186e0e198b8b115f0640803',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 10,
            _id: '6186e0e198b8b115f0640804',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 7,
            _id: '6186e0e198b8b115f0640805',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e198b8b115f0640806',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e198b8b115f0640807',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 13,
            _id: '6186e0e198b8b115f0640808',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 14,
            _id: '6186e0e198b8b115f0640809',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 15,
            _id: '6186e0e198b8b115f064080a',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 16,
            _id: '6186e0e198b8b115f064080b',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e198b8b115f064080c',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 18,
            _id: '6186e0e198b8b115f064080d',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 19,
            _id: '6186e0e198b8b115f064080e',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e198b8b115f064080f',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd64e65c4ef204466c93',
      opta_ID: 'dxzhgtcb4fdq91ckb2jil3aqc',
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Brighton & Hove Albion vs Arsenal',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e198b8b115f06407f8',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e198b8b115f06407f9',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e198b8b115f06407fa',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e198b8b115f06407fb',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e298b8b115f064083d',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e298b8b115f064083e',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e298b8b115f064083f',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e298b8b115f0640840',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e298b8b115f0640841',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e298b8b115f0640842',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e298b8b115f0640843',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e298b8b115f0640844',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 11,
            _id: '6186e0e298b8b115f0640845',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e298b8b115f0640846',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e298b8b115f0640847',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e298b8b115f0640848',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e298b8b115f0640849',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 14,
            _id: '6186e0e298b8b115f064084a',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 15,
            _id: '6186e0e298b8b115f064084b',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 16,
            _id: '6186e0e298b8b115f064084c',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 17,
            _id: '6186e0e298b8b115f064084d',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 18,
            _id: '6186e0e298b8b115f064084e',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 19,
            _id: '6186e0e298b8b115f064084f',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0e298b8b115f0640850',
          },
        ],
        away: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0e298b8b115f0640829',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e298b8b115f064082a',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 6,
            _id: '6186e0e298b8b115f064082b',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 5,
            _id: '6186e0e298b8b115f064082c',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e298b8b115f064082d',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 4,
            _id: '6186e0e298b8b115f064082e',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 11,
            _id: '6186e0e298b8b115f064082f',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 10,
            _id: '6186e0e298b8b115f0640830',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 8,
            _id: '6186e0e298b8b115f0640831',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 7,
            _id: '6186e0e298b8b115f0640832',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e298b8b115f0640833',
          },
          {
            squad: '6166c90ae65c4ef2043efc37',
            order: 12,
            _id: '6186e0e298b8b115f0640834',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 13,
            _id: '6186e0e298b8b115f0640835',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e298b8b115f0640836',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e298b8b115f0640837',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e298b8b115f0640838',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 17,
            _id: '6186e0e298b8b115f0640839',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 18,
            _id: '6186e0e298b8b115f064083a',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 19,
            _id: '6186e0e298b8b115f064083b',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 20,
            _id: '6186e0e298b8b115f064083c',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd64e65c4ef204466cc9',
      opta_ID: 'dyyl2a47fn74iqbny3k274c9g',
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Wolverhampton Wanderers vs Newcastle United',
      home_team: '6166c3dee65c4ef204380695',
      officials: [
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Main',
          _id: '6186e0e298b8b115f0640825',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640826',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640827',
        },
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640828',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e298b8b115f064086a',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 3,
            _id: '6186e0e298b8b115f064086b',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e298b8b115f064086c',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 5,
            _id: '6186e0e298b8b115f064086d',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 2,
            _id: '6186e0e298b8b115f064086e',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 4,
            _id: '6186e0e298b8b115f064086f',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 11,
            _id: '6186e0e298b8b115f0640870',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 10,
            _id: '6186e0e298b8b115f0640871',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 8,
            _id: '6186e0e298b8b115f0640872',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e298b8b115f0640873',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 9,
            _id: '6186e0e298b8b115f0640874',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e298b8b115f0640875',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 13,
            _id: '6186e0e298b8b115f0640876',
          },
          {
            squad: '6166c8dee65c4ef2043ec138',
            order: 14,
            _id: '6186e0e298b8b115f0640877',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 15,
            _id: '6186e0e298b8b115f0640878',
          },
          {
            squad: '6166c8dfe65c4ef2043ec339',
            order: 16,
            _id: '6186e0e298b8b115f0640879',
          },
          {
            squad: '6166c8dde65c4ef2043ec0a1',
            order: 17,
            _id: '6186e0e298b8b115f064087a',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 18,
            _id: '6186e0e298b8b115f064087b',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 19,
            _id: '6186e0e298b8b115f064087c',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e298b8b115f064087d',
          },
        ],
        away: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0e298b8b115f0640856',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 3,
            _id: '6186e0e298b8b115f0640857',
          },
          {
            squad: '6166c972e65c4ef2043f9e69',
            order: 6,
            _id: '6186e0e298b8b115f0640858',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e298b8b115f0640859',
          },
          {
            squad: '6166c970e65c4ef2043f991b',
            order: 2,
            _id: '6186e0e298b8b115f064085a',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 4,
            _id: '6186e0e298b8b115f064085b',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 8,
            _id: '6186e0e298b8b115f064085c',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 11,
            _id: '6186e0e298b8b115f064085d',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 10,
            _id: '6186e0e298b8b115f064085e',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e298b8b115f064085f',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e298b8b115f0640860',
          },
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 12,
            _id: '6186e0e298b8b115f0640861',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 13,
            _id: '6186e0e298b8b115f0640862',
          },
          {
            squad: '6166c971e65c4ef2043f9a14',
            order: 14,
            _id: '6186e0e298b8b115f0640863',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 15,
            _id: '6186e0e298b8b115f0640864',
          },
          {
            squad: '6166c973e65c4ef2043fa40f',
            order: 16,
            _id: '6186e0e298b8b115f0640865',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 17,
            _id: '6186e0e298b8b115f0640866',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 18,
            _id: '6186e0e298b8b115f0640867',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 19,
            _id: '6186e0e298b8b115f0640868',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 20,
            _id: '6186e0e298b8b115f0640869',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd65e65c4ef204466d0b',
      opta_ID: 'dye3wxuexle6herzeri81cufo',
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Leeds United vs Watford',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Main',
          _id: '6186e0e298b8b115f0640852',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640853',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640854',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640855',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e298b8b115f0640897',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e298b8b115f0640898',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0e298b8b115f0640899',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 6,
            _id: '6186e0e298b8b115f064089a',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 3,
            _id: '6186e0e298b8b115f064089b',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e298b8b115f064089c',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 7,
            _id: '6186e0e298b8b115f064089d',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 2,
            _id: '6186e0e298b8b115f064089e',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 11,
            _id: '6186e0e298b8b115f064089f',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 10,
            _id: '6186e0e298b8b115f06408a0',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e298b8b115f06408a1',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 12,
            _id: '6186e0e298b8b115f06408a2',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 13,
            _id: '6186e0e298b8b115f06408a3',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 14,
            _id: '6186e0e298b8b115f06408a4',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 15,
            _id: '6186e0e298b8b115f06408a5',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 16,
            _id: '6186e0e298b8b115f06408a6',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 17,
            _id: '6186e0e298b8b115f06408a7',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 18,
            _id: '6186e0e298b8b115f06408a8',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 19,
            _id: '6186e0e298b8b115f06408a9',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0e298b8b115f06408aa',
          },
        ],
        away: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e298b8b115f0640883',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0e298b8b115f0640884',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e298b8b115f0640885',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0e298b8b115f0640886',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e298b8b115f0640887',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 11,
            _id: '6186e0e298b8b115f0640888',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0e298b8b115f0640889',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 4,
            _id: '6186e0e298b8b115f064088a',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 7,
            _id: '6186e0e298b8b115f064088b',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 9,
            _id: '6186e0e298b8b115f064088c',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 10,
            _id: '6186e0e298b8b115f064088d',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 12,
            _id: '6186e0e298b8b115f064088e',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 13,
            _id: '6186e0e298b8b115f064088f',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 14,
            _id: '6186e0e298b8b115f0640890',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 15,
            _id: '6186e0e298b8b115f0640891',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 16,
            _id: '6186e0e298b8b115f0640892',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 17,
            _id: '6186e0e298b8b115f0640893',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 18,
            _id: '6186e0e298b8b115f0640894',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 19,
            _id: '6186e0e298b8b115f0640895',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 20,
            _id: '6186e0e298b8b115f0640896',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd65e65c4ef204466d4f',
      opta_ID: 'dy6rqhxzwljyqfcno6td9py50',
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Chelsea vs Southampton',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e298b8b115f064087f',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640880',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640881',
        },
        {
          official: '6166cd65e65c4ef204466d40',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640882',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e298b8b115f06408c4',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e298b8b115f06408c5',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 6,
            _id: '6186e0e298b8b115f06408c6',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 5,
            _id: '6186e0e298b8b115f06408c7',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e298b8b115f06408c8',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e298b8b115f06408c9',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0e298b8b115f06408ca',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e298b8b115f06408cb',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 7,
            _id: '6186e0e298b8b115f06408cc',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0e298b8b115f06408cd',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 10,
            _id: '6186e0e298b8b115f06408ce',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e298b8b115f06408cf',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0e298b8b115f06408d0',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 14,
            _id: '6186e0e298b8b115f06408d1',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 15,
            _id: '6186e0e298b8b115f06408d2',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 16,
            _id: '6186e0e298b8b115f06408d3',
          },
          {
            squad: '6166c88ae65c4ef2043ddf0e',
            order: 17,
            _id: '6186e0e298b8b115f06408d4',
          },
          {
            squad: '6166c888e65c4ef2043ddbd3',
            order: 18,
            _id: '6186e0e298b8b115f06408d5',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 19,
            _id: '6186e0e298b8b115f06408d6',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 20,
            _id: '6186e0e298b8b115f06408d7',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e298b8b115f06408b0',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 4,
            _id: '6186e0e298b8b115f06408b1',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e298b8b115f06408b2',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 6,
            _id: '6186e0e298b8b115f06408b3',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 3,
            _id: '6186e0e298b8b115f06408b4',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e298b8b115f06408b5',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 11,
            _id: '6186e0e298b8b115f06408b6',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 8,
            _id: '6186e0e298b8b115f06408b7',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e298b8b115f06408b8',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e298b8b115f06408b9',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 10,
            _id: '6186e0e298b8b115f06408ba',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e298b8b115f06408bb',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 13,
            _id: '6186e0e298b8b115f06408bc',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 14,
            _id: '6186e0e298b8b115f06408bd',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 15,
            _id: '6186e0e298b8b115f06408be',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 16,
            _id: '6186e0e298b8b115f06408bf',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 17,
            _id: '6186e0e298b8b115f06408c0',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 18,
            _id: '6186e0e298b8b115f06408c1',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 19,
            _id: '6186e0e298b8b115f06408c2',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 20,
            _id: '6186e0e298b8b115f06408c3',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd65e65c4ef204466d81',
      opta_ID: 'dy2wd11fi3out39fxh2nzkkyc',
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Burnley vs Norwich City',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Main',
          _id: '6186e0e298b8b115f06408ac',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f06408ad',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f06408ae',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f06408af',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '7',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e298b8b115f06408f1',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e298b8b115f06408f2',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 6,
            _id: '6186e0e298b8b115f06408f3',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0e298b8b115f06408f4',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e298b8b115f06408f5',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e298b8b115f06408f6',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 8,
            _id: '6186e0e298b8b115f06408f7',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 11,
            _id: '6186e0e298b8b115f06408f8',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e298b8b115f06408f9',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e298b8b115f06408fa',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0c6',
            order: 9,
            _id: '6186e0e298b8b115f06408fb',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0e298b8b115f06408fc',
          },
          {
            squad: '6166c8f5e65c4ef2043edf20',
            order: 13,
            _id: '6186e0e298b8b115f06408fd',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 14,
            _id: '6186e0e298b8b115f06408fe',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 15,
            _id: '6186e0e298b8b115f06408ff',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 16,
            _id: '6186e0e298b8b115f0640900',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 17,
            _id: '6186e0e298b8b115f0640901',
          },
          {
            squad: '6166c8f7e65c4ef2043ee126',
            order: 18,
            _id: '6186e0e298b8b115f0640902',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 19,
            _id: '6186e0e298b8b115f0640903',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e298b8b115f0640904',
          },
        ],
        away: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e298b8b115f06408dd',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e298b8b115f06408de',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e298b8b115f06408df',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 5,
            _id: '6186e0e298b8b115f06408e0',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 2,
            _id: '6186e0e298b8b115f06408e1',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 11,
            _id: '6186e0e298b8b115f06408e2',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e298b8b115f06408e3',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e298b8b115f06408e4',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e298b8b115f06408e5',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e298b8b115f06408e6',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 9,
            _id: '6186e0e298b8b115f06408e7',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e298b8b115f06408e8',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 13,
            _id: '6186e0e298b8b115f06408e9',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 14,
            _id: '6186e0e298b8b115f06408ea',
          },
          {
            squad: '6166c8bbe65c4ef2043e32e1',
            order: 15,
            _id: '6186e0e298b8b115f06408eb',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 16,
            _id: '6186e0e298b8b115f06408ec',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 17,
            _id: '6186e0e298b8b115f06408ed',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 18,
            _id: '6186e0e298b8b115f06408ee',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 19,
            _id: '6186e0e298b8b115f06408ef',
          },
          {
            squad: '6166c8bbe65c4ef2043e315b',
            order: 20,
            _id: '6186e0e298b8b115f06408f0',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6166cd65e65c4ef204466da6',
      opta_ID: 'dyn7kxgchlfwindndr948cges',
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-02Z',
      description: 'Manchester United vs Everton',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e298b8b115f06408d9',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f06408da',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f06408db',
        },
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f06408dc',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '7',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0edf',
      opta_ID: 'ew92044hbj98z55l8rxc6eqz8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Norwich City vs Tottenham Hotspur',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0ef4',
      opta_ID: 'ew3gjwasqfowc0cyimi3akzdg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Manchester City vs Aston Villa',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0efe',
      opta_ID: 'ew00782ij811c6pg9edxv5dec',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Liverpool vs Wolverhampton Wanderers',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0f09',
      opta_ID: 'evwer79avfuxtegru4k4hr2fo',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Leicester City vs Southampton',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0f15',
      opta_ID: 'evsvjnd27sxfxf6g2jnb36j9w',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Crystal Palace vs Manchester United',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0f2d',
      opta_ID: 'evp5zum2cjlf9mx6bqf23bh1w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Chelsea vs Watford',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0f39',
      opta_ID: 'evlk4ksvhlujhumcun9m3ixp0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Burnley vs Newcastle United',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0f7c',
      opta_ID: 'evhwmkxe35cpehjdezsmv3rpw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Brighton & Hove Albion vs West Ham United',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0fbe',
      opta_ID: 'evehu5km9odigiudd8tfxhp1w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Brentford vs Leeds United',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec0ffc',
      opta_ID: 'evazyz74jbbdix2ck2om4a2ac',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-22Z',
      description: 'Arsenal vs Everton',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '38',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec103d',
      opta_ID: 'ev7jd7vewl7wd97ltkvkj74lw',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Wolverhampton Wanderers vs Norwich City',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec107d',
      opta_ID: 'ev43wljl3ipbfh804sg1iun10',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'West Ham United vs Manchester City',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec10b4',
      opta_ID: 'ev0jmmdhugdkadi5jzxxgjbpw',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Watford vs Leicester City',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec10ea',
      opta_ID: 'euwszzmn1g1do9jdtcmuj5xxw',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Tottenham Hotspur vs Burnley',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec1133',
      opta_ID: 'eut6muannjkpg3ujhhco6syz8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Southampton vs Liverpool',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec1171',
      opta_ID: 'eupkif983hd4c7xzwrux8tgr8',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Newcastle United vs Arsenal',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec11b1',
      opta_ID: 'eum5fqcg17y99tjf4k8ykp5w4',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Manchester United vs Chelsea',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec11fc',
      opta_ID: 'eugc7iqlmazpi8v38kq7l69ec',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Leeds United vs Brighton & Hove Albion',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec123a',
      opta_ID: 'eucs0shuzz4dw979hfv0krehg',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Everton vs Brentford',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec127e',
      opta_ID: 'eu911qj08auitsvi0c9090rv8',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-15Z',
      description: 'Aston Villa vs Crystal Palace',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '37',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec12bd',
      opta_ID: 'eu5afddt4jn8hv7xk9v9w3sb8',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Norwich City vs West Ham United',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec12fe',
      opta_ID: 'eu1g45kc1lotthw7xjtz648ic',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Manchester City vs Newcastle United',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec133b',
      opta_ID: 'etxsf4f5g7emkxjc3l64exel0',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Liverpool vs Tottenham Hotspur',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec135a',
      opta_ID: 'ettyrum48hnu62tdatuu1drmc',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Leicester City vs Everton',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec136c',
      opta_ID: 'etq9mvad47vuzl6grv7b9xjis',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Crystal Palace vs Watford',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d5a4737fae26ec137b',
      opta_ID: 'etmdvs9uvuvlm250pu34gg3kk',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Chelsea vs Wolverhampton Wanderers',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1385',
      opta_ID: 'eti61j95amqm9oyzw6bbcp9uc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Burnley vs Aston Villa',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1392',
      opta_ID: 'ete2yuumm2wge6x3651f8i0b8',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Brighton & Hove Albion vs Manchester United',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec139f',
      opta_ID: 'et9nxzgih3v9n609o5o35tfro',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Brentford vs Southampton',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec13c5',
      opta_ID: 'et5dvt5ic5yw9ll73myns9k44',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-05-07Z',
      description: 'Arsenal vs Leeds United',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '36',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec13d0',
      opta_ID: 'et07qjxj8ssevrr7u8oxqqdqs',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Wolverhampton Wanderers vs Brighton & Hove Albion',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec13da',
      opta_ID: 'esw7k83xn1ro7pbgtocav76z8',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'West Ham United vs Arsenal',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec13e4',
      opta_ID: 'esslirjgwmgsa8zy4parafw2c',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Watford vs Burnley',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec13fa',
      opta_ID: 'esp208wturxz7y92i42x6xc7o',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Tottenham Hotspur vs Leicester City',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec141f',
      opta_ID: 'esjlwktg60ry3iidi5appqwwk',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Southampton vs Crystal Palace',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec142b',
      opta_ID: 'esfwer4bou8yq85lqfq1q1z4k',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Newcastle United vs Liverpool',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1446',
      opta_ID: 'esca2re70ddie5tb0f4duc1sk',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Manchester United vs Brentford',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1452',
      opta_ID: 'es8m6eqlxwcx6c8z025a5r9jo',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Leeds United vs Manchester City',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec145d',
      opta_ID: 'es511pocsykde2f8bqlaimsyc',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Everton vs Chelsea',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1476',
      opta_ID: 'es1d0o8v46k3wzis3s6dww8b8',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-30Z',
      description: 'Aston Villa vs Norwich City',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '35',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec14ae',
      opta_ID: 'erxfm722tonsr4nfrte4am6tw',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Norwich City vs Newcastle United',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec14f5',
      opta_ID: 'ertpadhwpw8hv23jogp6srr4k',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Manchester City vs Watford',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1536',
      opta_ID: 'erpybu3brufat2rub0qurs0t0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Liverpool vs Everton',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1570',
      opta_ID: 'ermbxprm0b2dq8hde6sqmgwes',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Leicester City vs Aston Villa',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec15a5',
      opta_ID: 'erij5dqy613m3puwouxy5r384',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Crystal Palace vs Leeds United',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec15e8',
      opta_ID: 'eret2ftd24049kljejnz1n85w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Chelsea vs West Ham United',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec161e',
      opta_ID: 'erb804ycphtxi9167mds8kcus',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Burnley vs Wolverhampton Wanderers',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec165e',
      opta_ID: 'er7peaudvodjcd7sbzqq5x5as',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Brighton & Hove Albion vs Southampton',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec16a4',
      opta_ID: 'er43r96u2vmhpms3u2uptv3tg',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Brentford vs Tottenham Hotspur',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec16ea',
      opta_ID: 'er0hk4uqcifoo8iucjpfizv2s',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-23Z',
      description: 'Arsenal vs Manchester United',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '34',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1726',
      opta_ID: 'equkppy5e29uu3o1q7ll0sges',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Wolverhampton Wanderers vs Manchester City',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec176b',
      opta_ID: 'eqr2pqj4i65vapqxgqq4pbqxg',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'West Ham United vs Burnley',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec17a0',
      opta_ID: 'eqnitmfl7cb1c3o60cbtwn0gk',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Watford vs Brentford',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec17db',
      opta_ID: 'eqjwvdapex8mbdmj06gtfeexg',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Tottenham Hotspur vs Brighton & Hove Albion',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec17eb',
      opta_ID: 'eqgap8dp445h5x1sfa45hl91w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Southampton vs Arsenal',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1821',
      opta_ID: 'eqclmd0hi11u6bqplmm25kbv8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Newcastle United vs Leicester City',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec184b',
      opta_ID: 'eq8xt9xan6at1huqwfgwd1w5w',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Manchester United vs Norwich City',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec186d',
      opta_ID: 'eq5eok0pqzm4gst0m1n4gkb9w',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Leeds United vs Chelsea',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d6a4737fae26ec1893',
      opta_ID: 'eq1qkkbxrjavbwuzo6ucrni1g',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Everton vs Crystal Palace',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec18cd',
      opta_ID: 'epy77rlqz8p2jtgcgido6sj6c',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-16Z',
      description: 'Aston Villa vs Liverpool',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '33',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec18d8',
      opta_ID: 'epukk29djg8zujkxg9a0f92xg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Watford vs Leeds United',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec18e8',
      opta_ID: 'epr20fb648ikmy73pgrhyun10',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Southampton vs Chelsea',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec18f4',
      opta_ID: 'epnhl68q3phz0lnovedtbz2fo',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Norwich City vs Burnley',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1900',
      opta_ID: 'epjq8bjt6ddzgjms23dqt7gus',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Newcastle United vs Wolverhampton Wanderers',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec190a',
      opta_ID: 'epg572vr3t5obarrvnqlzw7pw',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Manchester City vs Liverpool',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1915',
      opta_ID: 'epce4b4q3gsdu9pirxcqjxy50',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Leicester City vs Crystal Palace',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec192b',
      opta_ID: 'ep8ojskahwhlkq2xf297rh79w',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Everton vs Manchester United',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1936',
      opta_ID: 'ep31wj1chn6443glgaimdch04',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Brentford vs West Ham United',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1941',
      opta_ID: 'eoz38vz2qu8mf7wa0k78ja784',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Aston Villa vs Tottenham Hotspur',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec194b',
      opta_ID: 'eovex8tqshfac0hntjg49xfkk',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-09Z',
      description: 'Arsenal vs Brighton & Hove Albion',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '32',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1957',
      opta_ID: 'eorsn55y4mwy1ohksxl9hm4no',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Wolverhampton Wanderers vs Aston Villa',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1962',
      opta_ID: 'eonyvfyzgwpo5478xbp6hb4t0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'West Ham United vs Everton',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1994',
      opta_ID: 'eokbz0z3s5tmvg5vnf045k3ys',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Tottenham Hotspur vs Newcastle United',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec19d3',
      opta_ID: 'eogq1zd7ftrtegbtpmeong8ic',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Manchester United vs Leicester City',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1a0d',
      opta_ID: 'eod57w0skgsc8b090d6wnxyxg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Liverpool vs Watford',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1a4d',
      opta_ID: 'eo9j1lzfml86ntgqjfa20tnv8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Leeds United vs Southampton',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1a8a',
      opta_ID: 'eo5vew1yg24f7fsxx5z86wx78',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Crystal Palace vs Arsenal',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1ac5',
      opta_ID: 'eo24o1tdolmz8s9f3khyysv10',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Chelsea vs Brentford',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1b0b',
      opta_ID: 'enyae7xbuksfmxcre9ohlkys4',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Burnley vs Manchester City',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1b4e',
      opta_ID: 'enuoq67n1c4lkdm7i7su2ayac',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-04-02Z',
      description: 'Brighton & Hove Albion vs Norwich City',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '31',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1b89',
      opta_ID: 'enqyhgo5g6afb0p0ib8z73sic',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Wolverhampton Wanderers vs Leeds United',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1bc8',
      opta_ID: 'enmgdyyazgfvy4l1ta5ie0vmc',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Watford vs Everton',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1bdd',
      opta_ID: 'enirby3iotkly2j661y6zsutw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Tottenham Hotspur vs West Ham United',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1bf5',
      opta_ID: 'end4i7cwzz5dz3f51h4sc13x0',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Norwich City vs Chelsea',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1c23',
      opta_ID: 'en9edpb5d9gh2a2p4ir3rkmj8',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Newcastle United vs Crystal Palace',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1c5a',
      opta_ID: 'en5t1e2ff6s3fg1261mt8bztg',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Manchester City vs Brighton & Hove Albion',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1c88',
      opta_ID: 'en22ba2nfygu3sxra65icxjpw',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Liverpool vs Manchester United',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1cb7',
      opta_ID: 'emyfiop7h6izjzcyjxeg6diqc',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Leicester City vs Brentford',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1cc2',
      opta_ID: 'emutss79t34ajgivxh6180o44',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Burnley vs Southampton',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1ceb',
      opta_ID: 'emr8drvk7o2ehqnz80gjly9lg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-19Z',
      description: 'Aston Villa vs Arsenal',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '30',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1d1d',
      opta_ID: 'emnjv3vw4ao4al2cycn8dxrf8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'West Ham United vs Aston Villa',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d7a4737fae26ec1d4f',
      opta_ID: 'emjs3kw331h3o9dut8mmc2gwk',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Southampton vs Watford',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1d77',
      opta_ID: 'emg6gqhp6x85mtbftpy7hv7ro',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Manchester United vs Tottenham Hotspur',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1d94',
      opta_ID: 'emcj6kow35xdj6fyo4g5hkwes',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Leeds United vs Norwich City',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1da9',
      opta_ID: 'em90j0hnzmzpt3exmgvu522hg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Everton vs Wolverhampton Wanderers',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1df0',
      opta_ID: 'em5i8jequfj4hh96gswnh0hlg',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Crystal Palace vs Manchester City',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1dfd',
      opta_ID: 'em1xnf3tebisn9vb5y5ac4844',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Chelsea vs Newcastle United',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e08',
      opta_ID: 'elyih19l78fc8sw1eg0to055g',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Brighton & Hove Albion vs Liverpool',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e14',
      opta_ID: 'eluw99o823i9t5qu8vscx6sd0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Brentford vs Burnley',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e29',
      opta_ID: 'elr6nd6d7m3mh3p6ylh0rydxw',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-12Z',
      description: 'Arsenal vs Leicester City',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '29',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e34',
      opta_ID: 'ellpr7ezoe7b8wzet436qhel0',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Wolverhampton Wanderers vs Crystal Palace',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e40',
      opta_ID: 'eli23mjq4hnrrqziorcwenu38',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Watford vs Arsenal',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e4e',
      opta_ID: 'ele4vzsu6695723pkdoldeln8',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Tottenham Hotspur vs Everton',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e5b',
      opta_ID: 'elaodxeko9u9dgtnt6e1sbg2c',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Norwich City vs Brentford',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e66',
      opta_ID: 'el6tuvaqro336qqzqkbhk52xg',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Newcastle United vs Brighton & Hove Albion',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1e70',
      opta_ID: 'el2wswj6c9638mcdkkt9j1r10',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Manchester City vs Manchester United',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1eae',
      opta_ID: 'ekz3waoaqyf9ko78042joznkk',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Liverpool vs West Ham United',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1eec',
      opta_ID: 'ekuya5cbia4xsahpsd6l8ot90',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Leicester City vs Leeds United',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1f32',
      opta_ID: 'ekr70uo6lnu8nwm68576antas',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Burnley vs Chelsea',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1f77',
      opta_ID: 'eknnt6ibesukfeiv7uzjazztg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-03-05Z',
      description: 'Aston Villa vs Southampton',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '28',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1fa6',
      opta_ID: 'ekk3msfet9cnb3nmpbts7p3pw',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'West Ham United vs Wolverhampton Wanderers',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec1fd7',
      opta_ID: 'ekgfrnat6vcnn0avrc49i91jo',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Southampton vs Norwich City',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec201a',
      opta_ID: 'ekcpm4rjhsjj94ko4km96gnpw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Manchester United vs Watford',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec205f',
      opta_ID: 'ek94ycnpk3ry3sm81qnqn2eqc',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Leeds United vs Tottenham Hotspur',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec20a0',
      opta_ID: 'ek5m4gkuy0t9t0lpw5n509o2c',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Everton vs Manchester City',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec20d7',
      opta_ID: 'ek23z672nuzw795sk8kepyvpw',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Crystal Palace vs Burnley',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec2112',
      opta_ID: 'ejwo9te0ye4nkw4b04txqkbo4',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Chelsea vs Leicester City',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec2155',
      opta_ID: 'ejss2wgrwog5902q0g0oxc55g',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Brighton & Hove Albion vs Aston Villa',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec218f',
      opta_ID: 'ejp4k1ni4uij4unn2p49705ck',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Brentford vs Newcastle United',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec21ca',
      opta_ID: 'ejl0n3kllh1fxrjyklqeq50yc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-26Z',
      description: 'Arsenal vs Liverpool',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '27',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec21de',
      opta_ID: 'ejh5adz8m0d28km7ksbd6z3f8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Wolverhampton Wanderers vs Leicester City',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec21f5',
      opta_ID: 'ejd8d9qz0upvim4tzy05mil90',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'West Ham United vs Newcastle United',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec2208',
      opta_ID: 'ej98zl52yk3uekpucly0kp15g',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Southampton vs Everton',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec2220',
      opta_ID: 'ej57ruyhvo0gzpb8kb93cuw44',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Manchester City vs Tottenham Hotspur',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec2257',
      opta_ID: 'ej0wbovr11m8etwase0k51ul0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Liverpool vs Norwich City',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d8a4737fae26ec228d',
      opta_ID: 'eix25ffu9nse9w7wnucewohec',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Leeds United vs Manchester United',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec22c1',
      opta_ID: 'eisdbhmumap0h0pbml49bkhsk',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Crystal Palace vs Chelsea',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec22d7',
      opta_ID: 'eio927j60obvkzcdtkeln5las',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Brighton & Hove Albion vs Burnley',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec22e6',
      opta_ID: 'eik078wi1t2hifvl11u2lfod0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Aston Villa vs Watford',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec22f1',
      opta_ID: 'eifvtha2qk7ryfjsitw7hprtg',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-19Z',
      description: 'Arsenal vs Brentford',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '26',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec22fb',
      opta_ID: 'eibshge5g7et5jiw6s2exgr2s',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Watford vs Brighton & Hove Albion',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2308',
      opta_ID: 'ei6v8a6ms6gyj7tag3rc4pgyc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Tottenham Hotspur vs Wolverhampton Wanderers',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2316',
      opta_ID: 'ei2ss5ng6z4uc3emgrxaq4hzo',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Norwich City vs Manchester City',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec232b',
      opta_ID: 'ehw2je0o0t0sbxzqf1jrmfqj8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Newcastle United vs Aston Villa',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2337',
      opta_ID: 'ehryyo4a2dkzijf8aublc51ck',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Manchester United vs Southampton',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2341',
      opta_ID: 'ehny6xe5htuaib2fnx0ldgphw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Leicester City vs West Ham United',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec234d',
      opta_ID: 'ehk4nyx3pv3ir9nav51mbn3tg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Everton vs Leeds United',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2358',
      opta_ID: 'ehgkypott4xsf2myxzvyo33tg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Chelsea vs Arsenal',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2364',
      opta_ID: 'ehcwvi5zck83ku07edo7udl3o',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Burnley vs Liverpool',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec237b',
      opta_ID: 'eh9ai55h021wq4v78g1rg1wd0',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-12Z',
      description: 'Brentford vs Crystal Palace',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '25',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec23bb',
      opta_ID: 'egyc6jbs1w0sodgrhxo6jjzmc',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-09Z',
      description: 'Manchester City vs Brentford',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec23fd',
      opta_ID: 'eguit4kf9lph7dpueukgwt05w',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-09Z',
      description: 'Liverpool vs Leicester City',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2434',
      opta_ID: 'eh5nkm96tkebpdsvnd6ww3jmc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-09Z',
      description: 'Tottenham Hotspur vs Southampton',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2472',
      opta_ID: 'eh20hoz8tfe0c8kkeh3bwfok4',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-09Z',
      description: 'Newcastle United vs Everton',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec24b0',
      opta_ID: 'egqyn57j0dtgsa4l2xw939nh0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'Wolverhampton Wanderers vs Arsenal',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec24f8',
      opta_ID: 'egng9gwpg4q42k8eeasvlrbx0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'West Ham United vs Watford',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2535',
      opta_ID: 'egjyqsqr1w1ku2dnl9vr3og0k',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'Norwich City vs Crystal Palace',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2573',
      opta_ID: 'eggfgfx99lvt7xjz5o7llas5w',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'Burnley vs Manchester United',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec25b1',
      opta_ID: 'egctvrejmbql10ofbz9op0idw',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'Brighton & Hove Albion vs Chelsea',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec25ea',
      opta_ID: 'eg7g5suejevqwvgy9yw02wbv8',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-02-08Z',
      description: 'Aston Villa vs Leeds United',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '24',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2623',
      opta_ID: 'efde2ymhoiv62bs4z7vqgd5p0',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-23Z',
      description: 'Chelsea vs Tottenham Hotspur',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2668',
      opta_ID: 'efh06nuhia3zkgbcxvi7lqz9w',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-23Z',
      description: 'Crystal Palace vs Liverpool',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec26a3',
      opta_ID: 'efzm1yat21ho9e807osbxh638',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Southampton vs Manchester City',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec26e9',
      opta_ID: 'efvw1g28f68i2l05jm8jlsnbo',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Manchester United vs West Ham United',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec272a',
      opta_ID: 'efs2omfbs1mctkqoi7klmg4r8',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Leicester City vs Brighton & Hove Albion',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec2756',
      opta_ID: 'efoh8pvqnhifnbazbn1lu5444',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Leeds United vs Newcastle United',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec277f',
      opta_ID: 'ef9n6n0ppyl2iiic848yvs36c',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Brentford vs Wolverhampton Wanderers',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec279f',
      opta_ID: 'ef61qeiz3kpgd6thys2vgf2fo',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Arsenal vs Burnley',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec27b5',
      opta_ID: 'efkw0f39a52xad0a3jy8duc5w',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-22Z',
      description: 'Everton vs Aston Villa',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0d9a4737fae26ec27bf',
      opta_ID: 'eg3mh7vsx9vb5ncrnn7bzakno',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-21Z',
      description: 'Watford vs Norwich City',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '23',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec27cf',
      opta_ID: 'eeu11rxkv9kfsx6um148xvggk',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-16Z',
      description: 'Tottenham Hotspur vs Arsenal',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec27de',
      opta_ID: 'eexjelam2580huqxsks5xgy6s',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-16Z',
      description: 'West Ham United vs Leeds United',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec27e8',
      opta_ID: 'ee1bdvo3oxmxi5gqmhpfpc4d0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Aston Villa vs Manchester United',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec27f6',
      opta_ID: 'ef25noo2ydyscl9rqdb4dtmvo',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Wolverhampton Wanderers vs Southampton',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec281b',
      opta_ID: 'eeqcds4f9s1xgvwmmkuamt0k4',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Norwich City vs Everton',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2827',
      opta_ID: 'eemqfmtsynu9rfl2jg5f6jrx0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Newcastle United vs Watford',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec283e',
      opta_ID: 'eed676d5wvedxjzqlhy3qbevo',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Liverpool vs Brentford',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2852',
      opta_ID: 'ee8p1cl33qpxd0hsk281b5x5g',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Burnley vs Leicester City',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec285d',
      opta_ID: 'eej3v5zf3on7k0os16jzwsums',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-15Z',
      description: 'Manchester City vs Chelsea',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2873',
      opta_ID: 'ee4sj743flpi20508y42yhd04',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-14Z',
      description: 'Brighton & Hove Albion vs Crystal Palace',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '22',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2880',
      opta_ID: 'edp1u76soj6m7bptyc3ijieqc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-03Z',
      description: 'Manchester United vs Wolverhampton Wanderers',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec288c',
      opta_ID: 'ed5n7fm2z9iqh6m0wrkbox72s',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-02Z',
      description: 'Chelsea vs Liverpool',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec289b',
      opta_ID: 'edsxdwrrp613xyosdgbdx47x0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-02Z',
      description: 'Southampton vs Newcastle United',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec28bb',
      opta_ID: 'edhlfy1mrhiryyd6ihpxy91jo',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-02Z',
      description: 'Leeds United vs Burnley',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec28fa',
      opta_ID: 'eddwqebz660k2vjc28emgvy1g',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-02Z',
      description: 'Everton vs Brighton & Hove Albion',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec291c',
      opta_ID: 'ed1w7bnyghwpba4cl0dlngx04',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-02Z',
      description: 'Brentford vs Aston Villa',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2930',
      opta_ID: 'ed9gh92kl2eieqyvrr9c2lslw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-01Z',
      description: 'Crystal Palace vs West Ham United',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2954',
      opta_ID: 'edwuw9afxbp9yocajrkdx0yz8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-01Z',
      description: 'Watford vs Tottenham Hotspur',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2987',
      opta_ID: 'edl9md7qp12n8jt9wr6k5j8d0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-01Z',
      description: 'Leicester City vs Norwich City',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec29cb',
      opta_ID: 'ecy9pkbxasakk0485ei20noyc',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2022-01-01Z',
      description: 'Arsenal vs Manchester City',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '21',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2a0b',
      opta_ID: 'ecloi0rivbpp6z5x7y57pgidw',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-30Z',
      description: 'Manchester United vs Burnley',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2a4a',
      opta_ID: 'ecagczapr68g0jnvwdvnz5e6s',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-30Z',
      description: 'Everton vs Newcastle United',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2a91',
      opta_ID: 'ebz7ezqg8jlbw5l6y0086cgt0',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-29Z',
      description: 'Brentford vs Manchester City',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2acd',
      opta_ID: 'ec2qso95i21qrjhqb45qdc4d0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-29Z',
      description: 'Chelsea vs Brighton & Hove Albion',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2b0e',
      opta_ID: 'echvlml68moe5kgxhjqrxd4pg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Leicester City vs Liverpool',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2b4d',
      opta_ID: 'ece6k25ui08ozq0mbmjq1cd1w',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Leeds United vs Aston Villa',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2b8b',
      opta_ID: 'ecuqncw7q5afcs4ul48wjazo4',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Watford vs West Ham United',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2ba9',
      opta_ID: 'ecr7gxr3o3jbae19s1r8h5n9w',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Southampton vs Tottenham Hotspur',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2bd1',
      opta_ID: 'ec6ydl050ftmp587olfzb35lg',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Crystal Palace vs Norwich City',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2c15',
      opta_ID: 'ebvn56r49cr4r7jd2fe07m49g',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-28Z',
      description: 'Arsenal vs Wolverhampton Wanderers',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '20',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2c56',
      opta_ID: 'ebd6fggumot2krqcf62s07bis',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-27Z',
      description: 'Newcastle United vs Manchester United',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2c95',
      opta_ID: 'eayqpwbije3n4wiiwejkx24us',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Brighton & Hove Albion vs Brentford',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0daa4737fae26ec2ccf',
      opta_ID: 'eat3byf1uskrw48hdo3qfbxn8',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Aston Villa vs Chelsea',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2cdb',
      opta_ID: 'ebo8yf3tww889pgjdn4t84g0k',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'West Ham United vs Southampton',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2cf9',
      opta_ID: 'ebko7duytiei2mwmbbh1crrx0',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Tottenham Hotspur vs Crystal Palace',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d19',
      opta_ID: 'ebgz40s7hn9152ra1ckmu7tas',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Norwich City vs Arsenal',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d26',
      opta_ID: 'eb9bzth4mozvxbdpvfj0xidjo',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Manchester City vs Leicester City',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d39',
      opta_ID: 'eb2964kz3x3huh97fznys3310',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Burnley vs Everton',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d45',
      opta_ID: 'ebryz8ftxqu9d2rqh52c2n40k',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Wolverhampton Wanderers vs Watford',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d57',
      opta_ID: 'eb5r17yxgtppjbgxzgw0oj7ro',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-26Z',
      description: 'Liverpool vs Leeds United',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '19',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d6b',
      opta_ID: 'eaerjrrp4vrqt1ke4hqlbq0wk',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-19Z',
      description: 'Tottenham Hotspur vs Liverpool',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d82',
      opta_ID: 'ea7ff4y2r1upv0wztmvrqq904',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-19Z',
      description: 'Newcastle United vs Manchester City',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:15:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d8d',
      opta_ID: 'eapiy4cfpzokxetda79kjhu6s',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-19Z',
      description: 'Wolverhampton Wanderers vs Chelsea',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2d97',
      opta_ID: 'e9w18asmzv3l2mwia6y4tbq50',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-19Z',
      description: 'Everton vs Leicester City',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '12:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2da4',
      opta_ID: 'e9zvor0p5jivlv9n1cem5hs7o',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'Leeds United vs Arsenal',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2daf',
      opta_ID: 'ealxz1k1w9jzmp589lcxo3kes',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'West Ham United vs Norwich City',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2dd0',
      opta_ID: 'eaictdpqwngwid9b657ci2x3o',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'Watford vs Crystal Palace',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2e17',
      opta_ID: 'eab5qfilzalxpddxhnscdpuz8',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'Southampton vs Brentford',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2e4c',
      opta_ID: 'e9s4y43b7kjt54n3ti8ltz8d0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'Aston Villa vs Burnley',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2e8c',
      opta_ID: 'ea3othmbdlm74umqzmdb6ey38',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-18Z',
      description: 'Manchester United vs Brighton & Hove Albion',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '18',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2ece',
      opta_ID: 'e9kjxe98eee5flvbzxf507k7o',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-16Z',
      description: 'Liverpool vs Newcastle United',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2f09',
      opta_ID: 'e9giskesvj6e0wn9h0j0d1zbo',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-16Z',
      description: 'Chelsea vs Everton',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2f47',
      opta_ID: 'e98os8e7q5dn6prjiyzltzo5w',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-16Z',
      description: 'Leicester City vs Tottenham Hotspur',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2f87',
      opta_ID: 'e8mwzgf34qvlgh0mfy53ww6qc',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-15Z',
      description: 'Arsenal vs West Ham United',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec2fc7',
      opta_ID: 'e92eer7zmm0p3gd6witjjr5zo',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-15Z',
      description: 'Crystal Palace vs Southampton',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec3009',
      opta_ID: 'e8yoe8rqgs5te0lw2m7ey0oic',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-15Z',
      description: 'Burnley vs Watford',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec3046',
      opta_ID: 'e8uhnddk9qi7lkuo5mhtgsw7o',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-15Z',
      description: 'Brighton & Hove Albion vs Wolverhampton Wanderers',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec307e',
      opta_ID: 'e9o9guf16y5ckg946gt9iuq6s',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-14Z',
      description: 'Manchester City vs Leeds United',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec30ba',
      opta_ID: 'e9clynm48dro7ofrkzebw46j8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-14Z',
      description: 'Norwich City vs Aston Villa',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '19:45:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec30fa',
      opta_ID: 'e8qtlrw12ltbhn60p5yulxo9g',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-14Z',
      description: 'Brentford vs Manchester United',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '17',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec312e',
      opta_ID: 'e8491gnp22zzjxtg817vdfitw',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-12Z',
      description: 'Crystal Palace vs Everton',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec3165',
      opta_ID: 'e87p0tnsag9uhi2mfj9xib8k4',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-12Z',
      description: 'Leicester City vs Newcastle United',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec31a0',
      opta_ID: 'e7v5t2mxry3van5ev4mqa21hw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-12Z',
      description: 'Burnley vs West Ham United',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec31d7',
      opta_ID: 'e7rg9p1baxl6db90mm0p42g44',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-12Z',
      description: 'Brighton & Hove Albion vs Tottenham Hotspur',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec31ea',
      opta_ID: 'e8jbxysextbikxgrgfrndafis',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-11Z',
      description: 'Norwich City vs Manchester United',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dba4737fae26ec31f7',
      opta_ID: 'e8bi177yv2jh7ntb35dkz2nf8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-11Z',
      description: 'Liverpool vs Aston Villa',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3204',
      opta_ID: 'e7yyxxi6j89d65bi23w8w2hp0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-11Z',
      description: 'Chelsea vs Leeds United',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3213',
      opta_ID: 'e7jyzef21nzh45bcy036ntvys',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-11Z',
      description: 'Arsenal vs Southampton',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec321d',
      opta_ID: 'e8fpkdo0ks8e2fsp4ro8cw3kk',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-11Z',
      description: 'Manchester City vs Wolverhampton Wanderers',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3228',
      opta_ID: 'e7nk0ye8166cokt5pp6ea191w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-10Z',
      description: 'Brentford vs Watford',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '16',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3233',
      opta_ID: 'e6marhorozfc8uam8ofndb4t0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-06Z',
      description: 'Everton vs Arsenal',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec323f',
      opta_ID: 'e6ineafge4rxq09txnkm31444',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-05Z',
      description: 'Aston Villa vs Leicester City',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3256',
      opta_ID: 'e7409behib12gk9sug7vpgidw',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-05Z',
      description: 'Tottenham Hotspur vs Norwich City',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3260',
      opta_ID: 'e6tc9ch67ybvga2ogpfrcca38',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-05Z',
      description: 'Manchester United vs Crystal Palace',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec326a',
      opta_ID: 'e6psz69srgv2vjzx1zs6hnifo',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-05Z',
      description: 'Leeds United vs Brentford',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3274',
      opta_ID: 'e77nysz4npqujc895pvzyulg4',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-04Z',
      description: 'Watford vs Manchester City',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3283',
      opta_ID: 'e7gdwp1pvot3690gqadsv1fys',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-04Z',
      description: 'Wolverhampton Wanderers vs Liverpool',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec32a3',
      opta_ID: 'e70kbjkzfjd66ssy2rl8f78k4',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-04Z',
      description: 'Southampton vs Brighton & Hove Albion',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec32ae',
      opta_ID: 'e6x1h13lij32ka2awhqtgo8pg',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-04Z',
      description: 'Newcastle United vs Burnley',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec32f1',
      opta_ID: 'e7csg2op0tqee87fxnzja4qok',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-04Z',
      description: 'West Ham United vs Chelsea',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '15',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3331',
      opta_ID: 'e5rsf5k89h7malm4ztcbgj6z8',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-02Z',
      description: 'Manchester United vs Arsenal',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3364',
      opta_ID: 'e6f302hk0uoo4lqjawia1nc44',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-02Z',
      description: 'Tottenham Hotspur vs Brentford',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec339d',
      opta_ID: 'e5hn32eg75rkdoj2zcgo2xwr8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'Everton vs Liverpool',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec33ba',
      opta_ID: 'e5dwvacxawlocmfdfk4d2b5ec',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'Aston Villa vs Manchester City',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec33ec',
      opta_ID: 'e6bdnzbxe3vtleccflvservh0',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'Southampton vs Leicester City',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3429',
      opta_ID: 'e63h7syu0uophifs6yj2h05qs',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'Wolverhampton Wanderers vs Burnley',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3453',
      opta_ID: 'e5zgtidkj97bfxwnbys084wlw',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'West Ham United vs Brighton & Hove Albion',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3467',
      opta_ID: 'e5vivfd1x4i8qvw6tcvepcufo',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-12-01Z',
      description: 'Watford vs Chelsea',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3487',
      opta_ID: 'e5nimy5619lh65w7200lurbpw',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-30Z',
      description: 'Leeds United vs Crystal Palace',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '20:15:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec349d',
      opta_ID: 'e67kjnvokaltsvc3zmjb7q6fo',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-30Z',
      description: 'Newcastle United vs Norwich City',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '19:30:00Z',
      week: '14',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec34b4',
      opta_ID: 'e4rtrtejm7w27ewm8x0ul2y38',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-28Z',
      description: 'Chelsea vs Manchester United',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec34e5',
      opta_ID: 'e56q5k6rfz32uhuld9sc1ois4',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-28Z',
      description: 'Manchester City vs West Ham United',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec351d',
      opta_ID: 'e4za4abmzy1jpbn4wdtr3x1qs',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-28Z',
      description: 'Leicester City vs Watford',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3563',
      opta_ID: 'e4o0j9luq41yzu5u2n8tz2psk',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-28Z',
      description: 'Burnley vs Tottenham Hotspur',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec359c',
      opta_ID: 'e4flmdnlfv9du6ufl8oczswes',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-28Z',
      description: 'Brentford vs Everton',
      home_team: '6166c3dde65c4ef204380264',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec35d5',
      opta_ID: 'e4jr5ldbyr1uin4pkldh73eac',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-27Z',
      description: 'Brighton & Hove Albion vs Leeds United',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3605',
      opta_ID: 'e5acfdlutonearx4lsitfdjbo',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-27Z',
      description: 'Norwich City vs Wolverhampton Wanderers',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3642',
      opta_ID: 'e534eddzhatkk3s3klfwhhes4',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-27Z',
      description: 'Liverpool vs Southampton',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dca4737fae26ec3677',
      opta_ID: 'e4vnq7a0e6avq8lcyk9omxpn8',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-27Z',
      description: 'Crystal Palace vs Aston Villa',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec36a7',
      opta_ID: 'e4byx6r7stge9rdbwd934kefo',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-27Z',
      description: 'Arsenal vs Newcastle United',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '13',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec36da',
      opta_ID: 'e41d10tp05eypiy8m27ixc0es',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-21Z',
      description: 'Tottenham Hotspur vs Leeds United',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec370f',
      opta_ID: 'e3petgyijn3r16pt9ml5wudxw',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-21Z',
      description: 'Manchester City vs Everton',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3740',
      opta_ID: 'e3lqhae6ntewv05cblx08iv4k',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Liverpool vs Arsenal',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec374a',
      opta_ID: 'e489w11weimlt46zx2jkib66s',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Wolverhampton Wanderers vs West Ham United',
      home_team: '6166c3dee65c4ef204380695',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3756',
      opta_ID: 'e44rzmap48519a1le6vslqyhg',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Watford vs Manchester United',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3763',
      opta_ID: 'e3xv906c03rmjn4i70qhz7das',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Norwich City vs Southampton',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3778',
      opta_ID: 'e3ugviq3zt0cy4earnp755dsk',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Newcastle United vs Brentford',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3783',
      opta_ID: 'e3en5w5zvyaabyatqlt3u43ro',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Burnley vs Crystal Palace',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3790',
      opta_ID: 'e3b1cleof3cfunwi8mb8uhous',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Aston Villa vs Brighton & Hove Albion',
      home_team: '6166c3dee65c4ef204380414',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec379b',
      opta_ID: 'e3i9o7k2k0l1q335epspq1z4k',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-20Z',
      description: 'Leicester City vs Chelsea',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '12',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec37a8',
      opta_ID: 'e37dd7i9ohkxq9qb8o1i5z5zo',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-07Z',
      description: 'West Ham United vs Liverpool',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0de98b8b115f06400de',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f06400df',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f06400e0',
        },
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f06400e1',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '11',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec37b5',
      opta_ID: 'e2wmjoplx0ovq2wxnn14dy0wk',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-07Z',
      description: 'Leeds United vs Leicester City',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd55e65c4ef204465cb4',
          type: 'Main',
          _id: '6186e0de98b8b115f06400e3',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f06400e4',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f06400e5',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f06400e6',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '11',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec37c9',
      opta_ID: 'e2sz6mcyq9ws3nbh2tnxix6ok',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-07Z',
      description: 'Everton vs Tottenham Hotspur',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Main',
          _id: '6186e0de98b8b115f06400e8',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f06400e9',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f06400ea',
        },
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f06400eb',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '11',
    },
    {
      opta_lineup: { home: [], away: [] },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3808',
      opta_ID: 'e2andjz4fy9etwp7rkpm7xb84',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-07Z',
      description: 'Arsenal vs Watford',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Main',
          _id: '6186e0de98b8b115f06400ed',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f06400ee',
        },
        {
          official: '6166cd48e65c4ef204464f62',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f06400ef',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f06400f0',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0de98b8b115f064010a',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0de98b8b115f064010b',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 6,
            _id: '6186e0de98b8b115f064010c',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0de98b8b115f064010d',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0de98b8b115f064010e',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 4,
            _id: '6186e0de98b8b115f064010f',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 11,
            _id: '6186e0de98b8b115f0640110',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 10,
            _id: '6186e0de98b8b115f0640111',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 8,
            _id: '6186e0de98b8b115f0640112',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 7,
            _id: '6186e0de98b8b115f0640113',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 9,
            _id: '6186e0de98b8b115f0640114',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 12,
            _id: '6186e0de98b8b115f0640115',
          },
          {
            squad: '6166c87fe65c4ef2043dd07a',
            order: 13,
            _id: '6186e0de98b8b115f0640116',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 14,
            _id: '6186e0de98b8b115f0640117',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 15,
            _id: '6186e0de98b8b115f0640118',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 16,
            _id: '6186e0de98b8b115f0640119',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 17,
            _id: '6186e0de98b8b115f064011a',
          },
          {
            squad: '6166c87fe65c4ef2043dcf54',
            order: 18,
            _id: '6186e0de98b8b115f064011b',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 19,
            _id: '6186e0de98b8b115f064011c',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 20,
            _id: '6186e0de98b8b115f064011d',
          },
        ],
        away: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0de98b8b115f06400f6',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0de98b8b115f06400f7',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0de98b8b115f06400f8',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 6,
            _id: '6186e0de98b8b115f06400f9',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0de98b8b115f06400fa',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 2,
            _id: '6186e0de98b8b115f06400fb',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 11,
            _id: '6186e0de98b8b115f06400fc',
          },
          {
            squad: '6166c909e65c4ef2043efb0e',
            order: 10,
            _id: '6186e0de98b8b115f06400fd',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 8,
            _id: '6186e0de98b8b115f06400fe',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 7,
            _id: '6186e0de98b8b115f06400ff',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 9,
            _id: '6186e0de98b8b115f0640100',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 12,
            _id: '6186e0de98b8b115f0640101',
          },
          {
            squad: '6166c908e65c4ef2043ef9e6',
            order: 13,
            _id: '6186e0de98b8b115f0640102',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 14,
            _id: '6186e0de98b8b115f0640103',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 15,
            _id: '6186e0de98b8b115f0640104',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 16,
            _id: '6186e0de98b8b115f0640105',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 17,
            _id: '6186e0de98b8b115f0640106',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 18,
            _id: '6186e0de98b8b115f0640107',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 19,
            _id: '6186e0de98b8b115f0640108',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 20,
            _id: '6186e0de98b8b115f0640109',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec388f',
      opta_ID: 'e2hzq7wwi79vubdxp1om8urdg',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-06Z',
      description: 'Brighton & Hove Albion vs Newcastle United',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0de98b8b115f06400f2',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f06400f3',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f06400f4',
        },
        {
          official: '6166cd40e65c4ef2044645d2',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f06400f5',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '17:30:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0de98b8b115f0640137',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0de98b8b115f0640138',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0de98b8b115f0640139',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0de98b8b115f064013a',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0de98b8b115f064013b',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0de98b8b115f064013c',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0de98b8b115f064013d',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0de98b8b115f064013e',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 11,
            _id: '6186e0de98b8b115f064013f',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0de98b8b115f0640140',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 10,
            _id: '6186e0de98b8b115f0640141',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 12,
            _id: '6186e0de98b8b115f0640142',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0de98b8b115f0640143',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 14,
            _id: '6186e0de98b8b115f0640144',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 15,
            _id: '6186e0de98b8b115f0640145',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 16,
            _id: '6186e0de98b8b115f0640146',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 17,
            _id: '6186e0de98b8b115f0640147',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 18,
            _id: '6186e0de98b8b115f0640148',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 19,
            _id: '6186e0de98b8b115f0640149',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 20,
            _id: '6186e0de98b8b115f064014a',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0de98b8b115f0640123',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0de98b8b115f0640124',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0de98b8b115f0640125',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0de98b8b115f0640126',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 3,
            _id: '6186e0de98b8b115f0640127',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0de98b8b115f0640128',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0de98b8b115f0640129',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0de98b8b115f064012a',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 11,
            _id: '6186e0de98b8b115f064012b',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0de98b8b115f064012c',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0de98b8b115f064012d',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 12,
            _id: '6186e0de98b8b115f064012e',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 13,
            _id: '6186e0de98b8b115f064012f',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 14,
            _id: '6186e0de98b8b115f0640130',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0de98b8b115f0640131',
          },
          {
            squad: '6166c980e65c4ef2043fe19f',
            order: 16,
            _id: '6186e0de98b8b115f0640132',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 17,
            _id: '6186e0de98b8b115f0640133',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 18,
            _id: '6186e0de98b8b115f0640134',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 19,
            _id: '6186e0de98b8b115f0640135',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0de98b8b115f0640136',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec393e',
      opta_ID: 'e2p1rgmtv8nyfsbi3tefsd8us',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-06Z',
      description: 'Crystal Palace vs Wolverhampton Wanderers',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Main',
          _id: '6186e0de98b8b115f064011f',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f0640120',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f0640121',
        },
        {
          official: '6166cd56e65c4ef204465e44',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f0640122',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0de98b8b115f0640164',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0de98b8b115f0640165',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0de98b8b115f0640166',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 6,
            _id: '6186e0de98b8b115f0640167',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 3,
            _id: '6186e0de98b8b115f0640168',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 8,
            _id: '6186e0de98b8b115f0640169',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 7,
            _id: '6186e0de98b8b115f064016a',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0de98b8b115f064016b',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 9,
            _id: '6186e0de98b8b115f064016c',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 11,
            _id: '6186e0de98b8b115f064016d',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 10,
            _id: '6186e0de98b8b115f064016e',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0de98b8b115f064016f',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 13,
            _id: '6186e0de98b8b115f0640170',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 14,
            _id: '6186e0de98b8b115f0640171',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 15,
            _id: '6186e0de98b8b115f0640172',
          },
          {
            squad: '6166c89de65c4ef2043dfa18',
            order: 16,
            _id: '6186e0de98b8b115f0640173',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 17,
            _id: '6186e0de98b8b115f0640174',
          },
          {
            squad: '6166c89be65c4ef2043df798',
            order: 18,
            _id: '6186e0de98b8b115f0640175',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 19,
            _id: '6186e0de98b8b115f0640176',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0de98b8b115f0640177',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0de98b8b115f0640150',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0de98b8b115f0640151',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0de98b8b115f0640152',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0de98b8b115f0640153',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0de98b8b115f0640154',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0de98b8b115f0640155',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0de98b8b115f0640156',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0de98b8b115f0640157',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0de98b8b115f0640158',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0de98b8b115f0640159',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 10,
            _id: '6186e0de98b8b115f064015a',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 12,
            _id: '6186e0de98b8b115f064015b',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 13,
            _id: '6186e0de98b8b115f064015c',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 14,
            _id: '6186e0de98b8b115f064015d',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0de98b8b115f064015e',
          },
          {
            squad: '6166c88ae65c4ef2043ddf0e',
            order: 16,
            _id: '6186e0de98b8b115f064015f',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 17,
            _id: '6186e0de98b8b115f0640160',
          },
          {
            squad: '6166c889e65c4ef2043ddd94',
            order: 18,
            _id: '6186e0de98b8b115f0640161',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 19,
            _id: '6186e0de98b8b115f0640162',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0de98b8b115f0640163',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3997',
      opta_ID: 'e2lgyx6wzpo6tojjvd80z5mvo',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-06Z',
      description: 'Chelsea vs Burnley',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0de98b8b115f064014c',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 1',
          _id: '6186e0de98b8b115f064014d',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 2',
          _id: '6186e0de98b8b115f064014e',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0de98b8b115f064014f',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 1,
            _id: '6186e0df98b8b115f0640191',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0df98b8b115f0640192',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0df98b8b115f0640193',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 6,
            _id: '6186e0df98b8b115f0640194',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0df98b8b115f0640195',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0df98b8b115f0640196',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0df98b8b115f0640197',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 7,
            _id: '6186e0df98b8b115f0640198',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0df98b8b115f0640199',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0df98b8b115f064019a',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0df98b8b115f064019b',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 12,
            _id: '6186e0df98b8b115f064019c',
          },
          {
            squad: '6186b4f0a4737fae26aa5878',
            order: 13,
            _id: '6186e0df98b8b115f064019d',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 14,
            _id: '6186e0df98b8b115f064019e',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 15,
            _id: '6186e0df98b8b115f064019f',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 16,
            _id: '6186e0df98b8b115f06401a0',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 17,
            _id: '6186e0df98b8b115f06401a1',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0df98b8b115f06401a2',
          },
          {
            squad: '6166c87be65c4ef2043dc9f4',
            order: 19,
            _id: '6186e0df98b8b115f06401a3',
          },
          {
            squad: '6166c87be65c4ef2043dc94a',
            order: 20,
            _id: '6186e0df98b8b115f06401a4',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0df98b8b115f064017d',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 3,
            _id: '6186e0df98b8b115f064017e',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 6,
            _id: '6186e0df98b8b115f064017f',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 5,
            _id: '6186e0df98b8b115f0640180',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0df98b8b115f0640181',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0df98b8b115f0640182',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0df98b8b115f0640183',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 10,
            _id: '6186e0df98b8b115f0640184',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 4,
            _id: '6186e0df98b8b115f0640185',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 7,
            _id: '6186e0df98b8b115f0640186',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0df98b8b115f0640187',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 12,
            _id: '6186e0df98b8b115f0640188',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 13,
            _id: '6186e0df98b8b115f0640189',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 14,
            _id: '6186e0df98b8b115f064018a',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 15,
            _id: '6186e0df98b8b115f064018b',
          },
          {
            squad: '6166c912e65c4ef2043f05b8',
            order: 16,
            _id: '6186e0df98b8b115f064018c',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 17,
            _id: '6186e0df98b8b115f064018d',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 18,
            _id: '6186e0df98b8b115f064018e',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 19,
            _id: '6186e0df98b8b115f064018f',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 20,
            _id: '6186e0df98b8b115f0640190',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3a29',
      opta_ID: 'e2efw8mmtei853sslap3zt6ok',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-06Z',
      description: 'Brentford vs Norwich City',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd65e65c4ef204466d40',
          type: 'Main',
          _id: '6186e0df98b8b115f0640179',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f064017a',
        },
        {
          official: '6166cd55e65c4ef204465cc7',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f064017b',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f064017c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '15:00:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0df98b8b115f06401be',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 4,
            _id: '6186e0df98b8b115f06401bf',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0df98b8b115f06401c0',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 6,
            _id: '6186e0df98b8b115f06401c1',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0df98b8b115f06401c2',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 8,
            _id: '6186e0df98b8b115f06401c3',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 11,
            _id: '6186e0df98b8b115f06401c4',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 7,
            _id: '6186e0df98b8b115f06401c5',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0df98b8b115f06401c6',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0df98b8b115f06401c7',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 10,
            _id: '6186e0df98b8b115f06401c8',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 12,
            _id: '6186e0df98b8b115f06401c9',
          },
          {
            squad: '6166c8f5e65c4ef2043edf20',
            order: 13,
            _id: '6186e0df98b8b115f06401ca',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 14,
            _id: '6186e0df98b8b115f06401cb',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 15,
            _id: '6186e0df98b8b115f06401cc',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 16,
            _id: '6186e0df98b8b115f06401cd',
          },
          {
            squad: '6166c8f5e65c4ef2043edf3f',
            order: 17,
            _id: '6186e0df98b8b115f06401ce',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 18,
            _id: '6186e0df98b8b115f06401cf',
          },
          {
            squad: '6166c8f7e65c4ef2043ee126',
            order: 19,
            _id: '6186e0df98b8b115f06401d0',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0df98b8b115f06401d1',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0df98b8b115f06401aa',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0df98b8b115f06401ab',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 6,
            _id: '6186e0df98b8b115f06401ac',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 5,
            _id: '6186e0df98b8b115f06401ad',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0df98b8b115f06401ae',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 8,
            _id: '6186e0df98b8b115f06401af',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0df98b8b115f06401b0',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 7,
            _id: '6186e0df98b8b115f06401b1',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 11,
            _id: '6186e0df98b8b115f06401b2',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 9,
            _id: '6186e0df98b8b115f06401b3',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0df98b8b115f06401b4',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0df98b8b115f06401b5',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9bb',
            order: 13,
            _id: '6186e0df98b8b115f06401b6',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 14,
            _id: '6186e0df98b8b115f06401b7',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 15,
            _id: '6186e0df98b8b115f06401b8',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 16,
            _id: '6186e0df98b8b115f06401b9',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 17,
            _id: '6186e0df98b8b115f06401ba',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 18,
            _id: '6186e0df98b8b115f06401bb',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 19,
            _id: '6186e0df98b8b115f06401bc',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0df98b8b115f06401bd',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3aad',
      opta_ID: 'e30awjlluf4ivn2l2v45whhck',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-06Z',
      description: 'Manchester United vs Manchester City',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0df98b8b115f06401a6',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f06401a7',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f06401a8',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f06401a9',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '12:30:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0df98b8b115f06401eb',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0df98b8b115f06401ec',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0df98b8b115f06401ed',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0df98b8b115f06401ee',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0df98b8b115f06401ef',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 11,
            _id: '6186e0df98b8b115f06401f0',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0df98b8b115f06401f1',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 4,
            _id: '6186e0df98b8b115f06401f2',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 7,
            _id: '6186e0df98b8b115f06401f3',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 9,
            _id: '6186e0df98b8b115f06401f4',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 10,
            _id: '6186e0df98b8b115f06401f5',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 12,
            _id: '6186e0df98b8b115f06401f6',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 13,
            _id: '6186e0df98b8b115f06401f7',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 14,
            _id: '6186e0df98b8b115f06401f8',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 15,
            _id: '6186e0df98b8b115f06401f9',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 16,
            _id: '6186e0df98b8b115f06401fa',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 17,
            _id: '6186e0df98b8b115f06401fb',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 18,
            _id: '6186e0df98b8b115f06401fc',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 19,
            _id: '6186e0df98b8b115f06401fd',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 20,
            _id: '6186e0df98b8b115f06401fe',
          },
        ],
        away: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0df98b8b115f06401d7',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0df98b8b115f06401d8',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 6,
            _id: '6186e0df98b8b115f06401d9',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 5,
            _id: '6186e0df98b8b115f06401da',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0df98b8b115f06401db',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 4,
            _id: '6186e0df98b8b115f06401dc',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 8,
            _id: '6186e0df98b8b115f06401dd',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 11,
            _id: '6186e0df98b8b115f06401de',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 10,
            _id: '6186e0df98b8b115f06401df',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 7,
            _id: '6186e0df98b8b115f06401e0',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 9,
            _id: '6186e0df98b8b115f06401e1',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0df98b8b115f06401e2',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 13,
            _id: '6186e0df98b8b115f06401e3',
          },
          {
            squad: '6166c861e65c4ef2043da4c4',
            order: 14,
            _id: '6186e0df98b8b115f06401e4',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 15,
            _id: '6186e0df98b8b115f06401e5',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 16,
            _id: '6186e0df98b8b115f06401e6',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 17,
            _id: '6186e0df98b8b115f06401e7',
          },
          {
            squad: '6166c85ee65c4ef2043d9f56',
            order: 18,
            _id: '6186e0df98b8b115f06401e8',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 19,
            _id: '6186e0df98b8b115f06401e9',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0df98b8b115f06401ea',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dda4737fae26ec3af0',
      opta_ID: 'e33wnqm2c1zqonoj1dixqqk2c',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-05Z',
      description: 'Southampton vs Aston Villa',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Main',
          _id: '6186e0df98b8b115f06401d3',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f06401d4',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f06401d5',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f06401d6',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '11',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0df98b8b115f0640218',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0df98b8b115f0640219',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0df98b8b115f064021a',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0df98b8b115f064021b',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 3,
            _id: '6186e0df98b8b115f064021c',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0df98b8b115f064021d',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0df98b8b115f064021e',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0df98b8b115f064021f',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 11,
            _id: '6186e0df98b8b115f0640220',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0df98b8b115f0640221',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0df98b8b115f0640222',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 12,
            _id: '6186e0df98b8b115f0640223',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 13,
            _id: '6186e0df98b8b115f0640224',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 14,
            _id: '6186e0df98b8b115f0640225',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0df98b8b115f0640226',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 16,
            _id: '6186e0df98b8b115f0640227',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 17,
            _id: '6186e0df98b8b115f0640228',
          },
          {
            squad: '6186c936a4737fae26c68852',
            order: 18,
            _id: '6186e0df98b8b115f0640229',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 19,
            _id: '6186e0df98b8b115f064022a',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0df98b8b115f064022b',
          },
        ],
        away: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0df98b8b115f0640204',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 3,
            _id: '6186e0df98b8b115f0640205',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 6,
            _id: '6186e0df98b8b115f0640206',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 5,
            _id: '6186e0df98b8b115f0640207',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0df98b8b115f0640208',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 11,
            _id: '6186e0df98b8b115f0640209',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0df98b8b115f064020a',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 4,
            _id: '6186e0df98b8b115f064020b',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0df98b8b115f064020c',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 9,
            _id: '6186e0df98b8b115f064020d',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 10,
            _id: '6186e0df98b8b115f064020e',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0df98b8b115f064020f',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 13,
            _id: '6186e0df98b8b115f0640210',
          },
          {
            squad: '6166c8bde65c4ef2043e3952',
            order: 14,
            _id: '6186e0df98b8b115f0640211',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 15,
            _id: '6186e0df98b8b115f0640212',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 16,
            _id: '6186e0df98b8b115f0640213',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 17,
            _id: '6186e0df98b8b115f0640214',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 18,
            _id: '6186e0df98b8b115f0640215',
          },
          {
            squad: '6166c8bbe65c4ef2043e2fd4',
            order: 19,
            _id: '6186e0df98b8b115f0640216',
          },
          {
            squad: '6166c8bde65c4ef2043e3aa0',
            order: 20,
            _id: '6186e0df98b8b115f0640217',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3bcb',
      opta_ID: 'e2772a3mjkwtxujnrfxypda8k',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-11-01Z',
      description: 'Wolverhampton Wanderers vs Everton',
      home_team: '6166c3dee65c4ef204380695',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0df98b8b115f0640200',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f0640201',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f0640202',
        },
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f0640203',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '20:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0df98b8b115f0640245',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0df98b8b115f0640246',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 6,
            _id: '6186e0df98b8b115f0640247',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 5,
            _id: '6186e0df98b8b115f0640248',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0df98b8b115f0640249',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 8,
            _id: '6186e0df98b8b115f064024a',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 4,
            _id: '6186e0df98b8b115f064024b',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0df98b8b115f064024c',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 11,
            _id: '6186e0df98b8b115f064024d',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 9,
            _id: '6186e0df98b8b115f064024e',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 10,
            _id: '6186e0df98b8b115f064024f',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0df98b8b115f0640250',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 13,
            _id: '6186e0df98b8b115f0640251',
          },
          {
            squad: '6166c861e65c4ef2043da4c4',
            order: 14,
            _id: '6186e0df98b8b115f0640252',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 15,
            _id: '6186e0df98b8b115f0640253',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 16,
            _id: '6186e0df98b8b115f0640254',
          },
          {
            squad: '6166c85ee65c4ef2043d9f56',
            order: 17,
            _id: '6186e0df98b8b115f0640255',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 18,
            _id: '6186e0df98b8b115f0640256',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 19,
            _id: '6186e0df98b8b115f0640257',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0df98b8b115f0640258',
          },
        ],
        away: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0df98b8b115f0640231',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0df98b8b115f0640232',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0df98b8b115f0640233',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0df98b8b115f0640234',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 2,
            _id: '6186e0df98b8b115f0640235',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0df98b8b115f0640236',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0df98b8b115f0640237',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 11,
            _id: '6186e0df98b8b115f0640238',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 10,
            _id: '6186e0df98b8b115f0640239',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0df98b8b115f064023a',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0df98b8b115f064023b',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0df98b8b115f064023c',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 13,
            _id: '6186e0df98b8b115f064023d',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 14,
            _id: '6186e0df98b8b115f064023e',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 15,
            _id: '6186e0df98b8b115f064023f',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 16,
            _id: '6186e0df98b8b115f0640240',
          },
          {
            squad: '6166c978e65c4ef2043fbb13',
            order: 17,
            _id: '6186e0df98b8b115f0640241',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 18,
            _id: '6186e0df98b8b115f0640242',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 19,
            _id: '6186e0df98b8b115f0640243',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0df98b8b115f0640244',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3c13',
      opta_ID: 'e185kloh55y2kbq8qcgyf26tw',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-31Z',
      description: 'Aston Villa vs West Ham United',
      home_team: '6166c3dee65c4ef204380414',
      officials: [
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Main',
          _id: '6186e0df98b8b115f064022d',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f064022e',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f064022f',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f0640230',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0df98b8b115f0640272',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 3,
            _id: '6186e0df98b8b115f0640273',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 6,
            _id: '6186e0df98b8b115f0640274',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 5,
            _id: '6186e0df98b8b115f0640275',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0df98b8b115f0640276',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0df98b8b115f0640277',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0df98b8b115f0640278',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 4,
            _id: '6186e0df98b8b115f0640279',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 7,
            _id: '6186e0df98b8b115f064027a',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 9,
            _id: '6186e0df98b8b115f064027b',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 10,
            _id: '6186e0df98b8b115f064027c',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 12,
            _id: '6186e0df98b8b115f064027d',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 13,
            _id: '6186e0df98b8b115f064027e',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 14,
            _id: '6186e0df98b8b115f064027f',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 15,
            _id: '6186e0df98b8b115f0640280',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 16,
            _id: '6186e0df98b8b115f0640281',
          },
          {
            squad: '6166c912e65c4ef2043f05b8',
            order: 17,
            _id: '6186e0df98b8b115f0640282',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 18,
            _id: '6186e0df98b8b115f0640283',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 19,
            _id: '6186e0df98b8b115f0640284',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 20,
            _id: '6186e0df98b8b115f0640285',
          },
        ],
        away: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0df98b8b115f064025e',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 4,
            _id: '6186e0df98b8b115f064025f',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 5,
            _id: '6186e0df98b8b115f0640260',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 6,
            _id: '6186e0df98b8b115f0640261',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 3,
            _id: '6186e0df98b8b115f0640262',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 8,
            _id: '6186e0df98b8b115f0640263',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 2,
            _id: '6186e0df98b8b115f0640264',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 11,
            _id: '6186e0df98b8b115f0640265',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 7,
            _id: '6186e0df98b8b115f0640266',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 10,
            _id: '6186e0df98b8b115f0640267',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 9,
            _id: '6186e0df98b8b115f0640268',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0df98b8b115f0640269',
          },
          {
            squad: '6166c8dfe65c4ef2043ec268',
            order: 13,
            _id: '6186e0df98b8b115f064026a',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 14,
            _id: '6186e0df98b8b115f064026b',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 15,
            _id: '6186e0df98b8b115f064026c',
          },
          {
            squad: '6166c8dfe65c4ef2043ec339',
            order: 16,
            _id: '6186e0df98b8b115f064026d',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 17,
            _id: '6186e0df98b8b115f064026e',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 18,
            _id: '6186e0df98b8b115f064026f',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0df98b8b115f0640270',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0df98b8b115f0640271',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3c2b',
      opta_ID: 'e1ul1g9xousp5683yk9sekbo4',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-31Z',
      description: 'Norwich City vs Leeds United',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0df98b8b115f064025a',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f064025b',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f064025c',
        },
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f064025d',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0df98b8b115f064029f',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 3,
            _id: '6186e0df98b8b115f06402a0',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0df98b8b115f06402a1',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 5,
            _id: '6186e0df98b8b115f06402a2',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0df98b8b115f06402a3',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0df98b8b115f06402a4',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 8,
            _id: '6186e0df98b8b115f06402a5',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 11,
            _id: '6186e0df98b8b115f06402a6',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 10,
            _id: '6186e0df98b8b115f06402a7',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 7,
            _id: '6186e0df98b8b115f06402a8',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0df98b8b115f06402a9',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 12,
            _id: '6186e0df98b8b115f06402aa',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 13,
            _id: '6186e0df98b8b115f06402ab',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 14,
            _id: '6186e0df98b8b115f06402ac',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0df98b8b115f06402ad',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 16,
            _id: '6186e0df98b8b115f06402ae',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 17,
            _id: '6186e0df98b8b115f06402af',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 18,
            _id: '6186e0df98b8b115f06402b0',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 19,
            _id: '6186e0df98b8b115f06402b1',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 20,
            _id: '6186e0df98b8b115f06402b2',
          },
        ],
        away: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0df98b8b115f064028b',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 4,
            _id: '6186e0df98b8b115f064028c',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 5,
            _id: '6186e0df98b8b115f064028d',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 6,
            _id: '6186e0df98b8b115f064028e',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0df98b8b115f064028f',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 8,
            _id: '6186e0df98b8b115f0640290',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 11,
            _id: '6186e0df98b8b115f0640291',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 7,
            _id: '6186e0df98b8b115f0640292',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0df98b8b115f0640293',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0df98b8b115f0640294',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0c6',
            order: 10,
            _id: '6186e0df98b8b115f0640295',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0df98b8b115f0640296',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0df98b8b115f0640297',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 14,
            _id: '6186e0df98b8b115f0640298',
          },
          {
            squad: '6166c8f5e65c4ef2043edf20',
            order: 15,
            _id: '6186e0df98b8b115f0640299',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 16,
            _id: '6186e0df98b8b115f064029a',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 17,
            _id: '6186e0df98b8b115f064029b',
          },
          {
            squad: '6166c8f5e65c4ef2043edf3f',
            order: 18,
            _id: '6186e0df98b8b115f064029c',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 19,
            _id: '6186e0df98b8b115f064029d',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0df98b8b115f064029e',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3c48',
      opta_ID: 'e1y65owq9s5o38ga5ktijhj4k',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Tottenham Hotspur vs Manchester United',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0df98b8b115f0640287',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f0640288',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f0640289',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f064028a',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0df98b8b115f06402cc',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0df98b8b115f06402cd',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 6,
            _id: '6186e0df98b8b115f06402ce',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0df98b8b115f06402cf',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 2,
            _id: '6186e0df98b8b115f06402d0',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 11,
            _id: '6186e0df98b8b115f06402d1',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 8,
            _id: '6186e0df98b8b115f06402d2',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 4,
            _id: '6186e0df98b8b115f06402d3',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0df98b8b115f06402d4',
          },
          {
            squad: '6166c973e65c4ef2043fa40f',
            order: 10,
            _id: '6186e0df98b8b115f06402d5',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0df98b8b115f06402d6',
          },
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 12,
            _id: '6186e0df98b8b115f06402d7',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 13,
            _id: '6186e0df98b8b115f06402d8',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 14,
            _id: '6186e0df98b8b115f06402d9',
          },
          {
            squad: '6166c971e65c4ef2043f9a14',
            order: 15,
            _id: '6186e0df98b8b115f06402da',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 16,
            _id: '6186e0df98b8b115f06402db',
          },
          {
            squad: '6166c973e65c4ef2043fa1d9',
            order: 17,
            _id: '6186e0df98b8b115f06402dc',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 18,
            _id: '6186e0df98b8b115f06402dd',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 19,
            _id: '6186e0df98b8b115f06402de',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 20,
            _id: '6186e0df98b8b115f06402df',
          },
        ],
        away: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0df98b8b115f06402b8',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0df98b8b115f06402b9',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0df98b8b115f06402ba',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0df98b8b115f06402bb',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0df98b8b115f06402bc',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 11,
            _id: '6186e0df98b8b115f06402bd',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0df98b8b115f06402be',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 4,
            _id: '6186e0df98b8b115f06402bf',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 7,
            _id: '6186e0df98b8b115f06402c0',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 9,
            _id: '6186e0df98b8b115f06402c1',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 10,
            _id: '6186e0df98b8b115f06402c2',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 12,
            _id: '6186e0df98b8b115f06402c3',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 13,
            _id: '6186e0df98b8b115f06402c4',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 14,
            _id: '6186e0df98b8b115f06402c5',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 15,
            _id: '6186e0df98b8b115f06402c6',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 16,
            _id: '6186e0df98b8b115f06402c7',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 17,
            _id: '6186e0df98b8b115f06402c8',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0df98b8b115f06402c9',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0df98b8b115f06402ca',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 20,
            _id: '6186e0df98b8b115f06402cb',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3c57',
      opta_ID: 'e21urm1fium3c4ndeqc33gges',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Watford vs Southampton',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Main',
          _id: '6186e0df98b8b115f06402b4',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f06402b5',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f06402b6',
        },
        {
          official: '6166cd40e65c4ef2044645d2',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f06402b7',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0df98b8b115f06402f9',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0df98b8b115f06402fa',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0df98b8b115f06402fb',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 6,
            _id: '6186e0df98b8b115f06402fc',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0df98b8b115f06402fd',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0df98b8b115f06402fe',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 11,
            _id: '6186e0df98b8b115f06402ff',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 8,
            _id: '6186e0df98b8b115f0640300',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 7,
            _id: '6186e0df98b8b115f0640301',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0df98b8b115f0640302',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 10,
            _id: '6186e0df98b8b115f0640303',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 12,
            _id: '6186e0df98b8b115f0640304',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 13,
            _id: '6186e0df98b8b115f0640305',
          },
          {
            squad: '6166c908e65c4ef2043ef9e6',
            order: 14,
            _id: '6186e0df98b8b115f0640306',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 15,
            _id: '6186e0df98b8b115f0640307',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 16,
            _id: '6186e0df98b8b115f0640308',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 17,
            _id: '6186e0df98b8b115f0640309',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 18,
            _id: '6186e0df98b8b115f064030a',
          },
          {
            squad: '6166c909e65c4ef2043efb0e',
            order: 19,
            _id: '6186e0df98b8b115f064030b',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 20,
            _id: '6186e0df98b8b115f064030c',
          },
        ],
        away: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0df98b8b115f06402e5',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0df98b8b115f06402e6',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0df98b8b115f06402e7',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 6,
            _id: '6186e0df98b8b115f06402e8',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 3,
            _id: '6186e0df98b8b115f06402e9',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 8,
            _id: '6186e0df98b8b115f06402ea',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 7,
            _id: '6186e0df98b8b115f06402eb',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0df98b8b115f06402ec',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 11,
            _id: '6186e0df98b8b115f06402ed',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 10,
            _id: '6186e0df98b8b115f06402ee',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 9,
            _id: '6186e0df98b8b115f06402ef',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 12,
            _id: '6186e0df98b8b115f06402f0',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 13,
            _id: '6186e0df98b8b115f06402f1',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 14,
            _id: '6186e0df98b8b115f06402f2',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 15,
            _id: '6186e0df98b8b115f06402f3',
          },
          {
            squad: '6166c89de65c4ef2043dfa75',
            order: 16,
            _id: '6186e0df98b8b115f06402f4',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 17,
            _id: '6186e0df98b8b115f06402f5',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 18,
            _id: '6186e0df98b8b115f06402f6',
          },
          {
            squad: '6166c89be65c4ef2043df798',
            order: 19,
            _id: '6186e0df98b8b115f06402f7',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0df98b8b115f06402f8',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3cdc',
      opta_ID: 'e1r2wsxqai3vf14o4u7wa5ono',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Newcastle United vs Chelsea',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0df98b8b115f06402e1',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f06402e2',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f06402e3',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f06402e4',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0df98b8b115f0640326',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0df98b8b115f0640327',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0df98b8b115f0640328',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0df98b8b115f0640329',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0df98b8b115f064032a',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 8,
            _id: '6186e0df98b8b115f064032b',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0df98b8b115f064032c',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 7,
            _id: '6186e0df98b8b115f064032d',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0df98b8b115f064032e',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 9,
            _id: '6186e0df98b8b115f064032f',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0df98b8b115f0640330',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0df98b8b115f0640331',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 13,
            _id: '6186e0df98b8b115f0640332',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 14,
            _id: '6186e0df98b8b115f0640333',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 15,
            _id: '6186e0df98b8b115f0640334',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 16,
            _id: '6186e0df98b8b115f0640335',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 17,
            _id: '6186e0df98b8b115f0640336',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 18,
            _id: '6186e0df98b8b115f0640337',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0df98b8b115f0640338',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0df98b8b115f0640339',
          },
        ],
        away: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0df98b8b115f0640312',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0df98b8b115f0640313',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0df98b8b115f0640314',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0df98b8b115f0640315',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0df98b8b115f0640316',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0df98b8b115f0640317',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0df98b8b115f0640318',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0df98b8b115f0640319',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 11,
            _id: '6186e0df98b8b115f064031a',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 9,
            _id: '6186e0df98b8b115f064031b',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 10,
            _id: '6186e0df98b8b115f064031c',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 12,
            _id: '6186e0df98b8b115f064031d',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0df98b8b115f064031e',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 14,
            _id: '6186e0df98b8b115f064031f',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 15,
            _id: '6186e0df98b8b115f0640320',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 16,
            _id: '6186e0df98b8b115f0640321',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 17,
            _id: '6186e0df98b8b115f0640322',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 18,
            _id: '6186e0df98b8b115f0640323',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 19,
            _id: '6186e0df98b8b115f0640324',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 20,
            _id: '6186e0df98b8b115f0640325',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3d1f',
      opta_ID: 'e1nd8vgvbagt4ubhexnwm9hck',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Manchester City vs Crystal Palace',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0df98b8b115f064030e',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f064030f',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f0640310',
        },
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f0640311',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0df98b8b115f0640353',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0df98b8b115f0640354',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0df98b8b115f0640355',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 5,
            _id: '6186e0df98b8b115f0640356',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0df98b8b115f0640357',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 8,
            _id: '6186e0df98b8b115f0640358',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 4,
            _id: '6186e0df98b8b115f0640359',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 7,
            _id: '6186e0df98b8b115f064035a',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0df98b8b115f064035b',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 9,
            _id: '6186e0df98b8b115f064035c',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0df98b8b115f064035d',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 12,
            _id: '6186e0df98b8b115f064035e',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 13,
            _id: '6186e0df98b8b115f064035f',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 14,
            _id: '6186e0df98b8b115f0640360',
          },
          {
            squad: '6166c8ece65c4ef2043ed2c0',
            order: 15,
            _id: '6186e0df98b8b115f0640361',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 16,
            _id: '6186e0df98b8b115f0640362',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 17,
            _id: '6186e0df98b8b115f0640363',
          },
          {
            squad: '6166c8e8e65c4ef2043ece67',
            order: 18,
            _id: '6186e0df98b8b115f0640364',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 19,
            _id: '6186e0df98b8b115f0640365',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 20,
            _id: '6186e0df98b8b115f0640366',
          },
        ],
        away: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0df98b8b115f064033f',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0df98b8b115f0640340',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 6,
            _id: '6186e0df98b8b115f0640341',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0df98b8b115f0640342',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0df98b8b115f0640343',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 4,
            _id: '6186e0df98b8b115f0640344',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 8,
            _id: '6186e0df98b8b115f0640345',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 11,
            _id: '6186e0df98b8b115f0640346',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 10,
            _id: '6186e0df98b8b115f0640347',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 7,
            _id: '6186e0df98b8b115f0640348',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 9,
            _id: '6186e0df98b8b115f0640349',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 12,
            _id: '6186e0df98b8b115f064034a',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 13,
            _id: '6186e0df98b8b115f064034b',
          },
          {
            squad: '6166c87fe65c4ef2043dd07a',
            order: 14,
            _id: '6186e0df98b8b115f064034c',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 15,
            _id: '6186e0df98b8b115f064034d',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 16,
            _id: '6186e0df98b8b115f064034e',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 17,
            _id: '6186e0df98b8b115f064034f',
          },
          {
            squad: '6166c87fe65c4ef2043dcf54',
            order: 18,
            _id: '6186e0df98b8b115f0640350',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 19,
            _id: '6186e0df98b8b115f0640351',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 20,
            _id: '6186e0df98b8b115f0640352',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3d91',
      opta_ID: 'e1jpg4bty9hgz2onsi5ugt1qs',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Liverpool vs Brighton & Hove Albion',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0df98b8b115f064033b',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f064033c',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f064033d',
        },
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f064033e',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0df98b8b115f0640380',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0df98b8b115f0640381',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0df98b8b115f0640382',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0df98b8b115f0640383',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0df98b8b115f0640384',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0df98b8b115f0640385',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0df98b8b115f0640386',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0df98b8b115f0640387',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0df98b8b115f0640388',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0df98b8b115f0640389',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 10,
            _id: '6186e0df98b8b115f064038a',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0df98b8b115f064038b',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0df98b8b115f064038c',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 14,
            _id: '6186e0df98b8b115f064038d',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 15,
            _id: '6186e0df98b8b115f064038e',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 16,
            _id: '6186e0df98b8b115f064038f',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 17,
            _id: '6186e0df98b8b115f0640390',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 18,
            _id: '6186e0df98b8b115f0640391',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 19,
            _id: '6186e0df98b8b115f0640392',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0df98b8b115f0640393',
          },
        ],
        away: [
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 1,
            _id: '6186e0df98b8b115f064036c',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0df98b8b115f064036d',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0df98b8b115f064036e',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 6,
            _id: '6186e0df98b8b115f064036f',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0df98b8b115f0640370',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 8,
            _id: '6186e0df98b8b115f0640371',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0df98b8b115f0640372',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0df98b8b115f0640373',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0df98b8b115f0640374',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0df98b8b115f0640375',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 10,
            _id: '6186e0df98b8b115f0640376',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 12,
            _id: '6186e0df98b8b115f0640377',
          },
          {
            squad: '6186b4f0a4737fae26aa5878',
            order: 13,
            _id: '6186e0df98b8b115f0640378',
          },
          {
            squad: '6166c87ae65c4ef2043dc7d5',
            order: 14,
            _id: '6186e0df98b8b115f0640379',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 15,
            _id: '6186e0df98b8b115f064037a',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 16,
            _id: '6186e0df98b8b115f064037b',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 17,
            _id: '6186e0df98b8b115f064037c',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 18,
            _id: '6186e0df98b8b115f064037d',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 19,
            _id: '6186e0df98b8b115f064037e',
          },
          {
            squad: '6166c87be65c4ef2043dc9f4',
            order: 20,
            _id: '6186e0df98b8b115f064037f',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3e1c',
      opta_ID: 'e1c28wmnj0w8x8v1ac2yodq8k',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Burnley vs Brentford',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0df98b8b115f0640368',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0df98b8b115f0640369',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0df98b8b115f064036a',
        },
        {
          official: '6166cd54e65c4ef204465bbd',
          type: 'Fourth official',
          _id: '6186e0df98b8b115f064036b',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e098b8b115f06403ad',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 4,
            _id: '6186e0e098b8b115f06403ae',
          },
          {
            squad: '6166c8e1e65c4ef2043ec4b0',
            order: 5,
            _id: '6186e0e098b8b115f06403af',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 6,
            _id: '6186e0e098b8b115f06403b0',
          },
          {
            squad: '6166c8e0e65c4ef2043ec49a',
            order: 3,
            _id: '6186e0e098b8b115f06403b1',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 8,
            _id: '6186e0e098b8b115f06403b2',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 7,
            _id: '6186e0e098b8b115f06403b3',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 2,
            _id: '6186e0e098b8b115f06403b4',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 9,
            _id: '6186e0e098b8b115f06403b5',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 11,
            _id: '6186e0e098b8b115f06403b6',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 10,
            _id: '6186e0e098b8b115f06403b7',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 12,
            _id: '6186e0e098b8b115f06403b8',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 13,
            _id: '6186e0e098b8b115f06403b9',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 14,
            _id: '6186e0e098b8b115f06403ba',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 15,
            _id: '6186e0e098b8b115f06403bb',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 16,
            _id: '6186e0e098b8b115f06403bc',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 17,
            _id: '6186e0e098b8b115f06403bd',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 18,
            _id: '6186e0e098b8b115f06403be',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 19,
            _id: '6186e0e098b8b115f06403bf',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e098b8b115f06403c0',
          },
        ],
        away: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e098b8b115f0640399',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 3,
            _id: '6186e0e098b8b115f064039a',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e098b8b115f064039b',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e098b8b115f064039c',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e098b8b115f064039d',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 11,
            _id: '6186e0e098b8b115f064039e',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 8,
            _id: '6186e0e098b8b115f064039f',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 4,
            _id: '6186e0e098b8b115f06403a0',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 7,
            _id: '6186e0e098b8b115f06403a1',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 10,
            _id: '6186e0e098b8b115f06403a2',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e098b8b115f06403a3',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e098b8b115f06403a4',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 13,
            _id: '6186e0e098b8b115f06403a5',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 14,
            _id: '6186e0e098b8b115f06403a6',
          },
          {
            squad: '6166c85ce65c4ef2043d9c2c',
            order: 15,
            _id: '6186e0e098b8b115f06403a7',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 16,
            _id: '6186e0e098b8b115f06403a8',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e098b8b115f06403a9',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 18,
            _id: '6186e0e098b8b115f06403aa',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 19,
            _id: '6186e0e098b8b115f06403ab',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 20,
            _id: '6186e0e098b8b115f06403ac',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0dea4737fae26ec3e7e',
      opta_ID: 'e1g0547vpf1c4g5ml1frxx250',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-10-30Z',
      description: 'Leicester City vs Arsenal',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e098b8b115f0640395',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 1',
          _id: '6186e0e098b8b115f0640396',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e098b8b115f0640397',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e098b8b115f0640398',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '10',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e298b8b115f064091e',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e298b8b115f064091f',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e298b8b115f0640920',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e298b8b115f0640921',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e298b8b115f0640922',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e298b8b115f0640923',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 4,
            _id: '6186e0e298b8b115f0640924',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e298b8b115f0640925',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 11,
            _id: '6186e0e298b8b115f0640926',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 9,
            _id: '6186e0e298b8b115f0640927',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 10,
            _id: '6186e0e298b8b115f0640928',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 12,
            _id: '6186e0e298b8b115f0640929',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0e298b8b115f064092a',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 14,
            _id: '6186e0e298b8b115f064092b',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 15,
            _id: '6186e0e298b8b115f064092c',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 16,
            _id: '6186e0e298b8b115f064092d',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 17,
            _id: '6186e0e298b8b115f064092e',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 18,
            _id: '6186e0e298b8b115f064092f',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 19,
            _id: '6186e0e298b8b115f0640930',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 20,
            _id: '6186e0e298b8b115f0640931',
          },
        ],
        away: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e298b8b115f064090a',
          },
          {
            squad: '6166c87ee65c4ef2043dce78',
            order: 4,
            _id: '6186e0e298b8b115f064090b',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 5,
            _id: '6186e0e298b8b115f064090c',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 6,
            _id: '6186e0e298b8b115f064090d',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e298b8b115f064090e',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 8,
            _id: '6186e0e298b8b115f064090f',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 7,
            _id: '6186e0e298b8b115f0640910',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0e298b8b115f0640911',
          },
          {
            squad: '6166c880e65c4ef2043dd0c3',
            order: 11,
            _id: '6186e0e298b8b115f0640912',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e298b8b115f0640913',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e298b8b115f0640914',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 12,
            _id: '6186e0e298b8b115f0640915',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 13,
            _id: '6186e0e298b8b115f0640916',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 14,
            _id: '6186e0e298b8b115f0640917',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 15,
            _id: '6186e0e298b8b115f0640918',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 16,
            _id: '6186e0e298b8b115f0640919',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 17,
            _id: '6186e0e298b8b115f064091a',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 18,
            _id: '6186e0e298b8b115f064091b',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 19,
            _id: '6186e0e298b8b115f064091c',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 20,
            _id: '6186e0e298b8b115f064091d',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec4384',
      opta_ID: 'dxbxrv9wmr0itwmr4c7i8134k',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-27Z',
      description: 'Crystal Palace vs Brighton & Hove Albion',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0e298b8b115f0640906',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640907',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640908',
        },
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640909',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e298b8b115f064094b',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e298b8b115f064094c',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e298b8b115f064094d',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e298b8b115f064094e',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e298b8b115f064094f',
          },
          {
            squad: '6166c85be65c4ef2043d9b3b',
            order: 4,
            _id: '6186e0e298b8b115f0640950',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 8,
            _id: '6186e0e298b8b115f0640951',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 11,
            _id: '6186e0e298b8b115f0640952',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 10,
            _id: '6186e0e298b8b115f0640953',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 7,
            _id: '6186e0e298b8b115f0640954',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e298b8b115f0640955',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e298b8b115f0640956',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 13,
            _id: '6186e0e298b8b115f0640957',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 14,
            _id: '6186e0e298b8b115f0640958',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 15,
            _id: '6186e0e298b8b115f0640959',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 16,
            _id: '6186e0e298b8b115f064095a',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 17,
            _id: '6186e0e298b8b115f064095b',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 18,
            _id: '6186e0e298b8b115f064095c',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 19,
            _id: '6186e0e298b8b115f064095d',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e298b8b115f064095e',
          },
        ],
        away: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e298b8b115f0640937',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e298b8b115f0640938',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e298b8b115f0640939',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 5,
            _id: '6186e0e298b8b115f064093a',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 2,
            _id: '6186e0e298b8b115f064093b',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 8,
            _id: '6186e0e298b8b115f064093c',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 4,
            _id: '6186e0e298b8b115f064093d',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 7,
            _id: '6186e0e298b8b115f064093e',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 11,
            _id: '6186e0e298b8b115f064093f',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e298b8b115f0640940',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 10,
            _id: '6186e0e298b8b115f0640941',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e298b8b115f0640942',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 13,
            _id: '6186e0e298b8b115f0640943',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 14,
            _id: '6186e0e298b8b115f0640944',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 15,
            _id: '6186e0e298b8b115f0640945',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 16,
            _id: '6186e0e298b8b115f0640946',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 17,
            _id: '6186e0e298b8b115f0640947',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 18,
            _id: '6186e0e298b8b115f0640948',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 19,
            _id: '6186e0e298b8b115f0640949',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 20,
            _id: '6186e0e298b8b115f064094a',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec439d',
      opta_ID: 'dx0qyslu3jspwnd1kk8etbbx0',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-26Z',
      description: 'Arsenal vs Tottenham Hotspur',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e298b8b115f0640933',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640934',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640935',
        },
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640936',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e298b8b115f0640978',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0e298b8b115f0640979',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e298b8b115f064097a',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 5,
            _id: '6186e0e298b8b115f064097b',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e298b8b115f064097c',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 11,
            _id: '6186e0e298b8b115f064097d',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 8,
            _id: '6186e0e298b8b115f064097e',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 4,
            _id: '6186e0e298b8b115f064097f',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 7,
            _id: '6186e0e298b8b115f0640980',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e298b8b115f0640981',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 10,
            _id: '6186e0e298b8b115f0640982',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 12,
            _id: '6186e0e298b8b115f0640983',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 13,
            _id: '6186e0e298b8b115f0640984',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 14,
            _id: '6186e0e298b8b115f0640985',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 15,
            _id: '6186e0e298b8b115f0640986',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 16,
            _id: '6186e0e298b8b115f0640987',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 17,
            _id: '6186e0e298b8b115f0640988',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 18,
            _id: '6186e0e298b8b115f0640989',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 19,
            _id: '6186e0e298b8b115f064098a',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 20,
            _id: '6186e0e298b8b115f064098b',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e298b8b115f0640964',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e298b8b115f0640965',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e298b8b115f0640966',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e298b8b115f0640967',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e298b8b115f0640968',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 8,
            _id: '6186e0e298b8b115f0640969',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 7,
            _id: '6186e0e298b8b115f064096a',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e298b8b115f064096b',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 11,
            _id: '6186e0e298b8b115f064096c',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e298b8b115f064096d',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 10,
            _id: '6186e0e298b8b115f064096e',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 12,
            _id: '6186e0e298b8b115f064096f',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 13,
            _id: '6186e0e298b8b115f0640970',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 14,
            _id: '6186e0e298b8b115f0640971',
          },
          {
            squad: '6166c980e65c4ef2043fe19f',
            order: 15,
            _id: '6186e0e298b8b115f0640972',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 16,
            _id: '6186e0e298b8b115f0640973',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 17,
            _id: '6186e0e298b8b115f0640974',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 18,
            _id: '6186e0e298b8b115f0640975',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 19,
            _id: '6186e0e298b8b115f0640976',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 20,
            _id: '6186e0e298b8b115f0640977',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43a9',
      opta_ID: 'dxsjbfa9rqww1g4ci9a39395g',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-26Z',
      description: 'Southampton vs Wolverhampton Wanderers',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Main',
          _id: '6186e0e298b8b115f0640960',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640961',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640962',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640963',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e298b8b115f06409a5',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e298b8b115f06409a6',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e298b8b115f06409a7',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e298b8b115f06409a8',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e298b8b115f06409a9',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e298b8b115f06409aa',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e298b8b115f06409ab',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0e298b8b115f06409ac',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e298b8b115f06409ad',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e298b8b115f06409ae',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e298b8b115f06409af',
          },
          {
            squad: '6166c87be65c4ef2043dc970',
            order: 12,
            _id: '6186e0e298b8b115f06409b0',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 13,
            _id: '6186e0e298b8b115f06409b1',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 14,
            _id: '6186e0e298b8b115f06409b2',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 15,
            _id: '6186e0e298b8b115f06409b3',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 16,
            _id: '6186e0e298b8b115f06409b4',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 17,
            _id: '6186e0e298b8b115f06409b5',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 18,
            _id: '6186e0e298b8b115f06409b6',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 19,
            _id: '6186e0e298b8b115f06409b7',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e298b8b115f06409b8',
          },
        ],
        away: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e298b8b115f0640991',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e298b8b115f0640992',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e298b8b115f0640993',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e298b8b115f0640994',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e298b8b115f0640995',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 8,
            _id: '6186e0e298b8b115f0640996',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 4,
            _id: '6186e0e298b8b115f0640997',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 7,
            _id: '6186e0e298b8b115f0640998',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e298b8b115f0640999',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e298b8b115f064099a',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e298b8b115f064099b',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 12,
            _id: '6186e0e298b8b115f064099c',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 13,
            _id: '6186e0e298b8b115f064099d',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 14,
            _id: '6186e0e298b8b115f064099e',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 15,
            _id: '6186e0e298b8b115f064099f',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 16,
            _id: '6186e0e298b8b115f06409a0',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 17,
            _id: '6186e0e298b8b115f06409a1',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 18,
            _id: '6186e0e298b8b115f06409a2',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 19,
            _id: '6186e0e298b8b115f06409a3',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 20,
            _id: '6186e0e298b8b115f06409a4',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43b3',
      opta_ID: 'dx4rh553ulqxuhf9asgs3i6tw',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Brentford vs Liverpool',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e298b8b115f064098d',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f064098e',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f064098f',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640990',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0e298b8b115f06409d2',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 3,
            _id: '6186e0e298b8b115f06409d3',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 6,
            _id: '6186e0e298b8b115f06409d4',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e298b8b115f06409d5',
          },
          {
            squad: '6166c970e65c4ef2043f991b',
            order: 2,
            _id: '6186e0e298b8b115f06409d6',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 4,
            _id: '6186e0e298b8b115f06409d7',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 8,
            _id: '6186e0e298b8b115f06409d8',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 11,
            _id: '6186e0e298b8b115f06409d9',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 10,
            _id: '6186e0e298b8b115f06409da',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e298b8b115f06409db',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e298b8b115f06409dc',
          },
          {
            squad: '6166c96ee65c4ef2043f90b6',
            order: 12,
            _id: '6186e0e298b8b115f06409dd',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 13,
            _id: '6186e0e298b8b115f06409de',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 14,
            _id: '6186e0e298b8b115f06409df',
          },
          {
            squad: '6166c973e65c4ef2043fa40f',
            order: 15,
            _id: '6186e0e298b8b115f06409e0',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 16,
            _id: '6186e0e298b8b115f06409e1',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 17,
            _id: '6186e0e298b8b115f06409e2',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 18,
            _id: '6186e0e298b8b115f06409e3',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 19,
            _id: '6186e0e298b8b115f06409e4',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 20,
            _id: '6186e0e298b8b115f06409e5',
          },
        ],
        away: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0e298b8b115f06409be',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e298b8b115f06409bf',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 6,
            _id: '6186e0e298b8b115f06409c0',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 5,
            _id: '6186e0e298b8b115f06409c1',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e298b8b115f06409c2',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 4,
            _id: '6186e0e298b8b115f06409c3',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 11,
            _id: '6186e0e298b8b115f06409c4',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 10,
            _id: '6186e0e298b8b115f06409c5',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 8,
            _id: '6186e0e298b8b115f06409c6',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 7,
            _id: '6186e0e298b8b115f06409c7',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e298b8b115f06409c8',
          },
          {
            squad: '6166c90ae65c4ef2043efc37',
            order: 12,
            _id: '6186e0e298b8b115f06409c9',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 13,
            _id: '6186e0e298b8b115f06409ca',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e298b8b115f06409cb',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e298b8b115f06409cc',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e298b8b115f06409cd',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 17,
            _id: '6186e0e298b8b115f06409ce',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 18,
            _id: '6186e0e298b8b115f06409cf',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 19,
            _id: '6186e0e298b8b115f06409d0',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 20,
            _id: '6186e0e298b8b115f06409d1',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43c8',
      opta_ID: 'dxw0g31n1q1s5sts50gvi6gb8',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Watford vs Newcastle United',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [
        {
          official: '6166cd65e65c4ef204466d40',
          type: 'Main',
          _id: '6186e0e298b8b115f06409ba',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f06409bb',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f06409bc',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f06409bd',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e298b8b115f06409ff',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 3,
            _id: '6186e0e298b8b115f0640a00',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 6,
            _id: '6186e0e298b8b115f0640a01',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 5,
            _id: '6186e0e298b8b115f0640a02',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e298b8b115f0640a03',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 8,
            _id: '6186e0e298b8b115f0640a04',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e298b8b115f0640a05',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 7,
            _id: '6186e0e298b8b115f0640a06',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e298b8b115f0640a07',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e298b8b115f0640a08',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 10,
            _id: '6186e0e298b8b115f0640a09',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e298b8b115f0640a0a',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 13,
            _id: '6186e0e298b8b115f0640a0b',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 14,
            _id: '6186e0e298b8b115f0640a0c',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 15,
            _id: '6186e0e298b8b115f0640a0d',
          },
          {
            squad: '6166c8e1e65c4ef2043ec4b0',
            order: 16,
            _id: '6186e0e298b8b115f0640a0e',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 17,
            _id: '6186e0e298b8b115f0640a0f',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 18,
            _id: '6186e0e298b8b115f0640a10',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 19,
            _id: '6186e0e298b8b115f0640a11',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e298b8b115f0640a12',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e298b8b115f06409eb',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e298b8b115f06409ec',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e298b8b115f06409ed',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e298b8b115f06409ee',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e298b8b115f06409ef',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 11,
            _id: '6186e0e298b8b115f06409f0',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0e298b8b115f06409f1',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e298b8b115f06409f2',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 7,
            _id: '6186e0e298b8b115f06409f3',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0e298b8b115f06409f4',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 10,
            _id: '6186e0e298b8b115f06409f5',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e298b8b115f06409f6',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0e298b8b115f06409f7',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 14,
            _id: '6186e0e298b8b115f06409f8',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 15,
            _id: '6186e0e298b8b115f06409f9',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 16,
            _id: '6186e0e298b8b115f06409fa',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 17,
            _id: '6186e0e298b8b115f06409fb',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 18,
            _id: '6186e0e298b8b115f06409fc',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 19,
            _id: '6186e0e298b8b115f06409fd',
          },
          {
            squad: '6166c888e65c4ef2043ddbfa',
            order: 20,
            _id: '6186e0e298b8b115f06409fe',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43d5',
      opta_ID: 'dxlt6epr00iijmon262gxxwyc',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Leicester City vs Burnley',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [
        {
          official: '6166cd59e65c4ef2044661f6',
          type: 'Main',
          _id: '6186e0e298b8b115f06409e7',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f06409e8',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f06409e9',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f06409ea',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e298b8b115f0640a2c',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 3,
            _id: '6186e0e298b8b115f0640a2d',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e298b8b115f0640a2e',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 5,
            _id: '6186e0e298b8b115f0640a2f',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 2,
            _id: '6186e0e298b8b115f0640a30',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 4,
            _id: '6186e0e298b8b115f0640a31',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 11,
            _id: '6186e0e298b8b115f0640a32',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 10,
            _id: '6186e0e298b8b115f0640a33',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 8,
            _id: '6186e0e298b8b115f0640a34',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e298b8b115f0640a35',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 9,
            _id: '6186e0e298b8b115f0640a36',
          },
          {
            squad: '6166c8dfe65c4ef2043ec268',
            order: 12,
            _id: '6186e0e298b8b115f0640a37',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 13,
            _id: '6186e0e298b8b115f0640a38',
          },
          {
            squad: '6166c8dee65c4ef2043ec138',
            order: 14,
            _id: '6186e0e298b8b115f0640a39',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 15,
            _id: '6186e0e298b8b115f0640a3a',
          },
          {
            squad: '6166c8dfe65c4ef2043ec339',
            order: 16,
            _id: '6186e0e298b8b115f0640a3b',
          },
          {
            squad: '6166c8dde65c4ef2043ebfe3',
            order: 17,
            _id: '6186e0e298b8b115f0640a3c',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 18,
            _id: '6186e0e298b8b115f0640a3d',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e298b8b115f0640a3e',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e298b8b115f0640a3f',
          },
        ],
        away: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e298b8b115f0640a18',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e298b8b115f0640a19',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e298b8b115f0640a1a',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0e298b8b115f0640a1b',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e298b8b115f0640a1c',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e298b8b115f0640a1d',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e298b8b115f0640a1e',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 11,
            _id: '6186e0e298b8b115f0640a1f',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 10,
            _id: '6186e0e298b8b115f0640a20',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e298b8b115f0640a21',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e298b8b115f0640a22',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e298b8b115f0640a23',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 13,
            _id: '6186e0e298b8b115f0640a24',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 14,
            _id: '6186e0e298b8b115f0640a25',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 15,
            _id: '6186e0e298b8b115f0640a26',
          },
          {
            squad: '6166c979e65c4ef2043fbfe8',
            order: 16,
            _id: '6186e0e298b8b115f0640a27',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 17,
            _id: '6186e0e298b8b115f0640a28',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 18,
            _id: '6186e0e298b8b115f0640a29',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 19,
            _id: '6186e0e298b8b115f0640a2a',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e298b8b115f0640a2b',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43e4',
      opta_ID: 'dxil1295789k60j9aaa85d6ac',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Leeds United vs West Ham United',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd48e65c4ef204464f3f',
          type: 'Main',
          _id: '6186e0e298b8b115f0640a14',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e298b8b115f0640a15',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0e298b8b115f0640a16',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e298b8b115f0640a17',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e398b8b115f0640a59',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e398b8b115f0640a5a',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e398b8b115f0640a5b',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 5,
            _id: '6186e0e398b8b115f0640a5c',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 2,
            _id: '6186e0e398b8b115f0640a5d',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 11,
            _id: '6186e0e398b8b115f0640a5e',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e398b8b115f0640a5f',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e398b8b115f0640a60',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e398b8b115f0640a61',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e398b8b115f0640a62',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 9,
            _id: '6186e0e398b8b115f0640a63',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e398b8b115f0640a64',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 13,
            _id: '6186e0e398b8b115f0640a65',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 14,
            _id: '6186e0e398b8b115f0640a66',
          },
          {
            squad: '6166c8bbe65c4ef2043e32e1',
            order: 15,
            _id: '6186e0e398b8b115f0640a67',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 16,
            _id: '6186e0e398b8b115f0640a68',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 17,
            _id: '6186e0e398b8b115f0640a69',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 18,
            _id: '6186e0e398b8b115f0640a6a',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 19,
            _id: '6186e0e398b8b115f0640a6b',
          },
          {
            squad: '6166c8bbe65c4ef2043e3132',
            order: 20,
            _id: '6186e0e398b8b115f0640a6c',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e398b8b115f0640a45',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 4,
            _id: '6186e0e398b8b115f0640a46',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e398b8b115f0640a47',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 6,
            _id: '6186e0e398b8b115f0640a48',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 3,
            _id: '6186e0e398b8b115f0640a49',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e398b8b115f0640a4a',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 11,
            _id: '6186e0e398b8b115f0640a4b',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 8,
            _id: '6186e0e398b8b115f0640a4c',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e398b8b115f0640a4d',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e398b8b115f0640a4e',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 10,
            _id: '6186e0e398b8b115f0640a4f',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e398b8b115f0640a50',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 13,
            _id: '6186e0e398b8b115f0640a51',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 14,
            _id: '6186e0e398b8b115f0640a52',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 15,
            _id: '6186e0e398b8b115f0640a53',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 16,
            _id: '6186e0e398b8b115f0640a54',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 17,
            _id: '6186e0e398b8b115f0640a55',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 18,
            _id: '6186e0e398b8b115f0640a56',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 19,
            _id: '6186e0e398b8b115f0640a57',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 20,
            _id: '6186e0e398b8b115f0640a58',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec43fb',
      opta_ID: 'dxf8fimr8ax0720agesmbyfis',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Everton vs Norwich City',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0e398b8b115f0640a41',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640a42',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640a43',
        },
        {
          official: '6166cd57e65c4ef204465e99',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640a44',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e398b8b115f0640a86',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e398b8b115f0640a87',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e398b8b115f0640a88',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 5,
            _id: '6186e0e398b8b115f0640a89',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e398b8b115f0640a8a',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e398b8b115f0640a8b',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 8,
            _id: '6186e0e398b8b115f0640a8c',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 11,
            _id: '6186e0e398b8b115f0640a8d',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e398b8b115f0640a8e',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e398b8b115f0640a8f',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0e398b8b115f0640a90',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0c6',
            order: 12,
            _id: '6186e0e398b8b115f0640a91',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0e398b8b115f0640a92',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 14,
            _id: '6186e0e398b8b115f0640a93',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 15,
            _id: '6186e0e398b8b115f0640a94',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 16,
            _id: '6186e0e398b8b115f0640a95',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 17,
            _id: '6186e0e398b8b115f0640a96',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 18,
            _id: '6186e0e398b8b115f0640a97',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 19,
            _id: '6186e0e398b8b115f0640a98',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e398b8b115f0640a99',
          },
        ],
        away: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e398b8b115f0640a72',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e398b8b115f0640a73',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 5,
            _id: '6186e0e398b8b115f0640a74',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e398b8b115f0640a75',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e398b8b115f0640a76',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 8,
            _id: '6186e0e398b8b115f0640a77',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 11,
            _id: '6186e0e398b8b115f0640a78',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0e398b8b115f0640a79',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e398b8b115f0640a7a',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 9,
            _id: '6186e0e398b8b115f0640a7b',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 10,
            _id: '6186e0e398b8b115f0640a7c',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e398b8b115f0640a7d',
          },
          {
            squad: '6166c860e65c4ef2043da394',
            order: 13,
            _id: '6186e0e398b8b115f0640a7e',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 14,
            _id: '6186e0e398b8b115f0640a7f',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 15,
            _id: '6186e0e398b8b115f0640a80',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 16,
            _id: '6186e0e398b8b115f0640a81',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 17,
            _id: '6186e0e398b8b115f0640a82',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 18,
            _id: '6186e0e398b8b115f0640a83',
          },
          {
            squad: '6166c860e65c4ef2043da3df',
            order: 19,
            _id: '6186e0e398b8b115f0640a84',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e398b8b115f0640a85',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec4410',
      opta_ID: 'dxp5b335zdns0nnwl8hbw25uc',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Manchester United vs Aston Villa',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e398b8b115f0640a6e',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640a6f',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640a70',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640a71',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e398b8b115f0640ab3',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e398b8b115f0640ab4',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 5,
            _id: '6186e0e398b8b115f0640ab5',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 6,
            _id: '6186e0e398b8b115f0640ab6',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e398b8b115f0640ab7',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e398b8b115f0640ab8',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 11,
            _id: '6186e0e398b8b115f0640ab9',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 7,
            _id: '6186e0e398b8b115f0640aba',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0e398b8b115f0640abb',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 9,
            _id: '6186e0e398b8b115f0640abc',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 10,
            _id: '6186e0e398b8b115f0640abd',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0e398b8b115f0640abe',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 13,
            _id: '6186e0e398b8b115f0640abf',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 14,
            _id: '6186e0e398b8b115f0640ac0',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 15,
            _id: '6186e0e398b8b115f0640ac1',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 16,
            _id: '6186e0e398b8b115f0640ac2',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 17,
            _id: '6186e0e398b8b115f0640ac3',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 18,
            _id: '6186e0e398b8b115f0640ac4',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 19,
            _id: '6186e0e398b8b115f0640ac5',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0e398b8b115f0640ac6',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e398b8b115f0640a9f',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e398b8b115f0640aa0',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e398b8b115f0640aa1',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e398b8b115f0640aa2',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e398b8b115f0640aa3',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 8,
            _id: '6186e0e398b8b115f0640aa4',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e398b8b115f0640aa5',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 7,
            _id: '6186e0e398b8b115f0640aa6',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e398b8b115f0640aa7',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 9,
            _id: '6186e0e398b8b115f0640aa8',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e398b8b115f0640aa9',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e398b8b115f0640aaa',
          },
          {
            squad: '6166c8f3e65c4ef2043edc59',
            order: 13,
            _id: '6186e0e398b8b115f0640aab',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 14,
            _id: '6186e0e398b8b115f0640aac',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 15,
            _id: '6186e0e398b8b115f0640aad',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 16,
            _id: '6186e0e398b8b115f0640aae',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 17,
            _id: '6186e0e398b8b115f0640aaf',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 18,
            _id: '6186e0e398b8b115f0640ab0',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0e398b8b115f0640ab1',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 20,
            _id: '6186e0e398b8b115f0640ab2',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec441d',
      opta_ID: 'dx8cx9kwgq635wukh9ee7tvys',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-25Z',
      description: 'Chelsea vs Manchester City',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e398b8b115f0640a9b',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640a9c',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640a9d',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640a9e',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '6',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e398b8b115f0640ae0',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e398b8b115f0640ae1',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e398b8b115f0640ae2',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 5,
            _id: '6186e0e398b8b115f0640ae3',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0e398b8b115f0640ae4',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 7,
            _id: '6186e0e398b8b115f0640ae5',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 4,
            _id: '6186e0e398b8b115f0640ae6',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 8,
            _id: '6186e0e398b8b115f0640ae7',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 11,
            _id: '6186e0e398b8b115f0640ae8',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 10,
            _id: '6186e0e398b8b115f0640ae9',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 9,
            _id: '6186e0e398b8b115f0640aea',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e398b8b115f0640aeb',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e398b8b115f0640aec',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e398b8b115f0640aed',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e398b8b115f0640aee',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 16,
            _id: '6186e0e398b8b115f0640aef',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 17,
            _id: '6186e0e398b8b115f0640af0',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 18,
            _id: '6186e0e398b8b115f0640af1',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 19,
            _id: '6186e0e398b8b115f0640af2',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e398b8b115f0640af3',
          },
        ],
        away: [
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 1,
            _id: '6186e0e398b8b115f0640acc',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e398b8b115f0640acd',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0e398b8b115f0640ace',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 6,
            _id: '6186e0e398b8b115f0640acf',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e398b8b115f0640ad0',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e398b8b115f0640ad1',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 7,
            _id: '6186e0e398b8b115f0640ad2',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 2,
            _id: '6186e0e398b8b115f0640ad3',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 11,
            _id: '6186e0e398b8b115f0640ad4',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 10,
            _id: '6186e0e398b8b115f0640ad5',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e398b8b115f0640ad6',
          },
          {
            squad: '6166c89de65c4ef2043dfa75',
            order: 12,
            _id: '6186e0e398b8b115f0640ad7',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 13,
            _id: '6186e0e398b8b115f0640ad8',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 14,
            _id: '6186e0e398b8b115f0640ad9',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 15,
            _id: '6186e0e398b8b115f0640ada',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 16,
            _id: '6186e0e398b8b115f0640adb',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 17,
            _id: '6186e0e398b8b115f0640adc',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 18,
            _id: '6186e0e398b8b115f0640add',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 19,
            _id: '6186e0e398b8b115f0640ade',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 20,
            _id: '6186e0e398b8b115f0640adf',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e1a4737fae26ec4432',
      opta_ID: 'dwooapuei17x6j1c97bxeznys',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-19Z',
      description: 'Tottenham Hotspur vs Chelsea',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e398b8b115f0640ac8',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640ac9',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640aca',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640acb',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e398b8b115f0640b0d',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e398b8b115f0640b0e',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e398b8b115f0640b0f',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 5,
            _id: '6186e0e398b8b115f0640b10',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e398b8b115f0640b11',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 11,
            _id: '6186e0e398b8b115f0640b12',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 8,
            _id: '6186e0e398b8b115f0640b13',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 4,
            _id: '6186e0e398b8b115f0640b14',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 7,
            _id: '6186e0e398b8b115f0640b15',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 9,
            _id: '6186e0e398b8b115f0640b16',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 10,
            _id: '6186e0e398b8b115f0640b17',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e398b8b115f0640b18',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 13,
            _id: '6186e0e398b8b115f0640b19',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 14,
            _id: '6186e0e398b8b115f0640b1a',
          },
          {
            squad: '6166c97be65c4ef2043fc69e',
            order: 15,
            _id: '6186e0e398b8b115f0640b1b',
          },
          {
            squad: '6166c979e65c4ef2043fbfe8',
            order: 16,
            _id: '6186e0e398b8b115f0640b1c',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 17,
            _id: '6186e0e398b8b115f0640b1d',
          },
          {
            squad: '6166c978e65c4ef2043fbb13',
            order: 18,
            _id: '6186e0e398b8b115f0640b1e',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 19,
            _id: '6186e0e398b8b115f0640b1f',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e398b8b115f0640b20',
          },
        ],
        away: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e398b8b115f0640af9',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e398b8b115f0640afa',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e398b8b115f0640afb',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 5,
            _id: '6186e0e398b8b115f0640afc',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e398b8b115f0640afd',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e398b8b115f0640afe',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 8,
            _id: '6186e0e398b8b115f0640aff',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 11,
            _id: '6186e0e398b8b115f0640b00',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e398b8b115f0640b01',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e398b8b115f0640b02',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0e398b8b115f0640b03',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0e398b8b115f0640b04',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0e398b8b115f0640b05',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 14,
            _id: '6186e0e398b8b115f0640b06',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 15,
            _id: '6186e0e398b8b115f0640b07',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 16,
            _id: '6186e0e398b8b115f0640b08',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 17,
            _id: '6186e0e398b8b115f0640b09',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 18,
            _id: '6186e0e398b8b115f0640b0a',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 19,
            _id: '6186e0e398b8b115f0640b0b',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e398b8b115f0640b0c',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4447',
      opta_ID: 'dws0flwcg02pa3grmc32f2mms',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-19Z',
      description: 'West Ham United vs Manchester United',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e398b8b115f0640af5',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640af6',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640af7',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640af8',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e398b8b115f0640b3a',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e398b8b115f0640b3b',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 6,
            _id: '6186e0e398b8b115f0640b3c',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0e398b8b115f0640b3d',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0e398b8b115f0640b3e',
          },
          {
            squad: '6166c880e65c4ef2043dd0c3',
            order: 11,
            _id: '6186e0e398b8b115f0640b3f',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 8,
            _id: '6186e0e398b8b115f0640b40',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 4,
            _id: '6186e0e398b8b115f0640b41',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 7,
            _id: '6186e0e398b8b115f0640b42',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e398b8b115f0640b43',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e398b8b115f0640b44',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 12,
            _id: '6186e0e398b8b115f0640b45',
          },
          {
            squad: '6166c87ee65c4ef2043dce78',
            order: 13,
            _id: '6186e0e398b8b115f0640b46',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 14,
            _id: '6186e0e398b8b115f0640b47',
          },
          {
            squad: '6166c87ee65c4ef2043dcef7',
            order: 15,
            _id: '6186e0e398b8b115f0640b48',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 16,
            _id: '6186e0e398b8b115f0640b49',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 17,
            _id: '6186e0e398b8b115f0640b4a',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 18,
            _id: '6186e0e398b8b115f0640b4b',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 19,
            _id: '6186e0e398b8b115f0640b4c',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 20,
            _id: '6186e0e398b8b115f0640b4d',
          },
        ],
        away: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e398b8b115f0640b26',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 3,
            _id: '6186e0e398b8b115f0640b27',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 6,
            _id: '6186e0e398b8b115f0640b28',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 5,
            _id: '6186e0e398b8b115f0640b29',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e398b8b115f0640b2a',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 8,
            _id: '6186e0e398b8b115f0640b2b',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e398b8b115f0640b2c',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 7,
            _id: '6186e0e398b8b115f0640b2d',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e398b8b115f0640b2e',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e398b8b115f0640b2f',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 10,
            _id: '6186e0e398b8b115f0640b30',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e398b8b115f0640b31',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 13,
            _id: '6186e0e398b8b115f0640b32',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 14,
            _id: '6186e0e398b8b115f0640b33',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 15,
            _id: '6186e0e398b8b115f0640b34',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 16,
            _id: '6186e0e398b8b115f0640b35',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 17,
            _id: '6186e0e398b8b115f0640b36',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 18,
            _id: '6186e0e398b8b115f0640b37',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 19,
            _id: '6186e0e398b8b115f0640b38',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e398b8b115f0640b39',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4456',
      opta_ID: 'dw2zk0s0awnpz6bpm8aitbrpw',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-19Z',
      description: 'Brighton & Hove Albion vs Leicester City',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e398b8b115f0640b22',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640b23',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640b24',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640b25',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e398b8b115f0640b66',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e398b8b115f0640b67',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 5,
            _id: '6186e0e398b8b115f0640b68',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e398b8b115f0640b69',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e398b8b115f0640b6a',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e398b8b115f0640b6b',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 11,
            _id: '6186e0e398b8b115f0640b6c',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 8,
            _id: '6186e0e398b8b115f0640b6d',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 7,
            _id: '6186e0e398b8b115f0640b6e',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 9,
            _id: '6186e0e398b8b115f0640b6f',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 10,
            _id: '6186e0e398b8b115f0640b70',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e398b8b115f0640b71',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 13,
            _id: '6186e0e398b8b115f0640b72',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 14,
            _id: '6186e0e398b8b115f0640b73',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 15,
            _id: '6186e0e398b8b115f0640b74',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 16,
            _id: '6186e0e398b8b115f0640b75',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 17,
            _id: '6186e0e398b8b115f0640b76',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 18,
            _id: '6186e0e398b8b115f0640b77',
          },
          {
            squad: '6166c860e65c4ef2043da3df',
            order: 19,
            _id: '6186e0e398b8b115f0640b78',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e398b8b115f0640b79',
          },
        ],
        away: [
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 1,
            _id: '6186e0e398b8b115f0640b53',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e398b8b115f0640b54',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e398b8b115f0640b55',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 5,
            _id: '6186e0e398b8b115f0640b56',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 2,
            _id: '6186e0e398b8b115f0640b57',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 11,
            _id: '6186e0e398b8b115f0640b58',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e398b8b115f0640b59',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e398b8b115f0640b5a',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e398b8b115f0640b5b',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e398b8b115f0640b5c',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 9,
            _id: '6186e0e398b8b115f0640b5d',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 12,
            _id: '6186e0e398b8b115f0640b5e',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 13,
            _id: '6186e0e398b8b115f0640b5f',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 14,
            _id: '6186e0e398b8b115f0640b60',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 15,
            _id: '6186e0e398b8b115f0640b61',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 16,
            _id: '6186e0e398b8b115f0640b62',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 17,
            _id: '6186e0e398b8b115f0640b63',
          },
          {
            squad: '6166c8bbe65c4ef2043e3132',
            order: 18,
            _id: '6186e0e398b8b115f0640b64',
          },
          {
            squad: '6166c8bde65c4ef2043e3b1c',
            order: 19,
            _id: '6186e0e398b8b115f0640b65',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4462',
      opta_ID: 'dvzcso7mxzcq2u76xd73a893o',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Aston Villa vs Everton',
      home_team: '6166c3dee65c4ef204380414',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e398b8b115f0640b4f',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640b50',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640b51',
        },
        {
          official: '6166cd54e65c4ef204465c20',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640b52',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e398b8b115f0640b93',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 3,
            _id: '6186e0e398b8b115f0640b94',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 6,
            _id: '6186e0e398b8b115f0640b95',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 5,
            _id: '6186e0e398b8b115f0640b96',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e398b8b115f0640b97',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0e398b8b115f0640b98',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 4,
            _id: '6186e0e398b8b115f0640b99',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 7,
            _id: '6186e0e398b8b115f0640b9a',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0e398b8b115f0640b9b',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e398b8b115f0640b9c',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 10,
            _id: '6186e0e398b8b115f0640b9d',
          },
          {
            squad: '6166c914e65c4ef2043f079f',
            order: 12,
            _id: '6186e0e398b8b115f0640b9e',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 13,
            _id: '6186e0e398b8b115f0640b9f',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 14,
            _id: '6186e0e398b8b115f0640ba0',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 15,
            _id: '6186e0e398b8b115f0640ba1',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 16,
            _id: '6186e0e398b8b115f0640ba2',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 17,
            _id: '6186e0e398b8b115f0640ba3',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 18,
            _id: '6186e0e398b8b115f0640ba4',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 19,
            _id: '6186e0e398b8b115f0640ba5',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 20,
            _id: '6186e0e398b8b115f0640ba6',
          },
        ],
        away: [
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 1,
            _id: '6186e0e398b8b115f0640b7f',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 3,
            _id: '6186e0e398b8b115f0640b80',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 6,
            _id: '6186e0e398b8b115f0640b81',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e398b8b115f0640b82',
          },
          {
            squad: '6166c970e65c4ef2043f991b',
            order: 2,
            _id: '6186e0e398b8b115f0640b83',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 4,
            _id: '6186e0e398b8b115f0640b84',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 8,
            _id: '6186e0e398b8b115f0640b85',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 11,
            _id: '6186e0e398b8b115f0640b86',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 10,
            _id: '6186e0e398b8b115f0640b87',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e398b8b115f0640b88',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e398b8b115f0640b89',
          },
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 12,
            _id: '6186e0e398b8b115f0640b8a',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 13,
            _id: '6186e0e398b8b115f0640b8b',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 14,
            _id: '6186e0e398b8b115f0640b8c',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 15,
            _id: '6186e0e398b8b115f0640b8d',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 16,
            _id: '6186e0e398b8b115f0640b8e',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 17,
            _id: '6186e0e398b8b115f0640b8f',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 18,
            _id: '6186e0e398b8b115f0640b90',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 19,
            _id: '6186e0e398b8b115f0640b91',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 20,
            _id: '6186e0e398b8b115f0640b92',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec447a',
      opta_ID: 'dwl5ybf26frypfaof47vz7od0',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Norwich City vs Watford',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Main',
          _id: '6186e0e398b8b115f0640b7b',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640b7c',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640b7d',
        },
        {
          official: '6166cd56e65c4ef204465e1d',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640b7e',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e398b8b115f0640bc0',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e398b8b115f0640bc1',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 6,
            _id: '6186e0e398b8b115f0640bc2',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e398b8b115f0640bc3',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e398b8b115f0640bc4',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 8,
            _id: '6186e0e398b8b115f0640bc5',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 4,
            _id: '6186e0e398b8b115f0640bc6',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 7,
            _id: '6186e0e398b8b115f0640bc7',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e398b8b115f0640bc8',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 9,
            _id: '6186e0e398b8b115f0640bc9',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e398b8b115f0640bca',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9bb',
            order: 12,
            _id: '6186e0e398b8b115f0640bcb',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 13,
            _id: '6186e0e398b8b115f0640bcc',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 14,
            _id: '6186e0e398b8b115f0640bcd',
          },
          {
            squad: '6166c8f4e65c4ef2043edd59',
            order: 15,
            _id: '6186e0e398b8b115f0640bce',
          },
          {
            squad: '6166c8f3e65c4ef2043edc59',
            order: 16,
            _id: '6186e0e398b8b115f0640bcf',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 17,
            _id: '6186e0e398b8b115f0640bd0',
          },
          {
            squad: '6166c8f2e65c4ef2043edaa7',
            order: 18,
            _id: '6186e0e398b8b115f0640bd1',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 19,
            _id: '6186e0e398b8b115f0640bd2',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 20,
            _id: '6186e0e398b8b115f0640bd3',
          },
        ],
        away: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e398b8b115f0640bac',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0e398b8b115f0640bad',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 6,
            _id: '6186e0e398b8b115f0640bae',
          },
          {
            squad: '6166c94fe65c4ef2043f5b1f',
            order: 5,
            _id: '6186e0e398b8b115f0640baf',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e398b8b115f0640bb0',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 11,
            _id: '6186e0e398b8b115f0640bb1',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 8,
            _id: '6186e0e398b8b115f0640bb2',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 4,
            _id: '6186e0e398b8b115f0640bb3',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 7,
            _id: '6186e0e398b8b115f0640bb4',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e398b8b115f0640bb5',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 10,
            _id: '6186e0e398b8b115f0640bb6',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 12,
            _id: '6186e0e398b8b115f0640bb7',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 13,
            _id: '6186e0e398b8b115f0640bb8',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 14,
            _id: '6186e0e398b8b115f0640bb9',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 15,
            _id: '6186e0e398b8b115f0640bba',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 16,
            _id: '6186e0e398b8b115f0640bbb',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 17,
            _id: '6186e0e398b8b115f0640bbc',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 18,
            _id: '6186e0e398b8b115f0640bbd',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 19,
            _id: '6186e0e398b8b115f0640bbe',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 20,
            _id: '6186e0e398b8b115f0640bbf',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4488',
      opta_ID: 'dwe2n0lrtyaoge3cfh5g1ocgk',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Manchester City vs Southampton',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e398b8b115f0640ba8',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640ba9',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640baa',
        },
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640bab',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e398b8b115f0640bed',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 3,
            _id: '6186e0e398b8b115f0640bee',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e398b8b115f0640bef',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 5,
            _id: '6186e0e398b8b115f0640bf0',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 2,
            _id: '6186e0e398b8b115f0640bf1',
          },
          {
            squad: '6166c8ebe65c4ef2043ed203',
            order: 8,
            _id: '6186e0e398b8b115f0640bf2',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 4,
            _id: '6186e0e398b8b115f0640bf3',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 7,
            _id: '6186e0e398b8b115f0640bf4',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e398b8b115f0640bf5',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e398b8b115f0640bf6',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e398b8b115f0640bf7',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 12,
            _id: '6186e0e398b8b115f0640bf8',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 13,
            _id: '6186e0e398b8b115f0640bf9',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 14,
            _id: '6186e0e398b8b115f0640bfa',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 15,
            _id: '6186e0e398b8b115f0640bfb',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 16,
            _id: '6186e0e398b8b115f0640bfc',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 17,
            _id: '6186e0e398b8b115f0640bfd',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 18,
            _id: '6186e0e398b8b115f0640bfe',
          },
          {
            squad: '6166c8eae65c4ef2043ed030',
            order: 19,
            _id: '6186e0e398b8b115f0640bff',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 20,
            _id: '6186e0e398b8b115f0640c00',
          },
        ],
        away: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e398b8b115f0640bd9',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e398b8b115f0640bda',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e398b8b115f0640bdb',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e398b8b115f0640bdc',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e398b8b115f0640bdd',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e398b8b115f0640bde',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0e398b8b115f0640bdf',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e398b8b115f0640be0',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 11,
            _id: '6186e0e398b8b115f0640be1',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e398b8b115f0640be2',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 10,
            _id: '6186e0e398b8b115f0640be3',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 12,
            _id: '6186e0e398b8b115f0640be4',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 13,
            _id: '6186e0e398b8b115f0640be5',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 14,
            _id: '6186e0e398b8b115f0640be6',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 15,
            _id: '6186e0e398b8b115f0640be7',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 16,
            _id: '6186e0e398b8b115f0640be8',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 17,
            _id: '6186e0e398b8b115f0640be9',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 18,
            _id: '6186e0e398b8b115f0640bea',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 19,
            _id: '6186e0e398b8b115f0640beb',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 20,
            _id: '6186e0e398b8b115f0640bec',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec449e',
      opta_ID: 'dwa7gbd8x531e2ytsho9pu904',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Liverpool vs Crystal Palace',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Main',
          _id: '6186e0e398b8b115f0640bd5',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640bd6',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640bd7',
        },
        {
          official: '6166cd57e65c4ef204465f7e',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640bd8',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e398b8b115f0640c1a',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e398b8b115f0640c1b',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e398b8b115f0640c1c',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e398b8b115f0640c1d',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e398b8b115f0640c1e',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e398b8b115f0640c1f',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0e398b8b115f0640c20',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e398b8b115f0640c21',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0e398b8b115f0640c22',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 9,
            _id: '6186e0e398b8b115f0640c23',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 10,
            _id: '6186e0e398b8b115f0640c24',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e398b8b115f0640c25',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 13,
            _id: '6186e0e398b8b115f0640c26',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 14,
            _id: '6186e0e398b8b115f0640c27',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 15,
            _id: '6186e0e398b8b115f0640c28',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 16,
            _id: '6186e0e398b8b115f0640c29',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 17,
            _id: '6186e0e398b8b115f0640c2a',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 18,
            _id: '6186e0e398b8b115f0640c2b',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 19,
            _id: '6186e0e398b8b115f0640c2c',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0e398b8b115f0640c2d',
          },
        ],
        away: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e398b8b115f0640c06',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e398b8b115f0640c07',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e398b8b115f0640c08',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e398b8b115f0640c09',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e398b8b115f0640c0a',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 4,
            _id: '6186e0e398b8b115f0640c0b',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 11,
            _id: '6186e0e398b8b115f0640c0c',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 10,
            _id: '6186e0e398b8b115f0640c0d',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 8,
            _id: '6186e0e398b8b115f0640c0e',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 7,
            _id: '6186e0e398b8b115f0640c0f',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e398b8b115f0640c10',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e398b8b115f0640c11',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 13,
            _id: '6186e0e398b8b115f0640c12',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 14,
            _id: '6186e0e398b8b115f0640c13',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 15,
            _id: '6186e0e398b8b115f0640c14',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 16,
            _id: '6186e0e398b8b115f0640c15',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 17,
            _id: '6186e0e398b8b115f0640c16',
          },
          {
            squad: '6166c85de65c4ef2043d9df8',
            order: 18,
            _id: '6186e0e398b8b115f0640c17',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 19,
            _id: '6186e0e398b8b115f0640c18',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e398b8b115f0640c19',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec44b1',
      opta_ID: 'dw6ic525flbgkqszb3v4xt6ac',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Burnley vs Arsenal',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e398b8b115f0640c02',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640c03',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640c04',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640c05',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e398b8b115f0640c47',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e398b8b115f0640c48',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e398b8b115f0640c49',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e398b8b115f0640c4a',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e398b8b115f0640c4b',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e398b8b115f0640c4c',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e398b8b115f0640c4d',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e398b8b115f0640c4e',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e398b8b115f0640c4f',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e398b8b115f0640c50',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e398b8b115f0640c51',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e398b8b115f0640c52',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e398b8b115f0640c53',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 14,
            _id: '6186e0e398b8b115f0640c54',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0e398b8b115f0640c55',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 16,
            _id: '6186e0e398b8b115f0640c56',
          },
          {
            squad: '6166c981e65c4ef2043fe3be',
            order: 17,
            _id: '6186e0e398b8b115f0640c57',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 18,
            _id: '6186e0e398b8b115f0640c58',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 19,
            _id: '6186e0e398b8b115f0640c59',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0e398b8b115f0640c5a',
          },
        ],
        away: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e398b8b115f0640c33',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e398b8b115f0640c34',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e398b8b115f0640c35',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e398b8b115f0640c36',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e398b8b115f0640c37',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e398b8b115f0640c38',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e398b8b115f0640c39',
          },
          {
            squad: '6166c87be65c4ef2043dc970',
            order: 7,
            _id: '6186e0e398b8b115f0640c3a',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e398b8b115f0640c3b',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e398b8b115f0640c3c',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e398b8b115f0640c3d',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 12,
            _id: '6186e0e398b8b115f0640c3e',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 13,
            _id: '6186e0e398b8b115f0640c3f',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 14,
            _id: '6186e0e398b8b115f0640c40',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 15,
            _id: '6186e0e398b8b115f0640c41',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 16,
            _id: '6186e0e398b8b115f0640c42',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 17,
            _id: '6186e0e398b8b115f0640c43',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0e398b8b115f0640c44',
          },
          {
            squad: '6166c87be65c4ef2043dc94a',
            order: 19,
            _id: '6186e0e398b8b115f0640c45',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e398b8b115f0640c46',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec44c1',
      opta_ID: 'dwx8wdhsqluepxeeoumrpfa50',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-18Z',
      description: 'Wolverhampton Wanderers vs Brentford',
      home_team: '6166c3dee65c4ef204380695',
      officials: [
        {
          official: '6166cd55e65c4ef204465cb4',
          type: 'Main',
          _id: '6186e0e398b8b115f0640c2f',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640c30',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640c31',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640c32',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 1,
            _id: '6186e0e398b8b115f0640c74',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0e398b8b115f0640c75',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e398b8b115f0640c76',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 6,
            _id: '6186e0e398b8b115f0640c77',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e398b8b115f0640c78',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e398b8b115f0640c79',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 11,
            _id: '6186e0e398b8b115f0640c7a',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 10,
            _id: '6186e0e398b8b115f0640c7b',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 8,
            _id: '6186e0e398b8b115f0640c7c',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 7,
            _id: '6186e0e398b8b115f0640c7d',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e398b8b115f0640c7e',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 12,
            _id: '6186e0e398b8b115f0640c7f',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 13,
            _id: '6186e0e398b8b115f0640c80',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e398b8b115f0640c81',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e398b8b115f0640c82',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e398b8b115f0640c83',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 17,
            _id: '6186e0e398b8b115f0640c84',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 18,
            _id: '6186e0e398b8b115f0640c85',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 19,
            _id: '6186e0e398b8b115f0640c86',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 20,
            _id: '6186e0e398b8b115f0640c87',
          },
        ],
        away: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e398b8b115f0640c60',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 3,
            _id: '6186e0e398b8b115f0640c61',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e398b8b115f0640c62',
          },
          {
            squad: '6166c8dee65c4ef2043ec1a8',
            order: 5,
            _id: '6186e0e398b8b115f0640c63',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 2,
            _id: '6186e0e398b8b115f0640c64',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 4,
            _id: '6186e0e398b8b115f0640c65',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 11,
            _id: '6186e0e398b8b115f0640c66',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 10,
            _id: '6186e0e398b8b115f0640c67',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 8,
            _id: '6186e0e398b8b115f0640c68',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e398b8b115f0640c69',
          },
          {
            squad: '6166c8dee65c4ef2043ec120',
            order: 9,
            _id: '6186e0e398b8b115f0640c6a',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e398b8b115f0640c6b',
          },
          {
            squad: '6166c8dee65c4ef2043ec138',
            order: 13,
            _id: '6186e0e398b8b115f0640c6c',
          },
          {
            squad: '6166c8dfe65c4ef2043ec304',
            order: 14,
            _id: '6186e0e398b8b115f0640c6d',
          },
          {
            squad: '6166c8dde65c4ef2043ebfe3',
            order: 15,
            _id: '6186e0e398b8b115f0640c6e',
          },
          {
            squad: '6166c8dde65c4ef2043ec0a1',
            order: 16,
            _id: '6186e0e398b8b115f0640c6f',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 17,
            _id: '6186e0e398b8b115f0640c70',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 18,
            _id: '6186e0e398b8b115f0640c71',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e398b8b115f0640c72',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e398b8b115f0640c73',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec44d7',
      opta_ID: 'dwhp2g31zwx8vls15vgxx1l3o',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-17Z',
      description: 'Newcastle United vs Leeds United',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e398b8b115f0640c5c',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640c5d',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640c5e',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640c5f',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '5',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e398b8b115f0640ca1',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 4,
            _id: '6186e0e398b8b115f0640ca2',
          },
          {
            squad: '6166c8bce65c4ef2043e36a5',
            order: 5,
            _id: '6186e0e398b8b115f0640ca3',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 6,
            _id: '6186e0e398b8b115f0640ca4',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e398b8b115f0640ca5',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e398b8b115f0640ca6',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 7,
            _id: '6186e0e398b8b115f0640ca7',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e398b8b115f0640ca8',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 11,
            _id: '6186e0e398b8b115f0640ca9',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 9,
            _id: '6186e0e398b8b115f0640caa',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 10,
            _id: '6186e0e398b8b115f0640cab',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e398b8b115f0640cac',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 13,
            _id: '6186e0e398b8b115f0640cad',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 14,
            _id: '6186e0e398b8b115f0640cae',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 15,
            _id: '6186e0e398b8b115f0640caf',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 16,
            _id: '6186e0e398b8b115f0640cb0',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 17,
            _id: '6186e0e398b8b115f0640cb1',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 18,
            _id: '6186e0e398b8b115f0640cb2',
          },
          {
            squad: '6166c8bae65c4ef2043e2d00',
            order: 19,
            _id: '6186e0e398b8b115f0640cb3',
          },
          {
            squad: '6166c8bde65c4ef2043e3b1c',
            order: 20,
            _id: '6186e0e398b8b115f0640cb4',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e398b8b115f0640c8d',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e398b8b115f0640c8e',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e398b8b115f0640c8f',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e398b8b115f0640c90',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e398b8b115f0640c91',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e398b8b115f0640c92',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0e398b8b115f0640c93',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e398b8b115f0640c94',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0e398b8b115f0640c95',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 9,
            _id: '6186e0e398b8b115f0640c96',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 10,
            _id: '6186e0e398b8b115f0640c97',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e398b8b115f0640c98',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 13,
            _id: '6186e0e398b8b115f0640c99',
          },
          {
            squad: '6166c88ae65c4ef2043ddf55',
            order: 14,
            _id: '6186e0e398b8b115f0640c9a',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0e398b8b115f0640c9b',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 16,
            _id: '6186e0e398b8b115f0640c9c',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 17,
            _id: '6186e0e398b8b115f0640c9d',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 18,
            _id: '6186e0e398b8b115f0640c9e',
          },
          {
            squad: '6166c888e65c4ef2043ddbfa',
            order: 19,
            _id: '6186e0e398b8b115f0640c9f',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0e398b8b115f0640ca0',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec44e7',
      opta_ID: 'dvdcndzdm2e9j5rrp36kml2j8',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-13Z',
      description: 'Everton vs Burnley',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e398b8b115f0640c89',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e398b8b115f0640c8a',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 2',
          _id: '6186e0e398b8b115f0640c8b',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e398b8b115f0640c8c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e498b8b115f0640cce',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 3,
            _id: '6186e0e498b8b115f0640ccf',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e498b8b115f0640cd0',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 5,
            _id: '6186e0e498b8b115f0640cd1',
          },
          {
            squad: '6166c8dee65c4ef2043ec1a8',
            order: 2,
            _id: '6186e0e498b8b115f0640cd2',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 4,
            _id: '6186e0e498b8b115f0640cd3',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 11,
            _id: '6186e0e498b8b115f0640cd4',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 10,
            _id: '6186e0e498b8b115f0640cd5',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 8,
            _id: '6186e0e498b8b115f0640cd6',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e498b8b115f0640cd7',
          },
          {
            squad: '6166c8dee65c4ef2043ec120',
            order: 9,
            _id: '6186e0e498b8b115f0640cd8',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e498b8b115f0640cd9',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 13,
            _id: '6186e0e498b8b115f0640cda',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 14,
            _id: '6186e0e498b8b115f0640cdb',
          },
          {
            squad: '6166c8dde65c4ef2043ebfe3',
            order: 15,
            _id: '6186e0e498b8b115f0640cdc',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 16,
            _id: '6186e0e498b8b115f0640cdd',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 17,
            _id: '6186e0e498b8b115f0640cde',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 18,
            _id: '6186e0e498b8b115f0640cdf',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e498b8b115f0640ce0',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e498b8b115f0640ce1',
          },
        ],
        away: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e498b8b115f0640cba',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e498b8b115f0640cbb',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e498b8b115f0640cbc',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e498b8b115f0640cbd',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e498b8b115f0640cbe',
          },
          {
            squad: '6166c8ebe65c4ef2043ed203',
            order: 8,
            _id: '6186e0e498b8b115f0640cbf',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 4,
            _id: '6186e0e498b8b115f0640cc0',
          },
          {
            squad: '6166c8ece65c4ef2043ed287',
            order: 7,
            _id: '6186e0e498b8b115f0640cc1',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e498b8b115f0640cc2',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e498b8b115f0640cc3',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e498b8b115f0640cc4',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 12,
            _id: '6186e0e498b8b115f0640cc5',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 13,
            _id: '6186e0e498b8b115f0640cc6',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 14,
            _id: '6186e0e498b8b115f0640cc7',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 15,
            _id: '6186e0e498b8b115f0640cc8',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 16,
            _id: '6186e0e498b8b115f0640cc9',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 17,
            _id: '6186e0e498b8b115f0640cca',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 18,
            _id: '6186e0e498b8b115f0640ccb',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 19,
            _id: '6186e0e498b8b115f0640ccc',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 20,
            _id: '6186e0e498b8b115f0640ccd',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4501',
      opta_ID: 'dvgr64owsy1921p6w7wf71f6c',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-12Z',
      description: 'Leeds United vs Liverpool',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e498b8b115f0640cb6',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640cb7',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640cb8',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640cb9',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e498b8b115f0640cfb',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e498b8b115f0640cfc',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 5,
            _id: '6186e0e498b8b115f0640cfd',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 6,
            _id: '6186e0e498b8b115f0640cfe',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e498b8b115f0640cff',
          },
          {
            squad: '6166c89be65c4ef2043df845',
            order: 8,
            _id: '6186e0e498b8b115f0640d00',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 7,
            _id: '6186e0e498b8b115f0640d01',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 2,
            _id: '6186e0e498b8b115f0640d02',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 11,
            _id: '6186e0e498b8b115f0640d03',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 10,
            _id: '6186e0e498b8b115f0640d04',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e498b8b115f0640d05',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0e498b8b115f0640d06',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 13,
            _id: '6186e0e498b8b115f0640d07',
          },
          {
            squad: '6166c89ee65c4ef2043dfbd4',
            order: 14,
            _id: '6186e0e498b8b115f0640d08',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 15,
            _id: '6186e0e498b8b115f0640d09',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 16,
            _id: '6186e0e498b8b115f0640d0a',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 17,
            _id: '6186e0e498b8b115f0640d0b',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 18,
            _id: '6186e0e498b8b115f0640d0c',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 19,
            _id: '6186e0e498b8b115f0640d0d',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 20,
            _id: '6186e0e498b8b115f0640d0e',
          },
        ],
        away: [
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 1,
            _id: '6186e0e498b8b115f0640ce7',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 4,
            _id: '6186e0e498b8b115f0640ce8',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 5,
            _id: '6186e0e498b8b115f0640ce9',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 6,
            _id: '6186e0e498b8b115f0640cea',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e498b8b115f0640ceb',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e498b8b115f0640cec',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 11,
            _id: '6186e0e498b8b115f0640ced',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 8,
            _id: '6186e0e498b8b115f0640cee',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 7,
            _id: '6186e0e498b8b115f0640cef',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 9,
            _id: '6186e0e498b8b115f0640cf0',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 10,
            _id: '6186e0e498b8b115f0640cf1',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e498b8b115f0640cf2',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 13,
            _id: '6186e0e498b8b115f0640cf3',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 14,
            _id: '6186e0e498b8b115f0640cf4',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 15,
            _id: '6186e0e498b8b115f0640cf5',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 16,
            _id: '6186e0e498b8b115f0640cf6',
          },
          {
            squad: '6166c862e65c4ef2043da5ef',
            order: 17,
            _id: '6186e0e498b8b115f0640cf7',
          },
          {
            squad: '6166c85ee65c4ef2043d9f73',
            order: 18,
            _id: '6186e0e498b8b115f0640cf8',
          },
          {
            squad: '6166c860e65c4ef2043da3df',
            order: 19,
            _id: '6186e0e498b8b115f0640cf9',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 20,
            _id: '6186e0e498b8b115f0640cfa',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4517',
      opta_ID: 'dv4f9c0hutkmhjfyhme33lwd0',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Chelsea vs Aston Villa',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e498b8b115f0640ce3',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640ce4',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640ce5',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640ce6',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 1,
            _id: '6186e0e498b8b115f0640d28',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 3,
            _id: '6186e0e498b8b115f0640d29',
          },
          {
            squad: '6166c972e65c4ef2043f9e69',
            order: 6,
            _id: '6186e0e498b8b115f0640d2a',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e498b8b115f0640d2b',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 2,
            _id: '6186e0e498b8b115f0640d2c',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 4,
            _id: '6186e0e498b8b115f0640d2d',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 11,
            _id: '6186e0e498b8b115f0640d2e',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 10,
            _id: '6186e0e498b8b115f0640d2f',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 8,
            _id: '6186e0e498b8b115f0640d30',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e498b8b115f0640d31',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e498b8b115f0640d32',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 12,
            _id: '6186e0e498b8b115f0640d33',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 13,
            _id: '6186e0e498b8b115f0640d34',
          },
          {
            squad: '6166c96ee65c4ef2043f90b6',
            order: 14,
            _id: '6186e0e498b8b115f0640d35',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 15,
            _id: '6186e0e498b8b115f0640d36',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 16,
            _id: '6186e0e498b8b115f0640d37',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 17,
            _id: '6186e0e498b8b115f0640d38',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 18,
            _id: '6186e0e498b8b115f0640d39',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 19,
            _id: '6186e0e498b8b115f0640d3a',
          },
          {
            squad: '6166c970e65c4ef2043f97cb',
            order: 20,
            _id: '6186e0e498b8b115f0640d3b',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e498b8b115f0640d14',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e498b8b115f0640d15',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e498b8b115f0640d16',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e498b8b115f0640d17',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e498b8b115f0640d18',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e498b8b115f0640d19',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e498b8b115f0640d1a',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e498b8b115f0640d1b',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e498b8b115f0640d1c',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e498b8b115f0640d1d',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e498b8b115f0640d1e',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e498b8b115f0640d1f',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e498b8b115f0640d20',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 14,
            _id: '6186e0e498b8b115f0640d21',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 15,
            _id: '6186e0e498b8b115f0640d22',
          },
          {
            squad: '6166c981e65c4ef2043fe734',
            order: 16,
            _id: '6186e0e498b8b115f0640d23',
          },
          {
            squad: '6166c981e65c4ef2043fe3be',
            order: 17,
            _id: '6186e0e498b8b115f0640d24',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 18,
            _id: '6186e0e498b8b115f0640d25',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 19,
            _id: '6186e0e498b8b115f0640d26',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0e498b8b115f0640d27',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e2a4737fae26ec4538',
      opta_ID: 'dvw0daph9nr1po2ktvek7mdqs',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Watford vs Wolverhampton Wanderers',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Main',
          _id: '6186e0e498b8b115f0640d10',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640d11',
        },
        {
          official: '6166cd55e65c4ef204465cc7',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640d12',
        },
        {
          official: '6166cd44e65c4ef204464ba8',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640d13',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e498b8b115f0640d55',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 3,
            _id: '6186e0e498b8b115f0640d56',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e498b8b115f0640d57',
          },
          {
            squad: '6166c94fe65c4ef2043f5b1f',
            order: 5,
            _id: '6186e0e498b8b115f0640d58',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e498b8b115f0640d59',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 4,
            _id: '6186e0e498b8b115f0640d5a',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 11,
            _id: '6186e0e498b8b115f0640d5b',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 10,
            _id: '6186e0e498b8b115f0640d5c',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 8,
            _id: '6186e0e498b8b115f0640d5d',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 7,
            _id: '6186e0e498b8b115f0640d5e',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e498b8b115f0640d5f',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 12,
            _id: '6186e0e498b8b115f0640d60',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 13,
            _id: '6186e0e498b8b115f0640d61',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 14,
            _id: '6186e0e498b8b115f0640d62',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 15,
            _id: '6186e0e498b8b115f0640d63',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 16,
            _id: '6186e0e498b8b115f0640d64',
          },
          {
            squad: '6166c94de65c4ef2043f5827',
            order: 17,
            _id: '6186e0e498b8b115f0640d65',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0e498b8b115f0640d66',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0e498b8b115f0640d67',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 20,
            _id: '6186e0e498b8b115f0640d68',
          },
        ],
        away: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e498b8b115f0640d41',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e498b8b115f0640d42',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e498b8b115f0640d43',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 5,
            _id: '6186e0e498b8b115f0640d44',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e498b8b115f0640d45',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e498b8b115f0640d46',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e498b8b115f0640d47',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 11,
            _id: '6186e0e498b8b115f0640d48',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 10,
            _id: '6186e0e498b8b115f0640d49',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e498b8b115f0640d4a',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e498b8b115f0640d4b',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e498b8b115f0640d4c',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 13,
            _id: '6186e0e498b8b115f0640d4d',
          },
          {
            squad: '6166c97be65c4ef2043fc69e',
            order: 14,
            _id: '6186e0e498b8b115f0640d4e',
          },
          {
            squad: '6166c979e65c4ef2043fbfe8',
            order: 15,
            _id: '6186e0e498b8b115f0640d4f',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 16,
            _id: '6186e0e498b8b115f0640d50',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 17,
            _id: '6186e0e498b8b115f0640d51',
          },
          {
            squad: '6166c97ae65c4ef2043fc4a4',
            order: 18,
            _id: '6186e0e498b8b115f0640d52',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 19,
            _id: '6186e0e498b8b115f0640d53',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 20,
            _id: '6186e0e498b8b115f0640d54',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4555',
      opta_ID: 'dvski02rco5fpmfdt9yho6784',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Southampton vs West Ham United',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0e498b8b115f0640d3d',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640d3e',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640d3f',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640d40',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e498b8b115f0640d82',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e498b8b115f0640d83',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e498b8b115f0640d84',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 5,
            _id: '6186e0e498b8b115f0640d85',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e498b8b115f0640d86',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 4,
            _id: '6186e0e498b8b115f0640d87',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 8,
            _id: '6186e0e498b8b115f0640d88',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 11,
            _id: '6186e0e498b8b115f0640d89',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e498b8b115f0640d8a',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e498b8b115f0640d8b',
          },
          {
            squad: '6166c8f8e65c4ef2043ee30c',
            order: 9,
            _id: '6186e0e498b8b115f0640d8c',
          },
          {
            squad: '6166c8f6e65c4ef2043ee08e',
            order: 12,
            _id: '6186e0e498b8b115f0640d8d',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0e498b8b115f0640d8e',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 14,
            _id: '6186e0e498b8b115f0640d8f',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 15,
            _id: '6186e0e498b8b115f0640d90',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 16,
            _id: '6186e0e498b8b115f0640d91',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 17,
            _id: '6186e0e498b8b115f0640d92',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 18,
            _id: '6186e0e498b8b115f0640d93',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 19,
            _id: '6186e0e498b8b115f0640d94',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e498b8b115f0640d95',
          },
        ],
        away: [
          {
            squad: '6166c908e65c4ef2043ef9c9',
            order: 1,
            _id: '6186e0e498b8b115f0640d6e',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0e498b8b115f0640d6f',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e498b8b115f0640d70',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 6,
            _id: '6186e0e498b8b115f0640d71',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e498b8b115f0640d72',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 2,
            _id: '6186e0e498b8b115f0640d73',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 11,
            _id: '6186e0e498b8b115f0640d74',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 10,
            _id: '6186e0e498b8b115f0640d75',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 8,
            _id: '6186e0e498b8b115f0640d76',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 7,
            _id: '6186e0e498b8b115f0640d77',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e498b8b115f0640d78',
          },
          {
            squad: '6166c90ae65c4ef2043efbd8',
            order: 12,
            _id: '6186e0e498b8b115f0640d79',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 13,
            _id: '6186e0e498b8b115f0640d7a',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e498b8b115f0640d7b',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 15,
            _id: '6186e0e498b8b115f0640d7c',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 16,
            _id: '6186e0e498b8b115f0640d7d',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 17,
            _id: '6186e0e498b8b115f0640d7e',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 18,
            _id: '6186e0e498b8b115f0640d7f',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 19,
            _id: '6186e0e498b8b115f0640d80',
          },
          {
            squad: '6166c909e65c4ef2043efaf6',
            order: 20,
            _id: '6186e0e498b8b115f0640d81',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4569',
      opta_ID: 'dvp5g6bg6qs26mwcozubs0owk',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Manchester United vs Newcastle United',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e498b8b115f0640d6a',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640d6b',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640d6c',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640d6d',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e498b8b115f0640daf',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5d2',
            order: 3,
            _id: '6186e0e498b8b115f0640db0',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 6,
            _id: '6186e0e498b8b115f0640db1',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 5,
            _id: '6186e0e498b8b115f0640db2',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 2,
            _id: '6186e0e498b8b115f0640db3',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e498b8b115f0640db4',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 8,
            _id: '6186e0e498b8b115f0640db5',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e498b8b115f0640db6',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 10,
            _id: '6186e0e498b8b115f0640db7',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 7,
            _id: '6186e0e498b8b115f0640db8',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e498b8b115f0640db9',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 12,
            _id: '6186e0e498b8b115f0640dba',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 13,
            _id: '6186e0e498b8b115f0640dbb',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 14,
            _id: '6186e0e498b8b115f0640dbc',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 15,
            _id: '6186e0e498b8b115f0640dbd',
          },
          {
            squad: '6166c8e1e65c4ef2043ec4b0',
            order: 16,
            _id: '6186e0e498b8b115f0640dbe',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 17,
            _id: '6186e0e498b8b115f0640dbf',
          },
          {
            squad: '6166c8e1e65c4ef2043ec52c',
            order: 18,
            _id: '6186e0e498b8b115f0640dc0',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 19,
            _id: '6186e0e498b8b115f0640dc1',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e498b8b115f0640dc2',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e498b8b115f0640d9b',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e498b8b115f0640d9c',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e498b8b115f0640d9d',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e498b8b115f0640d9e',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e498b8b115f0640d9f',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 8,
            _id: '6186e0e498b8b115f0640da0',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e498b8b115f0640da1',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 7,
            _id: '6186e0e498b8b115f0640da2',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e498b8b115f0640da3',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 9,
            _id: '6186e0e498b8b115f0640da4',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e498b8b115f0640da5',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e498b8b115f0640da6',
          },
          {
            squad: '6166c8f1e65c4ef2043ed9bb',
            order: 13,
            _id: '6186e0e498b8b115f0640da7',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 14,
            _id: '6186e0e498b8b115f0640da8',
          },
          {
            squad: '6166c8f1e65c4ef2043ed997',
            order: 15,
            _id: '6186e0e498b8b115f0640da9',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 16,
            _id: '6186e0e498b8b115f0640daa',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 17,
            _id: '6186e0e498b8b115f0640dab',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 18,
            _id: '6186e0e498b8b115f0640dac',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0e498b8b115f0640dad',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0e498b8b115f0640dae',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4583',
      opta_ID: 'dvl2fv9g6o5f9ky0t0kvdn5sk',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Leicester City vs Manchester City',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e498b8b115f0640d97',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640d98',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640d99',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640d9a',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e498b8b115f0640ddc',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e498b8b115f0640ddd',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e498b8b115f0640dde',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e498b8b115f0640ddf',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e498b8b115f0640de0',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e498b8b115f0640de1',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e498b8b115f0640de2',
          },
          {
            squad: '6166c87be65c4ef2043dc970',
            order: 7,
            _id: '6186e0e498b8b115f0640de3',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e498b8b115f0640de4',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e498b8b115f0640de5',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e498b8b115f0640de6',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 12,
            _id: '6186e0e498b8b115f0640de7',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 13,
            _id: '6186e0e498b8b115f0640de8',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 14,
            _id: '6186e0e498b8b115f0640de9',
          },
          {
            squad: '6166c87ae65c4ef2043dc8b4',
            order: 15,
            _id: '6186e0e498b8b115f0640dea',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 16,
            _id: '6186e0e498b8b115f0640deb',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 17,
            _id: '6186e0e498b8b115f0640dec',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 18,
            _id: '6186e0e498b8b115f0640ded',
          },
          {
            squad: '6166c87be65c4ef2043dc94a',
            order: 19,
            _id: '6186e0e498b8b115f0640dee',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e498b8b115f0640def',
          },
        ],
        away: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e498b8b115f0640dc8',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 4,
            _id: '6186e0e498b8b115f0640dc9',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0e498b8b115f0640dca',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 6,
            _id: '6186e0e498b8b115f0640dcb',
          },
          {
            squad: '6166c87ee65c4ef2043dcedb',
            order: 3,
            _id: '6186e0e498b8b115f0640dcc',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 8,
            _id: '6186e0e498b8b115f0640dcd',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 7,
            _id: '6186e0e498b8b115f0640dce',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 2,
            _id: '6186e0e498b8b115f0640dcf',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 9,
            _id: '6186e0e498b8b115f0640dd0',
          },
          {
            squad: '6166c880e65c4ef2043dd0c3',
            order: 11,
            _id: '6186e0e498b8b115f0640dd1',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 10,
            _id: '6186e0e498b8b115f0640dd2',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 12,
            _id: '6186e0e498b8b115f0640dd3',
          },
          {
            squad: '6166c87fe65c4ef2043dd07a',
            order: 13,
            _id: '6186e0e498b8b115f0640dd4',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 14,
            _id: '6186e0e498b8b115f0640dd5',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 15,
            _id: '6186e0e498b8b115f0640dd6',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 16,
            _id: '6186e0e498b8b115f0640dd7',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 17,
            _id: '6186e0e498b8b115f0640dd8',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 18,
            _id: '6186e0e498b8b115f0640dd9',
          },
          {
            squad: '6166c87ee65c4ef2043dcf13',
            order: 19,
            _id: '6186e0e498b8b115f0640dda',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 20,
            _id: '6186e0e498b8b115f0640ddb',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4591',
      opta_ID: 'dv14nve1ly6p1ff2ypsu1te6s',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Brentford vs Brighton & Hove Albion',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Main',
          _id: '6186e0e498b8b115f0640dc4',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640dc5',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640dc6',
        },
        {
          official: '6166cd57e65c4ef204465e99',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640dc7',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 1,
            _id: '6186e0e498b8b115f0640e09',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e498b8b115f0640e0a',
          },
          {
            squad: '6166c85be65c4ef2043d9bdc',
            order: 6,
            _id: '6186e0e498b8b115f0640e0b',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e498b8b115f0640e0c',
          },
          {
            squad: '6166c859e65c4ef2043d9933',
            order: 2,
            _id: '6186e0e498b8b115f0640e0d',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 4,
            _id: '6186e0e498b8b115f0640e0e',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 8,
            _id: '6186e0e498b8b115f0640e0f',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 11,
            _id: '6186e0e498b8b115f0640e10',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 10,
            _id: '6186e0e498b8b115f0640e11',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 7,
            _id: '6186e0e498b8b115f0640e12',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e498b8b115f0640e13',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e498b8b115f0640e14',
          },
          {
            squad: '6166c859e65c4ef2043d9917',
            order: 13,
            _id: '6186e0e498b8b115f0640e15',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 14,
            _id: '6186e0e498b8b115f0640e16',
          },
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 15,
            _id: '6186e0e498b8b115f0640e17',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 16,
            _id: '6186e0e498b8b115f0640e18',
          },
          {
            squad: '6166c85de65c4ef2043d9df8',
            order: 17,
            _id: '6186e0e498b8b115f0640e19',
          },
          {
            squad: '6166c85ce65c4ef2043d9d3b',
            order: 18,
            _id: '6186e0e498b8b115f0640e1a',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 19,
            _id: '6186e0e498b8b115f0640e1b',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e498b8b115f0640e1c',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e498b8b115f0640df5',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 3,
            _id: '6186e0e498b8b115f0640df6',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 6,
            _id: '6186e0e498b8b115f0640df7',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 5,
            _id: '6186e0e498b8b115f0640df8',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e498b8b115f0640df9',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0e498b8b115f0640dfa',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 4,
            _id: '6186e0e498b8b115f0640dfb',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e498b8b115f0640dfc',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 11,
            _id: '6186e0e498b8b115f0640dfd',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e498b8b115f0640dfe',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 10,
            _id: '6186e0e498b8b115f0640dff',
          },
          {
            squad: '6166c913e65c4ef2043f063a',
            order: 12,
            _id: '6186e0e498b8b115f0640e00',
          },
          {
            squad: '6166c914e65c4ef2043f079f',
            order: 13,
            _id: '6186e0e498b8b115f0640e01',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 14,
            _id: '6186e0e498b8b115f0640e02',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 15,
            _id: '6186e0e498b8b115f0640e03',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 16,
            _id: '6186e0e498b8b115f0640e04',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 17,
            _id: '6186e0e498b8b115f0640e05',
          },
          {
            squad: '6166c912e65c4ef2043f056a',
            order: 18,
            _id: '6186e0e498b8b115f0640e06',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 19,
            _id: '6186e0e498b8b115f0640e07',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 20,
            _id: '6186e0e498b8b115f0640e08',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec45a0',
      opta_ID: 'duxxp2hsgmfmi368ngsy0wnbo',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Arsenal vs Norwich City',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e498b8b115f0640df1',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640df2',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640df3',
        },
        {
          official: '6166cd56e65c4ef204465e44',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640df4',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e498b8b115f0640e36',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e498b8b115f0640e37',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e498b8b115f0640e38',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e498b8b115f0640e39',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e498b8b115f0640e3a',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e498b8b115f0640e3b',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0e498b8b115f0640e3c',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e498b8b115f0640e3d',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 11,
            _id: '6186e0e498b8b115f0640e3e',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e498b8b115f0640e3f',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 10,
            _id: '6186e0e498b8b115f0640e40',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 12,
            _id: '6186e0e498b8b115f0640e41',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 13,
            _id: '6186e0e498b8b115f0640e42',
          },
          {
            squad: '6166c8b2e65c4ef2043e150a',
            order: 14,
            _id: '6186e0e498b8b115f0640e43',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 15,
            _id: '6186e0e498b8b115f0640e44',
          },
          {
            squad: '6166c8b0e65c4ef2043e1271',
            order: 16,
            _id: '6186e0e498b8b115f0640e45',
          },
          {
            squad: '6166c8b3e65c4ef2043e158e',
            order: 17,
            _id: '6186e0e498b8b115f0640e46',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 18,
            _id: '6186e0e498b8b115f0640e47',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 19,
            _id: '6186e0e498b8b115f0640e48',
          },
          {
            squad: '6166c8b2e65c4ef2043e14d6',
            order: 20,
            _id: '6186e0e498b8b115f0640e49',
          },
        ],
        away: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e498b8b115f0640e22',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e498b8b115f0640e23',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e498b8b115f0640e24',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 5,
            _id: '6186e0e498b8b115f0640e25',
          },
          {
            squad: '6166c965e65c4ef2043f785c',
            order: 2,
            _id: '6186e0e498b8b115f0640e26',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e498b8b115f0640e27',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 11,
            _id: '6186e0e498b8b115f0640e28',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 7,
            _id: '6186e0e498b8b115f0640e29',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 8,
            _id: '6186e0e498b8b115f0640e2a',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e498b8b115f0640e2b',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 10,
            _id: '6186e0e498b8b115f0640e2c',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e498b8b115f0640e2d',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e498b8b115f0640e2e',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e498b8b115f0640e2f',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e498b8b115f0640e30',
          },
          {
            squad: '6166c963e65c4ef2043f74c0',
            order: 16,
            _id: '6186e0e498b8b115f0640e31',
          },
          {
            squad: '6166c966e65c4ef2043f791f',
            order: 17,
            _id: '6186e0e498b8b115f0640e32',
          },
          {
            squad: '6166c963e65c4ef2043f744f',
            order: 18,
            _id: '6186e0e498b8b115f0640e33',
          },
          {
            squad: '6166c969e65c4ef2043f7bfa',
            order: 19,
            _id: '6186e0e498b8b115f0640e34',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 20,
            _id: '6186e0e498b8b115f0640e35',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec45ac',
      opta_ID: 'dv9mwrfizidou35b871dvv3f8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-09-11Z',
      description: 'Crystal Palace vs Tottenham Hotspur',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e498b8b115f0640e1e',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640e1f',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640e20',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640e21',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '4',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e498b8b115f0640e63',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e498b8b115f0640e64',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e498b8b115f0640e65',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e498b8b115f0640e66',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e498b8b115f0640e67',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e498b8b115f0640e68',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e498b8b115f0640e69',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e498b8b115f0640e6a',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e498b8b115f0640e6b',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e498b8b115f0640e6c',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e498b8b115f0640e6d',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e498b8b115f0640e6e',
          },
          {
            squad: '6166c97fe65c4ef2043fddbe',
            order: 13,
            _id: '6186e0e498b8b115f0640e6f',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 14,
            _id: '6186e0e498b8b115f0640e70',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 15,
            _id: '6186e0e498b8b115f0640e71',
          },
          {
            squad: '6166c945e65c4ef2043f4ea9',
            order: 16,
            _id: '6186e0e498b8b115f0640e72',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 17,
            _id: '6186e0e498b8b115f0640e73',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 18,
            _id: '6186e0e498b8b115f0640e74',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 19,
            _id: '6186e0e498b8b115f0640e75',
          },
          {
            squad: '6166c981e65c4ef2043fe659',
            order: 20,
            _id: '6186e0e498b8b115f0640e76',
          },
        ],
        away: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e498b8b115f0640e4f',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e498b8b115f0640e50',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e498b8b115f0640e51',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 5,
            _id: '6186e0e498b8b115f0640e52',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e498b8b115f0640e53',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e498b8b115f0640e54',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 8,
            _id: '6186e0e498b8b115f0640e55',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 11,
            _id: '6186e0e498b8b115f0640e56',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e498b8b115f0640e57',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 7,
            _id: '6186e0e498b8b115f0640e58',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 9,
            _id: '6186e0e498b8b115f0640e59',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0c6',
            order: 12,
            _id: '6186e0e498b8b115f0640e5a',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 13,
            _id: '6186e0e498b8b115f0640e5b',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 14,
            _id: '6186e0e498b8b115f0640e5c',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 15,
            _id: '6186e0e498b8b115f0640e5d',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 16,
            _id: '6186e0e498b8b115f0640e5e',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 17,
            _id: '6186e0e498b8b115f0640e5f',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 18,
            _id: '6186e0e498b8b115f0640e60',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 19,
            _id: '6186e0e498b8b115f0640e61',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e498b8b115f0640e62',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec45c3',
      opta_ID: 'dut0fusi3v9t9dohry1h9rm6s',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-29Z',
      description: 'Wolverhampton Wanderers vs Manchester United',
      home_team: '6166c3dee65c4ef204380695',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e498b8b115f0640e4b',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640e4c',
        },
        {
          official: '6166cd55e65c4ef204465c56',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640e4d',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640e4e',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e498b8b115f0640e90',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e498b8b115f0640e91',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e498b8b115f0640e92',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 5,
            _id: '6186e0e498b8b115f0640e93',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 2,
            _id: '6186e0e498b8b115f0640e94',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 8,
            _id: '6186e0e498b8b115f0640e95',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e498b8b115f0640e96',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 7,
            _id: '6186e0e498b8b115f0640e97',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 11,
            _id: '6186e0e498b8b115f0640e98',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 9,
            _id: '6186e0e498b8b115f0640e99',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 10,
            _id: '6186e0e498b8b115f0640e9a',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e498b8b115f0640e9b',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e498b8b115f0640e9c',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e498b8b115f0640e9d',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e498b8b115f0640e9e',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 16,
            _id: '6186e0e498b8b115f0640e9f',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 17,
            _id: '6186e0e498b8b115f0640ea0',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 18,
            _id: '6186e0e498b8b115f0640ea1',
          },
          {
            squad: '6166c967e65c4ef2043f79e9',
            order: 19,
            _id: '6186e0e498b8b115f0640ea2',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e498b8b115f0640ea3',
          },
        ],
        away: [
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 1,
            _id: '6186e0e498b8b115f0640e7c',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0e498b8b115f0640e7d',
          },
          {
            squad: '6166c972e65c4ef2043f9e69',
            order: 6,
            _id: '6186e0e498b8b115f0640e7e',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e498b8b115f0640e7f',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 2,
            _id: '6186e0e498b8b115f0640e80',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 4,
            _id: '6186e0e498b8b115f0640e81',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 11,
            _id: '6186e0e498b8b115f0640e82',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 10,
            _id: '6186e0e498b8b115f0640e83',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 8,
            _id: '6186e0e498b8b115f0640e84',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e498b8b115f0640e85',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 9,
            _id: '6186e0e498b8b115f0640e86',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 12,
            _id: '6186e0e498b8b115f0640e87',
          },
          {
            squad: '6166c96ee65c4ef2043f90b6',
            order: 13,
            _id: '6186e0e498b8b115f0640e88',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 14,
            _id: '6186e0e498b8b115f0640e89',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 15,
            _id: '6186e0e498b8b115f0640e8a',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 16,
            _id: '6186e0e498b8b115f0640e8b',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 17,
            _id: '6186e0e498b8b115f0640e8c',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 18,
            _id: '6186e0e498b8b115f0640e8d',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 19,
            _id: '6186e0e498b8b115f0640e8e',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 20,
            _id: '6186e0e498b8b115f0640e8f',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec45fb',
      opta_ID: 'dult9x1zq2hlotiuejukdwj6c',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-29Z',
      description: 'Tottenham Hotspur vs Watford',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0e498b8b115f0640e78',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640e79',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640e7a',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640e7b',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e498b8b115f0640ebd',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e498b8b115f0640ebe',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e498b8b115f0640ebf',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e498b8b115f0640ec0',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e498b8b115f0640ec1',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e498b8b115f0640ec2',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 8,
            _id: '6186e0e498b8b115f0640ec3',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e498b8b115f0640ec4',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0e498b8b115f0640ec5',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 9,
            _id: '6186e0e498b8b115f0640ec6',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 10,
            _id: '6186e0e498b8b115f0640ec7',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e498b8b115f0640ec8',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 13,
            _id: '6186e0e498b8b115f0640ec9',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 14,
            _id: '6186e0e498b8b115f0640eca',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0e498b8b115f0640ecb',
          },
          {
            squad: '6166c88ae65c4ef2043ddf6e',
            order: 16,
            _id: '6186e0e498b8b115f0640ecc',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 17,
            _id: '6186e0e498b8b115f0640ecd',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 18,
            _id: '6186e0e498b8b115f0640ece',
          },
          {
            squad: '6166c888e65c4ef2043ddbfa',
            order: 19,
            _id: '6186e0e498b8b115f0640ecf',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0e498b8b115f0640ed0',
          },
        ],
        away: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e498b8b115f0640ea9',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 4,
            _id: '6186e0e498b8b115f0640eaa',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 5,
            _id: '6186e0e498b8b115f0640eab',
          },
          {
            squad: '6166c8dee65c4ef2043ec1d5',
            order: 6,
            _id: '6186e0e498b8b115f0640eac',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 3,
            _id: '6186e0e498b8b115f0640ead',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 8,
            _id: '6186e0e498b8b115f0640eae',
          },
          {
            squad: '6166c8dee65c4ef2043ec1a8',
            order: 2,
            _id: '6186e0e498b8b115f0640eaf',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 11,
            _id: '6186e0e498b8b115f0640eb0',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 7,
            _id: '6186e0e498b8b115f0640eb1',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 10,
            _id: '6186e0e498b8b115f0640eb2',
          },
          {
            squad: '6166c8dee65c4ef2043ec120',
            order: 9,
            _id: '6186e0e498b8b115f0640eb3',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e498b8b115f0640eb4',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 13,
            _id: '6186e0e498b8b115f0640eb5',
          },
          {
            squad: '6166c8dde65c4ef2043ec073',
            order: 14,
            _id: '6186e0e498b8b115f0640eb6',
          },
          {
            squad: '6166c8dde65c4ef2043ebfe3',
            order: 15,
            _id: '6186e0e498b8b115f0640eb7',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 16,
            _id: '6186e0e498b8b115f0640eb8',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 17,
            _id: '6186e0e498b8b115f0640eb9',
          },
          {
            squad: '6166c8dde65c4ef2043ec020',
            order: 18,
            _id: '6186e0e498b8b115f0640eba',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e498b8b115f0640ebb',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e498b8b115f0640ebc',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4609',
      opta_ID: 'du4mgvbonx71p08in5sb7ipsk',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-29Z',
      description: 'Burnley vs Leeds United',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e498b8b115f0640ea5',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e498b8b115f0640ea6',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e498b8b115f0640ea7',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e498b8b115f0640ea8',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e598b8b115f0640eea',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 3,
            _id: '6186e0e598b8b115f0640eeb',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e598b8b115f0640eec',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e598b8b115f0640eed',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e598b8b115f0640eee',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 8,
            _id: '6186e0e598b8b115f0640eef',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 4,
            _id: '6186e0e598b8b115f0640ef0',
          },
          {
            squad: '6166c8ece65c4ef2043ed287',
            order: 7,
            _id: '6186e0e598b8b115f0640ef1',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e598b8b115f0640ef2',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 9,
            _id: '6186e0e598b8b115f0640ef3',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e598b8b115f0640ef4',
          },
          {
            squad: '6166c8ebe65c4ef2043ed203',
            order: 12,
            _id: '6186e0e598b8b115f0640ef5',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 13,
            _id: '6186e0e598b8b115f0640ef6',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 14,
            _id: '6186e0e598b8b115f0640ef7',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 15,
            _id: '6186e0e598b8b115f0640ef8',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 16,
            _id: '6186e0e598b8b115f0640ef9',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 17,
            _id: '6186e0e598b8b115f0640efa',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 18,
            _id: '6186e0e598b8b115f0640efb',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 19,
            _id: '6186e0e598b8b115f0640efc',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 20,
            _id: '6186e0e598b8b115f0640efd',
          },
        ],
        away: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e598b8b115f0640ed6',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e598b8b115f0640ed7',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 5,
            _id: '6186e0e598b8b115f0640ed8',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 6,
            _id: '6186e0e598b8b115f0640ed9',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e598b8b115f0640eda',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 8,
            _id: '6186e0e598b8b115f0640edb',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 7,
            _id: '6186e0e598b8b115f0640edc',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0e598b8b115f0640edd',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 11,
            _id: '6186e0e598b8b115f0640ede',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 10,
            _id: '6186e0e598b8b115f0640edf',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e598b8b115f0640ee0',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0e598b8b115f0640ee1',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 13,
            _id: '6186e0e598b8b115f0640ee2',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 14,
            _id: '6186e0e598b8b115f0640ee3',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 15,
            _id: '6186e0e598b8b115f0640ee4',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 16,
            _id: '6186e0e598b8b115f0640ee5',
          },
          {
            squad: '6166c89ee65c4ef2043dfbf0',
            order: 17,
            _id: '6186e0e598b8b115f0640ee6',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 18,
            _id: '6186e0e598b8b115f0640ee7',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 19,
            _id: '6186e0e598b8b115f0640ee8',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 20,
            _id: '6186e0e598b8b115f0640ee9',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4628',
      opta_ID: 'du7vszgj4outkoti1etvdu3h0',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Liverpool vs Chelsea',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e598b8b115f0640ed2',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640ed3',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640ed4',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640ed5',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e598b8b115f0640f17',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e598b8b115f0640f18',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e598b8b115f0640f19',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 5,
            _id: '6186e0e598b8b115f0640f1a',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e598b8b115f0640f1b',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e598b8b115f0640f1c',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e598b8b115f0640f1d',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 11,
            _id: '6186e0e598b8b115f0640f1e',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 10,
            _id: '6186e0e598b8b115f0640f1f',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e598b8b115f0640f20',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e598b8b115f0640f21',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e598b8b115f0640f22',
          },
          {
            squad: '6166c978e65c4ef2043fbb3e',
            order: 13,
            _id: '6186e0e598b8b115f0640f23',
          },
          {
            squad: '6166c921e65c4ef2043f1b75',
            order: 14,
            _id: '6186e0e598b8b115f0640f24',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 15,
            _id: '6186e0e598b8b115f0640f25',
          },
          {
            squad: '6166c97be65c4ef2043fc69e',
            order: 16,
            _id: '6186e0e598b8b115f0640f26',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 17,
            _id: '6186e0e598b8b115f0640f27',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 18,
            _id: '6186e0e598b8b115f0640f28',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 19,
            _id: '6186e0e598b8b115f0640f29',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e598b8b115f0640f2a',
          },
        ],
        away: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e598b8b115f0640f03',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e598b8b115f0640f04',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e598b8b115f0640f05',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e598b8b115f0640f06',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e598b8b115f0640f07',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e598b8b115f0640f08',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0e598b8b115f0640f09',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e598b8b115f0640f0a',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 11,
            _id: '6186e0e598b8b115f0640f0b',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e598b8b115f0640f0c',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 10,
            _id: '6186e0e598b8b115f0640f0d',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 12,
            _id: '6186e0e598b8b115f0640f0e',
          },
          {
            squad: '6166c8b0e65c4ef2043e123a',
            order: 13,
            _id: '6186e0e598b8b115f0640f0f',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 14,
            _id: '6186e0e598b8b115f0640f10',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 15,
            _id: '6186e0e598b8b115f0640f11',
          },
          {
            squad: '6166c8b2e65c4ef2043e1497',
            order: 16,
            _id: '6186e0e598b8b115f0640f12',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 17,
            _id: '6186e0e598b8b115f0640f13',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 18,
            _id: '6186e0e598b8b115f0640f14',
          },
          {
            squad: '6166c8b1e65c4ef2043e132d',
            order: 19,
            _id: '6186e0e598b8b115f0640f15',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 20,
            _id: '6186e0e598b8b115f0640f16',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec4641',
      opta_ID: 'dupat64pqi9lmobl3f549bx90',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'West Ham United vs Crystal Palace',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e598b8b115f0640eff',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640f00',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640f01',
        },
        {
          official: '6166cd40e65c4ef20446456c',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640f02',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e598b8b115f0640f44',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 3,
            _id: '6186e0e598b8b115f0640f45',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 6,
            _id: '6186e0e598b8b115f0640f46',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e598b8b115f0640f47',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e598b8b115f0640f48',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 8,
            _id: '6186e0e598b8b115f0640f49',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 4,
            _id: '6186e0e598b8b115f0640f4a',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e598b8b115f0640f4b',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0e598b8b115f0640f4c',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e598b8b115f0640f4d',
          },
          {
            squad: '6166c914e65c4ef2043f079f',
            order: 10,
            _id: '6186e0e598b8b115f0640f4e',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e598b8b115f0640f4f',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 13,
            _id: '6186e0e598b8b115f0640f50',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 14,
            _id: '6186e0e598b8b115f0640f51',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 15,
            _id: '6186e0e598b8b115f0640f52',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 16,
            _id: '6186e0e598b8b115f0640f53',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 17,
            _id: '6186e0e598b8b115f0640f54',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 18,
            _id: '6186e0e598b8b115f0640f55',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 19,
            _id: '6186e0e598b8b115f0640f56',
          },
          {
            squad: '6166c914e65c4ef2043f07e1',
            order: 20,
            _id: '6186e0e598b8b115f0640f57',
          },
        ],
        away: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e598b8b115f0640f30',
          },
          {
            squad: '6166c8e0e65c4ef2043ec49a',
            order: 3,
            _id: '6186e0e598b8b115f0640f31',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 6,
            _id: '6186e0e598b8b115f0640f32',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 5,
            _id: '6186e0e598b8b115f0640f33',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e598b8b115f0640f34',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e598b8b115f0640f35',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 8,
            _id: '6186e0e598b8b115f0640f36',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e598b8b115f0640f37',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 7,
            _id: '6186e0e598b8b115f0640f38',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 9,
            _id: '6186e0e598b8b115f0640f39',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 10,
            _id: '6186e0e598b8b115f0640f3a',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 12,
            _id: '6186e0e598b8b115f0640f3b',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 13,
            _id: '6186e0e598b8b115f0640f3c',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 14,
            _id: '6186e0e598b8b115f0640f3d',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 15,
            _id: '6186e0e598b8b115f0640f3e',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 16,
            _id: '6186e0e598b8b115f0640f3f',
          },
          {
            squad: '6166c8e1e65c4ef2043ec502',
            order: 17,
            _id: '6186e0e598b8b115f0640f40',
          },
          {
            squad: '6166c8e1e65c4ef2043ec56c',
            order: 18,
            _id: '6186e0e598b8b115f0640f41',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 19,
            _id: '6186e0e598b8b115f0640f42',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e598b8b115f0640f43',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e3a4737fae26ec467a',
      opta_ID: 'dui2a81k2l36qqulf97pw9r84',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Norwich City vs Leicester City',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Main',
          _id: '6186e0e598b8b115f0640f2c',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640f2d',
        },
        {
          official: '6166cd55e65c4ef204465c70',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640f2e',
        },
        {
          official: '6166cd40e65c4ef2044645d2',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640f2f',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c908e65c4ef2043ef9c9',
            order: 1,
            _id: '6186e0e598b8b115f0640f71',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 4,
            _id: '6186e0e598b8b115f0640f72',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e598b8b115f0640f73',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 6,
            _id: '6186e0e598b8b115f0640f74',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e598b8b115f0640f75',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 8,
            _id: '6186e0e598b8b115f0640f76',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 11,
            _id: '6186e0e598b8b115f0640f77',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 7,
            _id: '6186e0e598b8b115f0640f78',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 2,
            _id: '6186e0e598b8b115f0640f79',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e598b8b115f0640f7a',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 10,
            _id: '6186e0e598b8b115f0640f7b',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 12,
            _id: '6186e0e598b8b115f0640f7c',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 13,
            _id: '6186e0e598b8b115f0640f7d',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 14,
            _id: '6186e0e598b8b115f0640f7e',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 15,
            _id: '6186e0e598b8b115f0640f7f',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 16,
            _id: '6186e0e598b8b115f0640f80',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 17,
            _id: '6186e0e598b8b115f0640f81',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 18,
            _id: '6186e0e598b8b115f0640f82',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 19,
            _id: '6186e0e598b8b115f0640f83',
          },
          {
            squad: '6166c907e65c4ef2043ef897',
            order: 20,
            _id: '6186e0e598b8b115f0640f84',
          },
        ],
        away: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e598b8b115f0640f5d',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 3,
            _id: '6186e0e598b8b115f0640f5e',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e598b8b115f0640f5f',
          },
          {
            squad: '6166c94fe65c4ef2043f5b1f',
            order: 5,
            _id: '6186e0e598b8b115f0640f60',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e598b8b115f0640f61',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 11,
            _id: '6186e0e598b8b115f0640f62',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0e598b8b115f0640f63',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 4,
            _id: '6186e0e598b8b115f0640f64',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 7,
            _id: '6186e0e598b8b115f0640f65',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e598b8b115f0640f66',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 10,
            _id: '6186e0e598b8b115f0640f67',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 12,
            _id: '6186e0e598b8b115f0640f68',
          },
          {
            squad: '6166c89ce65c4ef2043df8ad',
            order: 13,
            _id: '6186e0e598b8b115f0640f69',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 14,
            _id: '6186e0e598b8b115f0640f6a',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 15,
            _id: '6186e0e598b8b115f0640f6b',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 16,
            _id: '6186e0e598b8b115f0640f6c',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 17,
            _id: '6186e0e598b8b115f0640f6d',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0e598b8b115f0640f6e',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0e598b8b115f0640f6f',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 20,
            _id: '6186e0e598b8b115f0640f70',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec4693',
      opta_ID: 'duel0cy0cohycnszh8xgf9vdg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Newcastle United vs Southampton',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e598b8b115f0640f59',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640f5a',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640f5b',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640f5c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e598b8b115f0640f9d',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 4,
            _id: '6186e0e598b8b115f0640f9e',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0e598b8b115f0640f9f',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 6,
            _id: '6186e0e598b8b115f0640fa0',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 3,
            _id: '6186e0e598b8b115f0640fa1',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 8,
            _id: '6186e0e598b8b115f0640fa2',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 7,
            _id: '6186e0e598b8b115f0640fa3',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 2,
            _id: '6186e0e598b8b115f0640fa4',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 11,
            _id: '6186e0e598b8b115f0640fa5',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e598b8b115f0640fa6',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e598b8b115f0640fa7',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 12,
            _id: '6186e0e598b8b115f0640fa8',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 13,
            _id: '6186e0e598b8b115f0640fa9',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 14,
            _id: '6186e0e598b8b115f0640faa',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 15,
            _id: '6186e0e598b8b115f0640fab',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 16,
            _id: '6186e0e598b8b115f0640fac',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 17,
            _id: '6186e0e598b8b115f0640fad',
          },
          {
            squad: '6166c880e65c4ef2043dd0c3',
            order: 18,
            _id: '6186e0e598b8b115f0640fae',
          },
          {
            squad: '6166c880e65c4ef2043dd09c',
            order: 19,
            _id: '6186e0e598b8b115f0640faf',
          },
          {
            squad: '6166c87fe65c4ef2043dcfc5',
            order: 20,
            _id: '6186e0e598b8b115f0640fb0',
          },
        ],
        away: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e598b8b115f0640f8a',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e598b8b115f0640f8b',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e598b8b115f0640f8c',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 5,
            _id: '6186e0e598b8b115f0640f8d',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e598b8b115f0640f8e',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 11,
            _id: '6186e0e598b8b115f0640f8f',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 8,
            _id: '6186e0e598b8b115f0640f90',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 4,
            _id: '6186e0e598b8b115f0640f91',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e598b8b115f0640f92',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 10,
            _id: '6186e0e598b8b115f0640f93',
          },
          {
            squad: '6166c8bae65c4ef2043e2e66',
            order: 9,
            _id: '6186e0e598b8b115f0640f94',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e598b8b115f0640f95',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 13,
            _id: '6186e0e598b8b115f0640f96',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 14,
            _id: '6186e0e598b8b115f0640f97',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 15,
            _id: '6186e0e598b8b115f0640f98',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 16,
            _id: '6186e0e598b8b115f0640f99',
          },
          {
            squad: '6166c8bae65c4ef2043e2cc2',
            order: 17,
            _id: '6186e0e598b8b115f0640f9a',
          },
          {
            squad: '6166c8bae65c4ef2043e2f22',
            order: 18,
            _id: '6186e0e598b8b115f0640f9b',
          },
          {
            squad: '6166c8bde65c4ef2043e3b1c',
            order: 19,
            _id: '6186e0e598b8b115f0640f9c',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46a2',
      opta_ID: 'du1a9bgu8dl0ab8ii1u0sxrmc',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Brighton & Hove Albion vs Everton',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e598b8b115f0640f86',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640f87',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640f88',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640f89',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e598b8b115f0640fca',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e598b8b115f0640fcb',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 6,
            _id: '6186e0e598b8b115f0640fcc',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 5,
            _id: '6186e0e598b8b115f0640fcd',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e598b8b115f0640fce',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 4,
            _id: '6186e0e598b8b115f0640fcf',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 11,
            _id: '6186e0e598b8b115f0640fd0',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 10,
            _id: '6186e0e598b8b115f0640fd1',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 8,
            _id: '6186e0e598b8b115f0640fd2',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 7,
            _id: '6186e0e598b8b115f0640fd3',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 9,
            _id: '6186e0e598b8b115f0640fd4',
          },
          {
            squad: '6166c860e65c4ef2043da29d',
            order: 12,
            _id: '6186e0e598b8b115f0640fd5',
          },
          {
            squad: '6166c85fe65c4ef2043da256',
            order: 13,
            _id: '6186e0e598b8b115f0640fd6',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 14,
            _id: '6186e0e598b8b115f0640fd7',
          },
          {
            squad: '6166c860e65c4ef2043da351',
            order: 15,
            _id: '6186e0e598b8b115f0640fd8',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 16,
            _id: '6186e0e598b8b115f0640fd9',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 17,
            _id: '6186e0e598b8b115f0640fda',
          },
          {
            squad: '6166c85ee65c4ef2043d9f56',
            order: 18,
            _id: '6186e0e598b8b115f0640fdb',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 19,
            _id: '6186e0e598b8b115f0640fdc',
          },
          {
            squad: '6166c85fe65c4ef2043da18b',
            order: 20,
            _id: '6186e0e598b8b115f0640fdd',
          },
        ],
        away: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e598b8b115f0640fb6',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e598b8b115f0640fb7',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e598b8b115f0640fb8',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e598b8b115f0640fb9',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e598b8b115f0640fba',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e598b8b115f0640fbb',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 7,
            _id: '6186e0e598b8b115f0640fbc',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e598b8b115f0640fbd',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 9,
            _id: '6186e0e598b8b115f0640fbe',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 11,
            _id: '6186e0e598b8b115f0640fbf',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e598b8b115f0640fc0',
          },
          {
            squad: '6166c87be65c4ef2043dc970',
            order: 12,
            _id: '6186e0e598b8b115f0640fc1',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 13,
            _id: '6186e0e598b8b115f0640fc2',
          },
          {
            squad: '6166c87ce65c4ef2043dcb3c',
            order: 14,
            _id: '6186e0e598b8b115f0640fc3',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 15,
            _id: '6186e0e598b8b115f0640fc4',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 16,
            _id: '6186e0e598b8b115f0640fc5',
          },
          {
            squad: '6166c87ce65c4ef2043dcbde',
            order: 17,
            _id: '6186e0e598b8b115f0640fc6',
          },
          {
            squad: '6166c87be65c4ef2043dc9f4',
            order: 18,
            _id: '6186e0e598b8b115f0640fc7',
          },
          {
            squad: '6166c87be65c4ef2043dc94a',
            order: 19,
            _id: '6186e0e598b8b115f0640fc8',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e598b8b115f0640fc9',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46ae',
      opta_ID: 'dty0hczvwc1kf0khugyq2rpxw',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Aston Villa vs Brentford',
      home_team: '6166c3dee65c4ef204380414',
      officials: [
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Main',
          _id: '6186e0e598b8b115f0640fb2',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640fb3',
        },
        {
          official: '6166cd55e65c4ef204465cc7',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640fb4',
        },
        {
          official: '6166cd4ce65c4ef204465391',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640fb5',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e598b8b115f0640ff7',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e598b8b115f0640ff8',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e598b8b115f0640ff9',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e598b8b115f0640ffa',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e598b8b115f0640ffb',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 8,
            _id: '6186e0e598b8b115f0640ffc',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e598b8b115f0640ffd',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 7,
            _id: '6186e0e598b8b115f0640ffe',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e598b8b115f0640fff',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 9,
            _id: '6186e0e598b8b115f0641000',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e598b8b115f0641001',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e598b8b115f0641002',
          },
          {
            squad: '6166c8f0e65c4ef2043ed8b1',
            order: 13,
            _id: '6186e0e598b8b115f0641003',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 14,
            _id: '6186e0e598b8b115f0641004',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 15,
            _id: '6186e0e598b8b115f0641005',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 16,
            _id: '6186e0e598b8b115f0641006',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 17,
            _id: '6186e0e598b8b115f0641007',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 18,
            _id: '6186e0e598b8b115f0641008',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 19,
            _id: '6186e0e598b8b115f0641009',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0e598b8b115f064100a',
          },
        ],
        away: [
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 1,
            _id: '6186e0e598b8b115f0640fe3',
          },
          {
            squad: '6166c85ce65c4ef2043d9c2c',
            order: 4,
            _id: '6186e0e598b8b115f0640fe4',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 5,
            _id: '6186e0e598b8b115f0640fe5',
          },
          {
            squad: '6166c859e65c4ef2043d9917',
            order: 6,
            _id: '6186e0e598b8b115f0640fe6',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e598b8b115f0640fe7',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 2,
            _id: '6186e0e598b8b115f0640fe8',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 11,
            _id: '6186e0e598b8b115f0640fe9',
          },
          {
            squad: '6166c85be65c4ef2043d9b3b',
            order: 10,
            _id: '6186e0e598b8b115f0640fea',
          },
          {
            squad: '6166c85ce65c4ef2043d9c99',
            order: 8,
            _id: '6186e0e598b8b115f0640feb',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 7,
            _id: '6186e0e598b8b115f0640fec',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 9,
            _id: '6186e0e598b8b115f0640fed',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 12,
            _id: '6186e0e598b8b115f0640fee',
          },
          {
            squad: '6166c85de65c4ef2043d9e38',
            order: 13,
            _id: '6186e0e598b8b115f0640fef',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 14,
            _id: '6186e0e598b8b115f0640ff0',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 15,
            _id: '6186e0e598b8b115f0640ff1',
          },
          {
            squad: '6166c85de65c4ef2043d9df8',
            order: 16,
            _id: '6186e0e598b8b115f0640ff2',
          },
          {
            squad: '6166c85be65c4ef2043d9b1e',
            order: 17,
            _id: '6186e0e598b8b115f0640ff3',
          },
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 18,
            _id: '6186e0e598b8b115f0640ff4',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 19,
            _id: '6186e0e598b8b115f0640ff5',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e598b8b115f0640ff6',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46c0',
      opta_ID: 'dub7xyi0g45ix0t7o8hsaok5w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-28Z',
      description: 'Manchester City vs Arsenal',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e598b8b115f0640fdf',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0640fe0',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0640fe1',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0640fe2',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '3',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e598b8b115f0641024',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e598b8b115f0641025',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e598b8b115f0641026',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 5,
            _id: '6186e0e598b8b115f0641027',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e598b8b115f0641028',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 4,
            _id: '6186e0e598b8b115f0641029',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 8,
            _id: '6186e0e598b8b115f064102a',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 11,
            _id: '6186e0e598b8b115f064102b',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 10,
            _id: '6186e0e598b8b115f064102c',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e598b8b115f064102d',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e598b8b115f064102e',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e598b8b115f064102f',
          },
          {
            squad: '6166c978e65c4ef2043fbb3e',
            order: 13,
            _id: '6186e0e598b8b115f0641030',
          },
          {
            squad: '6166c921e65c4ef2043f1b75',
            order: 14,
            _id: '6186e0e598b8b115f0641031',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 15,
            _id: '6186e0e598b8b115f0641032',
          },
          {
            squad: '6166c97be65c4ef2043fc69e',
            order: 16,
            _id: '6186e0e598b8b115f0641033',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 17,
            _id: '6186e0e598b8b115f0641034',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 18,
            _id: '6186e0e598b8b115f0641035',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 19,
            _id: '6186e0e598b8b115f0641036',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e598b8b115f0641037',
          },
        ],
        away: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e598b8b115f0641010',
          },
          {
            squad: '6166c8e0e65c4ef2043ec49a',
            order: 3,
            _id: '6186e0e598b8b115f0641011',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 6,
            _id: '6186e0e598b8b115f0641012',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 5,
            _id: '6186e0e598b8b115f0641013',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e598b8b115f0641014',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e598b8b115f0641015',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 8,
            _id: '6186e0e598b8b115f0641016',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e598b8b115f0641017',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 10,
            _id: '6186e0e598b8b115f0641018',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 7,
            _id: '6186e0e598b8b115f0641019',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e598b8b115f064101a',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e598b8b115f064101b',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6c2',
            order: 13,
            _id: '6186e0e598b8b115f064101c',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 14,
            _id: '6186e0e598b8b115f064101d',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 15,
            _id: '6186e0e598b8b115f064101e',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 16,
            _id: '6186e0e598b8b115f064101f',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 17,
            _id: '6186e0e598b8b115f0641020',
          },
          {
            squad: '6166c8e1e65c4ef2043ec56c',
            order: 18,
            _id: '6186e0e598b8b115f0641021',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 19,
            _id: '6186e0e598b8b115f0641022',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e598b8b115f0641023',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46d7',
      opta_ID: 'dtrer87wdcvfnjkpgz0qscavo',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f4c9',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-23Z',
      description: 'West Ham United vs Leicester City',
      home_team: '6166c3dbe65c4ef20437fa1b',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e598b8b115f064100c',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f064100d',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f064100e',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f064100f',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef20437521d',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 1,
            _id: '6186e0e598b8b115f0641051',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e598b8b115f0641052',
          },
          {
            squad: '6166c85de65c4ef2043d9df8',
            order: 6,
            _id: '6186e0e598b8b115f0641053',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 5,
            _id: '6186e0e598b8b115f0641054',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 2,
            _id: '6186e0e598b8b115f0641055',
          },
          {
            squad: '6166c85be65c4ef2043d9b3b',
            order: 4,
            _id: '6186e0e598b8b115f0641056',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 8,
            _id: '6186e0e598b8b115f0641057',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 11,
            _id: '6186e0e598b8b115f0641058',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 10,
            _id: '6186e0e598b8b115f0641059',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 7,
            _id: '6186e0e598b8b115f064105a',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 9,
            _id: '6186e0e598b8b115f064105b',
          },
          {
            squad: '6166c85ce65c4ef2043d9c52',
            order: 12,
            _id: '6186e0e598b8b115f064105c',
          },
          {
            squad: '6166c85de65c4ef2043d9da9',
            order: 13,
            _id: '6186e0e598b8b115f064105d',
          },
          {
            squad: '6166c859e65c4ef2043d9917',
            order: 14,
            _id: '6186e0e598b8b115f064105e',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 15,
            _id: '6186e0e598b8b115f064105f',
          },
          {
            squad: '6166c85ce65c4ef2043d9c2c',
            order: 16,
            _id: '6186e0e598b8b115f0641060',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e598b8b115f0641061',
          },
          {
            squad: '6166c85ce65c4ef2043d9cd2',
            order: 18,
            _id: '6186e0e598b8b115f0641062',
          },
          {
            squad: '6166c85be65c4ef2043d9b59',
            order: 19,
            _id: '6186e0e598b8b115f0641063',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e598b8b115f0641064',
          },
        ],
        away: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e598b8b115f064103d',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e598b8b115f064103e',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 5,
            _id: '6186e0e598b8b115f064103f',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 6,
            _id: '6186e0e598b8b115f0641040',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e598b8b115f0641041',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e598b8b115f0641042',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 7,
            _id: '6186e0e598b8b115f0641043',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 2,
            _id: '6186e0e598b8b115f0641044',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 11,
            _id: '6186e0e598b8b115f0641045',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 10,
            _id: '6186e0e598b8b115f0641046',
          },
          {
            squad: '6166c89de65c4ef2043df9b4',
            order: 9,
            _id: '6186e0e598b8b115f0641047',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0e598b8b115f0641048',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 13,
            _id: '6186e0e598b8b115f0641049',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 14,
            _id: '6186e0e598b8b115f064104a',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 15,
            _id: '6186e0e598b8b115f064104b',
          },
          {
            squad: '6166c89de65c4ef2043dfac8',
            order: 16,
            _id: '6186e0e598b8b115f064104c',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 17,
            _id: '6186e0e598b8b115f064104d',
          },
          {
            squad: '6166c89de65c4ef2043df9d7',
            order: 18,
            _id: '6186e0e598b8b115f064104e',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 19,
            _id: '6186e0e598b8b115f064104f',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 20,
            _id: '6186e0e598b8b115f0641050',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46e1',
      opta_ID: 'dsxmelzu7hgtib65wmnkm1o2c',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef2043802ff',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-22Z',
      description: 'Arsenal vs Chelsea',
      home_team: '6166c3dbe65c4ef20437f8fd',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e598b8b115f0641039',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f064103a',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f064103b',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f064103c',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35de65c4ef204374b33',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e598b8b115f064107e',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e598b8b115f064107f',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e598b8b115f0641080',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e598b8b115f0641081',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e598b8b115f0641082',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e598b8b115f0641083',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e598b8b115f0641084',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 2,
            _id: '6186e0e598b8b115f0641085',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e598b8b115f0641086',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e598b8b115f0641087',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e598b8b115f0641088',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e598b8b115f0641089',
          },
          {
            squad: '6166c981e65c4ef2043fe690',
            order: 13,
            _id: '6186e0e598b8b115f064108a',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 14,
            _id: '6186e0e598b8b115f064108b',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 15,
            _id: '6186e0e598b8b115f064108c',
          },
          {
            squad: '6166c982e65c4ef2043fe876',
            order: 16,
            _id: '6186e0e598b8b115f064108d',
          },
          {
            squad: '6166c945e65c4ef2043f4ea9',
            order: 17,
            _id: '6186e0e598b8b115f064108e',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 18,
            _id: '6186e0e598b8b115f064108f',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 19,
            _id: '6186e0e598b8b115f0641090',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 20,
            _id: '6186e0e598b8b115f0641091',
          },
        ],
        away: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e598b8b115f064106a',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e598b8b115f064106b',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e598b8b115f064106c',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 5,
            _id: '6186e0e598b8b115f064106d',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 2,
            _id: '6186e0e598b8b115f064106e',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 8,
            _id: '6186e0e598b8b115f064106f',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e598b8b115f0641070',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 7,
            _id: '6186e0e598b8b115f0641071',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 11,
            _id: '6186e0e598b8b115f0641072',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 9,
            _id: '6186e0e598b8b115f0641073',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 10,
            _id: '6186e0e598b8b115f0641074',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e598b8b115f0641075',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e598b8b115f0641076',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e598b8b115f0641077',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e598b8b115f0641078',
          },
          {
            squad: '6166c966e65c4ef2043f78f1',
            order: 16,
            _id: '6186e0e598b8b115f0641079',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 17,
            _id: '6186e0e598b8b115f064107a',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 18,
            _id: '6186e0e598b8b115f064107b',
          },
          {
            squad: '6166c967e65c4ef2043f79e9',
            order: 19,
            _id: '6186e0e598b8b115f064107c',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e598b8b115f064107d',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec46fb',
      opta_ID: 'dtuugix59m7q1yhkgenmokkr8',
      user: null,
      __v: 0,
      away_team: '6166c3dae65c4ef20437f50a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-22Z',
      description: 'Wolverhampton Wanderers vs Tottenham Hotspur',
      home_team: '6166c3dee65c4ef204380695',
      officials: [
        {
          official: '6166cd61e65c4ef20446696a',
          type: 'Main',
          _id: '6186e0e598b8b115f0641066',
        },
        {
          official: '6166cd41e65c4ef204464740',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0641067',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0641068',
        },
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0641069',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374ffb',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e598b8b115f06410ab',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 3,
            _id: '6186e0e598b8b115f06410ac',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e598b8b115f06410ad',
          },
          {
            squad: '6166c94fe65c4ef2043f5b1f',
            order: 5,
            _id: '6186e0e598b8b115f06410ae',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e598b8b115f06410af',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 11,
            _id: '6186e0e598b8b115f06410b0',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 8,
            _id: '6186e0e598b8b115f06410b1',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 4,
            _id: '6186e0e598b8b115f06410b2',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 7,
            _id: '6186e0e598b8b115f06410b3',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e598b8b115f06410b4',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 10,
            _id: '6186e0e598b8b115f06410b5',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 12,
            _id: '6186e0e598b8b115f06410b6',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 13,
            _id: '6186e0e598b8b115f06410b7',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 14,
            _id: '6186e0e598b8b115f06410b8',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 15,
            _id: '6186e0e598b8b115f06410b9',
          },
          {
            squad: '6166c94fe65c4ef2043f5aed',
            order: 16,
            _id: '6186e0e598b8b115f06410ba',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 17,
            _id: '6186e0e598b8b115f06410bb',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0e598b8b115f06410bc',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0e598b8b115f06410bd',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 20,
            _id: '6186e0e598b8b115f06410be',
          },
        ],
        away: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e598b8b115f0641097',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e598b8b115f0641098',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e598b8b115f0641099',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0e598b8b115f064109a',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e598b8b115f064109b',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 4,
            _id: '6186e0e598b8b115f064109c',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 8,
            _id: '6186e0e598b8b115f064109d',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 11,
            _id: '6186e0e598b8b115f064109e',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e598b8b115f064109f',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 7,
            _id: '6186e0e598b8b115f06410a0',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 9,
            _id: '6186e0e598b8b115f06410a1',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 12,
            _id: '6186e0e598b8b115f06410a2',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 13,
            _id: '6186e0e598b8b115f06410a3',
          },
          {
            squad: '6166c8f5e65c4ef2043edec2',
            order: 14,
            _id: '6186e0e598b8b115f06410a4',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 15,
            _id: '6186e0e598b8b115f06410a5',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 16,
            _id: '6186e0e598b8b115f06410a6',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 17,
            _id: '6186e0e598b8b115f06410a7',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 18,
            _id: '6186e0e598b8b115f06410a8',
          },
          {
            squad: '6166c8f4e65c4ef2043ede14',
            order: 19,
            _id: '6186e0e598b8b115f06410a9',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e598b8b115f06410aa',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec470a',
      opta_ID: 'dto0v9p9tb94zgd1c4a1tmuqc',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fe10',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-22Z',
      description: 'Southampton vs Manchester United',
      home_team: '6166c3dee65c4ef20438075c',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e598b8b115f0641093',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f0641094',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f0641095',
        },
        {
          official: '6166cd65e65c4ef204466d40',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f0641096',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e9c',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e598b8b115f06410d8',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 3,
            _id: '6186e0e598b8b115f06410d9',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 6,
            _id: '6186e0e598b8b115f06410da',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0e598b8b115f06410db',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 2,
            _id: '6186e0e598b8b115f06410dc',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 11,
            _id: '6186e0e598b8b115f06410dd',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 8,
            _id: '6186e0e598b8b115f06410de',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 4,
            _id: '6186e0e598b8b115f06410df',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 7,
            _id: '6186e0e598b8b115f06410e0',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 10,
            _id: '6186e0e598b8b115f06410e1',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e598b8b115f06410e2',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 12,
            _id: '6186e0e598b8b115f06410e3',
          },
          {
            squad: '6166c87de65c4ef2043dcd98',
            order: 13,
            _id: '6186e0e598b8b115f06410e4',
          },
          {
            squad: '6166c880e65c4ef2043dd149',
            order: 14,
            _id: '6186e0e598b8b115f06410e5',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 15,
            _id: '6186e0e598b8b115f06410e6',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 16,
            _id: '6186e0e598b8b115f06410e7',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 17,
            _id: '6186e0e598b8b115f06410e8',
          },
          {
            squad: '6166c881e65c4ef2043dd1fd',
            order: 18,
            _id: '6186e0e598b8b115f06410e9',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 19,
            _id: '6186e0e598b8b115f06410ea',
          },
          {
            squad: '6166c87fe65c4ef2043dcfc5',
            order: 20,
            _id: '6186e0e598b8b115f06410eb',
          },
        ],
        away: [
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 1,
            _id: '6186e0e598b8b115f06410c4',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0e598b8b115f06410c5',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 6,
            _id: '6186e0e598b8b115f06410c6',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e598b8b115f06410c7',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 2,
            _id: '6186e0e598b8b115f06410c8',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 4,
            _id: '6186e0e598b8b115f06410c9',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 11,
            _id: '6186e0e598b8b115f06410ca',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 10,
            _id: '6186e0e598b8b115f06410cb',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 8,
            _id: '6186e0e598b8b115f06410cc',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e598b8b115f06410cd',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 9,
            _id: '6186e0e598b8b115f06410ce',
          },
          {
            squad: '6166c974e65c4ef2043fa584',
            order: 12,
            _id: '6186e0e598b8b115f06410cf',
          },
          {
            squad: '6166c86ae65c4ef2043db163',
            order: 13,
            _id: '6186e0e598b8b115f06410d0',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 14,
            _id: '6186e0e598b8b115f06410d1',
          },
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 15,
            _id: '6186e0e598b8b115f06410d2',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 16,
            _id: '6186e0e598b8b115f06410d3',
          },
          {
            squad: '6166c971e65c4ef2043f9b93',
            order: 17,
            _id: '6186e0e598b8b115f06410d4',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 18,
            _id: '6186e0e598b8b115f06410d5',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 19,
            _id: '6186e0e598b8b115f06410d6',
          },
          {
            squad: '6166c972e65c4ef2043f9e69',
            order: 20,
            _id: '6186e0e598b8b115f06410d7',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec471a',
      opta_ID: 'dt4otg6nti6x03fm57juuqc5w',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f98c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Brighton & Hove Albion vs Watford',
      home_team: '6166c3dfe65c4ef2043807e7',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e598b8b115f06410c0',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f06410c1',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f06410c2',
        },
        {
          official: '6166cd62e65c4ef204466a35',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f06410c3',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef2043751f5',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e598b8b115f0641105',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 3,
            _id: '6186e0e598b8b115f0641106',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 6,
            _id: '6186e0e598b8b115f0641107',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e598b8b115f0641108',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 2,
            _id: '6186e0e598b8b115f0641109',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 8,
            _id: '6186e0e598b8b115f064110a',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 4,
            _id: '6186e0e598b8b115f064110b',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 7,
            _id: '6186e0e598b8b115f064110c',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 11,
            _id: '6186e0e598b8b115f064110d',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 9,
            _id: '6186e0e598b8b115f064110e',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 10,
            _id: '6186e0e598b8b115f064110f',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 12,
            _id: '6186e0e598b8b115f0641110',
          },
          {
            squad: '6166c8f0e65c4ef2043ed8b1',
            order: 13,
            _id: '6186e0e598b8b115f0641111',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 14,
            _id: '6186e0e598b8b115f0641112',
          },
          {
            squad: '6166c8f2e65c4ef2043edabf',
            order: 15,
            _id: '6186e0e598b8b115f0641113',
          },
          {
            squad: '6166c8f3e65c4ef2043edc7f',
            order: 16,
            _id: '6186e0e598b8b115f0641114',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 17,
            _id: '6186e0e598b8b115f0641115',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 18,
            _id: '6186e0e598b8b115f0641116',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 19,
            _id: '6186e0e598b8b115f0641117',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 20,
            _id: '6186e0e598b8b115f0641118',
          },
        ],
        away: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e598b8b115f06410f1',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 3,
            _id: '6186e0e598b8b115f06410f2',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 6,
            _id: '6186e0e598b8b115f06410f3',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e598b8b115f06410f4',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e598b8b115f06410f5',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 8,
            _id: '6186e0e598b8b115f06410f6',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 4,
            _id: '6186e0e598b8b115f06410f7',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e598b8b115f06410f8',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0e598b8b115f06410f9',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e598b8b115f06410fa',
          },
          {
            squad: '6166c914e65c4ef2043f079f',
            order: 10,
            _id: '6186e0e598b8b115f06410fb',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e598b8b115f06410fc',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 13,
            _id: '6186e0e598b8b115f06410fd',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 14,
            _id: '6186e0e598b8b115f06410fe',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 15,
            _id: '6186e0e598b8b115f06410ff',
          },
          {
            squad: '6166c912e65c4ef2043f0515',
            order: 16,
            _id: '6186e0e598b8b115f0641100',
          },
          {
            squad: '6166c912e65c4ef2043f05fe',
            order: 17,
            _id: '6186e0e598b8b115f0641101',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 18,
            _id: '6186e0e598b8b115f0641102',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 19,
            _id: '6186e0e598b8b115f0641103',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 20,
            _id: '6186e0e598b8b115f0641104',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec4725',
      opta_ID: 'dtkm0qtbjkt0vuf3wqa40pel0',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380c3a',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Manchester City vs Norwich City',
      home_team: '6166c3d9e65c4ef20437efcd',
      officials: [
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Main',
          _id: '6186e0e598b8b115f06410ed',
        },
        {
          official: '6166cd61e65c4ef204466975',
          type: 'Lineman 1',
          _id: '6186e0e598b8b115f06410ee',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 2',
          _id: '6186e0e598b8b115f06410ef',
        },
        {
          official: '6166cd62e65c4ef204466a67',
          type: 'Fourth official',
          _id: '6186e0e598b8b115f06410f0',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f58',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e698b8b115f0641132',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 4,
            _id: '6186e0e698b8b115f0641133',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 5,
            _id: '6186e0e698b8b115f0641134',
          },
          {
            squad: '6166c8dee65c4ef2043ec1a8',
            order: 6,
            _id: '6186e0e698b8b115f0641135',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 3,
            _id: '6186e0e698b8b115f0641136',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 8,
            _id: '6186e0e698b8b115f0641137',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 2,
            _id: '6186e0e698b8b115f0641138',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 11,
            _id: '6186e0e698b8b115f0641139',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 7,
            _id: '6186e0e698b8b115f064113a',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 10,
            _id: '6186e0e698b8b115f064113b',
          },
          {
            squad: '6166c8dee65c4ef2043ec120',
            order: 9,
            _id: '6186e0e698b8b115f064113c',
          },
          {
            squad: '6166c8dfe65c4ef2043ec251',
            order: 12,
            _id: '6186e0e698b8b115f064113d',
          },
          {
            squad: '6166c8dfe65c4ef2043ec268',
            order: 13,
            _id: '6186e0e698b8b115f064113e',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 14,
            _id: '6186e0e698b8b115f064113f',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 15,
            _id: '6186e0e698b8b115f0641140',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 16,
            _id: '6186e0e698b8b115f0641141',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 17,
            _id: '6186e0e698b8b115f0641142',
          },
          {
            squad: '6166c8dde65c4ef2043ec020',
            order: 18,
            _id: '6186e0e698b8b115f0641143',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e698b8b115f0641144',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e698b8b115f0641145',
          },
        ],
        away: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e698b8b115f064111e',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e698b8b115f064111f',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e698b8b115f0641120',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 5,
            _id: '6186e0e698b8b115f0641121',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e698b8b115f0641122',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 11,
            _id: '6186e0e698b8b115f0641123',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e698b8b115f0641124',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e698b8b115f0641125',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 7,
            _id: '6186e0e698b8b115f0641126',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 10,
            _id: '6186e0e698b8b115f0641127',
          },
          {
            squad: '6166c8bae65c4ef2043e2e66',
            order: 9,
            _id: '6186e0e698b8b115f0641128',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e698b8b115f0641129',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 13,
            _id: '6186e0e698b8b115f064112a',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 14,
            _id: '6186e0e698b8b115f064112b',
          },
          {
            squad: '6166c8bde65c4ef2043e3952',
            order: 15,
            _id: '6186e0e698b8b115f064112c',
          },
          {
            squad: '6166c8bee65c4ef2043e3def',
            order: 16,
            _id: '6186e0e698b8b115f064112d',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 17,
            _id: '6186e0e698b8b115f064112e',
          },
          {
            squad: '6166c8bbe65c4ef2043e3095',
            order: 18,
            _id: '6186e0e698b8b115f064112f',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 19,
            _id: '6186e0e698b8b115f0641130',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 20,
            _id: '6186e0e698b8b115f0641131',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec473b',
      opta_ID: 'dtbt00y5k7tmj5j1gvs8m7gno',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef204380b9d',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Leeds United vs Everton',
      home_team: '6166c3dbe65c4ef20437f59b',
      officials: [
        {
          official: '6166cd55e65c4ef204465cb4',
          type: 'Main',
          _id: '6186e0e698b8b115f064111a',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f064111b',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f064111c',
        },
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f064111d',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750d2',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e698b8b115f064115f',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e698b8b115f0641160',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e698b8b115f0641161',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 5,
            _id: '6186e0e698b8b115f0641162',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e698b8b115f0641163',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e698b8b115f0641164',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 4,
            _id: '6186e0e698b8b115f0641165',
          },
          {
            squad: '6166c8b2e65c4ef2043e14b3',
            order: 7,
            _id: '6186e0e698b8b115f0641166',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 11,
            _id: '6186e0e698b8b115f0641167',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 9,
            _id: '6186e0e698b8b115f0641168',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 10,
            _id: '6186e0e698b8b115f0641169',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 12,
            _id: '6186e0e698b8b115f064116a',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 13,
            _id: '6186e0e698b8b115f064116b',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 14,
            _id: '6186e0e698b8b115f064116c',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 15,
            _id: '6186e0e698b8b115f064116d',
          },
          {
            squad: '6166c8b0e65c4ef2043e1256',
            order: 16,
            _id: '6186e0e698b8b115f064116e',
          },
          {
            squad: '6166c8b2e65c4ef2043e1497',
            order: 17,
            _id: '6186e0e698b8b115f064116f',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 18,
            _id: '6186e0e698b8b115f0641170',
          },
          {
            squad: '6166c8b3e65c4ef2043e15c5',
            order: 19,
            _id: '6186e0e698b8b115f0641171',
          },
        ],
        away: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e698b8b115f064114b',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e698b8b115f064114c',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e698b8b115f064114d',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e698b8b115f064114e',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e698b8b115f064114f',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e698b8b115f0641150',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e698b8b115f0641151',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0e698b8b115f0641152',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e698b8b115f0641153',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e698b8b115f0641154',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e698b8b115f0641155',
          },
          {
            squad: '6166c87be65c4ef2043dca32',
            order: 12,
            _id: '6186e0e698b8b115f0641156',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 13,
            _id: '6186e0e698b8b115f0641157',
          },
          {
            squad: '6166c87ce65c4ef2043dcb3c',
            order: 14,
            _id: '6186e0e698b8b115f0641158',
          },
          {
            squad: '6166c87be65c4ef2043dca6b',
            order: 15,
            _id: '6186e0e698b8b115f0641159',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 16,
            _id: '6186e0e698b8b115f064115a',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 17,
            _id: '6186e0e698b8b115f064115b',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 18,
            _id: '6186e0e698b8b115f064115c',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 19,
            _id: '6186e0e698b8b115f064115d',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e698b8b115f064115e',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec4750',
      opta_ID: 'dt8d2mcw51joc7k0qz6qnbz84',
      user: null,
      __v: 0,
      away_team: '6166c3dde65c4ef204380264',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Crystal Palace vs Brentford',
      home_team: '6166c3d9e65c4ef20437efb7',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e698b8b115f0641147',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641148',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641149',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f064114a',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c377e65c4ef204376e82',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e698b8b115f064118b',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 3,
            _id: '6186e0e698b8b115f064118c',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 6,
            _id: '6186e0e698b8b115f064118d',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 5,
            _id: '6186e0e698b8b115f064118e',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e698b8b115f064118f',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 8,
            _id: '6186e0e698b8b115f0641190',
          },
          {
            squad: '6166c860e65c4ef2043da2d4',
            order: 4,
            _id: '6186e0e698b8b115f0641191',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 7,
            _id: '6186e0e698b8b115f0641192',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 11,
            _id: '6186e0e698b8b115f0641193',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 9,
            _id: '6186e0e698b8b115f0641194',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 10,
            _id: '6186e0e698b8b115f0641195',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 12,
            _id: '6186e0e698b8b115f0641196',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 13,
            _id: '6186e0e698b8b115f0641197',
          },
          {
            squad: '6166c860e65c4ef2043da351',
            order: 14,
            _id: '6186e0e698b8b115f0641198',
          },
          {
            squad: '6166c860e65c4ef2043da3fd',
            order: 15,
            _id: '6186e0e698b8b115f0641199',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 16,
            _id: '6186e0e698b8b115f064119a',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 17,
            _id: '6186e0e698b8b115f064119b',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 18,
            _id: '6186e0e698b8b115f064119c',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 19,
            _id: '6186e0e698b8b115f064119d',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 20,
            _id: '6186e0e698b8b115f064119e',
          },
        ],
        away: [
          {
            squad: '6166c908e65c4ef2043ef9c9',
            order: 1,
            _id: '6186e0e698b8b115f0641177',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 4,
            _id: '6186e0e698b8b115f0641178',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 5,
            _id: '6186e0e698b8b115f0641179',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 6,
            _id: '6186e0e698b8b115f064117a',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e698b8b115f064117b',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 2,
            _id: '6186e0e698b8b115f064117c',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 11,
            _id: '6186e0e698b8b115f064117d',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 8,
            _id: '6186e0e698b8b115f064117e',
          },
          {
            squad: '6166c85be65c4ef2043d9af7',
            order: 7,
            _id: '6186e0e698b8b115f064117f',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e698b8b115f0641180',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 10,
            _id: '6186e0e698b8b115f0641181',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 12,
            _id: '6186e0e698b8b115f0641182',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 13,
            _id: '6186e0e698b8b115f0641183',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 14,
            _id: '6186e0e698b8b115f0641184',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 15,
            _id: '6186e0e698b8b115f0641185',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 16,
            _id: '6186e0e698b8b115f0641186',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 17,
            _id: '6186e0e698b8b115f0641187',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 18,
            _id: '6186e0e698b8b115f0641188',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 19,
            _id: '6186e0e698b8b115f0641189',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 20,
            _id: '6186e0e698b8b115f064118a',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec476c',
      opta_ID: 'dt135dm8wf48v1j9lteu78gt0',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fea1',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Aston Villa vs Newcastle United',
      home_team: '6166c3dee65c4ef204380414',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0e698b8b115f0641173',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641174',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641175',
        },
        {
          official: '6166cd55e65c4ef204465cd6',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f0641176',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef20437530a',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e698b8b115f06411b8',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 3,
            _id: '6186e0e698b8b115f06411b9',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e698b8b115f06411ba',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e698b8b115f06411bb',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e698b8b115f06411bc',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 8,
            _id: '6186e0e698b8b115f06411bd',
          },
          {
            squad: '6166c8e8e65c4ef2043ece1e',
            order: 4,
            _id: '6186e0e698b8b115f06411be',
          },
          {
            squad: '6166c8ece65c4ef2043ed287',
            order: 7,
            _id: '6186e0e698b8b115f06411bf',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e698b8b115f06411c0',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e698b8b115f06411c1',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e698b8b115f06411c2',
          },
          {
            squad: '6166c8ebe65c4ef2043ed203',
            order: 12,
            _id: '6186e0e698b8b115f06411c3',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 13,
            _id: '6186e0e698b8b115f06411c4',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 14,
            _id: '6186e0e698b8b115f06411c5',
          },
          {
            squad: '6166c8e9e65c4ef2043ece99',
            order: 15,
            _id: '6186e0e698b8b115f06411c6',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 16,
            _id: '6186e0e698b8b115f06411c7',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 17,
            _id: '6186e0e698b8b115f06411c8',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 18,
            _id: '6186e0e698b8b115f06411c9',
          },
          {
            squad: '6166c8ece65c4ef2043ed29d',
            order: 19,
            _id: '6186e0e698b8b115f06411ca',
          },
          {
            squad: '6166c8e8e65c4ef2043ece67',
            order: 20,
            _id: '6186e0e698b8b115f06411cb',
          },
        ],
        away: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e698b8b115f06411a4',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e698b8b115f06411a5',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e698b8b115f06411a6',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e698b8b115f06411a7',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e698b8b115f06411a8',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e698b8b115f06411a9',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 8,
            _id: '6186e0e698b8b115f06411aa',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 4,
            _id: '6186e0e698b8b115f06411ab',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0e698b8b115f06411ac',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 9,
            _id: '6186e0e698b8b115f06411ad',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 10,
            _id: '6186e0e698b8b115f06411ae',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e698b8b115f06411af',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 13,
            _id: '6186e0e698b8b115f06411b0',
          },
          {
            squad: '6166c88ae65c4ef2043ddef0',
            order: 14,
            _id: '6186e0e698b8b115f06411b1',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 15,
            _id: '6186e0e698b8b115f06411b2',
          },
          {
            squad: '6166c888e65c4ef2043ddbd3',
            order: 16,
            _id: '6186e0e698b8b115f06411b3',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 17,
            _id: '6186e0e698b8b115f06411b4',
          },
          {
            squad: '6166c888e65c4ef2043ddc68',
            order: 18,
            _id: '6186e0e698b8b115f06411b5',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 19,
            _id: '6186e0e698b8b115f06411b6',
          },
          {
            squad: '6166c888e65c4ef2043ddbfa',
            order: 20,
            _id: '6186e0e698b8b115f06411b7',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e4a4737fae26ec4784',
      opta_ID: 'dtfardp7klxkqwuhqdz2a83kk',
      user: null,
      __v: 0,
      away_team: '6166c3dce65c4ef20437fd8b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-21Z',
      description: 'Liverpool vs Burnley',
      home_team: '6166c3dee65c4ef2043806f4',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e698b8b115f06411a0',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f06411a1',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f06411a2',
        },
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f06411a3',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c376e65c4ef204376e6b',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '2',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c968e65c4ef2043f7bca',
            order: 1,
            _id: '6186e0e698b8b115f06411e5',
          },
          {
            squad: '6166c966e65c4ef2043f78be',
            order: 3,
            _id: '6186e0e698b8b115f06411e6',
          },
          {
            squad: '6166c966e65c4ef2043f78a2',
            order: 6,
            _id: '6186e0e698b8b115f06411e7',
          },
          {
            squad: '6166c964e65c4ef2043f7587',
            order: 5,
            _id: '6186e0e698b8b115f06411e8',
          },
          {
            squad: '6166c964e65c4ef2043f7609',
            order: 2,
            _id: '6186e0e698b8b115f06411e9',
          },
          {
            squad: '6166c964e65c4ef2043f7553',
            order: 8,
            _id: '6186e0e698b8b115f06411ea',
          },
          {
            squad: '6166c965e65c4ef2043f7844',
            order: 4,
            _id: '6186e0e698b8b115f06411eb',
          },
          {
            squad: '6166c964e65c4ef2043f75ca',
            order: 7,
            _id: '6186e0e698b8b115f06411ec',
          },
          {
            squad: '6166c965e65c4ef2043f771a',
            order: 11,
            _id: '6186e0e698b8b115f06411ed',
          },
          {
            squad: '6166c963e65c4ef2043f7415',
            order: 9,
            _id: '6186e0e698b8b115f06411ee',
          },
          {
            squad: '6166c965e65c4ef2043f77bf',
            order: 10,
            _id: '6186e0e698b8b115f06411ef',
          },
          {
            squad: '6166c965e65c4ef2043f77a1',
            order: 12,
            _id: '6186e0e698b8b115f06411f0',
          },
          {
            squad: '6166c963e65c4ef2043f74e3',
            order: 13,
            _id: '6186e0e698b8b115f06411f1',
          },
          {
            squad: '6166c967e65c4ef2043f7a36',
            order: 14,
            _id: '6186e0e698b8b115f06411f2',
          },
          {
            squad: '6166c969e65c4ef2043f7c1d',
            order: 15,
            _id: '6186e0e698b8b115f06411f3',
          },
          {
            squad: '6166c968e65c4ef2043f7b02',
            order: 16,
            _id: '6186e0e698b8b115f06411f4',
          },
          {
            squad: '6166c963e65c4ef2043f7484',
            order: 17,
            _id: '6186e0e698b8b115f06411f5',
          },
          {
            squad: '6166c964e65c4ef2043f7527',
            order: 18,
            _id: '6186e0e698b8b115f06411f6',
          },
          {
            squad: '6166c968e65c4ef2043f7ba8',
            order: 19,
            _id: '6186e0e698b8b115f06411f7',
          },
          {
            squad: '6166c964e65c4ef2043f7687',
            order: 20,
            _id: '6186e0e698b8b115f06411f8',
          },
        ],
        away: [
          {
            squad: '6166c8efe65c4ef2043ed79a',
            order: 1,
            _id: '6186e0e698b8b115f06411d1',
          },
          {
            squad: '6166c8f2e65c4ef2043edabf',
            order: 3,
            _id: '6186e0e698b8b115f06411d2',
          },
          {
            squad: '6166c8f1e65c4ef2043eda49',
            order: 6,
            _id: '6186e0e698b8b115f06411d3',
          },
          {
            squad: '6166c8f0e65c4ef2043ed81e',
            order: 5,
            _id: '6186e0e698b8b115f06411d4',
          },
          {
            squad: '6166c8efe65c4ef2043ed7bb',
            order: 2,
            _id: '6186e0e698b8b115f06411d5',
          },
          {
            squad: '6166c85fe65c4ef2043da215',
            order: 8,
            _id: '6186e0e698b8b115f06411d6',
          },
          {
            squad: '6166c8f3e65c4ef2043edc02',
            order: 4,
            _id: '6186e0e698b8b115f06411d7',
          },
          {
            squad: '6166c8f2e65c4ef2043edae7',
            order: 7,
            _id: '6186e0e698b8b115f06411d8',
          },
          {
            squad: '6166c8f3e65c4ef2043edce5',
            order: 11,
            _id: '6186e0e698b8b115f06411d9',
          },
          {
            squad: '6166c8f0e65c4ef2043ed805',
            order: 9,
            _id: '6186e0e698b8b115f06411da',
          },
          {
            squad: '6166c8f3e65c4ef2043edd1e',
            order: 10,
            _id: '6186e0e698b8b115f06411db',
          },
          {
            squad: '6166c8f2e65c4ef2043edbd6',
            order: 12,
            _id: '6186e0e698b8b115f06411dc',
          },
          {
            squad: '6166c8f3e65c4ef2043edcaf',
            order: 13,
            _id: '6186e0e698b8b115f06411dd',
          },
          {
            squad: '6166c8f1e65c4ef2043eda25',
            order: 14,
            _id: '6186e0e698b8b115f06411de',
          },
          {
            squad: '6166c8f2e65c4ef2043edb93',
            order: 15,
            _id: '6186e0e698b8b115f06411df',
          },
          {
            squad: '6166c8f3e65c4ef2043edd3f',
            order: 16,
            _id: '6186e0e698b8b115f06411e0',
          },
          {
            squad: '6166c8f0e65c4ef2043ed845',
            order: 17,
            _id: '6186e0e698b8b115f06411e1',
          },
          {
            squad: '6166c8f3e65c4ef2043edd00',
            order: 18,
            _id: '6186e0e698b8b115f06411e2',
          },
          {
            squad: '6166c8f0e65c4ef2043ed87c',
            order: 19,
            _id: '6186e0e698b8b115f06411e3',
          },
          {
            squad: '6166c8f3e65c4ef2043edc1e',
            order: 20,
            _id: '6186e0e698b8b115f06411e4',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec479a',
      opta_ID: 'dsps3fih744xubmvca3yz4mj8',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efcd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-15Z',
      description: 'Tottenham Hotspur vs Manchester City',
      home_team: '6166c3dae65c4ef20437f50a',
      officials: [
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Main',
          _id: '6186e0e698b8b115f06411cd',
        },
        {
          official: '6166cd61e65c4ef2044669bc',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f06411ce',
        },
        {
          official: '6166cd62e65c4ef2044669c7',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f06411cf',
        },
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f06411d0',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef204375381',
      stage: 'Regular Season',
      time: '15:30:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c908e65c4ef2043ef9c9',
            order: 1,
            _id: '6186e0e698b8b115f0641212',
          },
          {
            squad: '6166c90ae65c4ef2043efb76',
            order: 4,
            _id: '6186e0e698b8b115f0641213',
          },
          {
            squad: '6166c90ae65c4ef2043efc4e',
            order: 5,
            _id: '6186e0e698b8b115f0641214',
          },
          {
            squad: '6166c908e65c4ef2043efa0e',
            order: 6,
            _id: '6186e0e698b8b115f0641215',
          },
          {
            squad: '6166c909e65c4ef2043efa3d',
            order: 3,
            _id: '6186e0e698b8b115f0641216',
          },
          {
            squad: '6166c908e65c4ef2043ef8ee',
            order: 2,
            _id: '6186e0e698b8b115f0641217',
          },
          {
            squad: '6166c909e65c4ef2043efb28',
            order: 11,
            _id: '6186e0e698b8b115f0641218',
          },
          {
            squad: '6166c909e65c4ef2043efb0e',
            order: 8,
            _id: '6186e0e698b8b115f0641219',
          },
          {
            squad: '6166c907e65c4ef2043ef8ba',
            order: 7,
            _id: '6186e0e698b8b115f064121a',
          },
          {
            squad: '6166c90ae65c4ef2043efc02',
            order: 9,
            _id: '6186e0e698b8b115f064121b',
          },
          {
            squad: '6166c908e65c4ef2043ef95b',
            order: 10,
            _id: '6186e0e698b8b115f064121c',
          },
          {
            squad: '6166c90ae65c4ef2043efb61',
            order: 12,
            _id: '6186e0e698b8b115f064121d',
          },
          {
            squad: '6166c908e65c4ef2043ef9b2',
            order: 13,
            _id: '6186e0e698b8b115f064121e',
          },
          {
            squad: '6166c909e65c4ef2043efac4',
            order: 14,
            _id: '6186e0e698b8b115f064121f',
          },
          {
            squad: '6166c907e65c4ef2043ef8d8',
            order: 15,
            _id: '6186e0e698b8b115f0641220',
          },
          {
            squad: '6166c908e65c4ef2043ef909',
            order: 16,
            _id: '6186e0e698b8b115f0641221',
          },
          {
            squad: '6166c909e65c4ef2043efa25',
            order: 17,
            _id: '6186e0e698b8b115f0641222',
          },
          {
            squad: '6166c909e65c4ef2043efa59',
            order: 18,
            _id: '6186e0e698b8b115f0641223',
          },
          {
            squad: '6166c909e65c4ef2043efa96',
            order: 19,
            _id: '6186e0e698b8b115f0641224',
          },
          {
            squad: '6166c90ae65c4ef2043efc1c',
            order: 20,
            _id: '6186e0e698b8b115f0641225',
          },
        ],
        away: [
          {
            squad: '6166c97ae65c4ef2043fc48b',
            order: 1,
            _id: '6186e0e698b8b115f06411fe',
          },
          {
            squad: '6166c979e65c4ef2043fc00d',
            order: 3,
            _id: '6186e0e698b8b115f06411ff',
          },
          {
            squad: '6166c979e65c4ef2043fc07e',
            order: 6,
            _id: '6186e0e698b8b115f0641200',
          },
          {
            squad: '6166c978e65c4ef2043fb888',
            order: 5,
            _id: '6186e0e698b8b115f0641201',
          },
          {
            squad: '6166c978e65c4ef2043fbaa1',
            order: 2,
            _id: '6186e0e698b8b115f0641202',
          },
          {
            squad: '6166c979e65c4ef2043fbd76',
            order: 4,
            _id: '6186e0e698b8b115f0641203',
          },
          {
            squad: '6166c978e65c4ef2043fbcbd',
            order: 8,
            _id: '6186e0e698b8b115f0641204',
          },
          {
            squad: '6166c978e65c4ef2043fbb8e',
            order: 11,
            _id: '6186e0e698b8b115f0641205',
          },
          {
            squad: '6166c97ae65c4ef2043fc52e',
            order: 10,
            _id: '6186e0e698b8b115f0641206',
          },
          {
            squad: '6166c97ae65c4ef2043fc356',
            order: 7,
            _id: '6186e0e698b8b115f0641207',
          },
          {
            squad: '6166c978e65c4ef2043fb93a',
            order: 9,
            _id: '6186e0e698b8b115f0641208',
          },
          {
            squad: '6166c979e65c4ef2043fbeca',
            order: 12,
            _id: '6186e0e698b8b115f0641209',
          },
          {
            squad: '6166c978e65c4ef2043fbb3e',
            order: 13,
            _id: '6186e0e698b8b115f064120a',
          },
          {
            squad: '6166c921e65c4ef2043f1b75',
            order: 14,
            _id: '6186e0e698b8b115f064120b',
          },
          {
            squad: '6166c97ae65c4ef2043fc2a7',
            order: 15,
            _id: '6186e0e698b8b115f064120c',
          },
          {
            squad: '6166c97be65c4ef2043fc69e',
            order: 16,
            _id: '6186e0e698b8b115f064120d',
          },
          {
            squad: '6166c978e65c4ef2043fbc16',
            order: 17,
            _id: '6186e0e698b8b115f064120e',
          },
          {
            squad: '6166c97ae65c4ef2043fc4d9',
            order: 18,
            _id: '6186e0e698b8b115f064120f',
          },
          {
            squad: '6166c978e65c4ef2043fbbc7',
            order: 19,
            _id: '6186e0e698b8b115f0641210',
          },
          {
            squad: '6166c979e65c4ef2043fbe2b',
            order: 20,
            _id: '6186e0e698b8b115f0641211',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec47b8',
      opta_ID: 'dsgi0d24o1fvpztnwuylorj84',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437fa1b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-15Z',
      description: 'Newcastle United vs West Ham United',
      home_team: '6166c3dce65c4ef20437fea1',
      officials: [
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Main',
          _id: '6186e0e698b8b115f06411fa',
        },
        {
          official: '6166cd62e65c4ef204466aff',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f06411fb',
        },
        {
          official: '6166cd62e65c4ef204466a79',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f06411fc',
        },
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f06411fd',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c360e65c4ef204374f6e',
      stage: 'Regular Season',
      time: '13:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c913e65c4ef2043f0692',
            order: 1,
            _id: '6186e0e698b8b115f064123f',
          },
          {
            squad: '6166c913e65c4ef2043f0675',
            order: 3,
            _id: '6186e0e698b8b115f0641240',
          },
          {
            squad: '6166c912e65c4ef2043f05a1',
            order: 6,
            _id: '6186e0e698b8b115f0641241',
          },
          {
            squad: '6166c911e65c4ef2043f049f',
            order: 5,
            _id: '6186e0e698b8b115f0641242',
          },
          {
            squad: '6166c912e65c4ef2043f0619',
            order: 2,
            _id: '6186e0e698b8b115f0641243',
          },
          {
            squad: '6166c913e65c4ef2043f0715',
            order: 8,
            _id: '6186e0e698b8b115f0641244',
          },
          {
            squad: '6166c912e65c4ef2043f052f',
            order: 4,
            _id: '6186e0e698b8b115f0641245',
          },
          {
            squad: '6166c914e65c4ef2043f0833',
            order: 7,
            _id: '6186e0e698b8b115f0641246',
          },
          {
            squad: '6166c911e65c4ef2043f0457',
            order: 11,
            _id: '6186e0e698b8b115f0641247',
          },
          {
            squad: '6166c913e65c4ef2043f0659',
            order: 9,
            _id: '6186e0e698b8b115f0641248',
          },
          {
            squad: '6166c914e65c4ef2043f079f',
            order: 10,
            _id: '6186e0e698b8b115f0641249',
          },
          {
            squad: '6166c914e65c4ef2043f0856',
            order: 12,
            _id: '6186e0e698b8b115f064124a',
          },
          {
            squad: '6166c911e65c4ef2043f04cf',
            order: 13,
            _id: '6186e0e698b8b115f064124b',
          },
          {
            squad: '6166c913e65c4ef2043f0744',
            order: 14,
            _id: '6186e0e698b8b115f064124c',
          },
          {
            squad: '6166c912e65c4ef2043f054d',
            order: 15,
            _id: '6186e0e698b8b115f064124d',
          },
          {
            squad: '6166c912e65c4ef2043f0515',
            order: 16,
            _id: '6186e0e698b8b115f064124e',
          },
          {
            squad: '6166c911e65c4ef2043f04ed',
            order: 17,
            _id: '6186e0e698b8b115f064124f',
          },
          {
            squad: '6166c913e65c4ef2043f075e',
            order: 18,
            _id: '6186e0e698b8b115f0641250',
          },
          {
            squad: '6166c913e65c4ef2043f06fb',
            order: 19,
            _id: '6186e0e698b8b115f0641251',
          },
          {
            squad: '6166c914e65c4ef2043f07e1',
            order: 20,
            _id: '6186e0e698b8b115f0641252',
          },
        ],
        away: [
          {
            squad: '6166c8e9e65c4ef2043ecf1b',
            order: 1,
            _id: '6186e0e698b8b115f064122b',
          },
          {
            squad: '6166c8e9e65c4ef2043eceb7',
            order: 3,
            _id: '6186e0e698b8b115f064122c',
          },
          {
            squad: '6166c8ebe65c4ef2043ed26e',
            order: 6,
            _id: '6186e0e698b8b115f064122d',
          },
          {
            squad: '6166c8ece65c4ef2043ed2dc',
            order: 5,
            _id: '6186e0e698b8b115f064122e',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdd7',
            order: 2,
            _id: '6186e0e698b8b115f064122f',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf9c',
            order: 8,
            _id: '6186e0e698b8b115f0641230',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf04',
            order: 4,
            _id: '6186e0e698b8b115f0641231',
          },
          {
            squad: '6166c8eae65c4ef2043ed07e',
            order: 7,
            _id: '6186e0e698b8b115f0641232',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1c3',
            order: 11,
            _id: '6186e0e698b8b115f0641233',
          },
          {
            squad: '6166c8ebe65c4ef2043ed179',
            order: 9,
            _id: '6186e0e698b8b115f0641234',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf32',
            order: 10,
            _id: '6186e0e698b8b115f0641235',
          },
          {
            squad: '6166c8e9e65c4ef2043ecf65',
            order: 12,
            _id: '6186e0e698b8b115f0641236',
          },
          {
            squad: '6166c8ece65c4ef2043ed287',
            order: 13,
            _id: '6186e0e698b8b115f0641237',
          },
          {
            squad: '6166c8e8e65c4ef2043ece40',
            order: 14,
            _id: '6186e0e698b8b115f0641238',
          },
          {
            squad: '6166c8ebe65c4ef2043ed24f',
            order: 15,
            _id: '6186e0e698b8b115f0641239',
          },
          {
            squad: '6166c8e9e65c4ef2043ece81',
            order: 16,
            _id: '6186e0e698b8b115f064123a',
          },
          {
            squad: '6166c8ebe65c4ef2043ed1eb',
            order: 17,
            _id: '6186e0e698b8b115f064123b',
          },
          {
            squad: '6166c8e8e65c4ef2043ecdec',
            order: 18,
            _id: '6186e0e698b8b115f064123c',
          },
          {
            squad: '6166c8eae65c4ef2043ecfcc',
            order: 19,
            _id: '6186e0e698b8b115f064123d',
          },
          {
            squad: '6166c8ebe65c4ef2043ed21e',
            order: 20,
            _id: '6186e0e698b8b115f064123e',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec47d0',
      opta_ID: 'dsl7ubid2l3qpsfhy9dkjgs9g',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef2043806f4',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Norwich City vs Liverpool',
      home_team: '6166c3dfe65c4ef204380c3a',
      officials: [
        {
          official: '6166cd61e65c4ef204466933',
          type: 'Main',
          _id: '6186e0e698b8b115f0641227',
        },
        {
          official: '6166cd5ee65c4ef2044665d3',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641228',
        },
        {
          official: '6166cd55e65c4ef204465d35',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641229',
        },
        {
          official: '6166cd64e65c4ef204466c2e',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f064122a',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437508b',
      stage: 'Regular Season',
      time: '16:30:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c96ee65c4ef2043f9180',
            order: 1,
            _id: '6186e0e698b8b115f064126c',
          },
          {
            squad: '6166c970e65c4ef2043f9767',
            order: 3,
            _id: '6186e0e698b8b115f064126d',
          },
          {
            squad: '6166c96fe65c4ef2043f9608',
            order: 6,
            _id: '6186e0e698b8b115f064126e',
          },
          {
            squad: '6166c970e65c4ef2043f9843',
            order: 5,
            _id: '6186e0e698b8b115f064126f',
          },
          {
            squad: '6166c971e65c4ef2043f99c1',
            order: 2,
            _id: '6186e0e698b8b115f0641270',
          },
          {
            squad: '6166c96ee65c4ef2043f90f5',
            order: 4,
            _id: '6186e0e698b8b115f0641271',
          },
          {
            squad: '6166c970e65c4ef2043f98ac',
            order: 11,
            _id: '6186e0e698b8b115f0641272',
          },
          {
            squad: '6166c974e65c4ef2043fa4c8',
            order: 10,
            _id: '6186e0e698b8b115f0641273',
          },
          {
            squad: '6166c971e65c4ef2043f9ae1',
            order: 8,
            _id: '6186e0e698b8b115f0641274',
          },
          {
            squad: '6166c96fe65c4ef2043f9431',
            order: 7,
            _id: '6186e0e698b8b115f0641275',
          },
          {
            squad: '6166c96fe65c4ef2043f93b9',
            order: 9,
            _id: '6186e0e698b8b115f0641276',
          },
          {
            squad: '6166c86ae65c4ef2043db163',
            order: 12,
            _id: '6186e0e698b8b115f0641277',
          },
          {
            squad: '6166c972e65c4ef2043f9f3d',
            order: 13,
            _id: '6186e0e698b8b115f0641278',
          },
          {
            squad: '6166c96fe65c4ef2043f933f',
            order: 14,
            _id: '6186e0e698b8b115f0641279',
          },
          {
            squad: '6166c971e65c4ef2043f9a14',
            order: 15,
            _id: '6186e0e698b8b115f064127a',
          },
          {
            squad: '6166c971e65c4ef2043f9a80',
            order: 16,
            _id: '6186e0e698b8b115f064127b',
          },
          {
            squad: '6166c972e65c4ef2043f9d39',
            order: 17,
            _id: '6186e0e698b8b115f064127c',
          },
          {
            squad: '6166c96ee65c4ef2043f9205',
            order: 18,
            _id: '6186e0e698b8b115f064127d',
          },
          {
            squad: '6166c973e65c4ef2043fa0c3',
            order: 19,
            _id: '6186e0e698b8b115f064127e',
          },
          {
            squad: '6166c972e65c4ef2043f9e69',
            order: 20,
            _id: '6186e0e698b8b115f064127f',
          },
        ],
        away: [
          {
            squad: '6166c85fe65c4ef2043da10e',
            order: 1,
            _id: '6186e0e698b8b115f0641258',
          },
          {
            squad: '6166c85fe65c4ef2043da275',
            order: 3,
            _id: '6186e0e698b8b115f0641259',
          },
          {
            squad: '6166c85ee65c4ef2043d9ffe',
            order: 6,
            _id: '6186e0e698b8b115f064125a',
          },
          {
            squad: '6166c860e65c4ef2043da32f',
            order: 5,
            _id: '6186e0e698b8b115f064125b',
          },
          {
            squad: '6166c85de65c4ef2043d9e9d',
            order: 2,
            _id: '6186e0e698b8b115f064125c',
          },
          {
            squad: '6166c861e65c4ef2043da50e',
            order: 4,
            _id: '6186e0e698b8b115f064125d',
          },
          {
            squad: '6166c861e65c4ef2043da536',
            order: 8,
            _id: '6186e0e698b8b115f064125e',
          },
          {
            squad: '6166c860e65c4ef2043da30b',
            order: 11,
            _id: '6186e0e698b8b115f064125f',
          },
          {
            squad: '6166c862e65c4ef2043da655',
            order: 10,
            _id: '6186e0e698b8b115f0641260',
          },
          {
            squad: '6166c861e65c4ef2043da575',
            order: 7,
            _id: '6186e0e698b8b115f0641261',
          },
          {
            squad: '6166c861e65c4ef2043da4a5',
            order: 9,
            _id: '6186e0e698b8b115f0641262',
          },
          {
            squad: '6166c85fe65c4ef2043da135',
            order: 12,
            _id: '6186e0e698b8b115f0641263',
          },
          {
            squad: '6166c85fe65c4ef2043da1a9',
            order: 13,
            _id: '6186e0e698b8b115f0641264',
          },
          {
            squad: '6166c85ee65c4ef2043da0ec',
            order: 14,
            _id: '6186e0e698b8b115f0641265',
          },
          {
            squad: '6166c860e65c4ef2043da3fd',
            order: 15,
            _id: '6186e0e698b8b115f0641266',
          },
          {
            squad: '6166c862e65c4ef2043da679',
            order: 16,
            _id: '6186e0e698b8b115f0641267',
          },
          {
            squad: '6166c861e65c4ef2043da4e3',
            order: 17,
            _id: '6186e0e698b8b115f0641268',
          },
          {
            squad: '6166c85ee65c4ef2043da0ae',
            order: 18,
            _id: '6186e0e698b8b115f0641269',
          },
          {
            squad: '6166c860e65c4ef2043da3df',
            order: 19,
            _id: '6186e0e698b8b115f064126a',
          },
          {
            squad: '6166c861e65c4ef2043da597',
            order: 20,
            _id: '6186e0e698b8b115f064126b',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec47de',
      opta_ID: 'dsu2nkbt8h70x10919uwm0ftg',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380414',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Watford vs Aston Villa',
      home_team: '6166c3dbe65c4ef20437f98c',
      officials: [
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Main',
          _id: '6186e0e698b8b115f0641254',
        },
        {
          official: '6166cd55e65c4ef204465d1c',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641255',
        },
        {
          official: '6166cd61e65c4ef2044668f2',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641256',
        },
        {
          official: '6166cd5ae65c4ef20446628b',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f0641257',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef20437506e',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8e1e65c4ef2043ec553',
            order: 1,
            _id: '6186e0e698b8b115f0641299',
          },
          {
            squad: '6166c8e0e65c4ef2043ec49a',
            order: 3,
            _id: '6186e0e698b8b115f064129a',
          },
          {
            squad: '6166c8e1e65c4ef2043ec5b6',
            order: 6,
            _id: '6186e0e698b8b115f064129b',
          },
          {
            squad: '6166c8e2e65c4ef2043ec665',
            order: 5,
            _id: '6186e0e698b8b115f064129c',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3b7',
            order: 2,
            _id: '6186e0e698b8b115f064129d',
          },
          {
            squad: '6166c8e0e65c4ef2043ec461',
            order: 4,
            _id: '6186e0e698b8b115f064129e',
          },
          {
            squad: '6166c8e2e65c4ef2043ec68d',
            order: 8,
            _id: '6186e0e698b8b115f064129f',
          },
          {
            squad: '6166c8e0e65c4ef2043ec36f',
            order: 11,
            _id: '6186e0e698b8b115f06412a0',
          },
          {
            squad: '6166c8e1e65c4ef2043ec59b',
            order: 10,
            _id: '6186e0e698b8b115f06412a1',
          },
          {
            squad: '6166c8e2e65c4ef2043ec603',
            order: 7,
            _id: '6186e0e698b8b115f06412a2',
          },
          {
            squad: '6166c8e0e65c4ef2043ec482',
            order: 9,
            _id: '6186e0e698b8b115f06412a3',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6db',
            order: 12,
            _id: '6186e0e698b8b115f06412a4',
          },
          {
            squad: '6166c8e2e65c4ef2043ec5ed',
            order: 13,
            _id: '6186e0e698b8b115f06412a5',
          },
          {
            squad: '6166c8e2e65c4ef2043ec6a8',
            order: 14,
            _id: '6186e0e698b8b115f06412a6',
          },
          {
            squad: '6166c8e0e65c4ef2043ec395',
            order: 15,
            _id: '6186e0e698b8b115f06412a7',
          },
          {
            squad: '6166c8e0e65c4ef2043ec414',
            order: 16,
            _id: '6186e0e698b8b115f06412a8',
          },
          {
            squad: '6166c8e1e65c4ef2043ec56c',
            order: 17,
            _id: '6186e0e698b8b115f06412a9',
          },
          {
            squad: '6166c8e2e65c4ef2043ec641',
            order: 18,
            _id: '6186e0e698b8b115f06412aa',
          },
          {
            squad: '6166c8e0e65c4ef2043ec3d0',
            order: 19,
            _id: '6186e0e698b8b115f06412ab',
          },
          {
            squad: '6166c8e0e65c4ef2043ec42e',
            order: 20,
            _id: '6186e0e698b8b115f06412ac',
          },
        ],
        away: [
          {
            squad: '6166c980e65c4ef2043fe112',
            order: 1,
            _id: '6186e0e698b8b115f0641285',
          },
          {
            squad: '6166c982e65c4ef2043feaed',
            order: 4,
            _id: '6186e0e698b8b115f0641286',
          },
          {
            squad: '6166c982e65c4ef2043feaa0',
            order: 5,
            _id: '6186e0e698b8b115f0641287',
          },
          {
            squad: '6166c97fe65c4ef2043fda39',
            order: 6,
            _id: '6186e0e698b8b115f0641288',
          },
          {
            squad: '6166c97fe65c4ef2043fdb1f',
            order: 3,
            _id: '6186e0e698b8b115f0641289',
          },
          {
            squad: '6166c981e65c4ef2043fe632',
            order: 8,
            _id: '6186e0e698b8b115f064128a',
          },
          {
            squad: '6166c981e65c4ef2043fe473',
            order: 7,
            _id: '6186e0e698b8b115f064128b',
          },
          {
            squad: '6166c980e65c4ef2043fe064',
            order: 2,
            _id: '6186e0e698b8b115f064128c',
          },
          {
            squad: '6166c982e65c4ef2043fea12',
            order: 11,
            _id: '6186e0e698b8b115f064128d',
          },
          {
            squad: '6166c980e65c4ef2043fdf1e',
            order: 9,
            _id: '6186e0e698b8b115f064128e',
          },
          {
            squad: '6166c980e65c4ef2043fe256',
            order: 10,
            _id: '6186e0e698b8b115f064128f',
          },
          {
            squad: '6166c981e65c4ef2043fe5e2',
            order: 12,
            _id: '6186e0e698b8b115f0641290',
          },
          {
            squad: '6166c97fe65c4ef2043fda8e',
            order: 13,
            _id: '6186e0e698b8b115f0641291',
          },
          {
            squad: '6166c981e65c4ef2043fe690',
            order: 14,
            _id: '6186e0e698b8b115f0641292',
          },
          {
            squad: '6166c982e65c4ef2043fe7cf',
            order: 15,
            _id: '6186e0e698b8b115f0641293',
          },
          {
            squad: '6166c97fe65c4ef2043fd941',
            order: 16,
            _id: '6186e0e698b8b115f0641294',
          },
          {
            squad: '6166c982e65c4ef2043fe876',
            order: 17,
            _id: '6186e0e698b8b115f0641295',
          },
          {
            squad: '6166c945e65c4ef2043f4ea9',
            order: 18,
            _id: '6186e0e698b8b115f0641296',
          },
          {
            squad: '6166c981e65c4ef2043fe677',
            order: 19,
            _id: '6186e0e698b8b115f0641297',
          },
          {
            squad: '6166c980e65c4ef2043fe1b9',
            order: 20,
            _id: '6186e0e698b8b115f0641298',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec47ec',
      opta_ID: 'ds7fa1dyrmk8h3u0lgl3vpces',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef204380695',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Leicester City vs Wolverhampton Wanderers',
      home_team: '6166c3dae65c4ef20437f4c9',
      officials: [
        {
          official: '6166cd62e65c4ef204466af3',
          type: 'Main',
          _id: '6186e0e698b8b115f0641281',
        },
        {
          official: '6166cd63e65c4ef204466bfa',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641282',
        },
        {
          official: '6166cd5ce65c4ef2044664dd',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641283',
        },
        {
          official: '6166cd62e65c4ef204466ac2',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f0641284',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef2043750aa',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8bee65c4ef2043e3f3f',
            order: 1,
            _id: '6186e0e698b8b115f06412c6',
          },
          {
            squad: '6166c8bee65c4ef2043e3f9d',
            order: 3,
            _id: '6186e0e698b8b115f06412c7',
          },
          {
            squad: '6166c8bbe65c4ef2043e3361',
            order: 6,
            _id: '6186e0e698b8b115f06412c8',
          },
          {
            squad: '6166c8bbe65c4ef2043e31b0',
            order: 5,
            _id: '6186e0e698b8b115f06412c9',
          },
          {
            squad: '6166c8bce65c4ef2043e38b1',
            order: 2,
            _id: '6186e0e698b8b115f06412ca',
          },
          {
            squad: '6166c8bce65c4ef2043e360b',
            order: 4,
            _id: '6186e0e698b8b115f06412cb',
          },
          {
            squad: '6166c8bee65c4ef2043e3fd8',
            order: 8,
            _id: '6186e0e698b8b115f06412cc',
          },
          {
            squad: '6166c8bde65c4ef2043e3b4b',
            order: 11,
            _id: '6186e0e698b8b115f06412cd',
          },
          {
            squad: '6166c8bbe65c4ef2043e3189',
            order: 10,
            _id: '6186e0e698b8b115f06412ce',
          },
          {
            squad: '6166c8bae65c4ef2043e2d9d',
            order: 7,
            _id: '6186e0e698b8b115f06412cf',
          },
          {
            squad: '6166c8bae65c4ef2043e2e66',
            order: 9,
            _id: '6186e0e698b8b115f06412d0',
          },
          {
            squad: '6166c8bbe65c4ef2043e31e7',
            order: 12,
            _id: '6186e0e698b8b115f06412d1',
          },
          {
            squad: '6166c8bae65c4ef2043e2ce3',
            order: 13,
            _id: '6186e0e698b8b115f06412d2',
          },
          {
            squad: '6166c8bce65c4ef2043e3474',
            order: 14,
            _id: '6186e0e698b8b115f06412d3',
          },
          {
            squad: '6166c8bde65c4ef2043e3bbd',
            order: 15,
            _id: '6186e0e698b8b115f06412d4',
          },
          {
            squad: '6166c8bde65c4ef2043e3952',
            order: 16,
            _id: '6186e0e698b8b115f06412d5',
          },
          {
            squad: '6166c8bce65c4ef2043e3687',
            order: 17,
            _id: '6186e0e698b8b115f06412d6',
          },
          {
            squad: '6166c8bce65c4ef2043e3668',
            order: 18,
            _id: '6186e0e698b8b115f06412d7',
          },
          {
            squad: '6166c8bee65c4ef2043e3e84',
            order: 19,
            _id: '6186e0e698b8b115f06412d8',
          },
          {
            squad: '6166c8bde65c4ef2043e3d3e',
            order: 20,
            _id: '6186e0e698b8b115f06412d9',
          },
        ],
        away: [
          {
            squad: '6166c94fe65c4ef2043f5a87',
            order: 1,
            _id: '6186e0e698b8b115f06412b2',
          },
          {
            squad: '6166c94de65c4ef2043f58ce',
            order: 3,
            _id: '6186e0e698b8b115f06412b3',
          },
          {
            squad: '6166c94ce65c4ef2043f57d9',
            order: 6,
            _id: '6186e0e698b8b115f06412b4',
          },
          {
            squad: '6166c94fe65c4ef2043f5b1f',
            order: 5,
            _id: '6186e0e698b8b115f06412b5',
          },
          {
            squad: '6166c94ee65c4ef2043f5a30',
            order: 2,
            _id: '6186e0e698b8b115f06412b6',
          },
          {
            squad: '6166c94fe65c4ef2043f5b02',
            order: 11,
            _id: '6186e0e698b8b115f06412b7',
          },
          {
            squad: '6166c94fe65c4ef2043f5a6a',
            order: 8,
            _id: '6186e0e698b8b115f06412b8',
          },
          {
            squad: '6166c94ee65c4ef2043f5948',
            order: 4,
            _id: '6186e0e698b8b115f06412b9',
          },
          {
            squad: '6166c94ce65c4ef2043f57be',
            order: 7,
            _id: '6186e0e698b8b115f06412ba',
          },
          {
            squad: '6166c86fe65c4ef2043db744',
            order: 9,
            _id: '6186e0e698b8b115f06412bb',
          },
          {
            squad: '6166c94de65c4ef2043f5896',
            order: 10,
            _id: '6186e0e698b8b115f06412bc',
          },
          {
            squad: '6166c94ee65c4ef2043f5a14',
            order: 12,
            _id: '6186e0e698b8b115f06412bd',
          },
          {
            squad: '6166c94ce65c4ef2043f578f',
            order: 13,
            _id: '6186e0e698b8b115f06412be',
          },
          {
            squad: '6166c94ee65c4ef2043f592b',
            order: 14,
            _id: '6186e0e698b8b115f06412bf',
          },
          {
            squad: '6166c94ee65c4ef2043f5913',
            order: 15,
            _id: '6186e0e698b8b115f06412c0',
          },
          {
            squad: '6166c94ee65c4ef2043f5961',
            order: 16,
            _id: '6186e0e698b8b115f06412c1',
          },
          {
            squad: '6166c94de65c4ef2043f58f5',
            order: 17,
            _id: '6186e0e698b8b115f06412c2',
          },
          {
            squad: '6166c94fe65c4ef2043f5aa2',
            order: 18,
            _id: '6186e0e698b8b115f06412c3',
          },
          {
            squad: '6166c94ee65c4ef2043f59a8',
            order: 19,
            _id: '6186e0e698b8b115f06412c4',
          },
          {
            squad: '6166c94de65c4ef2043f57f8',
            order: 20,
            _id: '6186e0e698b8b115f06412c5',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec47ff',
      opta_ID: 'ds2x16s85zkxe7c7dgpalao7o',
      user: null,
      __v: 0,
      away_team: '6166c3dee65c4ef20438075c',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Everton vs Southampton',
      home_team: '6166c3dfe65c4ef204380b9d',
      officials: [
        {
          official: '6166cd55e65c4ef204465d0c',
          type: 'Main',
          _id: '6186e0e698b8b115f06412ae',
        },
        {
          official: '6166cd48e65c4ef204464f4d',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f06412af',
        },
        {
          official: '6166cd61e65c4ef2044668dc',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f06412b0',
        },
        {
          official: '6166cd63e65c4ef204466ba1',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f06412b1',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c363e65c4ef2043752ff',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c89be65c4ef2043df77e',
            order: 1,
            _id: '6186e0e698b8b115f06412f3',
          },
          {
            squad: '6166c89ce65c4ef2043df8d0',
            order: 4,
            _id: '6186e0e698b8b115f06412f4',
          },
          {
            squad: '6166c89be65c4ef2043df73c',
            order: 5,
            _id: '6186e0e698b8b115f06412f5',
          },
          {
            squad: '6166c89ce65c4ef2043df85f',
            order: 6,
            _id: '6186e0e698b8b115f06412f6',
          },
          {
            squad: '6166c89de65c4ef2043dfa38',
            order: 3,
            _id: '6186e0e698b8b115f06412f7',
          },
          {
            squad: '6166c89ee65c4ef2043dfc30',
            order: 8,
            _id: '6186e0e698b8b115f06412f8',
          },
          {
            squad: '6166c89de65c4ef2043dfa5b',
            order: 7,
            _id: '6186e0e698b8b115f06412f9',
          },
          {
            squad: '6166c89de65c4ef2043dfafc',
            order: 2,
            _id: '6186e0e698b8b115f06412fa',
          },
          {
            squad: '6166c89de65c4ef2043dfa18',
            order: 11,
            _id: '6186e0e698b8b115f06412fb',
          },
          {
            squad: '6166c89ce65c4ef2043df97d',
            order: 10,
            _id: '6186e0e698b8b115f06412fc',
          },
          {
            squad: '6166c89be65c4ef2043df7f3',
            order: 9,
            _id: '6186e0e698b8b115f06412fd',
          },
          {
            squad: '6166c89ce65c4ef2043df8f1',
            order: 12,
            _id: '6186e0e698b8b115f06412fe',
          },
          {
            squad: '6166c89be65c4ef2043df82b',
            order: 13,
            _id: '6186e0e698b8b115f06412ff',
          },
          {
            squad: '6166c89ce65c4ef2043df941',
            order: 14,
            _id: '6186e0e698b8b115f0641300',
          },
          {
            squad: '6166c89ee65c4ef2043dfc0f',
            order: 15,
            _id: '6186e0e698b8b115f0641301',
          },
          {
            squad: '6166c89ce65c4ef2043df95c',
            order: 16,
            _id: '6186e0e698b8b115f0641302',
          },
          {
            squad: '6166c89ee65c4ef2043dfbb4',
            order: 17,
            _id: '6186e0e698b8b115f0641303',
          },
          {
            squad: '6166c89ce65c4ef2043df997',
            order: 18,
            _id: '6186e0e698b8b115f0641304',
          },
          {
            squad: '6166c89de65c4ef2043dfa8c',
            order: 19,
            _id: '6186e0e698b8b115f0641305',
          },
          {
            squad: '6166c89ee65c4ef2043dfb34',
            order: 20,
            _id: '6186e0e698b8b115f0641306',
          },
        ],
        away: [
          {
            squad: '6166c8b1e65c4ef2043e137f',
            order: 1,
            _id: '6186e0e698b8b115f06412df',
          },
          {
            squad: '6166c8b1e65c4ef2043e12e8',
            order: 3,
            _id: '6186e0e698b8b115f06412e0',
          },
          {
            squad: '6166c8b1e65c4ef2043e13ec',
            order: 6,
            _id: '6186e0e698b8b115f06412e1',
          },
          {
            squad: '6166c8b2e65c4ef2043e143f',
            order: 5,
            _id: '6186e0e698b8b115f06412e2',
          },
          {
            squad: '6166c8b0e65c4ef2043e1220',
            order: 2,
            _id: '6186e0e698b8b115f06412e3',
          },
          {
            squad: '6166c8b1e65c4ef2043e1304',
            order: 11,
            _id: '6186e0e698b8b115f06412e4',
          },
          {
            squad: '6166c8b2e65c4ef2043e1427',
            order: 8,
            _id: '6186e0e698b8b115f06412e5',
          },
          {
            squad: '6166c8b2e65c4ef2043e1468',
            order: 4,
            _id: '6186e0e698b8b115f06412e6',
          },
          {
            squad: '6166c8b1e65c4ef2043e13af',
            order: 7,
            _id: '6186e0e698b8b115f06412e7',
          },
          {
            squad: '6166c8b2e65c4ef2043e1529',
            order: 9,
            _id: '6186e0e698b8b115f06412e8',
          },
          {
            squad: '6166c8b1e65c4ef2043e13c7',
            order: 10,
            _id: '6186e0e698b8b115f06412e9',
          },
          {
            squad: '6166c8b1e65c4ef2043e12c5',
            order: 12,
            _id: '6186e0e698b8b115f06412ea',
          },
          {
            squad: '6166c8b0e65c4ef2043e1295',
            order: 13,
            _id: '6186e0e698b8b115f06412eb',
          },
          {
            squad: '6166c8b3e65c4ef2043e15a7',
            order: 14,
            _id: '6186e0e698b8b115f06412ec',
          },
          {
            squad: '6166c8b1e65c4ef2043e135e',
            order: 15,
            _id: '6186e0e698b8b115f06412ed',
          },
          {
            squad: '6166c8b3e65c4ef2043e1576',
            order: 16,
            _id: '6186e0e698b8b115f06412ee',
          },
          {
            squad: '6166c8b0e65c4ef2043e123a',
            order: 17,
            _id: '6186e0e698b8b115f06412ef',
          },
          {
            squad: '6166c8b1e65c4ef2043e1398',
            order: 18,
            _id: '6186e0e698b8b115f06412f0',
          },
          {
            squad: '6166c8b2e65c4ef2043e1497',
            order: 19,
            _id: '6186e0e698b8b115f06412f1',
          },
          {
            squad: '6166c8b1e65c4ef2043e132d',
            order: 20,
            _id: '6186e0e698b8b115f06412f2',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec480f',
      opta_ID: 'dryijd5suanvjisatmc85zh1w',
      user: null,
      __v: 0,
      away_team: '6166c3d9e65c4ef20437efb7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Chelsea vs Crystal Palace',
      home_team: '6166c3dde65c4ef2043802ff',
      officials: [
        {
          official: '6166cd63e65c4ef204466b7e',
          type: 'Main',
          _id: '6186e0e698b8b115f06412db',
        },
        {
          official: '6166cd63e65c4ef204466b8b',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f06412dc',
        },
        {
          official: '6166cd63e65c4ef204466b96',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f06412dd',
        },
        {
          official: '6166cd54e65c4ef204465be9',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f06412de',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c35fe65c4ef204374da5',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c889e65c4ef2043ddddf',
            order: 1,
            _id: '6186e0e698b8b115f0641320',
          },
          {
            squad: '6166c889e65c4ef2043dddb7',
            order: 3,
            _id: '6186e0e698b8b115f0641321',
          },
          {
            squad: '6166c888e65c4ef2043ddc1f',
            order: 6,
            _id: '6186e0e698b8b115f0641322',
          },
          {
            squad: '6166c887e65c4ef2043ddb8d',
            order: 5,
            _id: '6186e0e698b8b115f0641323',
          },
          {
            squad: '6166c888e65c4ef2043ddd57',
            order: 2,
            _id: '6186e0e698b8b115f0641324',
          },
          {
            squad: '6166c888e65c4ef2043ddc99',
            order: 11,
            _id: '6186e0e698b8b115f0641325',
          },
          {
            squad: '6166c887e65c4ef2043ddb6e',
            order: 8,
            _id: '6186e0e698b8b115f0641326',
          },
          {
            squad: '6166c888e65c4ef2043ddd76',
            order: 4,
            _id: '6186e0e698b8b115f0641327',
          },
          {
            squad: '6166c888e65c4ef2043ddcd1',
            order: 7,
            _id: '6186e0e698b8b115f0641328',
          },
          {
            squad: '6166c889e65c4ef2043dde41',
            order: 9,
            _id: '6186e0e698b8b115f0641329',
          },
          {
            squad: '6166c889e65c4ef2043dde7c',
            order: 10,
            _id: '6186e0e698b8b115f064132a',
          },
          {
            squad: '6166c888e65c4ef2043ddbab',
            order: 12,
            _id: '6186e0e698b8b115f064132b',
          },
          {
            squad: '6166c889e65c4ef2043dde5e',
            order: 13,
            _id: '6186e0e698b8b115f064132c',
          },
          {
            squad: '6166c889e65c4ef2043ddebb',
            order: 14,
            _id: '6186e0e698b8b115f064132d',
          },
          {
            squad: '6166c889e65c4ef2043ddea2',
            order: 15,
            _id: '6186e0e698b8b115f064132e',
          },
          {
            squad: '6166c888e65c4ef2043ddd1a',
            order: 16,
            _id: '6186e0e698b8b115f064132f',
          },
          {
            squad: '6166c889e65c4ef2043dde1e',
            order: 17,
            _id: '6186e0e698b8b115f0641330',
          },
          {
            squad: '6166c888e65c4ef2043ddc68',
            order: 18,
            _id: '6186e0e698b8b115f0641331',
          },
          {
            squad: '6166c888e65c4ef2043ddbfa',
            order: 19,
            _id: '6186e0e698b8b115f0641332',
          },
          {
            squad: '6166c889e65c4ef2043dde01',
            order: 20,
            _id: '6186e0e698b8b115f0641333',
          },
        ],
        away: [
          {
            squad: '6166c880e65c4ef2043dd16b',
            order: 1,
            _id: '6186e0e698b8b115f064130c',
          },
          {
            squad: '6166c87de65c4ef2043dcd62',
            order: 3,
            _id: '6186e0e698b8b115f064130d',
          },
          {
            squad: '6166c881e65c4ef2043dd21f',
            order: 6,
            _id: '6186e0e698b8b115f064130e',
          },
          {
            squad: '6166c87fe65c4ef2043dcf31',
            order: 5,
            _id: '6186e0e698b8b115f064130f',
          },
          {
            squad: '6166c87ee65c4ef2043dce98',
            order: 2,
            _id: '6186e0e698b8b115f0641310',
          },
          {
            squad: '6166c87ee65c4ef2043dce57',
            order: 4,
            _id: '6186e0e698b8b115f0641311',
          },
          {
            squad: '6166c87fe65c4ef2043dcf7b',
            order: 11,
            _id: '6186e0e698b8b115f0641312',
          },
          {
            squad: '6166c87de65c4ef2043dcc79',
            order: 10,
            _id: '6186e0e698b8b115f0641313',
          },
          {
            squad: '6166c87de65c4ef2043dccdf',
            order: 8,
            _id: '6186e0e698b8b115f0641314',
          },
          {
            squad: '6166c87de65c4ef2043dcd04',
            order: 7,
            _id: '6186e0e698b8b115f0641315',
          },
          {
            squad: '6166c881e65c4ef2043dd25e',
            order: 9,
            _id: '6186e0e698b8b115f0641316',
          },
          {
            squad: '6166c87fe65c4ef2043dd05b',
            order: 12,
            _id: '6186e0e698b8b115f0641317',
          },
          {
            squad: '6166c87de65c4ef2043dcd98',
            order: 13,
            _id: '6186e0e698b8b115f0641318',
          },
          {
            squad: '6166c87fe65c4ef2043dcfa9',
            order: 14,
            _id: '6186e0e698b8b115f0641319',
          },
          {
            squad: '6166c881e65c4ef2043dd1de',
            order: 15,
            _id: '6186e0e698b8b115f064131a',
          },
          {
            squad: '6166c87fe65c4ef2043dd01c',
            order: 16,
            _id: '6186e0e698b8b115f064131b',
          },
          {
            squad: '6166c87ee65c4ef2043dce39',
            order: 17,
            _id: '6186e0e698b8b115f064131c',
          },
          {
            squad: '6166c87ee65c4ef2043dceb6',
            order: 18,
            _id: '6186e0e698b8b115f064131d',
          },
          {
            squad: '6166c87de65c4ef2043dcd22',
            order: 19,
            _id: '6186e0e698b8b115f064131e',
          },
          {
            squad: '6166c87fe65c4ef2043dcfc5',
            order: 20,
            _id: '6186e0e698b8b115f064131f',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec4822',
      opta_ID: 'dru80d3leis6aelo350fviuj8',
      user: null,
      __v: 0,
      away_team: '6166c3dfe65c4ef2043807e7',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Burnley vs Brighton & Hove Albion',
      home_team: '6166c3dce65c4ef20437fd8b',
      officials: [
        {
          official: '6166cd5ce65c4ef204466433',
          type: 'Main',
          _id: '6186e0e698b8b115f0641308',
        },
        {
          official: '6166cd62e65c4ef204466ab5',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641309',
        },
        {
          official: '6166cd62e65c4ef204466a1e',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f064130a',
        },
        {
          official: '6166cd61e65c4ef204466984',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f064130b',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204375005',
      stage: 'Regular Season',
      time: '14:00:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c8f8e65c4ef2043ee27f',
            order: 1,
            _id: '6186e0e698b8b115f064134d',
          },
          {
            squad: '6166c8f6e65c4ef2043edff2',
            order: 3,
            _id: '6186e0e698b8b115f064134e',
          },
          {
            squad: '6166c8f4e65c4ef2043eddb7',
            order: 6,
            _id: '6186e0e698b8b115f064134f',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0ac',
            order: 5,
            _id: '6186e0e698b8b115f0641350',
          },
          {
            squad: '6166c8f8e65c4ef2043ee29b',
            order: 2,
            _id: '6186e0e698b8b115f0641351',
          },
          {
            squad: '6166c8f7e65c4ef2043ee261',
            order: 4,
            _id: '6186e0e698b8b115f0641352',
          },
          {
            squad: '6166c8f6e65c4ef2043ee06c',
            order: 8,
            _id: '6186e0e698b8b115f0641353',
          },
          {
            squad: '6166c8f8e65c4ef2043ee2db',
            order: 11,
            _id: '6186e0e698b8b115f0641354',
          },
          {
            squad: '6166c8f5e65c4ef2043ede6e',
            order: 10,
            _id: '6186e0e698b8b115f0641355',
          },
          {
            squad: '6166c8dfe65c4ef2043ec31e',
            order: 7,
            _id: '6186e0e698b8b115f0641356',
          },
          {
            squad: '6166c8f5e65c4ef2043edf95',
            order: 9,
            _id: '6186e0e698b8b115f0641357',
          },
          {
            squad: '6166c8f4e65c4ef2043ede37',
            order: 12,
            _id: '6186e0e698b8b115f0641358',
          },
          {
            squad: '6166c8f6e65c4ef2043ee018',
            order: 13,
            _id: '6186e0e698b8b115f0641359',
          },
          {
            squad: '6166c8f6e65c4ef2043ee0e3',
            order: 14,
            _id: '6186e0e698b8b115f064135a',
          },
          {
            squad: '6166c8f4e65c4ef2043ede54',
            order: 15,
            _id: '6186e0e698b8b115f064135b',
          },
          {
            squad: '6166c8f7e65c4ef2043ee182',
            order: 16,
            _id: '6186e0e698b8b115f064135c',
          },
          {
            squad: '6166c8f7e65c4ef2043ee1b2',
            order: 17,
            _id: '6186e0e698b8b115f064135d',
          },
          {
            squad: '6166c8f7e65c4ef2043ee206',
            order: 18,
            _id: '6186e0e698b8b115f064135e',
          },
          {
            squad: '6166c8f6e65c4ef2043edfcc',
            order: 19,
            _id: '6186e0e698b8b115f064135f',
          },
          {
            squad: '6166c8f5e65c4ef2043edf73',
            order: 20,
            _id: '6186e0e698b8b115f0641360',
          },
        ],
        away: [
          {
            squad: '6166c8dee65c4ef2043ec192',
            order: 1,
            _id: '6186e0e698b8b115f0641339',
          },
          {
            squad: '6166c8dee65c4ef2043ec16e',
            order: 3,
            _id: '6186e0e698b8b115f064133a',
          },
          {
            squad: '6166c8dde65c4ef2043ec0f1',
            order: 6,
            _id: '6186e0e698b8b115f064133b',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2dd',
            order: 5,
            _id: '6186e0e698b8b115f064133c',
          },
          {
            squad: '6166c8dee65c4ef2043ec1a8',
            order: 2,
            _id: '6186e0e698b8b115f064133d',
          },
          {
            squad: '6166c8dde65c4ef2043ec001',
            order: 4,
            _id: '6186e0e698b8b115f064133e',
          },
          {
            squad: '6166c8dee65c4ef2043ec108',
            order: 11,
            _id: '6186e0e698b8b115f064133f',
          },
          {
            squad: '6166c8dce65c4ef2043ebfcd',
            order: 10,
            _id: '6186e0e698b8b115f0641340',
          },
          {
            squad: '6166c8dde65c4ef2043ec08a',
            order: 8,
            _id: '6186e0e698b8b115f0641341',
          },
          {
            squad: '6166c8dde65c4ef2043ec051',
            order: 7,
            _id: '6186e0e698b8b115f0641342',
          },
          {
            squad: '6166c8dee65c4ef2043ec120',
            order: 9,
            _id: '6186e0e698b8b115f0641343',
          },
          {
            squad: '6166c8dee65c4ef2043ec222',
            order: 12,
            _id: '6186e0e698b8b115f0641344',
          },
          {
            squad: '6166c8dfe65c4ef2043ec2bb',
            order: 13,
            _id: '6186e0e698b8b115f0641345',
          },
          {
            squad: '6166c8dee65c4ef2043ec138',
            order: 14,
            _id: '6186e0e698b8b115f0641346',
          },
          {
            squad: '6166c8dee65c4ef2043ec1bd',
            order: 15,
            _id: '6186e0e698b8b115f0641347',
          },
          {
            squad: '6166c8dee65c4ef2043ec1f8',
            order: 16,
            _id: '6186e0e698b8b115f0641348',
          },
          {
            squad: '6166c8dde65c4ef2043ec0b9',
            order: 17,
            _id: '6186e0e698b8b115f0641349',
          },
          {
            squad: '6166c8dde65c4ef2043ec020',
            order: 18,
            _id: '6186e0e698b8b115f064134a',
          },
          {
            squad: '6166c8dee65c4ef2043ec14d',
            order: 19,
            _id: '6186e0e698b8b115f064134b',
          },
          {
            squad: '6166c8dfe65c4ef2043ec238',
            order: 20,
            _id: '6186e0e698b8b115f064134c',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec4834',
      opta_ID: 'dsbrf3m5r9ni3oaj4yy84olck',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f59b',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-14Z',
      description: 'Manchester United vs Leeds United',
      home_team: '6166c3dce65c4ef20437fe10',
      officials: [
        {
          official: '6166cd63e65c4ef204466bc7',
          type: 'Main',
          _id: '6186e0e698b8b115f0641335',
        },
        {
          official: '6166cd62e65c4ef204466aa9',
          type: 'Lineman 1',
          _id: '6186e0e698b8b115f0641336',
        },
        {
          official: '6166cd5ce65c4ef20446643e',
          type: 'Lineman 2',
          _id: '6186e0e698b8b115f0641337',
        },
        {
          official: '6166cd61e65c4ef2044669b0',
          type: 'Fourth official',
          _id: '6186e0e698b8b115f0641338',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c361e65c4ef204374fbe',
      stage: 'Regular Season',
      time: '11:30:00Z',
      week: '1',
    },
    {
      opta_lineup: {
        home: [
          {
            squad: '6166c87ae65c4ef2043dc8da',
            order: 1,
            _id: '6186e0e798b8b115f064137a',
          },
          {
            squad: '6166c87ce65c4ef2043dcb82',
            order: 4,
            _id: '6186e0e798b8b115f064137b',
          },
          {
            squad: '6166c87ae65c4ef2043dc8f9',
            order: 5,
            _id: '6186e0e798b8b115f064137c',
          },
          {
            squad: '6166c87ae65c4ef2043dc866',
            order: 6,
            _id: '6186e0e798b8b115f064137d',
          },
          {
            squad: '6166c87ae65c4ef2043dc7ef',
            order: 3,
            _id: '6186e0e798b8b115f064137e',
          },
          {
            squad: '6166c87be65c4ef2043dc9d9',
            order: 8,
            _id: '6186e0e798b8b115f064137f',
          },
          {
            squad: '6166c87be65c4ef2043dc992',
            order: 11,
            _id: '6186e0e798b8b115f0641380',
          },
          {
            squad: '6166c87ae65c4ef2043dc87f',
            order: 7,
            _id: '6186e0e798b8b115f0641381',
          },
          {
            squad: '6166c87ce65c4ef2043dcc01',
            order: 2,
            _id: '6186e0e798b8b115f0641382',
          },
          {
            squad: '6166c87ce65c4ef2043dca9f',
            order: 9,
            _id: '6186e0e798b8b115f0641383',
          },
          {
            squad: '6166c87de65c4ef2043dcc50',
            order: 10,
            _id: '6186e0e798b8b115f0641384',
          },
          {
            squad: '6166c87be65c4ef2043dca32',
            order: 12,
            _id: '6186e0e798b8b115f0641385',
          },
          {
            squad: '6166c87ce65c4ef2043dcbbe',
            order: 13,
            _id: '6186e0e798b8b115f0641386',
          },
          {
            squad: '6166c87ce65c4ef2043dcb3c',
            order: 14,
            _id: '6186e0e798b8b115f0641387',
          },
          {
            squad: '6166c87ce65c4ef2043dcc1c',
            order: 15,
            _id: '6186e0e798b8b115f0641388',
          },
          {
            squad: '6166c87ae65c4ef2043dc847',
            order: 16,
            _id: '6186e0e798b8b115f0641389',
          },
          {
            squad: '6166c87ae65c4ef2043dc912',
            order: 17,
            _id: '6186e0e798b8b115f064138a',
          },
          {
            squad: '6166c87ae65c4ef2043dc80e',
            order: 18,
            _id: '6186e0e798b8b115f064138b',
          },
          {
            squad: '6166c87ae65c4ef2043dc92f',
            order: 19,
            _id: '6186e0e798b8b115f064138c',
          },
          {
            squad: '6166c87ce65c4ef2043dcb66',
            order: 20,
            _id: '6186e0e798b8b115f064138d',
          },
        ],
        away: [
          {
            squad: '6166c85ae65c4ef2043d997c',
            order: 1,
            _id: '6186e0e798b8b115f0641366',
          },
          {
            squad: '6166c85be65c4ef2043d9bbf',
            order: 3,
            _id: '6186e0e798b8b115f0641367',
          },
          {
            squad: '6166c85de65c4ef2043d9df8',
            order: 6,
            _id: '6186e0e798b8b115f0641368',
          },
          {
            squad: '6166c85ce65c4ef2043d9cf1',
            order: 5,
            _id: '6186e0e798b8b115f0641369',
          },
          {
            squad: '6166c859e65c4ef2043d9917',
            order: 2,
            _id: '6186e0e798b8b115f064136a',
          },
          {
            squad: '6166c85be65c4ef2043d9b3b',
            order: 4,
            _id: '6186e0e798b8b115f064136b',
          },
          {
            squad: '6166c85ae65c4ef2043d99c3',
            order: 8,
            _id: '6186e0e798b8b115f064136c',
          },
          {
            squad: '6166c85be65c4ef2043d9b9d',
            order: 11,
            _id: '6186e0e798b8b115f064136d',
          },
          {
            squad: '6166c85ae65c4ef2043d9a64',
            order: 10,
            _id: '6186e0e798b8b115f064136e',
          },
          {
            squad: '6166c85ae65c4ef2043d9a46',
            order: 7,
            _id: '6186e0e798b8b115f064136f',
          },
          {
            squad: '6166c85de65c4ef2043d9da9',
            order: 9,
            _id: '6186e0e798b8b115f0641370',
          },
          {
            squad: '6166c85de65c4ef2043d9e7a',
            order: 12,
            _id: '6186e0e798b8b115f0641371',
          },
          {
            squad: '6166c85ae65c4ef2043d99fe',
            order: 13,
            _id: '6186e0e798b8b115f0641372',
          },
          {
            squad: '6166c85ae65c4ef2043d9a1f',
            order: 14,
            _id: '6186e0e798b8b115f0641373',
          },
          {
            squad: '6166c85ce65c4ef2043d9d75',
            order: 15,
            _id: '6186e0e798b8b115f0641374',
          },
          {
            squad: '6166c85be65c4ef2043d9ad3',
            order: 16,
            _id: '6186e0e798b8b115f0641375',
          },
          {
            squad: '6166c85ce65c4ef2043d9d5a',
            order: 17,
            _id: '6186e0e798b8b115f0641376',
          },
          {
            squad: '6166c85ce65c4ef2043d9cd2',
            order: 18,
            _id: '6186e0e798b8b115f0641377',
          },
          {
            squad: '6166c85be65c4ef2043d9bf8',
            order: 19,
            _id: '6186e0e798b8b115f0641378',
          },
          {
            squad: '6166c85ce65c4ef2043d9d1b',
            order: 20,
            _id: '6186e0e798b8b115f0641379',
          },
        ],
      },
      lineup: { home: [], away: [] },
      home_team_kit: 'home',
      away_team_kit: 'home',
      home_scorers: [],
      away_scorers: [],
      live_data: { periods: [{ number: 1, kick_off: null }] },
      _id: '6186e0e5a4737fae26ec4840',
      opta_ID: 'drnl1etmyzjz088y0bb4kh4pg',
      user: null,
      __v: 0,
      away_team: '6166c3dbe65c4ef20437f8fd',
      competition: '6166b93be65c4ef2042bebc1',
      date: '2021-08-13Z',
      description: 'Brentford vs Arsenal',
      home_team: '6166c3dde65c4ef204380264',
      officials: [
        {
          official: '6166cd63e65c4ef204466b26',
          type: 'Main',
          _id: '6186e0e798b8b115f0641362',
        },
        {
          official: '6166cd63e65c4ef204466b32',
          type: 'Lineman 1',
          _id: '6186e0e798b8b115f0641363',
        },
        {
          official: '6166cd63e65c4ef204466b48',
          type: 'Lineman 2',
          _id: '6186e0e798b8b115f0641364',
        },
        {
          official: '6166cd61e65c4ef2044668cd',
          type: 'Fourth official',
          _id: '6186e0e798b8b115f0641365',
        },
      ],
      season: '6166ba57e65c4ef2042d4af1',
      stadium: '6166c362e65c4ef204375229',
      stage: 'Regular Season',
      time: '19:00:00Z',
      week: '1',
    },
  ],
};

export default data;
