import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  Button {
    border-radius: 0;
    border-right: 1px solid transparent;
  }
  Button:last-of-type {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 1px solid #c0c0c0;
  }
  Button:nth-of-type(1) {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Label = styled.label`
  display: flex;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const Buttons = styled.div`
  display: flex;
`;
export default function ButtonGroup({ items, label, onChange, value }) {
  const [selected, setSelected] = useState(value);

  return (
    <Main>
      {label && <Label>{label}</Label>}
      <Buttons>
        {items.map((item) => {
          return (
            <Button
              label={item.label}
              primary={selected === item.value}
              onClick={() => {
                setSelected(item.value);
                onChange(item.value);
              }}
            />
          );
        })}
      </Buttons>
    </Main>
  );
}
