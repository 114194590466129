import { useQuery } from '@apollo/client';
import React from 'react';
import { PROJECTS, SCENES_IN_PROJECT } from '../../../api/GraphQL';

export default function Projects({ user }) {
  const { loading, data } = useQuery(PROJECTS, {
    variables: { userId: user._id },
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Project name</th>
          <th># of scenes</th>
        </tr>
      </thead>
      {data?.projectsByUser.map((project) => {
        return <ProjectRow project={project} />;
      })}
    </table>
  );
}

function ProjectRow({ project }) {
  const { loading, data } = useQuery(SCENES_IN_PROJECT, {
    variables: { project_id: project._id },
  });
  return (
    <tr>
      <td>{project.name}</td>
      <td>{data?.scenesInProject?.length}</td>
    </tr>
  );
}
