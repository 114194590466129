import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../ui/form/Button';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import data from '../../../OPTADATA/OPTA_DATA.js';
import projectData from '../../../OPTADATA/ProjectData.js';
const Main = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  padding: 15px;
  flex-direction: column;
`;

export default function OPTA({ user }) {
  function importOPTAData(d) {
    let data = JSON.stringify(d);

    fetch('http://localhost:4001/api/import/' + user._id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: data,
    }).then((res) => {
      console.log(res);
    });
  }

  function importProject(d) {
    let data = JSON.stringify(d);

    fetch('http://localhost:4000/api/import/project/' + user._id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: data,
    }).then((res) => {
      console.log(res);
    });
  }
  return (
    <Main>
      <Button
        label="Import OPTA Data"
        onClick={() => {
          importOPTAData(data);
        }}
      />
      <Button
        label="Import Project"
        onClick={() => {
          importProject(projectData);
        }}
      />
    </Main>
  );
}
