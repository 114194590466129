import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ADD_APIKEY, APIKEYS_BY_USER } from '../../api/GraphQL';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';

function CreateAPI({ user ,update}) {
  const history = useHistory();
  const [addAPIKey, { data, loading, error }] = useMutation(ADD_APIKEY, {
    onCompleted: () => {
      update();
      history.goBack();
    },
  });
  const [name, setName] = useState('');
  const [key, setKey] = useState(uuidv4());

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  return (
    <Row>
      <Panel title={'Create API Key'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_name"
                label={'Name'}
                placeholder={'Name'}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Column>
            <Column size="4">
              <Input
                value={key}
                disabled="true"
                id="new_key"
                label={'Key'}
                placeholder={'Key'}
                type="text"
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Create New API Key'}
                onClick={() => {
                  addAPIKey({
                    variables: {
                      name,
                      key,
                      user: user._id,
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Row>
  );
}

export default CreateAPI;
