import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  padding-bottom: 15px;
  padding: 11px 10px;
  height: 47px;
  border: none;
  align-items: center;
  border-bottom: ${(props) => (props.active ? '1px solid #007bff' : '')};
  color: ${(props) => (props.active ? '#007bff' : '')};
  cursor: pointer;
  :hover {
    color: ${(props) => (props.active ? '#007bff' : '#b8d1eb')};
    border-bottom: ${(props) =>
      props.active ? '1px solid #007bff' : '1px solid #b8d1eb'};
  }
`;
export default function NavigationBarItem({ label, active, onClick }) {
  return (
    <Main active={active} onClick={onClick}>
      {label}
    </Main>
  );
}
